import { App, Flex, Form } from "antd";
import { useCallback, useState } from "react";
import { useMutation } from "urql";
import { useAtomValue, useSetAtom } from "jotai";
import {
  clearCreatePRStatusOverrideWizardFormDataAtom,
  createPRStatusOverrideWizardFormDataAtom,
  updateCreatePRStatusOverrideWizardFormDataAtom,
} from "./states/create.pr.status.override.wizard.form.data";
import { useNavigate } from "react-router-dom";
import { createStatusOverrideMutation } from "../../../../../../../providers/graphql/mutations/createStatusOverride.mutation";
import { ErrorView } from "../../../../../../../components/ErrorView/ErrorView";
import { NewPRStatusOverrideWizardButtons } from "./components/NewPRStatusOverrideWizardButtons/NewPRStatusOverrideWizardButtons";
import { NewPRStatusOverrideWizardSteps } from "./components/NewPRStatusOverrideWizardSteps/NewPRStatusOverrideWizardSteps";
import { NewPRStatusOverrideWizardContent } from "./components/NewPRStatusOverrideWizardContent/NewPRStatusOverrideWizardContent";

export type NewPRStatusOverrideWizardFormFields = {
  repositoryName?: string;
  pullRequestId?: string;
  statusChecks?: string[];
};

export const NewPRStatusOverrideWizardSection = (): JSX.Element => {
  const [{ fetching, error }, mutation] = useMutation(
    createStatusOverrideMutation()
  );

  const createPRStatusOverrideWizardFormData = useAtomValue(
    createPRStatusOverrideWizardFormDataAtom
  );

  const updateCreatePRStatusOverrideWizardFormData = useSetAtom(
    updateCreatePRStatusOverrideWizardFormDataAtom
  );

  const clearCreatePRStatusOverrideWizardFormData = useSetAtom(
    clearCreatePRStatusOverrideWizardFormDataAtom
  );

  const { message } = App.useApp();

  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState<number>(0);

  const lastStep = 1;

  const [form] = Form.useForm<NewPRStatusOverrideWizardFormFields>();

  const validateFormFields = async () => {
    try {
      await form.validateFields({ validateOnly: true });
      return true;
    } catch (error) {
      return false;
    }
  };

  const onPrevious = () => {
    setCurrentStep(Math.max(currentStep - 1, 0));
  };

  const onSubmit = async (): Promise<void> => {
    if (fetching) return;

    const submittable = await validateFormFields();

    if (!submittable) return;

    if (currentStep !== lastStep) {
      setCurrentStep(Math.min(currentStep + 1, lastStep));
      return;
    }

    return onCreatePRStatusOverride();
  };

  const onCreatePRStatusOverride = useCallback(async (): Promise<void> => {
    const data = createPRStatusOverrideWizardFormData;

    const { error } = await mutation({
      createStatusOverrideInput: {
        ...(data ?? {}),
      },
    });

    if (error) {
      return;
    }

    clearCreatePRStatusOverrideWizardFormData();

    message.info(`PR status override created successfully!`);

    navigate("/dashboard/more/pr-status-overrides");
  }, [createPRStatusOverrideWizardFormData]);

  return (
    <ErrorView error={error} onRefetch={onCreatePRStatusOverride}>
      <Form
        disabled={fetching}
        form={form}
        size="large"
        layout="vertical"
        onFinish={onSubmit}
        onFinishFailed={(error) => {
          if (!error.errorFields.length) {
            onSubmit();
          }
        }}
        onValuesChange={(_, values) =>
          updateCreatePRStatusOverrideWizardFormData(values)
        }
        scrollToFirstError
      >
        <Flex
          vertical
          gap="middle"
          style={{ width: "100%", height: "100%", padding: 35 }}
        >
          <NewPRStatusOverrideWizardSteps
            fetching={fetching}
            error={error}
            currentStep={currentStep}
          />
          <NewPRStatusOverrideWizardContent currentStep={currentStep} />
          <NewPRStatusOverrideWizardButtons
            lastStep={lastStep}
            currentStep={currentStep}
            onPrevious={onPrevious}
          />
        </Flex>
      </Form>
    </ErrorView>
  );
};
