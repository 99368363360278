import { Flex } from "antd";
import { NewPRStatusOverrideWizardSection } from "./components/NewPRStatusOverrideWizardSection/NewPRStatusOverrideWizardSection";
import { NewPRStatusOverrideHeaderSection } from "./components/NewPRStatusOverrideHeaderSection/NewPRStatusOverrideHeaderSection";

const NewPRStatusOverridePage = (): JSX.Element => {
  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <NewPRStatusOverrideHeaderSection />
      <NewPRStatusOverrideWizardSection />
    </Flex>
  );
};

export default NewPRStatusOverridePage;
