import { Form, Input, Typography } from "antd";

export const ReleaseDetailsFormSection = (): JSX.Element => {
  return (
    <>
      <Form.Item>
        <Typography.Title
          level={3}
          style={{ display: "block", textAlign: "center" }}
        >
          Release Details
        </Typography.Title>
        <Typography.Text
          type="secondary"
          style={{ display: "block", textAlign: "center" }}
        >
          Enter the details of the release
        </Typography.Text>
      </Form.Item>
      <Form.Item
        name="releaseName"
        label="Release name"
        rules={[
          {
            required: true,
            message: "Please input the release name!",
          },
        ]}
      >
        <Input size="large" type="text" />
      </Form.Item>
      <Form.Item
        name="releaseNotes"
        label="Release Notes"
        rules={[
          {
            required: true,
            message: "Please input the release notes!",
          },
        ]}
      >
        <Input.TextArea rows={7} />
      </Form.Item>
    </>
  );
};
