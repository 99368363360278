import { UserOutlined } from "@ant-design/icons";
import { Avatar, Empty, Flex, List } from "antd";
import { CSSProperties } from "react";
import { UserJSON } from "../../types/user/user.type";

export type UsersViewerProps = {
  style?: CSSProperties;
  users: UserJSON[];
};

export const UsersViewer = ({
  style,
  users,
}: UsersViewerProps): JSX.Element => {
  return (
    <List
      style={{
        ...style,
        overflowY: "auto",
      }}
      locale={{
        emptyText: (
          <Flex
            justify="center"
            align="center"
            style={{
              height: style?.height,
            }}
          >
            <Empty description="No users" />
          </Flex>
        ),
      }}
      dataSource={users}
      renderItem={(item, index) => (
        <List.Item key={index}>
          <List.Item.Meta
            avatar={
              <Avatar size={70} src={item.photo} icon={<UserOutlined />} />
            }
            title={item.fullName}
            description={item.email}
          />
        </List.Item>
      )}
    />
  );
};
