import { App, Button, Dropdown, MenuProps, Modal } from "antd";
import { useMutation } from "urql";
import { useError } from "../../../../hooks/useError";
import { ReleaseRepository } from "../../../../types/release/release.type";
import { deleteReleaseRepositoryMutation } from "../../../../providers/graphql/mutations/deleteReleaseRepository.mutation";
import { ReleaseRepositoryStatus } from "../../../../types/release/release.enum";
import { UpsertReleaseRepositoryForm } from "../../../UpsertReleaseRepositoryForm/UpsertReleaseRepositoryForm";
import { approveReleaseRepositoryMutation } from "../../../../providers/graphql/mutations/approveReleaseRepository.mutation";
import { useUserContext } from "../../../../context/UserContext";
import { Nullable } from "../../../../utils/nullable/nullable.type";
import { Role } from "../../../../types/role/role.enum";
import { reDeployReleaseRepositoryMutation } from "../../../../providers/graphql/mutations/reDeployReleaseRepository.mutation";
import { useRepositoriesContext } from "../../../../context/RepositoriesContext";

export type ReleaseRepositoryMoreButtonProps = {
  releaseRepository: ReleaseRepository;
  releaseName: string;
  onRefetch: () => void;
};

export const ReleaseRepositoryMoreButton = ({
  releaseRepository,
  releaseName,
  onRefetch,
}: ReleaseRepositoryMoreButtonProps): Nullable<JSX.Element> => {
  const { repositoryMapping } = useRepositoriesContext();

  const { modal, notification, message } = App.useApp();

  const { errorNotificationMessage } = useError();

  const { user } = useUserContext();

  const roles = user?.relations?.roles ?? [];

  const [{ fetching: isDeletingReleaseRepository }, deleteReleaseRepository] =
    useMutation(deleteReleaseRepositoryMutation());

  const [{ fetching: isApprovingReleaseRepository }, approveReleaseRepository] =
    useMutation(approveReleaseRepositoryMutation());

  const [
    { fetching: isRedeployingReleaseRepository },
    reDeployReleaseRepository,
  ] = useMutation(reDeployReleaseRepositoryMutation());

  const fetching =
    isApprovingReleaseRepository ||
    isDeletingReleaseRepository ||
    isRedeployingReleaseRepository;

  const hasDeploymentSettings = !!(repositoryMapping ?? {})[
    releaseRepository.repositoryAlias
  ]?.deploySettings;

  const items = ((): MenuProps["items"] => {
    const items: MenuProps["items"] = [];

    if (!roles.includes(Role.UPDATE_RELEASES)) return items;

    if (
      releaseRepository.status ===
      ReleaseRepositoryStatus.APPROVED_FOR_DEPLOYMENT
    ) {
      items.push({
        key: "redeploy-release-repository-from-build",
        label: "Redeploy repository from build",
      });

      if (hasDeploymentSettings) {
        items.push({
          key: "redeploy-release-repository-skipping-build",
          label: "Redeploy repository skipping build",
        });
      }

      return items;
    } else {
      items.push({
        key: "update-release-repository",
        label: "Update version & ref of repository",
      });
    }

    if (releaseRepository.status === ReleaseRepositoryStatus.PENDING_APPROVAL) {
      items.push({
        key: "approve-release-repository",
        label: "Approve & deploy repository",
      });
    }

    items.push({
      key: "delete-release-repository",
      label: "Remove repository from release",
    });

    return items;
  })();

  const onMenuItemSelected = ({ key }: { key: string }): void => {
    switch (key) {
      case "redeploy-release-repository-from-build":
        modal.confirm({
          icon: null,
          title: "Redeploy Repository From Build",
          content: `Are you sure you want to redeploy ${releaseRepository.repositoryAlias} repository from build in release ${releaseName}?`,
          okButtonProps: {
            style: {
              backgroundColor: "purple",
            },
          },
          okText: "Redeploy Repository From Build",
          onOk: async (): Promise<void> => {
            const { error } = await reDeployReleaseRepository({
              reDeployReleaseRepositoryInput: {
                releaseName,
                repositoryAlias: releaseRepository.repositoryAlias,
                country: releaseRepository.country,
                deployOnly: false,
              },
            });

            if (error) {
              notification.error(errorNotificationMessage(error));
              return;
            }

            message.success("Repository will be redeployed from build shortly");

            onRefetch();

            Modal.destroyAll();
          },
        });
        break;
      case "redeploy-release-repository-skipping-build":
        modal.confirm({
          icon: null,
          title: "Redeploy Repository Skipping Build",
          content: `Are you sure you want to redeploy ${releaseRepository.repositoryAlias} repository skipping build in release ${releaseName}?`,
          okButtonProps: {
            style: {
              backgroundColor: "purple",
            },
          },

          okText: "Redeploy Repository Skipping Build",
          onOk: async (): Promise<void> => {
            const { error } = await reDeployReleaseRepository({
              reDeployReleaseRepositoryInput: {
                releaseName,
                repositoryAlias: releaseRepository.repositoryAlias,
                country: releaseRepository.country,
                deployOnly: true,
              },
            });

            if (error) {
              notification.error(errorNotificationMessage(error));
              return;
            }

            message.success(
              "Repository will be redeployed skipping build shortly"
            );

            onRefetch();

            Modal.destroyAll();
          },
        });
        break;
      case "approve-release-repository":
        modal.confirm({
          icon: null,
          title: "Approve & Deploy Repository",
          content: `Are you sure you want to approve and deploy ${releaseRepository.repositoryAlias} repository in release ${releaseName}?`,
          okButtonProps: {
            style: {
              backgroundColor: "green",
            },
          },
          okText: "Approve & Deploy Repository",
          onOk: async (): Promise<void> => {
            const { error } = await approveReleaseRepository({
              approveReleaseRepositoryInput: {
                releaseName: releaseName,
                repositoryAlias: releaseRepository.repositoryAlias,
                country: releaseRepository.country,
              },
            });

            if (error) {
              notification.error(errorNotificationMessage(error));
              return;
            }

            message.success("Repository approved successfully");

            onRefetch();

            Modal.destroyAll();
          },
        });
        break;
      case "update-release-repository":
        modal.info({
          icon: null,
          width: "75%",
          title: "Update Repository",
          content: (
            <UpsertReleaseRepositoryForm
              releaseName={releaseName}
              onRefetch={onRefetch}
              releaseRepository={releaseRepository}
            />
          ),
          okButtonProps: {
            type: "default",
          },
          okText: "Cancel",
        });
        break;
      case "delete-release-repository":
        modal.confirm({
          icon: null,
          title: "Remove Repository from Release",
          content: `Are you sure you want to remove ${releaseRepository.repositoryAlias} repository from release ${releaseName}?`,
          okButtonProps: {
            danger: true,
          },
          okText: "Remove Repository",
          onOk: async (): Promise<void> => {
            const { error } = await deleteReleaseRepository({
              deleteReleaseRepositoryInput: {
                releaseName: releaseName,
                repositoryAlias: releaseRepository.repositoryAlias,
                country: releaseRepository.country,
              },
            });

            if (error) {
              notification.error(errorNotificationMessage(error));
              return;
            }

            message.success("Repository removed from release successfully");

            onRefetch();

            Modal.destroyAll();
          },
        });
        break;
      default:
        break;
    }
  };

  if (!items?.length) {
    return null;
  }

  return (
    <Dropdown
      menu={{
        items,
        onClick: onMenuItemSelected,
        disabled: fetching,
        style: { width: 200 },
      }}
      placement="bottom"
      arrow
    >
      <Button loading={fetching} type="primary">
        More
      </Button>
    </Dropdown>
  );
};
