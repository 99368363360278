import { Alert, App, Flex, Form } from "antd";
import { NewDeploymentWizardSteps } from "./components/NewDeploymentWizardSteps/NewDeploymentWizardSteps";
import { useCallback, useState } from "react";
import { NewDeploymentWizardButtons } from "./components/NewDeploymentWizardButtons/NewDeploymentWizardButtons";
import { NewDeploymentWizardContent } from "./components/NewDeploymentWizardContent/NewDeploymentWizardContent";
import {
  DeploymentJSON,
  DeploymentOwners,
  DeploymentRepository,
  DeploymentReviewers,
} from "../../../../../../types/deployment/deployment.type";
import { Country } from "../../../../../../types/country/country.enum";
import { Nullable } from "../../../../../../utils/nullable/nullable.type";
import { createDeploymentMutation } from "../../../../../../providers/graphql/mutations/createDeployment.mutation";
import { useMutation } from "urql";
import { ErrorView } from "../../../../../../components/ErrorView/ErrorView";
import { useAtomValue, useSetAtom } from "jotai";
import {
  clearCreateDeploymentWizardFormDataAtom,
  createDeploymentWizardFormDataAtom,
  updateCreateDeploymentWizardFormDataAtom,
} from "./states/create.deployment.wizard.form.data";
import { useNavigate } from "react-router-dom";
import { DeploymentFeature } from "../../../../../../types/deployment/deployment.enum";
import { EntityType } from "./type/entity.enum";
import { useRepositoriesContext } from "../../../../../../context/RepositoriesContext";
import { RepositoryJSON } from "../../../../../../types/repository/repository.type";
import { RepositoryCharacteristic } from "../../../../../../types/repository/repository.enum";

export type NewDeploymentWizardFormFields = {
  deploymentName?: string;
  deploymentDescription?: string;
  repositories?: Array<
    Partial<
      Pick<DeploymentRepository, "repositoryAlias" | "ref" | "features"> & {
        countries: Nullable<Country[]>;
        cronJobs?: string[];
      }
    >
  >;
  environmentId?: string;
  workflowId?: string;
  features?: DeploymentFeature[];
  reviewers?: Array<{
    entityType?: EntityType;
    email?: string;
    teamName?: string;
  }>;
  owners?: Array<{
    entityType?: EntityType;
    email?: string;
    teamName?: string;
  }>;
};

export const NewDeploymentWizardSection = (): JSX.Element => {
  const { repositories, repositoryMapping } = useRepositoriesContext();

  const [{ fetching, error }, mutation] = useMutation<{
    createDeployment: DeploymentJSON;
  }>(createDeploymentMutation());

  const createDeploymentWizardFormData = useAtomValue(
    createDeploymentWizardFormDataAtom
  );

  const updateCreateDeploymentWizardFormData = useSetAtom(
    updateCreateDeploymentWizardFormDataAtom
  );

  const clearCreateDeploymentWizardFormData = useSetAtom(
    clearCreateDeploymentWizardFormDataAtom
  );

  const { message } = App.useApp();

  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState<number>(0);

  const lastStep = 3;

  const [form] = Form.useForm<NewDeploymentWizardFormFields>();

  const validateFormFields = async () => {
    try {
      await form.validateFields({ validateOnly: true });
      return true;
    } catch (error) {
      return false;
    }
  };

  const onPrevious = () => {
    setCurrentStep(Math.max(currentStep - 1, 0));
  };

  const onSubmit = async (): Promise<void> => {
    if (fetching) return;

    const submittable = await validateFormFields();

    if (!submittable) return;

    if (currentStep !== lastStep) {
      setCurrentStep(Math.min(currentStep + 1, lastStep));
      return;
    }

    return onCreateDeployment();
  };

  const getRepositoryFromRepositoryAlias = (
    repositoryAlias?: string
  ): Nullable<RepositoryJSON> => {
    return (repositoryMapping ?? {})[repositoryAlias ?? ""] ?? null;
  };

  const onCreateDeployment = useCallback(async (): Promise<void> => {
    const data = createDeploymentWizardFormData;

    const { error, ...rest } = await mutation({
      createDeploymentInput: {
        ...(data ?? {}),
        workflowId: data?.workflowId ?? null,
        environmentId: data?.environmentId ?? null,
        features: data?.features ?? [],
        reviewers: data?.reviewers?.reduce(
          (previous, current) => {
            if (current.email) {
              previous.emails.push(current.email);
            }

            if (current.teamName) {
              previous.teamNames.push(current.teamName);
            }

            return previous;
          },
          {
            emails: [],
            teamNames: [],
          } as DeploymentReviewers
        ) ?? {
          emails: [],
          teamNames: [],
        },
        owners: data?.owners?.reduce(
          (previous, current) => {
            if (current.email) {
              previous.emails.push(current.email);
            }

            if (current.teamName) {
              previous.teamNames.push(current.teamName);
            }

            return previous;
          },
          {
            emails: [],
            teamNames: [],
          } as DeploymentOwners
        ) ?? {
          emails: [],
          teamNames: [],
        },
        repositories: data?.repositories?.map((repository) => {
          const repositoryFromAlias = getRepositoryFromRepositoryAlias(
            repository.repositoryAlias
          );

          return {
            ...repository,
            countries: repositoryFromAlias?.countryOptions.enabledForDeployments
              ? repository.countries ?? null
              : null,
            features: repository.features ?? [],
            cronJobs: repositoryFromAlias?.characteristics.some(
              (characteristic) =>
                characteristic === RepositoryCharacteristic.CAN_DEPLOY_CRON_JOBS
            )
              ? repository.cronJobs?.map((cronJob) => {
                  return {
                    name: cronJob,
                    suspended: false,
                  };
                }) ?? null
              : null,
          };
        }),
      },
    });

    if (error) {
      return;
    }

    clearCreateDeploymentWizardFormData();

    message.info(`Deployment ${data.deploymentName} created successfully!`);

    navigate(
      `/dashboard/deployments/${rest.data?.createDeployment?.deploymentId}`
    );
  }, [createDeploymentWizardFormData]);

  if (!repositories || !repositoryMapping) {
    return (
      <Alert
        message="No repositories found. Please check if you have permissions to view repositories"
        type="error"
      />
    );
  }

  return (
    <ErrorView error={error} onRefetch={onCreateDeployment}>
      <Form
        disabled={fetching}
        form={form}
        size="large"
        layout="vertical"
        onFinish={onSubmit}
        onFinishFailed={(error) => {
          if (!error.errorFields.length) {
            onSubmit();
          }
        }}
        onValuesChange={(_, values) =>
          updateCreateDeploymentWizardFormData(values)
        }
        scrollToFirstError
      >
        <Flex
          vertical
          gap="middle"
          style={{ width: "100%", height: "100%", padding: 35 }}
        >
          <NewDeploymentWizardSteps
            fetching={fetching}
            error={error}
            currentStep={currentStep}
          />
          <NewDeploymentWizardContent currentStep={currentStep} />
          <NewDeploymentWizardButtons
            lastStep={lastStep}
            currentStep={currentStep}
            onPrevious={onPrevious}
          />
        </Flex>
      </Form>
    </ErrorView>
  );
};
