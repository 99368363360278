import { Button, Flex } from "antd";
import { Link } from "react-router-dom";

export type CommitLinkProps = {
  repositoryName: string;
  commit: string;
  extra?: React.ReactNode;
};

export const CommitLink = ({
  repositoryName,
  commit,
  extra,
}: CommitLinkProps): JSX.Element => {
  return (
    <Flex vertical gap="small" justify="center" align="center">
      <Link
        to={`https://github.com/soum-sa/${repositoryName}/commit/${commit}`}
        target="_blank"
      >
        <Button type="link">{commit}</Button>
      </Link>
      {extra}
    </Flex>
  );
};
