import { App, Button, Flex, Modal, StepProps, Steps } from "antd";
import { DeploymentJSON } from "../../../../../../../../../../types/deployment/deployment.type";
import { DeploymentStatus } from "../../../../../../../../../../types/deployment/deployment.enum";
import { useMutation } from "urql";
import { submitDeploymentForReviewMutation } from "../../../../../../../../../../providers/graphql/mutations/submitDeploymentForReview.mutation";
import { setDeploymentAsReviewedMutation } from "../../../../../../../../../../providers/graphql/mutations/setDeploymentAsReviewed.mutation";
import { useError } from "../../../../../../../../../../hooks/useError";
import { revertDeploymentToInProgressMutation } from "../../../../../../../../../../providers/graphql/mutations/revertDeploymentToInProgress.mutation";

export type DeploymentProgressSectionProps = {
  deployment?: Partial<DeploymentJSON>;
  isOwner: boolean;
  onRefetch: () => void;
};

export const DeploymentProgressSection = ({
  deployment,
  isOwner,
  onRefetch,
}: DeploymentProgressSectionProps): JSX.Element => {
  const status = deployment?.status;

  const { modal, notification, message } = App.useApp();

  const { errorNotificationMessage } = useError();

  const [
    { fetching: isSubmittingDeploymentForReview },
    submitDeploymentForReview,
  ] = useMutation(submitDeploymentForReviewMutation());

  const [{ fetching: isSettingDeploymentAsReviewed }, setDeploymentAsReviewed] =
    useMutation(setDeploymentAsReviewedMutation());

  const [
    { fetching: isRevertingDeploymentToInProgress },
    revertDeploymentToInProgress,
  ] = useMutation(revertDeploymentToInProgressMutation());

  const fetching =
    isSubmittingDeploymentForReview ||
    isSettingDeploymentAsReviewed ||
    isRevertingDeploymentToInProgress;

  const statusStepIndexMapping: Record<DeploymentStatus, number> = {
    [DeploymentStatus.IN_PROGRESS]: 0,
    [DeploymentStatus.IN_REVIEW]: 1,
    [DeploymentStatus.REVIEWED]: 2,
    [DeploymentStatus.FAILED_REVIEW]: 1,
  };

  const statusStepStatusMapping: Record<DeploymentStatus, StepProps["status"]> =
    {
      [DeploymentStatus.IN_PROGRESS]: "process",
      [DeploymentStatus.IN_REVIEW]: "wait",
      [DeploymentStatus.REVIEWED]: "finish",
      [DeploymentStatus.FAILED_REVIEW]: "error",
    };

  const onRevertToInProgress = () => {
    modal.confirm({
      icon: null,
      title: "Revert To In Progress",
      content:
        "Are you sure you want to revert this deployment to in progress ?",
      okText: "Revert",
      okButtonProps: {
        style: { backgroundColor: "purple" },
      },
      onOk: async () => {
        const { error } = await revertDeploymentToInProgress({
          revertDeploymentToInProgressInput: {
            deploymentId: deployment?.deploymentId,
          },
        });

        if (error) {
          notification.error(errorNotificationMessage(error));
          return;
        }

        message.success("Deployment has been reverted to in progress");

        onRefetch();

        Modal.destroyAll();
      },
    });
  };

  const onSubmitForReview = () => {
    modal.confirm({
      icon: null,
      title: "Submit For Review",
      content: "Are you sure you want to submit this deployment for review ?",
      okText: "Submit",
      okButtonProps: {
        style: { backgroundColor: "purple" },
      },
      onOk: async () => {
        const { error } = await submitDeploymentForReview({
          submitDeploymentForReviewInput: {
            deploymentId: deployment?.deploymentId,
          },
        });

        if (error) {
          notification.error(errorNotificationMessage(error));
          return;
        }

        message.success("Deployment has been submitted for review");

        onRefetch();

        Modal.destroyAll();
      },
    });
  };

  const onSetAsReviewed = (accepted: boolean) => {
    modal.confirm({
      icon: null,
      title: accepted ? "Mark As Accepted" : "Mark As Rejected",
      content: accepted
        ? "Are you sure you want to mark this deployment as accepted ?"
        : "Are you sure you want to mark this deployment as rejected ?",
      okText: accepted ? "Accept" : "Reject",
      okButtonProps: {
        style: { backgroundColor: accepted ? "green" : "red" },
      },
      onOk: async () => {
        const { error } = await setDeploymentAsReviewed({
          setDeploymentAsReviewedInput: {
            deploymentId: deployment?.deploymentId,
            accepted,
          },
        });

        if (error) {
          notification.error(errorNotificationMessage(error));
          return;
        }

        message.success(
          accepted
            ? "Deployment has been marked as accepted. You can now merge al prs related to the deployment"
            : "Deployment has been marked as rejected"
        );

        onRefetch();

        Modal.destroyAll();
      },
    });
  };

  return (
    <Flex vertical gap="middle">
      {status && (
        <Steps
          current={statusStepIndexMapping[status]}
          status={statusStepStatusMapping[status]}
          items={[
            {
              title: "In Progress",
            },
            {
              title: "Pending Review",
              description:
                status === DeploymentStatus.FAILED_REVIEW
                  ? "Failed review. Please resubmit for reviewing again"
                  : undefined,
            },
            {
              title: "Reviewed",
            },
          ]}
        />
      )}
      {status !== DeploymentStatus.REVIEWED && (
        <Flex justify="flex-end" gap="middle">
          {(status === DeploymentStatus.IN_PROGRESS ||
            status === DeploymentStatus.FAILED_REVIEW) && (
            <Button
              loading={fetching}
              type="primary"
              style={{ backgroundColor: "purple" }}
              onClick={onSubmitForReview}
            >
              Submit For Review
            </Button>
          )}
          {status === DeploymentStatus.IN_REVIEW && (
            <>
              {isOwner && (
                <Button
                  loading={fetching}
                  type="primary"
                  style={{ backgroundColor: "purple" }}
                  onClick={onRevertToInProgress}
                >
                  Revert Back To In Progress (Only For Owners)
                </Button>
              )}
              <Button
                loading={fetching}
                type="primary"
                style={{ backgroundColor: "red" }}
                onClick={onSetAsReviewed.bind(this, false)}
              >
                Mark As Reject
              </Button>
              <Button
                loading={fetching}
                type="primary"
                style={{ backgroundColor: "green" }}
                onClick={onSetAsReviewed.bind(this, true)}
              >
                Mark As Accept
              </Button>
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
};
