import { ReactNode, createContext, useContext } from "react";
import { Nullable } from "../utils/nullable/nullable.type";
import { RepositoryJSON } from "../types/repository/repository.type";

export const RepositoriesContext = createContext<{
  repositoryMapping?: Nullable<
    Record<RepositoryJSON["repositoryName"], RepositoryJSON>
  >;
  repositories?: Nullable<RepositoryJSON[]>;
}>({
  repositories: null,
  repositoryMapping: null,
});

export const useRepositoriesContext = (): {
  repositoryMapping?: Nullable<
    Record<RepositoryJSON["repositoryName"], RepositoryJSON>
  >;
  repositories?: Nullable<RepositoryJSON[]>;
} => useContext(RepositoriesContext);

export type RepositoriesContextProviderProps = {
  children: ReactNode;
  repositories?: Nullable<RepositoryJSON[]>;
};

export const RepositoriesContextProvider = ({
  children,
  repositories,
}: RepositoriesContextProviderProps): JSX.Element => {
  return (
    <RepositoriesContext.Provider
      value={{
        repositories: repositories,
        repositoryMapping: repositories
          ? repositories.reduce((previous, current) => {
              return { ...previous, [current.repositoryAlias]: current };
            }, {})
          : null,
      }}
    >
      {children}
    </RepositoriesContext.Provider>
  );
};
