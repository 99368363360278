import { Breadcrumb, Flex, Typography } from "antd";
import { Link, useParams } from "react-router-dom";

export const UpdateEnvironmentHeaderSection = (): JSX.Element => {
  const { environmentId } = useParams<{ environmentId: string }>();

  return (
    <Flex justify="space-between" align="center">
      <Flex vertical>
        <Typography.Title level={2}>
          Update Environment: {environmentId}
        </Typography.Title>
        <Breadcrumb
          items={[
            {
              title: <Link to="/dashboard">Dashboard</Link>,
            },
            {
              title: <Link to="/dashboard/environments">Environments</Link>,
            },
            {
              title: environmentId,
            },
          ]}
        />
      </Flex>
    </Flex>
  );
};
