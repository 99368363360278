import { Button } from "antd";
import { Link } from "react-router-dom";
import { RunJSON } from "../../types/run/run.type";
import { ReactNode } from "react";
import { RunPlatform } from "../../types/run.platform/run.platform.enum";

export type RunIdLinkProps = {
  run: RunJSON;
  component?: ReactNode;
};

export const RunIdLink = ({
  run,
  component = <Button type="link">{run.runId}</Button>,
}: RunIdLinkProps): JSX.Element => {
  const link =
    run.runPlatform === RunPlatform.BITRISE
      ? `https://app.bitrise.io/build/${run.runId}`
      : `https://github.com/soum-sa/${run.repositoryName}/actions/runs/${run.runId}`;

  return (
    <Link to={link} target="_blank">
      {component}
    </Link>
  );
};
