import { Card, Flex, Form, Spin, Typography } from "antd";
import { GoogleLogin } from "@react-oauth/google";
import Logo from "../../../../assets/logo.png";
import { useMutation } from "urql";
import { loginMutation } from "../../../../providers/graphql/mutations/login.mutation";
import { ErrorView } from "../../../../components/ErrorView/ErrorView";
import { useSearchParams } from "react-router-dom";

export const LoginForm = (): JSX.Element => {
  const [searchParams] = useSearchParams();

  const [{ fetching, error }, mutation] = useMutation(loginMutation());

  const onLogin = async (token?: string): Promise<void> => {
    if (!token) return;

    const { error } = await mutation({
      loginInput: {
        token,
      },
    });

    if (error) {
      return;
    }

    window.location.replace(searchParams.get("to") ?? "/dashboard/deployments");
  };

  return (
    <ErrorView disableRetryButton error={error}>
      <Card style={{ padding: 35 }}>
        <Form size="large" disabled={fetching}>
          <Form.Item>
            <Typography.Title level={1} style={{ textAlign: "center" }}>
              Soum Web Portal
            </Typography.Title>
          </Form.Item>
          <Form.Item>
            <img
              src={Logo}
              alt="logo"
              style={{ height: 100, display: "block", margin: "auto" }}
            />
          </Form.Item>
          <Form.Item>
            <Typography.Text style={{ display: "block", textAlign: "center" }}>
              Login to the Soum Web Portal
            </Typography.Text>
          </Form.Item>
          <Form.Item>
            <Flex justify="center">
              <GoogleLogin
                size="large"
                width="100%"
                onSuccess={(credentialResponse): void => {
                  onLogin(credentialResponse?.credential ?? "");
                }}
              />
            </Flex>
          </Form.Item>
        </Form>
      </Card>
      {fetching && <Spin fullscreen />}
    </ErrorView>
  );
};
