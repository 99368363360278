import { query } from "gql-query-builder";
import { TypedDocumentNode, gql } from "urql";

export const getGitFileQuery = (): TypedDocumentNode => {
  return gql(
    query({
      operation: "gitFile",
      variables: {
        gitFileInput: {
          type: "GitFileInput",
          required: true,
        },
      },
    }).query
  );
};
