import { useQuery } from "urql";
import { ReleaseJSON } from "../types/release/release.type";
import { useEffect } from "react";
import { getReleaseQuery } from "../providers/graphql/queries/getRelease.query";
import IQueryBuilderOptions from "gql-query-builder/build/IQueryBuilderOptions";

export const useRelease = (
  fields: IQueryBuilderOptions["fields"],
  releaseName?: string,
  refetchInterval: number = 30000
) => {
  const [{ data, error, fetching }, refetch] = useQuery<{
    release: ReleaseJSON;
  }>({
    query: getReleaseQuery({
      fields,
    }),
    variables: {
      releaseInput: {
        releaseName,
      },
    },
    requestPolicy: "network-only",
    pause: !releaseName,
  });

  const release = data?.release;

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  useEffect(() => {
    if (!refetchInterval) return;

    const interval = setInterval(() => {
      onRefetch();
    }, refetchInterval);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return {
    release,
    error,
    fetching,
    onRefetch,
  };
};
