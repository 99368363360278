import { App, Button, Dropdown, MenuProps, Modal } from "antd";
import { useError } from "../../../../../../../hooks/useError";
import { useMutation } from "urql";
import { useUserContext } from "../../../../../../../context/UserContext";
import { Nullable } from "../../../../../../../utils/nullable/nullable.type";
import { Role } from "../../../../../../../types/role/role.enum";
import { DeploymentJSON } from "../../../../../../../types/deployment/deployment.type";
import { deleteDeploymentMutation } from "../../../../../../../providers/graphql/mutations/deleteDeployment.mutation";
import { useNavigate } from "react-router-dom";
import { DeploymentPurpose } from "../../../../../../../types/deployment/deployment.enum";
import { CloneDeploymentForm } from "../../../../../../../components/CloneDeploymentForm/CloneDeploymentForm";

export type DeploymentsMoreButtonProps = {
  deployment: DeploymentJSON;
  onRefetch: () => void;
};

export const DeploymentsMoreButton = ({
  deployment,
  onRefetch,
}: DeploymentsMoreButtonProps): Nullable<JSX.Element> => {
  const { modal, notification, message } = App.useApp();

  const { errorNotificationMessage } = useError();

  const { user } = useUserContext();

  const navigate = useNavigate();

  const roles = user?.relations?.roles ?? [];

  const [{ fetching }, deleteDeployment] = useMutation(
    deleteDeploymentMutation()
  );

  const items = ((): MenuProps["items"] => {
    const items: MenuProps["items"] = [];

    items.push({
      key: "view-deployment",
      label: "View deployment",
    });

    if (deployment.purpose === DeploymentPurpose.DEVELOPMENT) {
      items.push({
        key: "clone-deployment",
        label: "Clone deployment",
      });
    }

    if (roles.includes(Role.DELETE_ENVIRONMENTS)) {
      items.push({
        key: "delete-deployment",
        label: "Delete deployment",
      });
    }

    return items;
  })();

  const onMenuItemSelected = ({ key }: { key: string }): void => {
    switch (key) {
      case "view-deployment":
        navigate(`/dashboard/deployments/${deployment.deploymentId}`);
        break;
      case "clone-deployment":
        modal.info({
          icon: null,
          width: "75%",
          title: `Clone Deployment: ${deployment.deploymentName}`,
          content: (
            <CloneDeploymentForm
              deployment={deployment}
              onRefetch={onRefetch}
            />
          ),
          okButtonProps: {
            type: "default",
          },
          okText: "Cancel",
        });
        break;
      case "delete-deployment":
        modal.confirm({
          icon: null,
          title: "Delete Deployment",
          content: `Are you sure you want to delete deployment: ${deployment.deploymentId}?`,
          okButtonProps: {
            danger: true,
          },
          okText: "Delete Deployment",
          onOk: async (): Promise<void> => {
            const { error } = await deleteDeployment({
              deleteDeploymentInput: {
                deploymentId: deployment.deploymentId,
              },
            });

            if (error) {
              notification.error(errorNotificationMessage(error));
              return;
            }

            message.success("Deployment deleted successfully");

            onRefetch();

            Modal.destroyAll();
          },
        });
        break;
      default:
        break;
    }
  };

  if (!items?.length) {
    return null;
  }

  return (
    <Dropdown
      menu={{
        items,
        onClick: onMenuItemSelected,
        disabled: fetching,
        style: { width: 200 },
      }}
      placement="bottom"
      arrow
    >
      <Button loading={fetching} type="primary">
        More
      </Button>
    </Dropdown>
  );
};
