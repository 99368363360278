import { useRelease } from "../../../../../../../hooks/useRelease";
import { ReleaseRepositoriesTable } from "../../../../../../../components/ReleaseRepositoriesTable/ReleaseRepositoriesTable";
import { Flex, Typography } from "antd";
import { SearchBar } from "../../../../../../../components/SearchBar/SearchBar";
import { useState } from "react";

export type ReleaseRepositoriesViewProps = {
  releaseName?: string;
};

export const ReleaseRepositoriesView = ({
  releaseName,
}: ReleaseRepositoriesViewProps): JSX.Element => {
  const [search, setSearch] = useState<string>();

  const fields = [
    "id",
    "releaseName",
    "isReadOnly",
    {
      repositories: [
        "id",
        "repositoryName",
        "repositoryAlias",
        "ref",
        "commit",
        "version",
        "status",
        "country",
        "error",
        "existing",
        "buildTrackId",
        "deployTrackId",
      ],
      relations: [
        {
          latestBuildRuns: [
            "runId",
            "repositoryName",
            "runPlatform",
            "status",
            "tertiaryId",
          ],
          latestDeploymentRuns: [
            "runId",
            "repositoryName",
            "runPlatform",
            "status",
            "tertiaryId",
          ],
        },
      ],
    },
  ];

  const {
    release: releaseWithRepositories,
    error: releaseWithRepositoriesError,
    onRefetch: onRefetchReleaseWithRepositories,
  } = useRelease(fields, releaseName);

  return (
    <ReleaseRepositoriesTable
      style={{ padding: 15 }}
      release={releaseWithRepositories}
      search={search}
      error={releaseWithRepositoriesError}
      onRefetch={onRefetchReleaseWithRepositories}
      title={
        <Flex justify="space-between" align="center">
          <Typography.Title level={3}>Repositories</Typography.Title>
          <Flex gap="middle">
            <SearchBar
              style={{ height: 40 }}
              setSearch={setSearch}
              placeholder="Search repositories ..."
            />
          </Flex>
        </Flex>
      }
    />
  );
};
