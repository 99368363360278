import { Alert, App, Flex, Form } from "antd";
import { NewReleaseWizardSteps } from "./components/NewReleaseWizardSteps/NewReleaseWizardSteps";
import { useCallback, useState } from "react";
import { NewReleaseWizardButtons } from "./components/NewReleaseWizardButtons/NewReleaseWizardButtons";
import { NewReleaseWizardContent } from "./components/NewReleaseWizardContent/NewReleaseWizardContent";
import { ReleaseRepository } from "../../../../../../types/release/release.type";
import { Country } from "../../../../../../types/country/country.enum";
import { Nullable } from "../../../../../../utils/nullable/nullable.type";
import { createReleaseMutation } from "../../../../../../providers/graphql/mutations/createRelease.mutation";
import { useMutation } from "urql";
import { ErrorView } from "../../../../../../components/ErrorView/ErrorView";
import { useAtomValue, useSetAtom } from "jotai";
import {
  clearCreateReleaseWizardFormDataAtom,
  createReleaseWizardFormDataAtom,
  updateCreateReleaseWizardFormDataAtom,
} from "./states/create.release.wizard.form.data";
import { useNavigate } from "react-router-dom";
import { useRepositoriesContext } from "../../../../../../context/RepositoriesContext";

export type NewReleaseWizardFormFields = {
  releaseName?: string;
  releaseDescription?: string;
  repositories?: Array<
    Partial<
      Pick<ReleaseRepository, "repositoryAlias" | "ref" | "version"> & {
        countries: Nullable<Country[]>;
      }
    >
  >;
};

export const NewReleaseWizardSection = (): JSX.Element => {
  const { repositories, repositoryMapping } = useRepositoriesContext();

  const [{ fetching, error }, mutation] = useMutation(createReleaseMutation());

  const createReleaseWizardFormData = useAtomValue(
    createReleaseWizardFormDataAtom
  );

  const updateCreateReleaseWizardFormData = useSetAtom(
    updateCreateReleaseWizardFormDataAtom
  );

  const clearCreateReleaseWizardFormData = useSetAtom(
    clearCreateReleaseWizardFormDataAtom
  );

  const { message } = App.useApp();

  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState<number>(0);

  const lastStep = 1;

  const [form] = Form.useForm<NewReleaseWizardFormFields>();

  const validateFormFields = async () => {
    try {
      await form.validateFields({ validateOnly: true });
      return true;
    } catch (error) {
      return false;
    }
  };

  const onPrevious = () => {
    setCurrentStep(Math.max(currentStep - 1, 0));
  };

  const onSubmit = async (): Promise<void> => {
    if (fetching) return;

    const submittable = await validateFormFields();

    if (!submittable) return;

    if (currentStep !== lastStep) {
      setCurrentStep(Math.min(currentStep + 1, lastStep));
      return;
    }

    return onCreateRelease();
  };

  const onCreateRelease = useCallback(async (): Promise<void> => {
    const data = createReleaseWizardFormData;

    const { error } = await mutation({
      createReleaseInput: {
        ...(data ?? {}),
        repositories: data?.repositories?.map((repository) => ({
          ...repository,
          countries: (repositoryMapping ?? {})[repository.repositoryAlias ?? ""]
            .countryOptions.enabledForReleases
            ? repository.countries ?? null
            : null,
        })),
      },
    });

    if (error) {
      return;
    }

    clearCreateReleaseWizardFormData();

    message.info(`Release ${data.releaseName} created successfully!`);

    navigate(`/dashboard/releases/${encodeURI(data.releaseName!)}`);
  }, [createReleaseWizardFormData]);

  if (!repositories || !repositoryMapping) {
    return (
      <Alert
        message="No repositories found. Please check if you have permissions to view repositories"
        type="error"
      />
    );
  }

  return (
    <ErrorView error={error} onRefetch={onCreateRelease}>
      <Form
        disabled={fetching}
        form={form}
        size="large"
        layout="vertical"
        onFinish={onSubmit}
        onFinishFailed={(error) => {
          if (!error.errorFields.length) {
            onSubmit();
          }
        }}
        onValuesChange={(_, values) =>
          updateCreateReleaseWizardFormData(values)
        }
      >
        <Flex
          vertical
          gap="middle"
          style={{ width: "100%", height: "100%", padding: 35 }}
        >
          <NewReleaseWizardSteps
            fetching={fetching}
            error={error}
            currentStep={currentStep}
          />
          <NewReleaseWizardContent currentStep={currentStep} />
          <NewReleaseWizardButtons
            lastStep={lastStep}
            currentStep={currentStep}
            onPrevious={onPrevious}
          />
        </Flex>
      </Form>
    </ErrorView>
  );
};
