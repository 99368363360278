import { App, Button, Tag } from "antd";
import { usePagination } from "../../../../../hooks/usePagination";
import { useQuery } from "urql";
import { PaginationResponse } from "../../../../../types/pagination/pagination.type";
import { EnvironmentJSON } from "../../../../../types/environment/environment.type";
import { ErrorView } from "../../../../../components/ErrorView/ErrorView";
import { TableView } from "../../../../../components/TableView/TableView";
import { ReactNode } from "react";
import { TextFilterInput } from "../../../../../components/TextFilterInput/TextFilterInput";
import { getFilterValueUtility } from "../../../../../utils/filter/filter.utility";
import { getPaginateEnvironmentsQuery } from "../../../../../providers/graphql/queries/getPaginateEnvironments.query";
import {
  booleanEnumColorMapping,
  environmentAccountColorMapping,
  environmentPurposeColorMapping,
  environmentSyncModeColorMapping,
  mapEnumValueToKey,
  runStatusColorMapping,
} from "../../../../../utils/enum/enum";
import {
  EnvironmentAccount,
  EnvironmentPurpose,
  EnvironmentSyncMode,
} from "../../../../../types/environment/environment.enum";
import { BooleanEnum } from "../../../../../types/boolean/boolean.enum";
import moment from "moment";
import { UsersViewer } from "../../../../../components/UsersViewer/UsersViewer";
import { TeamsViewer } from "../../../../../components/TeamsViewer/TeamsViewer";
import { EnvironmentHibernationOptionsView } from "./components/EnvironmentHibernationOptionsView/EnvironmentHibernationOptionsView";
import { EnvironmentsMoreButton } from "../../../../../components/EnvironmentsMoreButton/EnvironmentsMoreButton";
import { RunIdLink } from "../../../../../components/RunIdLink/RunIdLink";
import { RunStatus } from "../../../../../types/run/run.enum";
import { DeploymentLink } from "../../../../../components/DeploymentLink/DeploymentLink";

export const EnvironmentsTableSection = (): JSX.Element => {
  const { paginationRequest } = usePagination({
    filterNames: [
      "environmentIds",
      "teamNames",
      "emails",
      "accounts",
      "purposes",
      "hibernating",
      "expired",
      "syncModes",
    ],
  });

  const { modal } = App.useApp();

  const { search, limit, skip, sortField, sortOrder, filters } =
    paginationRequest;

  const [{ data, error, fetching }, refetch] = useQuery<{
    environments: PaginationResponse<EnvironmentJSON>;
  }>({
    query: getPaginateEnvironmentsQuery({
      fields: [
        "total",
        {
          data: [
            "id",
            "environmentId",
            "environmentName",
            "teamNames",
            "emails",
            "account",
            "syncMode",
            "purpose",
            "hibernating",
            "suspendHibernationUntil",
            "hibernationOptions",
            "expired",
            "suspendExpirationUntil",
            {
              relations: [
                {
                  users: ["id", "fullName", "email"],
                  teams: [
                    "id",
                    "teamName",
                    "emails",
                    {
                      relations: [
                        {
                          users: ["id", "fullName", "email"],
                        },
                      ],
                    },
                  ],
                  deployment: ["id", "deploymentId", "deploymentName"],
                  latestSync: [
                    "runId",
                    "repositoryName",
                    "runPlatform",
                    "status",
                    "tertiaryId",
                  ],
                },
              ],
            },
          ],
        },
      ],
    }),
    variables: {
      environmentPaginationInput: {
        search,
        limit,
        skip,
        sortField,
        sortOrder,
        filters: {
          ...(filters ? { ...filters } : {}),
        },
      },
    },
    requestPolicy: "network-only",
  });

  const environments = data?.environments.data ?? [],
    total = data?.environments.total ?? 0;

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  const onShowUsers = (environment: EnvironmentJSON): void => {
    modal.info({
      icon: null,
      title: `Users Who Can Access Environment: ${environment.environmentId}`,
      width: "75%",
      content: (
        <UsersViewer
          style={{ height: "65vh" }}
          users={environment.relations?.users ?? []}
        />
      ),
      okText: "Cancel",
      okButtonProps: {
        type: "default",
      },
    });
  };

  const onShowTeams = (environment: EnvironmentJSON): void => {
    modal.info({
      icon: null,
      title: `Teams That Can Access Environment: ${environment.environmentId}`,
      width: "75%",
      content: (
        <TeamsViewer
          style={{ height: "65vh" }}
          teams={environment.relations?.teams ?? []}
        />
      ),
      okText: "Cancel",
      okButtonProps: {
        type: "default",
      },
    });
  };

  const onShowHibernationOptions = (environment: EnvironmentJSON): void => {
    modal.info({
      icon: null,
      title: `Hibernation Options For Environment: ${environment.environmentId}`,
      width: "75%",
      content: (
        <EnvironmentHibernationOptionsView
          style={{ height: "65vh" }}
          environment={environment}
        />
      ),
      okText: "Cancel",
      okButtonProps: {
        type: "default",
      },
    });
  };

  return (
    <ErrorView error={error} onRefetch={onRefetch}>
      <TableView
        rowKey="environmentId"
        loading={fetching}
        sortableColumns={["environmentName"]}
        filterableColumnMapping={{
          environmentId: "environmentIds",
          teamNames: "teamNames",
          emails: "emails",
          accounts: "accounts",
          purposes: "purposes",
          hibernating: "hibernating",
          expired: "expired",
          syncMode: "syncModes",
        }}
        dataSource={environments}
        total={total}
        columns={[
          {
            key: "syncStatus",
            title: "Sync Status",
            align: "center",
            width: 175,
            render: (_value: string, record: EnvironmentJSON): ReactNode => {
              const run = record.relations?.latestSync;

              if (record.account === EnvironmentAccount.PRODUCTION) {
                return "-";
              }

              if (!run) {
                return <span style={{ color: "red" }}>No Sync</span>;
              }

              return (
                <RunIdLink
                  run={run}
                  component={
                    <Tag color={runStatusColorMapping[run.status]}>
                      {mapEnumValueToKey(RunStatus, run.status)}
                    </Tag>
                  }
                />
              );
            },
          },
          {
            key: "environmentId",
            dataIndex: "environmentId",
            title: "Environment ID",
            align: "center",
            width: 200,
            fixed: "left",
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "environmentIds",
            }),
            filterDropdown: TextFilterInput,
          },
          {
            key: "environmentName",
            dataIndex: "environmentName",
            title: "Environment Name",
            align: "center",
            width: 250,
          },
          {
            key: "deployment",
            title: "Linked Deployment",
            align: "center",
            width: 300,
            render: (_value: string, record: EnvironmentJSON): ReactNode => {
              return (
                <DeploymentLink
                  deployment={record.relations?.deployment}
                  emptyView={
                    <span style={{ color: "green" }}>Free Environment</span>
                  }
                />
              );
            },
          },
          {
            key: "emails",
            dataIndex: "emails",
            title: "Users",
            align: "center",
            width: 200,
            render: (_value: undefined, record: EnvironmentJSON): ReactNode => {
              return (
                <Button type="link" onClick={onShowUsers.bind(this, record)}>
                  {record.emails.length ?? 0} users
                </Button>
              );
            },
          },
          {
            key: "teamNames",
            dataIndex: "teamNames",
            title: "Teams",
            align: "center",
            width: 200,
            render: (_value: undefined, record: EnvironmentJSON): ReactNode => {
              return (
                <Button type="link" onClick={onShowTeams.bind(this, record)}>
                  {record.teamNames.length ?? 0} teams
                </Button>
              );
            },
          },
          {
            key: "account",
            dataIndex: "account",
            title: "Account",
            align: "center",
            width: 200,
            defaultFilteredValue: [],
            filterResetToDefaultFilteredValue: false,
            filterMultiple: true,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "accounts",
            }),
            filters: Object.values(EnvironmentAccount).map((value) => {
              return {
                text: mapEnumValueToKey(EnvironmentAccount, value),
                value,
              };
            }),
            render: (value: string, _record: EnvironmentJSON): ReactNode => {
              return (
                <Tag
                  color={
                    environmentAccountColorMapping[value as EnvironmentAccount]
                  }
                >
                  {mapEnumValueToKey(EnvironmentAccount, value)}
                </Tag>
              );
            },
          },
          {
            key: "purpose",
            dataIndex: "purpose",
            title: "Purpose",
            align: "center",
            width: 200,
            defaultFilteredValue: [],
            filterResetToDefaultFilteredValue: false,
            filterMultiple: true,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "purposes",
            }),
            filters: Object.values(EnvironmentPurpose).map((value) => {
              return {
                text: mapEnumValueToKey(EnvironmentPurpose, value),
                value,
              };
            }),
            render: (value: string, _record: EnvironmentJSON): ReactNode => {
              return (
                <Tag
                  color={
                    environmentPurposeColorMapping[value as EnvironmentPurpose]
                  }
                >
                  {mapEnumValueToKey(EnvironmentPurpose, value)}
                </Tag>
              );
            },
          },
          {
            key: "syncMode",
            dataIndex: "syncMode",
            title: "Sync Mode",
            align: "center",
            width: 200,
            defaultFilteredValue: [],
            filterResetToDefaultFilteredValue: false,
            filterMultiple: true,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "syncModes",
            }),
            filters: Object.values(EnvironmentSyncMode).map((value) => {
              return {
                text: mapEnumValueToKey(EnvironmentSyncMode, value),
                value,
              };
            }),
            render: (value: string, _record: EnvironmentJSON): ReactNode => {
              if (!value) return "-";

              return (
                <Tag
                  color={
                    environmentSyncModeColorMapping[
                      value as EnvironmentSyncMode
                    ]
                  }
                >
                  {mapEnumValueToKey(EnvironmentSyncMode, value)}
                </Tag>
              );
            },
          },
          {
            key: "hibernating",
            dataIndex: "hibernating",
            title: "Hibernating",
            align: "center",
            width: 200,
            defaultFilteredValue: [],
            filterResetToDefaultFilteredValue: false,
            filterMultiple: true,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "hibernating",
            }),
            filters: Object.values(BooleanEnum).map((value) => {
              return {
                text: mapEnumValueToKey(BooleanEnum, value),
                value,
              };
            }),
            render: (value: string, _record: EnvironmentJSON): ReactNode => {
              return (
                <Tag
                  color={
                    booleanEnumColorMapping[value.toString() as BooleanEnum]
                  }
                >
                  {mapEnumValueToKey(BooleanEnum, value.toString())}
                </Tag>
              );
            },
          },
          {
            key: "suspendHibernationUntil",
            dataIndex: "suspendHibernationUntil",
            title: "Suspend Hibernation Until",
            align: "center",
            width: 250,
            render: (value: string, _record: EnvironmentJSON): ReactNode => {
              if (!value) {
                return "-";
              }

              return moment(new Date(value)).format("LLL");
            },
          },
          {
            key: "hibernationOptions",
            title: "Hibernation Options",
            align: "center",
            width: 250,
            render: (_value: undefined, record: EnvironmentJSON): ReactNode => {
              if (!record.hibernationOptions) {
                return "-";
              }

              return (
                <Button
                  type="link"
                  onClick={onShowHibernationOptions.bind(this, record)}
                >
                  View Hibernation Options
                </Button>
              );
            },
          },
          {
            key: "expired",
            dataIndex: "expired",
            title: "Expired",
            align: "center",
            width: 200,
            defaultFilteredValue: [],
            filterResetToDefaultFilteredValue: false,
            filterMultiple: true,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "expired",
            }),
            filters: Object.values(BooleanEnum).map((value) => {
              return {
                text: mapEnumValueToKey(BooleanEnum, value),
                value,
              };
            }),
            render: (value: string, _record: EnvironmentJSON): ReactNode => {
              return (
                <Tag
                  color={
                    booleanEnumColorMapping[value.toString() as BooleanEnum]
                  }
                >
                  {mapEnumValueToKey(BooleanEnum, value.toString())}
                </Tag>
              );
            },
          },
          {
            key: "suspendExpirationUntil",
            dataIndex: "suspendExpirationUntil",
            title: "Suspend Expiration Until",
            align: "center",
            width: 250,
            render: (value: string, _record: EnvironmentJSON): ReactNode => {
              if (!value) {
                return "-";
              }

              return moment(new Date(value)).format("LLL");
            },
          },
          {
            key: "more",
            align: "center",
            width: 100,
            fixed: "right",
            render: (_value: undefined, record: EnvironmentJSON): ReactNode => {
              return (
                <EnvironmentsMoreButton
                  environment={record}
                  onRefetch={onRefetch}
                />
              );
            },
          },
        ]}
      />
    </ErrorView>
  );
};
