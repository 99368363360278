import { TypedDocumentNode, gql } from "urql";
import { query } from "gql-query-builder";

export const vpnClientConfigurationQuery = (): TypedDocumentNode => {
  return gql(
    query({
      operation: "vpnClientConfiguration",
    }).query
  );
};
