import { Button } from "antd";
import { Link } from "react-router-dom";
import { DeploymentJSON } from "../../types/deployment/deployment.type";
import { Nullable } from "../../utils/nullable/nullable.type";

export type DeploymentLinkLinkProps = {
  deployment?: DeploymentJSON;
  emptyView: Nullable<JSX.Element>;
};

export const DeploymentLink = ({
  deployment,
  emptyView,
}: DeploymentLinkLinkProps): Nullable<JSX.Element> => {
  if (!deployment) {
    return emptyView;
  }

  return (
    <Link
      to={`/dashboard/deployments/${deployment.deploymentId}`}
      target="_blank"
    >
      <Button type="link">{deployment.deploymentName}</Button>
    </Link>
  );
};
