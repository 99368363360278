import { Flex, NotificationArgsProps } from "antd";
import { AxiosError } from "axios";
import { CombinedError } from "urql";

export type ErrorExtension = {
  [key: string]: unknown;
  name?: string;
  stacktrace?: string[];
  extensions?: ErrorExtension;
  validations?: Array<{
    message: string;
    field: string;
    meta?: Record<string, unknown>;
    rule: string;
  }>;
  error?: ResponseError;
};

export type AxiosResponseError = Array<{
  name?: string;
  message: string;
  stacktrace?: string[];
  extensions?: ErrorExtension;
}>;

export type GraphQlResponseError = ResponseError & {
  path: string[];
  locations: Array<{
    line: number;
    column: number;
  }>;
};

export type ResponseError = {
  name?: string;
  message: string;
  extensions?: ErrorExtension;
};

export type useErrorType = {
  parseError: (error?: Error | AxiosError | CombinedError | unknown) => {
    messages: string[];
    status: number;
  };
  errorNotificationMessage(error: unknown): NotificationArgsProps;
};

const parseResponseError = (
  messages: string[],
  error?: ResponseError
): void => {
  if (!error) return;

  messages.push(error.message);

  if (error.name) {
    messages.push(`SubError: ${error.name}`);
  }

  if (error.extensions) {
    const {
      name,
      stacktrace,
      validations,
      extensions,
      error: subError,
      ...rest
    } = error.extensions;

    if (name) {
      messages.push(`Error: ${name}`);
    }

    validations?.forEach((validation) => {
      messages.push(`• ${validation.message}`);
    });

    const obj = { ...rest, ...extensions };

    console.log(obj);

    obj.validations?.forEach((validation) => {
      messages.push(
        `• ${validation.message} ( ${JSON.stringify(validation.meta)} )`
      );
    });

    Object.keys(obj).forEach((key) => {
      if (["error", "stacktrace", "validations", "name"].includes(key)) return;

      const value = obj[key];

      messages.push(
        `${key}: ${
          typeof value === "object" ? JSON.stringify(value, null, 4) : value
        }`
      );
    });

    parseResponseError(messages, subError);

    parseResponseError(messages, extensions?.error);
  }
};

export const useError = (): useErrorType => {
  const errorNotificationMessage = (error: unknown): NotificationArgsProps => {
    const { status, messages } = parseError(error);

    return {
      message:
        status === 0
          ? "Oops! An Error Occurred"
          : `Oops! A ${status} Error Occurred`,
      type: "error",
      description: (
        <Flex vertical gap="middle" style={{ marginTop: 25 }}>
          {messages.map((message, index) => (
            <pre style={{ textWrap: "wrap" }} key={index}>
              {message}
            </pre>
          ))}
        </Flex>
      ),
      placement: "topRight",
    };
  };

  const parseError = (
    error: Error | AxiosError | CombinedError | unknown
  ): {
    messages: string[];
    status: number;
  } => {
    if (!error) return { messages: [], status: 0 };

    const messages: string[] = [];

    if (error instanceof CombinedError) {
      const { graphQLErrors, message } = error;

      if (!graphQLErrors) {
        messages.push(message);
      } else {
        (graphQLErrors as unknown as GraphQlResponseError[]).forEach(
          (error) => {
            parseResponseError(messages, error);
          }
        );
      }

      return {
        messages,
        status: 0,
      };
    }

    if (error instanceof AxiosError) {
      const { response, message } = error;

      const errors: AxiosResponseError = response?.data?.errors || [];

      errors.forEach((error) => {
        parseResponseError(messages, error);
      });

      if (!messages.length) {
        messages.push(message);
      }

      return {
        messages,
        status: response?.status ?? 0,
      };
    }

    const { message } = error as Error;

    messages.push(message);

    return {
      messages,
      status: 500,
    };
  };

  return {
    parseError,
    errorNotificationMessage,
  };
};
