import { ReactNode, createContext, useContext } from "react";
import { Nullable } from "../utils/nullable/nullable.type";
import { UserJSON } from "../types/user/user.type";

export const UserContext = createContext<{
  user?: Nullable<UserJSON>;
}>({
  user: null,
});

export const useUserContext = (): {
  user?: Nullable<UserJSON>;
} => useContext(UserContext);

export type UserContextProviderProps = {
  children: ReactNode;
  user?: Nullable<UserJSON>;
};

export const UserContextProvider = ({
  children,
  user,
}: UserContextProviderProps): JSX.Element => {
  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
};
