import { ChangeEvent } from "react";
import { Button, Divider, Flex, Input } from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";

export type TextFilterInputProps = FilterDropdownProps;

export const TextFilterInput = ({
  setSelectedKeys,
  confirm,
  selectedKeys,
}: TextFilterInputProps): JSX.Element => {
  const value = selectedKeys[0];

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedKeys([decodeURI(event.currentTarget.value)]);
  };

  const onReset = (): void => {
    setSelectedKeys([]);
    confirm();
  };

  const onConfirm = (): void => {
    setSelectedKeys([encodeURI(value.toString().trim())]);
    confirm();
  };

  const isResetDisabled = !value;

  return (
    <div style={{ padding: 10 }}>
      <Flex vertical gap="unset">
        <Input
          style={{ width: 250 }}
          value={decodeURI(value?.toString() ?? "")}
          onChange={onChange}
        />
        <Divider style={{ marginBottom: 5 }} />
        <Flex justify="space-between">
          <Button disabled={isResetDisabled} type="link" onClick={onReset}>
            Reset
          </Button>
          <Button type="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </Flex>
      </Flex>
    </div>
  );
};
