import { usePagination } from "../../../../../../hooks/usePagination";
import { useQuery } from "urql";
import { PaginationResponse } from "../../../../../../types/pagination/pagination.type";
import { ErrorView } from "../../../../../../components/ErrorView/ErrorView";
import { TableView } from "../../../../../../components/TableView/TableView";
import { TextFilterInput } from "../../../../../../components/TextFilterInput/TextFilterInput";
import { getFilterValueUtility } from "../../../../../../utils/filter/filter.utility";
import { getPaginateWorkflowsQuery } from "../../../../../../providers/graphql/queries/getPaginateWorkflows.query";
import { WorkflowJSON } from "../../../../../../types/workflow/workflow.type";

export const WorkflowsTableSection = (): JSX.Element => {
  const { paginationRequest } = usePagination({
    filterNames: ["workflowIds"],
  });

  const { search, limit, skip, sortField, sortOrder, filters } =
    paginationRequest;

  const [{ data, error, fetching }, refetch] = useQuery<{
    workflows: PaginationResponse<WorkflowJSON>;
  }>({
    query: getPaginateWorkflowsQuery({
      fields: [
        "total",
        {
          data: [
            "id",
            "workflowId",
            "workflowName",
            "matchBaseRef",
            "matchHeadRef",
          ],
        },
      ],
    }),
    variables: {
      workflowPaginationInput: {
        search,
        limit,
        skip,
        sortField,
        sortOrder,
        filters: {
          ...(filters ? { ...filters } : {}),
        },
      },
    },
    requestPolicy: "network-only",
  });

  const Workflows = data?.workflows.data ?? [],
    total = data?.workflows.total ?? 0;

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  return (
    <ErrorView error={error} onRefetch={onRefetch}>
      <TableView
        rowKey="workflowName"
        loading={fetching}
        sortableColumns={["workflowName"]}
        filterableColumnMapping={{
          workflowId: "workflowIds",
        }}
        dataSource={Workflows}
        total={total}
        columns={[
          {
            key: "workflowId",
            dataIndex: "workflowId",
            title: "Workflow ID",
            align: "center",
            width: 200,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "workflowIds",
            }),
            filterDropdown: TextFilterInput,
          },
          {
            key: "workflowName",
            dataIndex: "workflowName",
            title: "Workflow Name",
            align: "center",
            width: 300,
          },
          {
            key: "matchBaseRef",
            dataIndex: "matchBaseRef",
            title: "Match Base Ref",
            align: "center",
            width: 200,
          },
          {
            key: "matchHeadRef",
            dataIndex: "matchHeadRef",
            title: "Match Head Ref",
            align: "center",
            width: 200,
          },
        ]}
      />
    </ErrorView>
  );
};
