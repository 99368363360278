import { Button, Flex, Typography } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { ReactNode } from "react";

export type StepTitleProps = {
  step: number;
  currentStep: number;
  title: string;
  description: ReactNode;
  nextButtonText: string;
  onNextButtonClick: () => void;
  onPreviousButtonClick?: () => void;
};

export const StepTitle = ({
  step,
  currentStep,
  title,
  description,
  nextButtonText,
  onNextButtonClick,
  onPreviousButtonClick,
}: StepTitleProps): JSX.Element => {
  return (
    <Flex justify="space-between">
      <Flex vertical>
        <Typography.Text>
          Step {step + 1}: {title}
        </Typography.Text>
        <Typography.Text style={{ fontSize: "0.85em" }} type="secondary">
          {description}
        </Typography.Text>
      </Flex>
      <>
        {currentStep > step && (
          <CheckCircleOutlined style={{ color: "green", fontSize: "2em" }} />
        )}
        {currentStep === step && (
          <Flex gap="middle">
            {onPreviousButtonClick && (
              <Button size="large" onClick={onPreviousButtonClick}>
                Previous Step
              </Button>
            )}
            <Button size="large" type="primary" onClick={onNextButtonClick}>
              {nextButtonText}
            </Button>
          </Flex>
        )}
      </>
    </Flex>
  );
};
