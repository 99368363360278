import { useEffect, useMemo, useState } from "react";
import { ErrorView } from "../../../../../../../../components/ErrorView/ErrorView";
import { App, Button, Flex, Input } from "antd";
import { ReleaseJSON } from "../../../../../../../../types/release/release.type";
import { useMutation } from "urql";
import { updateReleaseNotesMutation } from "../../../../../../../../providers/graphql/mutations/updateReleaseNotes.mutation";
import { useError } from "../../../../../../../../hooks/useError";
import { useUserContext } from "../../../../../../../../context/UserContext";
import { Role } from "../../../../../../../../types/role/role.enum";

export type EditReleaseNotesViewProps = {
  release: Partial<ReleaseJSON>;
};

export const EditReleaseNotesView = ({
  release,
}: EditReleaseNotesViewProps): JSX.Element => {
  const currentReleasNotes = useMemo(
    () => release.releaseNotes ?? "",
    [release?.releaseNotes]
  );

  const [releaseNotes, setReleaseNotes] = useState<string>();

  useEffect(() => {
    setReleaseNotes(currentReleasNotes);
  }, [currentReleasNotes, setReleaseNotes]);

  const { user } = useUserContext();

  const roles = user?.relations?.roles ?? [];

  const { notification, message } = App.useApp();

  const { errorNotificationMessage } = useError();

  const [{ fetching, error }, updateReleaseNotes] = useMutation(
    updateReleaseNotesMutation()
  );

  const haveReleaseNotesChanged = releaseNotes !== release.releaseNotes;

  const onUpdateReleaseNotes = async (): Promise<void> => {
    const { error } = await updateReleaseNotes({
      updateReleaseNotesInput: {
        releaseNotes,
        releaseName: release.releaseName,
      },
    });

    if (error) {
      notification.error(errorNotificationMessage(error));
      return;
    }

    message.success("Release notes updated successfully");
  };

  return (
    <ErrorView error={error} onRefetch={onUpdateReleaseNotes}>
      <Flex vertical gap="middle">
        <Input.TextArea
          readOnly={release.isReadOnly}
          value={releaseNotes ?? currentReleasNotes}
          onChange={(event) => setReleaseNotes(event.currentTarget.value)}
          rows={5}
        />
        {roles.includes(Role.UPDATE_RELEASES) && (
          <Button
            disabled={fetching || !haveReleaseNotesChanged}
            type="primary"
            style={{ width: 250, margin: "auto" }}
            onClick={onUpdateReleaseNotes}
          >
            Edit Release Notes
          </Button>
        )}
      </Flex>
    </ErrorView>
  );
};
