import { NewReleaseWizardFormFields } from "../NewReleaseWizardSection";
import { atom } from "jotai";

export const createReleaseWizardFormDataAtom = atom<NewReleaseWizardFormFields>(
  {}
);

export const updateCreateReleaseWizardFormDataAtom = atom(
  null,
  (get, set, value: Partial<NewReleaseWizardFormFields>) => {
    const createReleaseWizardFormData = get(createReleaseWizardFormDataAtom);

    set(
      createReleaseWizardFormDataAtom,
      Object.assign(createReleaseWizardFormData ?? {}, value ?? {})
    );
  }
);

export const clearCreateReleaseWizardFormDataAtom = atom(null, (_, set) => {
  set(createReleaseWizardFormDataAtom, {});
});
