import { EnvironmentDetailsFormSection } from "./components/EnvironmentDetailsFormSection/EnvironmentDetailsFormSection";
import { HibernationOptionsSection } from "./components/HibernationOptionsSection/HibernationOptionsSection";
import { SelectTeamsSection } from "./components/SelectTeamsSection/SelectTeamsSection";
import { SelectUsersSection } from "./components/SelectUsersSection/SelectUsersSection";

export type UpsertEnvironmentWizardContentProps = {
  currentStep: number;
};

export const UpsertEnvironmentWizardContent = ({
  currentStep,
}: UpsertEnvironmentWizardContentProps): JSX.Element => {
  return (
    <div style={{ minHeight: "50vh", paddingLeft: 50, paddingRight: 50 }}>
      {currentStep === 0 && <EnvironmentDetailsFormSection />}
      {currentStep === 1 && <SelectTeamsSection />}
      {currentStep === 2 && <SelectUsersSection />}
      {currentStep === 3 && <HibernationOptionsSection />}
    </div>
  );
};
