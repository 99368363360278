import { useQuery } from "urql";
import { DeploymentJSON } from "../types/deployment/deployment.type";
import { useEffect } from "react";
import { getDeploymentQuery } from "../providers/graphql/queries/getDeployment.query";
import IQueryBuilderOptions from "gql-query-builder/build/IQueryBuilderOptions";

export const useDeployment = (
  fields: IQueryBuilderOptions["fields"],
  deploymentId?: string,
  refetchInterval: number = 30000
) => {
  const [{ data, error, fetching }, refetch] = useQuery<{
    deployment: DeploymentJSON;
  }>({
    query: getDeploymentQuery({ fields }),
    variables: {
      deploymentInput: {
        deploymentId,
      },
    },
    requestPolicy: "network-only",
    pause: !deploymentId,
  });

  const deployment = data?.deployment;

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  useEffect(() => {
    if (!refetchInterval) return;

    const interval = setInterval(() => {
      onRefetch();
    }, refetchInterval);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return {
    deployment,
    error,
    fetching,
    onRefetch,
  };
};
