import { App, Button, Form, Input, Modal, Select } from "antd";
import { ErrorView } from "../ErrorView/ErrorView";
import { useMutation } from "urql";
import { GitRefBranch } from "../../types/git/git.type";
import { createBranchMutation } from "../../providers/graphql/mutations/createBranch.mutation";

type CreateBranchFormFormFields = {
  ref?: string;
  fromRef?: string;
};

export type CreateBranchFormFormProps = {
  repositoryName: string;
  gitBranches: GitRefBranch[];
  defaultFromRef?: string;
  onRefetch?: () => void;
  onChange?: (value: string) => void;
};

export const CreateBranchFormForm = ({
  repositoryName,
  gitBranches,
  defaultFromRef,
  onRefetch,
  onChange,
}: CreateBranchFormFormProps): JSX.Element => {
  const { message } = App.useApp();

  const [{ fetching, error }, mutation] = useMutation<{
    createBranch?: GitRefBranch;
  }>(
    createBranchMutation({
      fields: ["id", "repositoryName", "ref"],
    })
  );

  const onSubmit = async (
    values: CreateBranchFormFormFields
  ): Promise<void> => {
    const { error, data } = await mutation({
      createBranchInput: {
        ...values,
        repositoryName,
      },
    });

    if (error) {
      return;
    }

    if (onChange) {
      onChange(data?.createBranch?.ref!);
    }

    if (onRefetch) {
      onRefetch();
    }

    message.info(`Branch created successfully!`);

    Modal.destroyAll();
  };

  return (
    <ErrorView disableRetryButton error={error}>
      <Form
        size="large"
        layout="vertical"
        disabled={fetching}
        onFinish={onSubmit}
        scrollToFirstError
      >
        <Form.Item
          name="ref"
          label="New Branch name"
          rules={[
            {
              required: true,
              message: "Please input the new branch name!",
            },
            {
              pattern: /^[a-zA-Z0-9-]+$/,
              message:
                "Branch name should only contain alphanumeric characters and hyphens",
            },
          ]}
        >
          <Input size="large" type="text" />
        </Form.Item>
        <Form.Item
          name="fromRef"
          label="Create From Branch"
          rules={[
            {
              required: true,
              message: "Please select the branch to create from",
            },
          ]}
          initialValue={defaultFromRef}
        >
          <Select>
            {gitBranches.map((branch) => (
              <Select.Option key={branch.ref} value={branch.ref}>
                {branch.ref}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button loading={fetching} block type="primary" htmlType="submit">
            Create Branch
          </Button>
        </Form.Item>
      </Form>
    </ErrorView>
  );
};
