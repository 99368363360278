import { CSSProperties } from "react";
import { Button, Flex, Select, Spin, Tooltip } from "antd";
import { ErrorView } from "../ErrorView/ErrorView";
import { useQuery } from "urql";
import { Nullable } from "../../utils/nullable/nullable.type";
import { IoIosRefresh } from "react-icons/io";
import { getDeploymentsThatTestCanRunOn } from "../../providers/graphql/queries/getDeploymentsThatTestCanRunOn.query";
import { DeploymentJSON } from "../../types/deployment/deployment.type";

export type SelectDeploymentForTestInputProps = {
  style?: CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
};

export const SelectDeploymentForTestInput = ({
  style,
  value,
  onChange,
}: SelectDeploymentForTestInputProps): Nullable<JSX.Element> => {
  const [{ data, error, fetching }, refetch] = useQuery<{
    getDeploymentsThatTestCanRunOn: DeploymentJSON[];
  }>({
    query: getDeploymentsThatTestCanRunOn({
      fields: ["id", "deploymentId", "deploymentName"],
    }),
    requestPolicy: "network-only",
  });

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  const userEnvironments = data?.getDeploymentsThatTestCanRunOn ?? [];

  return (
    <ErrorView error={error} onRefetch={onRefetch}>
      <Flex gap="middle">
        <Select
          value={value}
          onChange={onChange}
          showSearch
          style={style}
          dropdownRender={(menu) => {
            if (fetching) {
              return (
                <Flex justify="center">
                  <Spin />
                </Flex>
              );
            }

            return menu;
          }}
        >
          <Select.Option value={"null"}>New Deployment</Select.Option>
          {userEnvironments.map((value, index) => {
            return (
              <Select.Option key={index} value={value.deploymentId}>
                {value.deploymentName}
              </Select.Option>
            );
          })}
        </Select>
        <Tooltip title="Refresh">
          <Button
            loading={fetching}
            icon={<IoIosRefresh />}
            type="link"
            onClick={onRefetch}
          />
        </Tooltip>
      </Flex>
    </ErrorView>
  );
};
