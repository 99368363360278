import { App, Button, Form, Input, Modal } from "antd";
import { useMutation } from "urql";
import { FaUsers } from "react-icons/fa";
import { createGroupMutation } from "../../../../../../../../providers/graphql/mutations/createGroup.mutation";
import { ErrorView } from "../../../../../../../../components/ErrorView/ErrorView";

type CreateGroupFormFields = {
  teamName?: string;
};

export const CreateGroupForm = (): JSX.Element => {
  const { message } = App.useApp();

  const [{ fetching, error }, mutation] = useMutation(createGroupMutation());

  const onSubmit = async (values: CreateGroupFormFields): Promise<void> => {
    const { error } = await mutation({
      createGroupInput: {
        ...values,
      },
    });

    if (error) {
      return;
    }

    message.info("Group created successfully");

    Modal.destroyAll();
  };

  return (
    <ErrorView disableRetryButton error={error}>
      <Form
        size="large"
        layout="vertical"
        disabled={fetching}
        onFinish={onSubmit}
        scrollToFirstError
      >
        <Form.Item
          name="groupName"
          label="Group name"
          rules={[
            {
              required: true,
              message: "Please input the group name!",
            },
          ]}
        >
          <Input size="large" prefix={<FaUsers />} type="text" />
        </Form.Item>

        <Form.Item>
          <Button loading={fetching} block type="primary" htmlType="submit">
            Create Group
          </Button>
        </Form.Item>
      </Form>
    </ErrorView>
  );
};
