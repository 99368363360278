import { Affix, Button, Checkbox, List, Typography } from "antd";
import { CSSProperties, useState } from "react";
import { Role } from "../../types/role/role.enum";

const roleNameMapping: Record<Role, string> = {
  [Role.VIEW_USERS]: "View users",
  [Role.CREATE_USERS]: "Create users",
  [Role.UPDATE_USERS]: "Update users",
  [Role.DELETE_USERS]: "Delete users",
  [Role.VIEW_GROUPS]: "View groups",
  [Role.CREATE_GROUPS]: "Create groups",
  [Role.UPDATE_GROUPS]: "Update groups",
  [Role.DELETE_GROUPS]: "Delete groups",
  [Role.VIEW_TEAMS]: "View teams",
  [Role.CREATE_TEAMS]: "Create teams",
  [Role.UPDATE_TEAMS]: "Update teams",
  [Role.DELETE_TEAMS]: "Delete teams",
  [Role.VIEW_REPOSITORIES]: "View repository",
  [Role.CREATE_REPOSITORIES]: "Create repository",
  [Role.UPDATE_REPOSITORIES]: "Update repository",
  [Role.DELETE_REPOSITORIES]: "Delete repository",
  [Role.VIEW_WORKFLOWS]: "View workflows",
  [Role.CREATE_WORKFLOWS]: "Create workflows",
  [Role.UPDATE_WORKFLOWS]: "Update workflows",
  [Role.DELETE_WORKFLOWS]: "Delete workflows",
  [Role.VIEW_RUNS]: "View runs",
  [Role.VIEW_RELEASES]: "View releases",
  [Role.CREATE_RELEASES]: "Create releases",
  [Role.UPDATE_RELEASES]: "Update releases",
  [Role.DELETE_RELEASES]: "Delete releases",
  [Role.VIEW_STATUS_OVERRIDES]: "View pull request status overrides",
  [Role.CREATE_STATUS_OVERRIDES]: "Create pull request status overrides",
  [Role.UPDATE_STATUS_OVERRIDES]: "Update pull request status overrides",
  [Role.DELETE_STATUS_OVERRIDES]: "Delete pull request status overrides",
  [Role.VIEW_DATABASE_MIGRATIONS]: "View database migrations",
  [Role.VIEW_ENVIRONMENTS]: "View environments",
  [Role.CREATE_ENVIRONMENTS]: "Create environments",
  [Role.UPDATE_ENVIRONMENTS]: "Update environments",
  [Role.DELETE_ENVIRONMENTS]: "Delete environments",
  [Role.VIEW_DEPLOYMENTS]: "View deployments",
  [Role.CREATE_DEPLOYMENTS]: "Create deployments",
  [Role.UPDATE_DEPLOYMENTS]: "Update deployments",
  [Role.DELETE_DEPLOYMENTS]: "Delete deployments",
  [Role.VIEW_RULE_SETS]: "View rule sets",
  [Role.CREATE_RULE_SETS]: "Create rule sets",
  [Role.UPDATE_RULE_SETS]: "Update rule sets",
  [Role.DELETE_RULE_SETS]: "Delete rule sets",
  [Role.VIEW_TESTS]: "View tests",
  [Role.CREATE_TESTS]: "Create tests",
  [Role.UPDATE_TESTS]: "Update tests",
  [Role.DELETE_TESTS]: "Delete tests",
  [Role.VIEW_VPN_CLIENT_CONFIGURATION]: "View VPN client configuration",
};

const roleDescriptionMapping: Record<Role, string> = {
  [Role.VIEW_USERS]: "Gives permission to view other users",
  [Role.CREATE_USERS]: "Gives permission to create new users",
  [Role.UPDATE_USERS]: "Gives permission to update existing users",
  [Role.DELETE_USERS]: "Gives permission to delete users",
  [Role.VIEW_GROUPS]: "Gives permission to view groups",
  [Role.CREATE_GROUPS]: "Gives permission to create new groups",
  [Role.UPDATE_GROUPS]: "Gives permission to update existing groups",
  [Role.DELETE_GROUPS]: "Gives permission to delete groups",
  [Role.VIEW_TEAMS]: "Gives permission to view teams",
  [Role.CREATE_TEAMS]: "Gives permission to create new teams",
  [Role.UPDATE_TEAMS]: "Gives permission to update existing teams",
  [Role.DELETE_TEAMS]: "Gives permission to delete teams",
  [Role.VIEW_REPOSITORIES]: "Gives permission to view repository",
  [Role.CREATE_REPOSITORIES]: "Gives permission to create new repository",
  [Role.UPDATE_REPOSITORIES]: "Gives permission to update existing repository",
  [Role.DELETE_REPOSITORIES]: "Gives permission to delete repository",
  [Role.VIEW_WORKFLOWS]: "Gives permission to view workflows",
  [Role.CREATE_WORKFLOWS]: "Gives permission to create new workflows",
  [Role.UPDATE_WORKFLOWS]: "Gives permission to update existing workflows",
  [Role.DELETE_WORKFLOWS]: "Gives permission to delete workflows",
  [Role.VIEW_RUNS]: "Gives permission to view runs",
  [Role.VIEW_RELEASES]: "Gives permission to view releases",
  [Role.CREATE_RELEASES]: "Gives permission to create releases",
  [Role.UPDATE_RELEASES]: "Gives permission to update releases",
  [Role.DELETE_RELEASES]: "Gives permission to delete releases",
  [Role.VIEW_STATUS_OVERRIDES]:
    "Gives permission to view pull request status overrides",
  [Role.CREATE_STATUS_OVERRIDES]:
    "Gives permission to create pull request status overrides",
  [Role.UPDATE_STATUS_OVERRIDES]:
    "Gives permission to update pull request status overrides",
  [Role.DELETE_STATUS_OVERRIDES]:
    "Gives permission to delete pull request status overrides",
  [Role.VIEW_DATABASE_MIGRATIONS]:
    "Gives permission to view database migrations",
  [Role.VIEW_ENVIRONMENTS]: "Gives permission to view environments",
  [Role.CREATE_ENVIRONMENTS]: "Gives permission to create environments",
  [Role.UPDATE_ENVIRONMENTS]: "Gives permission to update environments",
  [Role.DELETE_ENVIRONMENTS]: "Gives permission to delete environments",
  [Role.VIEW_DEPLOYMENTS]: "Gives permission to view deployments",
  [Role.CREATE_DEPLOYMENTS]: "Gives permission to create deployments",
  [Role.UPDATE_DEPLOYMENTS]: "Gives permission to update deployments",
  [Role.DELETE_DEPLOYMENTS]: "Gives permission to delete deployments",
  [Role.VIEW_RULE_SETS]: "Gives permission to view rule sets",
  [Role.CREATE_RULE_SETS]: "Gives permission to create rule sets",
  [Role.UPDATE_RULE_SETS]: "Gives permission to update rule sets",
  [Role.DELETE_RULE_SETS]: "Gives permission to delete rule sets",
  [Role.VIEW_TESTS]: "Gives permission to view tests",
  [Role.CREATE_TESTS]: "Gives permission to create tests",
  [Role.UPDATE_TESTS]: "Gives permission to update tests",
  [Role.DELETE_TESTS]: "Gives permission to delete tests",
  [Role.VIEW_VPN_CLIENT_CONFIGURATION]:
    "Gives permission to download VPN client configuration and view vpn setup instructions",
};

const roleDependanciesMapping: Record<Role, Role[]> = {
  [Role.VIEW_USERS]: [],
  [Role.CREATE_USERS]: [Role.VIEW_USERS],
  [Role.UPDATE_USERS]: [Role.VIEW_USERS],
  [Role.DELETE_USERS]: [Role.VIEW_USERS],
  [Role.VIEW_GROUPS]: [Role.VIEW_USERS],
  [Role.CREATE_GROUPS]: [Role.VIEW_GROUPS, Role.VIEW_USERS],
  [Role.UPDATE_GROUPS]: [Role.VIEW_GROUPS],
  [Role.DELETE_GROUPS]: [Role.VIEW_GROUPS],
  [Role.VIEW_TEAMS]: [Role.VIEW_USERS],
  [Role.CREATE_TEAMS]: [Role.VIEW_TEAMS, Role.VIEW_USERS],
  [Role.UPDATE_TEAMS]: [Role.VIEW_TEAMS],
  [Role.DELETE_TEAMS]: [Role.VIEW_TEAMS],
  [Role.VIEW_REPOSITORIES]: [],
  [Role.CREATE_REPOSITORIES]: [Role.VIEW_REPOSITORIES],
  [Role.UPDATE_REPOSITORIES]: [Role.VIEW_REPOSITORIES],
  [Role.DELETE_REPOSITORIES]: [Role.VIEW_REPOSITORIES],
  [Role.VIEW_WORKFLOWS]: [],
  [Role.CREATE_WORKFLOWS]: [Role.VIEW_WORKFLOWS],
  [Role.UPDATE_WORKFLOWS]: [Role.VIEW_WORKFLOWS],
  [Role.DELETE_WORKFLOWS]: [Role.VIEW_WORKFLOWS],
  [Role.VIEW_RUNS]: [],
  [Role.VIEW_RELEASES]: [Role.VIEW_RUNS, Role.VIEW_REPOSITORIES],
  [Role.CREATE_RELEASES]: [Role.VIEW_REPOSITORIES, Role.VIEW_RELEASES],
  [Role.UPDATE_RELEASES]: [Role.VIEW_REPOSITORIES, Role.VIEW_RELEASES],
  [Role.DELETE_RELEASES]: [Role.VIEW_RELEASES],
  [Role.VIEW_STATUS_OVERRIDES]: [Role.VIEW_REPOSITORIES, Role.VIEW_USERS],
  [Role.CREATE_STATUS_OVERRIDES]: [
    Role.VIEW_REPOSITORIES,
    Role.VIEW_STATUS_OVERRIDES,
    Role.VIEW_USERS,
  ],
  [Role.UPDATE_STATUS_OVERRIDES]: [Role.VIEW_STATUS_OVERRIDES],
  [Role.DELETE_STATUS_OVERRIDES]: [Role.VIEW_STATUS_OVERRIDES],
  [Role.VIEW_DATABASE_MIGRATIONS]: [],
  [Role.VIEW_ENVIRONMENTS]: [
    Role.VIEW_DEPLOYMENTS,
    Role.VIEW_TEAMS,
    Role.VIEW_USERS,
    Role.VIEW_RUNS,
  ],
  [Role.CREATE_ENVIRONMENTS]: [
    Role.VIEW_ENVIRONMENTS,
    Role.VIEW_TEAMS,
    Role.VIEW_USERS,
  ],
  [Role.UPDATE_ENVIRONMENTS]: [
    Role.VIEW_ENVIRONMENTS,
    Role.VIEW_TEAMS,
    Role.VIEW_USERS,
  ],
  [Role.DELETE_ENVIRONMENTS]: [Role.VIEW_ENVIRONMENTS],
  [Role.VIEW_DEPLOYMENTS]: [
    Role.VIEW_ENVIRONMENTS,
    Role.VIEW_REPOSITORIES,
    Role.VIEW_WORKFLOWS,
    Role.VIEW_RUNS,
  ],
  [Role.CREATE_DEPLOYMENTS]: [
    Role.VIEW_ENVIRONMENTS,
    Role.VIEW_DEPLOYMENTS,
    Role.VIEW_REPOSITORIES,
    Role.VIEW_WORKFLOWS,
    Role.VIEW_TEAMS,
    Role.VIEW_USERS,
  ],
  [Role.UPDATE_DEPLOYMENTS]: [
    Role.VIEW_ENVIRONMENTS,
    Role.VIEW_DEPLOYMENTS,
    Role.VIEW_REPOSITORIES,
    Role.VIEW_WORKFLOWS,
    Role.VIEW_TEAMS,
    Role.VIEW_USERS,
    Role.VIEW_RUNS,
  ],
  [Role.DELETE_DEPLOYMENTS]: [Role.VIEW_DEPLOYMENTS],
  [Role.VIEW_RULE_SETS]: [Role.VIEW_REPOSITORIES],
  [Role.CREATE_RULE_SETS]: [Role.VIEW_RULE_SETS, Role.VIEW_REPOSITORIES],
  [Role.UPDATE_RULE_SETS]: [Role.VIEW_RULE_SETS, Role.VIEW_REPOSITORIES],
  [Role.DELETE_RULE_SETS]: [Role.VIEW_RULE_SETS],
  [Role.VIEW_TESTS]: [
    Role.VIEW_REPOSITORIES,
    Role.VIEW_DEPLOYMENTS,
    Role.VIEW_USERS,
    Role.VIEW_RUNS,
  ],
  [Role.CREATE_TESTS]: [
    Role.VIEW_TESTS,
    Role.VIEW_REPOSITORIES,
    Role.VIEW_DEPLOYMENTS,
    Role.VIEW_RUNS,
    Role.VIEW_ENVIRONMENTS,
  ],
  [Role.UPDATE_TESTS]: [Role.VIEW_TESTS, Role.VIEW_REPOSITORIES],
  [Role.DELETE_TESTS]: [Role.VIEW_TESTS],
  [Role.VIEW_VPN_CLIENT_CONFIGURATION]: [],
};

export type PermissionsViewerProps = {
  title?: string;
  style?: CSSProperties;
  readOnly?: boolean;
  selectedPermissions: Role[];
  onUpdatePermissions?: (roles: Role[]) => void;
};

export const PermissionsViewer = ({
  title,
  style,
  readOnly,
  selectedPermissions,
  onUpdatePermissions,
}: PermissionsViewerProps): JSX.Element => {
  const [roles, setRoles] = useState<Role[]>(selectedPermissions);

  const allPermissions = Object.values(Role);

  const onPermissionSelected = (role: Role, checked: boolean): void => {
    if (readOnly) {
      return;
    }

    if (checked) {
      setRoles((previous): Role[] => {
        return Array.from(
          new Set(previous.filter((previousRole) => previousRole !== role))
        );
      });
    } else {
      setRoles((previous): Role[] => {
        return Array.from(
          new Set([...previous, role, ...roleDependanciesMapping[role]])
        );
      });
    }
  };

  return (
    <>
      <List
        header={
          <>{title && <Typography.Title level={3}>{title}</Typography.Title>}</>
        }
        style={style}
        dataSource={allPermissions}
        renderItem={(item, index) => {
          const checked = roles.includes(item);

          return (
            <List.Item
              key={index}
              onClick={onPermissionSelected.bind(this, item, checked)}
              actions={[
                <Checkbox
                  key="1"
                  checked={checked}
                  style={{ marginRight: 15 }}
                />,
              ]}
            >
              <List.Item.Meta
                title={roleNameMapping[item]}
                description={roleDescriptionMapping[item]}
              />
            </List.Item>
          );
        }}
      />
      {onUpdatePermissions && (
        <Affix offsetBottom={120} style={{ paddingTop: 15 }}>
          <Button
            onClick={onUpdatePermissions.bind(this, roles)}
            type="primary"
            size="large"
          >
            Update roles
          </Button>
        </Affix>
      )}
    </>
  );
};
