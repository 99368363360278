import { Button, Flex } from "antd";
import { Link } from "react-router-dom";

export type RefLinkProps = {
  repositoryName: string;
  refName: string;
  extra?: React.ReactNode;
};

export const RefLink = ({
  repositoryName,
  refName,
  extra,
}: RefLinkProps): JSX.Element => {
  return (
    <Flex vertical gap="small" align="center">
      <Link
        to={`https://github.com/soum-sa/${repositoryName}/tree/${refName}`}
        target="_blank"
      >
        <Button type="link">{refName}</Button>
      </Link>
      {extra}
    </Flex>
  );
};
