import { useCallback, useEffect, useState } from "react";
import queryString from "querystring";
import { PaginationSortOrder } from "../types/pagination/pagination.enum";
import { PaginationRequest } from "../types/pagination/pagination.type";
import { useSearchParams } from "react-router-dom";

export type DefaultPaginationParameters = {
  limit: number;
  page: number;
  sortOrder: PaginationSortOrder;
};

export type PaginationParameters<F, S> = Omit<
  PaginationRequest<F, S>,
  "filters"
> & {
  [k in keyof F]?: F[k];
};

const defaultPaginationParameters: DefaultPaginationParameters = {
  page: 1,
  limit: 10,
  sortOrder: PaginationSortOrder.DESC,
};

export const usePagination = <
  F extends Record<string, unknown>,
  S extends string
>({
  filterNames,
  defaultSortOrder,
}: {
  filterNames?: Array<keyof F>;
  defaultSortOrder?: PaginationSortOrder;
}): {
  defaultPaginationParameters: DefaultPaginationParameters;
  paginationRequest: PaginationRequest<F, S>;
  setPaginationParameters: (
    paginationParameters: PaginationParameters<F, S>
  ) => void;
} => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [paginationParameters, setPaginationParameters] = useState<
    PaginationParameters<F, S>
  >({});

  const formatAllQueryStringsToPaginationRequest =
    useCallback((): PaginationRequest<F, S> => {
      const request: PaginationRequest<F, S> = {};

      const { search, skip, limit, sortField, sortOrder, ...rest } =
        queryString.parse(searchParams.toString()) as PaginationParameters<
          F,
          S
        >;

      if (search) {
        request["search"] = search;
      }

      if (skip && skip >= 0) {
        request["skip"] = Number.parseInt(String(skip));
      } else {
        request["skip"] =
          (defaultPaginationParameters.page - 1) *
          defaultPaginationParameters.limit;
      }

      if (limit && limit >= 1) {
        request["limit"] = Number.parseInt(String(limit));
      } else {
        request["limit"] = defaultPaginationParameters.limit;
      }

      if (sortField) {
        request["sortField"] = sortField;
      }

      if (sortOrder) {
        request["sortOrder"] = sortOrder;
      } else {
        request["sortOrder"] =
          defaultSortOrder ?? defaultPaginationParameters.sortOrder;
      }

      let filters = {} as F;

      const setFilters = rest as {
        [k in keyof F]?: F[k];
      };

      filterNames?.forEach((filterName) => {
        if (setFilters[filterName]) {
          filters = { ...filters, [filterName]: setFilters[filterName] };
        }
      });

      request["filters"] = filters;

      return request;
    }, [searchParams]);

  const [paginationRequest, setPaginationRequest] = useState<
    PaginationRequest<F, S>
  >(formatAllQueryStringsToPaginationRequest());

  const createQueryString = useCallback((): string => {
    return queryString.stringify(
      Object.assign(
        {},
        queryString.parse(searchParams.toString()),
        paginationParameters
      )
    );
  }, [paginationParameters, searchParams]);

  useEffect(() => {
    setSearchParams(createQueryString());
  }, [setSearchParams, createQueryString]);

  useEffect(() => {
    setPaginationRequest(formatAllQueryStringsToPaginationRequest());
  }, [formatAllQueryStringsToPaginationRequest, setPaginationRequest]);

  return {
    setPaginationParameters,
    paginationRequest,
    defaultPaginationParameters,
  };
};
