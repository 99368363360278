import { Breadcrumb, Button, Flex, Typography } from "antd";
import { usePagination } from "../../../../../../hooks/usePagination";
import { Link } from "react-router-dom";
import { SearchBar } from "../../../../../../components/SearchBar/SearchBar";
import { useUserContext } from "../../../../../../context/UserContext";
import { Role } from "../../../../../../types/role/role.enum";

export const PRStatusOverridesHeaderSection = (): JSX.Element => {
  const { setPaginationParameters, paginationRequest } = usePagination({});

  const { user } = useUserContext();

  const roles = user?.relations?.roles ?? [];

  const onSearchChanged = (search: string): void => {
    setPaginationParameters({
      search,
    });
  };

  return (
    <Flex justify="space-between" align="center">
      <Flex vertical>
        <Typography.Title level={2}>PR Status Override</Typography.Title>
        <Breadcrumb
          items={[
            {
              title: <Link to="/dashboard">Dashboard</Link>,
            },
            {
              title: "More",
            },
            {
              title: "PR Status Override",
            },
          ]}
        />
      </Flex>
      <Flex gap="middle">
        <SearchBar
          placeholder={"Search pr status overrides ..."}
          search={paginationRequest.search}
          setSearch={onSearchChanged}
        />
        {roles.includes(Role.CREATE_STATUS_OVERRIDES) && (
          <Link to="/dashboard/more/pr-status-overrides/new">
            <Button size="large" type="primary">
              Create New PR Status Override
            </Button>
          </Link>
        )}
      </Flex>
    </Flex>
  );
};
