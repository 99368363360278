import { Flex } from "antd";
import { LoginForm } from "./components/LoginForm/LoginForm";

const LoginPage = (): JSX.Element => {
  return (
    <Flex
      align="center"
      justify="center"
      style={{ height: "100%", width: "100%" }}
    >
      <LoginForm />
    </Flex>
  );
};

export default LoginPage;
