import {
  App,
  Avatar,
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Typography,
} from "antd";
import { UserOutlined, MailOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Nullable } from "../../utils/nullable/nullable.type";
import { UserJSON } from "../../types/user/user.type";
import { ErrorView } from "../ErrorView/ErrorView";
import { useMutation } from "urql";
import { createUserMutation } from "../../providers/graphql/mutations/createUser.mutation";
import { updateUserProfileMutation } from "../../providers/graphql/mutations/updateUserProfile.mutation";
import { MdEditSquare } from "react-icons/md";

type UserUpsertFormFields = {
  fullName?: string;
  email?: string;
  gitId?: Nullable<string>;
  notificationId?: Nullable<string>;
};

export type UserUpsertFormProps = {
  user?: Nullable<UserJSON>;
};

export const UserUpsertForm = ({ user }: UserUpsertFormProps): JSX.Element => {
  const { message } = App.useApp();

  const [photo] = useState<Nullable<string>>(user?.photo ?? null);

  const isEditing = Boolean(user);

  const [{ fetching, error }, mutation] = useMutation(
    isEditing ? updateUserProfileMutation() : createUserMutation()
  );

  const onSubmit = async (values: UserUpsertFormFields): Promise<void> => {
    const { error } = await mutation({
      ...(isEditing
        ? {
            updateUserProfileInput: {
              ...values,
              gitId: values.gitId ?? null,
              notificationId: values.notificationId ?? null,
              photo,
            },
          }
        : {
            createUserInput: {
              ...values,
              gitId: values.gitId ?? null,
              notificationId: values.notificationId ?? null,
              photo,
            },
          }),
    });

    if (error) {
      return;
    }

    message.info(`User ${isEditing ? "updated" : "created"} successfully!`);

    Modal.destroyAll();
  };

  return (
    <ErrorView disableRetryButton error={error}>
      <Form
        size="large"
        layout="vertical"
        disabled={fetching}
        onFinish={onSubmit}
        initialValues={{
          ...(user && { ...user }),
        }}
        scrollToFirstError
      >
        <Form.Item>
          <Flex vertical gap="middle" align="center">
            <Typography.Text style={{ display: "block", textAlign: "center" }}>
              Profile Photo
            </Typography.Text>
            <Avatar src={user?.photo} size={150} icon={<UserOutlined />} />
          </Flex>
        </Form.Item>
        <Form.Item
          name="fullName"
          label="Full name"
          rules={[
            {
              required: true,
              message: "Full name is required",
            },
          ]}
        >
          <Input size="large" prefix={<UserOutlined />} type="text" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email address"
          rules={[
            {
              required: true,
              message: "Email address is required",
            },
          ]}
        >
          <Input
            disabled={isEditing}
            readOnly={isEditing}
            prefix={<MailOutlined />}
            type="email"
          />
        </Form.Item>
        <Form.Item name="gitId" label="Github username">
          <Input prefix={<MdEditSquare />} type="text" />
        </Form.Item>
        <Form.Item name="notificationId" label="Slack user id">
          <Input prefix={<MdEditSquare />} type="text" />
        </Form.Item>
        <Form.Item>
          <Button loading={fetching} block type="primary" htmlType="submit">
            {isEditing ? "Update Profile" : "Create User"}
          </Button>
        </Form.Item>
      </Form>
    </ErrorView>
  );
};
