import { mutation } from "gql-query-builder";
import IQueryBuilderOptions from "gql-query-builder/build/IQueryBuilderOptions";
import { TypedDocumentNode, gql } from "urql";

export const deleteEnvironmentMutation = (
  args?: Pick<IQueryBuilderOptions, "fields">
): TypedDocumentNode => {
  return gql(
    mutation({
      variables: {
        deleteEnvironmentInput: {
          type: "DeleteEnvironmentInput",
          required: true,
        },
      },
      operation: "deleteEnvironment",
      fields: args?.fields ?? ["id"],
    }).query
  );
};
