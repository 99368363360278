import { Button } from "antd";
import { Link } from "react-router-dom";

export type UserLinkProps = {
  email: string;
  fullName?: string;
};

export const UserLink = ({ email, fullName }: UserLinkProps): JSX.Element => {
  return (
    <Link to={`/dashboard/iam/users?emails${email}`} target="_blank">
      <Button type="link">{fullName ?? email}</Button>
    </Link>
  );
};
