import { Button, Divider, Flex, List, Typography } from "antd";
import { TeamJSON } from "../../../../types/team/team.type";
import { useState } from "react";
import { UsersViewer } from "../../../UsersViewer/UsersViewer";
import { FaRegSquarePlus } from "react-icons/fa6";
import { FaRegMinusSquare } from "react-icons/fa";

export type TeamListItemViewProps = {
  team: TeamJSON;
};

export const TeamListItemView = ({ team }: TeamListItemViewProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const onExpand = (): void => {
    setExpanded((prev) => {
      return !prev;
    });
  };

  return (
    <Flex vertical gap="small">
      <List.Item>
        <List.Item.Meta
          avatar={
            <Button
              icon={expanded ? <FaRegMinusSquare /> : <FaRegSquarePlus />}
              onClick={onExpand}
            />
          }
          title={team.teamName}
          description={`${team.relations?.users?.length ?? 0} users`}
        />
      </List.Item>
      {expanded && (
        <div style={{ paddingLeft: 50 }}>
          <Typography.Title
            type="secondary"
            level={3}
            style={{ textAlign: "center" }}
          >
            Users in team: {team.teamName}
          </Typography.Title>
          <Divider />
          <UsersViewer users={team.relations?.users ?? []} />
        </div>
      )}
    </Flex>
  );
};
