import { Button } from "antd";
import { Link } from "react-router-dom";

export type RepositoryLinkProps = {
  repositoryName: string;
  repositoryAlias?: string;
};

export const RepositoryLink = ({
  repositoryName,
  repositoryAlias,
}: RepositoryLinkProps): JSX.Element => {
  return (
    <Link to={`https://github.com/soum-sa/${repositoryName}`} target="_blank">
      <Button type="link">{repositoryAlias ?? repositoryName}</Button>
    </Link>
  );
};
