import { NewPRStatusOverrideWizardFormFields } from "../NewPRStatusOverrideWizardSection";
import { atom } from "jotai";

export const createPRStatusOverrideWizardFormDataAtom =
  atom<NewPRStatusOverrideWizardFormFields>({});

export const updateCreatePRStatusOverrideWizardFormDataAtom = atom(
  null,
  (get, set, value: Partial<NewPRStatusOverrideWizardFormFields>) => {
    const createPRStatusOverrideWizardFormData = get(
      createPRStatusOverrideWizardFormDataAtom
    );
    set(
      createPRStatusOverrideWizardFormDataAtom,
      Object.assign(createPRStatusOverrideWizardFormData ?? {}, value ?? {})
    );
  }
);

export const clearCreatePRStatusOverrideWizardFormDataAtom = atom(
  null,
  (_, set) => {
    set(createPRStatusOverrideWizardFormDataAtom, {});
  }
);
