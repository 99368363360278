import { CSSProperties } from "react";
import { Button, Descriptions, Flex, Select, Spin, Tooltip } from "antd";
import { ErrorView } from "../ErrorView/ErrorView";
import { useQuery } from "urql";
import { Nullable } from "../../utils/nullable/nullable.type";
import { getGitFileQuery } from "../../providers/graphql/queries/getGitFile.query";
import { IoIosRefresh } from "react-icons/io";

type CronJob = {
  name: string;
  schedule: string;
  path: string;
  method: string;
};

export type SelectCronjobsInputProps = {
  style?: CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
  repositoryName: string;
  refName: string;
};

export const SelectCronjobsInput = ({
  style,
  value,
  onChange,
  repositoryName,
  refName,
}: SelectCronjobsInputProps): Nullable<JSX.Element> => {
  const [{ data, error, fetching }, refetch] = useQuery<{
    gitFile: Nullable<string>;
  }>({
    query: getGitFileQuery(),
    variables: {
      gitFileInput: {
        repositoryName,
        ref: refName,
        path: "cron-job-config.json",
      },
    },
    requestPolicy: "network-only",
    pause: !repositoryName,
  });

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  const cronJobs = ((): CronJob[] => {
    try {
      return JSON.parse(data?.gitFile ?? "[]");
    } catch (error) {
      return [];
    }
  })();

  return (
    <ErrorView error={error} onRefetch={onRefetch}>
      <Flex gap="middle">
        <Select
          mode="multiple"
          value={value}
          onChange={onChange}
          showSearch
          style={style}
          dropdownRender={(menu) => {
            if (fetching) {
              return (
                <Flex justify="center">
                  <Spin />
                </Flex>
              );
            }

            return menu;
          }}
        >
          {cronJobs.map((value, index) => {
            return (
              <Select.Option key={index} value={value.name}>
                <Descriptions
                  column={1}
                  items={[
                    {
                      label: "Name",
                      children: value.name,
                    },
                    {
                      label: "Request",
                      children: `${value.method} ${value.path}`,
                    },
                    {
                      label: "Schedule",
                      children: value.schedule,
                    },
                  ]}
                />
              </Select.Option>
            );
          })}
        </Select>
        <Tooltip title="Refresh">
          <Button
            loading={fetching}
            icon={<IoIosRefresh />}
            type="link"
            onClick={onRefetch}
          />
        </Tooltip>
      </Flex>
    </ErrorView>
  );
};
