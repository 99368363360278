import { Flex } from "antd";
import { NewDeploymentWizardSection } from "./components/NewDeploymentWizardSection/NewDeploymentWizardSection";
import { NewDeploymentHeaderSection } from "./components/NewDeploymentHeaderSection/NewDeploymentHeaderSection";

const NewDeploymentPage = (): JSX.Element => {
  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <NewDeploymentHeaderSection />
      <NewDeploymentWizardSection />
    </Flex>
  );
};

export default NewDeploymentPage;
