import { App, Flex, Form, Spin } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "urql";
import { useAtomValue, useSetAtom } from "jotai";
import {
  clearUpsertEnvironmentWizardFormDataAtom,
  upsertEnvironmentWizardFormDataAtom,
  updateUpsertEnvironmentWizardFormDataAtom,
} from "./states/upsert.environment.wizard.form.data";
import { useNavigate } from "react-router-dom";
import {
  EnvironmentAccount,
  EnvironmentHibernationType,
  EnvironmentSyncMode,
} from "../../types/environment/environment.enum";
import { ErrorView } from "../ErrorView/ErrorView";
import { UpsertEnvironmentWizardSteps } from "./components/UpsertEnvironmentWizardSteps/UpsertEnvironmentWizardSteps";
import { UpsertEnvironmentWizardButtons } from "./components/UpsertEnvironmentWizardButtons/UpsertEnvironmentWizardButtons";
import { UpsertEnvironmentWizardContent } from "./components/UpsertEnvironmentWizardContent/UpsertEnvironmentWizardContent";
import { updateEnvironmentMutation } from "../../providers/graphql/mutations/updateEnvironment.mutation";
import { createEnvironmentMutation } from "../../providers/graphql/mutations/createEnvironment.mutation";
import { EnvironmentJSON } from "../../types/environment/environment.type";
import { getEnvironmentQuery } from "../../providers/graphql/queries/getEnvironment.query";
import { Nullable } from "../../utils/nullable/nullable.type";

export type UpsertEnvironmentWizardFormFields = {
  environmentId?: string;
  environmentName?: string;
  account?: EnvironmentAccount;
  syncMode?: Nullable<EnvironmentSyncMode>;
  teams?: Array<{
    teamName: string;
  }>;
  users?: Array<{
    email: string;
  }>;
  hibernationOptions?: Array<{
    type?: EnvironmentHibernationType;
    hoursWithNoDeploymentChange?: number;
    daysOfTheWeek?: Array<{
      dayOfTheWeek: number;
      hoursOfTheDay: Array<{
        startHour: number;
        endHour: number;
      }>;
    }>;
  }>;
};

export type UpsertEnvironmentWizardSectionProps = {
  environmentId?: string;
};

export const UpsertEnvironmentWizardSection = ({
  environmentId,
}: UpsertEnvironmentWizardSectionProps): JSX.Element => {
  const isUpdating = Boolean(environmentId);

  const [{ fetching: isMutating, error: mutatingError }, mutation] =
    useMutation(
      isUpdating ? updateEnvironmentMutation() : createEnvironmentMutation()
    );

  const [{ data, error: queryError, fetching: isQuerying }, refetch] =
    useQuery<{
      environment: EnvironmentJSON;
    }>({
      query: getEnvironmentQuery({
        fields: [
          "id",
          "environmentId",
          "environmentName",
          "teamNames",
          "emails",
          "account",
          "syncMode",
          "hibernationOptions",
        ],
      }),
      variables: {
        environmentInput: {
          environmentId,
        },
      },
      requestPolicy: "network-only",
      pause: true,
    });

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  const error = mutatingError || queryError;

  const fetching = isMutating || isQuerying;

  const upsertEnvironmentWizardFormData = useAtomValue(
    upsertEnvironmentWizardFormDataAtom
  );

  const updateUpsertEnvironmentWizardFormData = useSetAtom(
    updateUpsertEnvironmentWizardFormDataAtom
  );

  const clearUpsertEnvironmentWizardFormData = useSetAtom(
    clearUpsertEnvironmentWizardFormDataAtom
  );

  const { message } = App.useApp();

  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState<number>(0);

  const lastStep = 3;

  const [form] = Form.useForm<UpsertEnvironmentWizardFormFields>();

  const validateFormFields = async () => {
    try {
      await form.validateFields({ validateOnly: true });
      return true;
    } catch (error) {
      return false;
    }
  };

  const initialFormData = useMemo(
    () =>
      data?.environment
        ? {
            syncMode: data.environment.syncMode,
            environmentId: data.environment.environmentId,
            environmentName: data.environment.environmentName,
            account: data.environment.account,
            teams: data.environment.teamNames.map((teamName) => ({
              teamName,
            })),
            users: data.environment.emails.map((email) => ({ email })),
            hibernationOptions: data.environment.hibernationOptions?.map(
              (hibernationOption) => {
                switch (hibernationOption.type) {
                  case EnvironmentHibernationType.NO_DEPLOYMENT_CHANGE_OVER_TIME:
                    return {
                      type: hibernationOption.type,
                      hoursWithNoDeploymentChange:
                        hibernationOption.hoursWithNoDeploymentChange,
                    };
                  case EnvironmentHibernationType.DURING_CERTAIN_DAYS_OF_THE_WEEK:
                    return {
                      type: hibernationOption.type,
                      daysOfTheWeek: hibernationOption.daysOfTheWeek.map(
                        (dayOfTheWeek) => ({
                          dayOfTheWeek: dayOfTheWeek.dayOfTheWeek,
                          hoursOfTheDay: dayOfTheWeek.hoursOfTheDay.map(
                            (hourOfTheDay) => ({
                              startHour: hourOfTheDay.startHour,
                              endHour: hourOfTheDay.endHour,
                            })
                          ),
                        })
                      ),
                    };
                }
              }
            ),
          }
        : {
            hibernationOptions: [
              {
                type: EnvironmentHibernationType.NO_DEPLOYMENT_CHANGE_OVER_TIME,
                hoursWithNoDeploymentChange: 4,
              },
              {
                type: EnvironmentHibernationType.DURING_CERTAIN_DAYS_OF_THE_WEEK,
                daysOfTheWeek: [
                  {
                    dayOfTheWeek: 5,
                    hoursOfTheDay: [
                      {
                        endHour: 23,
                        startHour: 0,
                      },
                    ],
                  },
                  {
                    dayOfTheWeek: 6,
                    hoursOfTheDay: [
                      {
                        endHour: 23,
                        startHour: 0,
                      },
                    ],
                  },
                  {
                    dayOfTheWeek: 1,
                    hoursOfTheDay: [
                      {
                        endHour: 3,
                        startHour: 0,
                      },
                      {
                        endHour: 23,
                        startHour: 18,
                      },
                    ],
                  },
                  {
                    dayOfTheWeek: 0,
                    hoursOfTheDay: [
                      {
                        endHour: 3,
                        startHour: 0,
                      },
                      {
                        endHour: 23,
                        startHour: 18,
                      },
                    ],
                  },
                  {
                    dayOfTheWeek: 2,
                    hoursOfTheDay: [
                      {
                        endHour: 3,
                        startHour: 0,
                      },
                      {
                        endHour: 23,
                        startHour: 18,
                      },
                    ],
                  },
                  {
                    dayOfTheWeek: 3,
                    hoursOfTheDay: [
                      {
                        endHour: 3,
                        startHour: 0,
                      },
                      {
                        endHour: 23,
                        startHour: 18,
                      },
                    ],
                  },
                  {
                    dayOfTheWeek: 4,
                    hoursOfTheDay: [
                      {
                        endHour: 3,
                        startHour: 0,
                      },
                      {
                        endHour: 23,
                        startHour: 18,
                      },
                    ],
                  },
                ],
              },
            ],
          },
    [data?.environment]
  );

  useEffect(() => {
    form.setFieldsValue(initialFormData);

    updateUpsertEnvironmentWizardFormData(initialFormData);
  }, [initialFormData]);

  useEffect(() => {
    if (environmentId) {
      refetch({ requestPolicy: "network-only" });
    }
  }, [environmentId]);

  const onPrevious = () => {
    setCurrentStep(Math.max(currentStep - 1, 0));
  };

  const onSubmit = async (): Promise<void> => {
    if (fetching) return;

    const submittable = await validateFormFields();

    if (!submittable) return;

    if (currentStep !== lastStep) {
      setCurrentStep(Math.min(currentStep + 1, lastStep));
      return;
    }

    return onUpsertEnvironment();
  };

  const onUpsertEnvironment = useCallback(async (): Promise<void> => {
    const data = upsertEnvironmentWizardFormData;

    const input = {
      environmentId: data.environmentId,
      environmentName: data.environmentName,
      account: data.account,
      syncMode:
        data.account === EnvironmentAccount.PRODUCTION
          ? null
          : data.syncMode ?? null,
      teamNames: data.teams?.map((team) => team.teamName) ?? [],
      emails: data.users?.map((user) => user.email) ?? [],
      hibernationOptions: data?.hibernationOptions ?? null,
    };

    if (isUpdating) {
      delete input.account;
    }

    const { error } = await mutation(
      isUpdating
        ? {
            updateEnvironmentInput: input,
          }
        : {
            createEnvironmentInput: input,
          }
    );

    if (error) {
      return;
    }

    clearUpsertEnvironmentWizardFormData();

    message.info(
      `Environment ${data.environmentName} ${
        isUpdating ? "updated" : "created"
      } successfully!`
    );

    navigate(`/dashboard/environments`);
  }, [upsertEnvironmentWizardFormData]);

  const hasQueried = !(!!environmentId && !data?.environment);

  return (
    <ErrorView
      hideChildrenOnError={!hasQueried}
      error={error}
      onRefetch={hasQueried ? onUpsertEnvironment : onRefetch}
    >
      {isQuerying && <Spin fullscreen />}
      <Form
        disabled={fetching}
        form={form}
        size="large"
        layout="vertical"
        onFinish={onSubmit}
        onFinishFailed={(error) => {
          if (!error.errorFields.length) {
            onSubmit();
          }
        }}
        onValuesChange={(_, values) =>
          updateUpsertEnvironmentWizardFormData(values)
        }
        scrollToFirstError
      >
        <Flex
          vertical
          gap="middle"
          style={{ width: "100%", height: "100%", padding: 35 }}
        >
          <UpsertEnvironmentWizardSteps
            fetching={fetching}
            error={error}
            currentStep={currentStep}
          />
          <UpsertEnvironmentWizardContent currentStep={currentStep} />
          <UpsertEnvironmentWizardButtons
            lastStep={lastStep}
            currentStep={currentStep}
            onPrevious={onPrevious}
          />
        </Flex>
      </Form>
    </ErrorView>
  );
};
