import { query } from "gql-query-builder";
import IQueryBuilderOptions from "gql-query-builder/build/IQueryBuilderOptions";
import { TypedDocumentNode, gql } from "urql";

export const getAllBranchesQuery = ({
  fields,
}: Pick<IQueryBuilderOptions, "fields">): TypedDocumentNode => {
  return gql(
    query({
      fields: [...(fields ?? ["id"])],
      operation: "allBranches",
      variables: {
        allBranchesInput: {
          type: "AllBranchesInput",
          required: true,
        },
      },
    }).query
  );
};
