import { App, Button, Dropdown, MenuProps, Modal } from "antd";
import { useMutation } from "urql";
import { useError } from "../../../../hooks/useError";
import { DeploymentRepository } from "../../../../types/deployment/deployment.type";
import { reDeployDeploymentRepositoryMutation } from "../../../../providers/graphql/mutations/reDeployDeploymentRepository.mutation";
import { useUserContext } from "../../../../context/UserContext";
import { Nullable } from "../../../../utils/nullable/nullable.type";
import { Role } from "../../../../types/role/role.enum";
import { deleteDeploymentRepositoryMutation } from "../../../../providers/graphql/mutations/deleteDeploymentRepository.mutation";
import { UpsertDeploymentRepositoryForm } from "../../../UpsertDeploymentRepositoryForm/UpsertDeploymentRepositoryForm";
import { useRepositoriesContext } from "../../../../context/RepositoriesContext";
import { Country } from "../../../../types/country/country.enum";
import { EnvironmentJSON } from "../../../../types/environment/environment.type";

export type DeploymentRepositoryMoreButtonProps = {
  deploymentRepository: DeploymentRepository;
  deploymentId: string;
  deploymentName: string;
  onRefetch: () => void;
  isEditable: boolean;
  environment?: Partial<EnvironmentJSON>;
};

export const DeploymentRepositoryMoreButton = ({
  deploymentRepository,
  deploymentId,
  deploymentName,
  isEditable,
  environment,
  onRefetch,
}: DeploymentRepositoryMoreButtonProps): Nullable<JSX.Element> => {
  const { repositoryMapping } = useRepositoriesContext();

  const { modal, notification, message } = App.useApp();

  const { errorNotificationMessage } = useError();

  const { user } = useUserContext();

  const roles = user?.relations?.roles ?? [];

  const [
    { fetching: isDeletingDeploymentRepository },
    deleteDeploymentRepository,
  ] = useMutation(deleteDeploymentRepositoryMutation());

  const [
    { fetching: isRedeployingDeploymentRepository },
    reDeployDeploymentRepository,
  ] = useMutation(reDeployDeploymentRepositoryMutation());

  const fetching =
    isDeletingDeploymentRepository || isRedeployingDeploymentRepository;

  const items = ((): MenuProps["items"] => {
    const items: MenuProps["items"] = [];

    const hasDeploymentSettings = !!(repositoryMapping ?? {})[
      deploymentRepository.repositoryAlias
    ]?.deploySettings;

    if (!roles.includes(Role.UPDATE_DEPLOYMENTS)) return items;

    if (!isEditable) return items;

    if (environment && !environment?.hibernating && !environment?.expired) {
      items.push({
        key: "update-deployment-repository",
        label: "Update repository",
      });

      items.push({
        key: "redeploy-deployment-repository-from-build",
        label: "Redeploy repository from build",
      });

      if (hasDeploymentSettings) {
        items.push({
          key: "redeploy-deployment-repository-skipping-build",
          label: "Redeploy repository skipping build",
        });
      }

      items.push({
        key: "view-argocd-application",
        label: "View Argo CD Application",
      });
    }

    items.push({
      key: "delete-deployment-repository",
      label: "Remove repository from deployment",
    });

    return items;
  })();

  const onMenuItemSelected = ({ key }: { key: string }): void => {
    switch (key) {
      case "view-argocd-application":
        window.open(
          `https://argocd.${environment?.account}.soum.sa/applications/argocd/${
            deploymentRepository.repositoryAlias
          }-${environment?.environmentId}-${
            deploymentRepository.country ?? Country.SAUDI_ARABIA
          }`
        );
        break;
      case "redeploy-deployment-repository-from-build":
        modal.confirm({
          icon: null,
          title: "Redeploy Repository From Build",
          content: `Are you sure you want to redeploy ${deploymentRepository.repositoryAlias} repository from build in deployment ${deploymentName}?`,
          okButtonProps: {
            style: {
              backgroundColor: "purple",
            },
          },
          okText: "Redeploy Repository From Build",
          onOk: async (): Promise<void> => {
            const { error } = await reDeployDeploymentRepository({
              reDeployDeploymentRepositoryInput: {
                deploymentId,
                repositoryAlias: deploymentRepository.repositoryAlias,
                country: deploymentRepository.country,
                deployOnly: false,
              },
            });

            if (error) {
              notification.error(errorNotificationMessage(error));
              return;
            }

            message.success("Repository will be redeployed from build shortly");

            onRefetch();

            Modal.destroyAll();
          },
        });
        break;
      case "redeploy-deployment-repository-skipping-build":
        modal.confirm({
          icon: null,
          title: "Redeploy Repository Skipping Build",
          content: `Are you sure you want to redeploy ${deploymentRepository.repositoryAlias} repository skipping build in deployment ${deploymentName}?`,
          okButtonProps: {
            style: {
              backgroundColor: "purple",
            },
          },

          okText: "Redeploy Repository Skipping Build",
          onOk: async (): Promise<void> => {
            const { error } = await reDeployDeploymentRepository({
              reDeployDeploymentRepositoryInput: {
                deploymentId,
                repositoryAlias: deploymentRepository.repositoryAlias,
                country: deploymentRepository.country,
                deployOnly: true,
              },
            });

            if (error) {
              notification.error(errorNotificationMessage(error));
              return;
            }

            message.success(
              "Repository will be redeployed skipping build shortly"
            );

            onRefetch();

            Modal.destroyAll();
          },
        });
        break;
      case "update-deployment-repository":
        modal.info({
          icon: null,
          width: "75%",
          title: "Update Repository",
          content: (
            <UpsertDeploymentRepositoryForm
              deploymentId={deploymentId}
              deploymentRepository={deploymentRepository}
              onRefetch={onRefetch}
            />
          ),
          okButtonProps: {
            type: "default",
          },
          okText: "Cancel",
        });
        break;
      case "delete-deployment-repository":
        modal.confirm({
          icon: null,
          title: "Remove Repository from Deployment",
          content: `Are you sure you want to remove ${deploymentRepository.repositoryAlias} repository from deployment ${deploymentName}?`,
          okButtonProps: {
            danger: true,
          },
          okText: "Remove Repository",
          onOk: async (): Promise<void> => {
            const { error } = await deleteDeploymentRepository({
              deleteDeploymentRepositoryInput: {
                deploymentId: deploymentId,
                repositoryAlias: deploymentRepository.repositoryAlias,
                country: deploymentRepository.country,
              },
            });

            if (error) {
              notification.error(errorNotificationMessage(error));
              return;
            }

            message.success("Repository removed from deployment successfully");

            onRefetch();

            Modal.destroyAll();
          },
        });
        break;
      default:
        break;
    }
  };

  if (!items?.length) {
    return null;
  }

  return (
    <Dropdown
      menu={{
        items,
        onClick: onMenuItemSelected,
        disabled: fetching,
        style: { width: 250 },
      }}
      placement="bottom"
      arrow
    >
      <Button loading={fetching} type="primary">
        More
      </Button>
    </Dropdown>
  );
};
