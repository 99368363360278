import { usePagination } from "../../../../../hooks/usePagination";
import { useQuery } from "urql";
import { PaginationResponse } from "../../../../../types/pagination/pagination.type";
import { ErrorView } from "../../../../../components/ErrorView/ErrorView";
import { TableView } from "../../../../../components/TableView/TableView";
import { ReactNode } from "react";
import { TextFilterInput } from "../../../../../components/TextFilterInput/TextFilterInput";
import { DeploymentsMoreButton } from "./components/DeploymentsMoreButton/DeploymentsMoreButton";
import { getFilterValueUtility } from "../../../../../utils/filter/filter.utility";
import { getPaginateDeploymentsQuery } from "../../../../../providers/graphql/queries/getPaginateDeployments.query";
import { DeploymentJSON } from "../../../../../types/deployment/deployment.type";
import {
  DeploymentPurpose,
  DeploymentStatus,
} from "../../../../../types/deployment/deployment.enum";
import {
  deploymentPurposeColorMapping,
  deploymentStatusColorMapping,
  mapEnumValueToKey,
} from "../../../../../utils/enum/enum";
import { Button, Tag } from "antd";
import { Link } from "react-router-dom";

export const DeploymentsTableSection = (): JSX.Element => {
  const { paginationRequest } = usePagination({
    filterNames: [
      "deploymentIds",
      "workflowIds",
      "environmentIds",
      "features",
      "statuses",
      "teamReviewers",
      "teamOwners",
      "userReviewers",
      "userOwners",
      "purposes",
    ],
  });

  const { search, limit, skip, sortField, sortOrder, filters } =
    paginationRequest;

  const [{ data, error, fetching }, refetch] = useQuery<{
    deployments: PaginationResponse<DeploymentJSON>;
  }>({
    query: getPaginateDeploymentsQuery({
      fields: [
        "total",
        {
          data: [
            "id",
            "deploymentName",
            "deploymentId",
            "purpose",
            "status",
            "environmentId",
            "workflowId",
            {
              relations: [
                {
                  environment: ["id", "environmentId", "environmentName"],
                  workflow: ["id", "workflowId", "workflowName"],
                },
              ],
            },
          ],
        },
      ],
    }),
    variables: {
      deploymentPaginationInput: {
        search,
        limit,
        skip,
        sortField,
        sortOrder,
        filters: {
          ...(filters ? { ...filters } : {}),
        },
      },
    },
    requestPolicy: "network-only",
  });

  const deployments = data?.deployments.data ?? [],
    total = data?.deployments.total ?? 0;

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  return (
    <ErrorView error={error} onRefetch={onRefetch}>
      <TableView
        rowKey="deploymentId"
        loading={fetching}
        sortableColumns={["deploymentName"]}
        filterableColumnMapping={{
          deploymentId: "deploymentIds",
          workflowId: "workflowIds",
          environmentId: "environmentIds",
          features: "features",
          status: "statuses",
          purpose: "purposes",
        }}
        dataSource={deployments}
        total={total}
        columns={[
          {
            key: "status",
            dataIndex: "status",
            title: "Status",
            align: "center",
            width: 200,
            defaultFilteredValue: [],
            filterResetToDefaultFilteredValue: false,
            filterMultiple: true,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "statuses",
            }),
            filters: Object.values(DeploymentStatus).map((value) => {
              return {
                text: mapEnumValueToKey(DeploymentStatus, value),
                value,
              };
            }),
            render: (value: string, _record: DeploymentJSON): ReactNode => {
              if (!value) return "-";

              return (
                <Tag
                  color={
                    deploymentStatusColorMapping[value as DeploymentStatus]
                  }
                >
                  {mapEnumValueToKey(DeploymentStatus, value)}
                </Tag>
              );
            },
          },
          {
            key: "deploymentId",
            dataIndex: "deploymentId",
            title: "Deployment ID",
            align: "center",
            width: 200,
            fixed: "left",
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "deploymentIds",
            }),
            filterDropdown: TextFilterInput,
          },
          {
            key: "deploymentName",
            dataIndex: "deploymentName",
            title: "Deployment Name",
            align: "center",
            width: 250,
          },
          {
            key: "workflowId",
            title: "Workflow Name",
            align: "center",
            width: 250,
            render: (_value: string, record: DeploymentJSON): ReactNode => {
              const workflow = record.relations?.workflow;

              if (workflow) {
                return (
                  <Link
                    to={`/dashboard/settings/workflows?workflowIds=${workflow?.workflowId}`}
                    target="_blank"
                  >
                    <Button type="link">{workflow?.workflowName}</Button>
                  </Link>
                );
              }

              return "-";
            },
          },
          {
            key: "environmentId",
            title: "Environment Name",
            align: "center",
            width: 250,
            render: (_value: string, record: DeploymentJSON): ReactNode => {
              const environment = record.relations?.environment;

              if (environment) {
                return (
                  <Link
                    to={`/dashboard/environments?environmentIds=${environment?.environmentId}`}
                    target="_blank"
                  >
                    <Button type="link">{environment?.environmentName}</Button>
                  </Link>
                );
              }

              return "-";
            },
          },
          {
            key: "purpose",
            dataIndex: "purpose",
            title: "Purpose",
            align: "center",
            width: 200,
            defaultFilteredValue: [],
            filterResetToDefaultFilteredValue: false,
            filterMultiple: true,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "purposes",
            }),
            filters: Object.values(DeploymentPurpose).map((value) => {
              return {
                text: mapEnumValueToKey(DeploymentPurpose, value),
                value,
              };
            }),
            render: (value: string, _record: DeploymentJSON): ReactNode => {
              return (
                <Tag
                  color={
                    deploymentPurposeColorMapping[value as DeploymentPurpose]
                  }
                >
                  {mapEnumValueToKey(DeploymentPurpose, value)}
                </Tag>
              );
            },
          },
          {
            key: "more",
            align: "center",
            fixed: "right",
            width: 100,
            render: (_value: undefined, record: DeploymentJSON): ReactNode => {
              return (
                <DeploymentsMoreButton
                  deployment={record}
                  onRefetch={onRefetch}
                />
              );
            },
          },
        ]}
      />
    </ErrorView>
  );
};
