import { NewDeploymentWizardFormFields } from "../NewDeploymentWizardSection";
import { atom } from "jotai";

export const createDeploymentWizardFormDataAtom =
  atom<NewDeploymentWizardFormFields>({});

export const updateCreateDeploymentWizardFormDataAtom = atom(
  null,
  (get, set, value: Partial<NewDeploymentWizardFormFields>) => {
    const createDeploymentWizardFormData = get(
      createDeploymentWizardFormDataAtom
    );
    set(
      createDeploymentWizardFormDataAtom,
      Object.assign(createDeploymentWizardFormData ?? {}, value ?? {})
    );
  }
);

export const clearCreateDeploymentWizardFormDataAtom = atom(null, (_, set) => {
  set(createDeploymentWizardFormDataAtom, {});
});
