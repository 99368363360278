export enum ReleaseStatus {
  INCOMPLETE = "incomplete",
  COMPLETE = "complete",
}

export enum ReleaseRepositoryStatus {
  FAILED_TO_CREATE_RELEASE_TAG = "failed-to-create-release-tag",
  PENDING_APPROVAL = "pending-approval",
  APPROVED_FOR_DEPLOYMENT = "approved-for-deployment",
}
