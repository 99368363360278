import { Breadcrumb, Button, Flex, Typography } from "antd";
import { usePagination } from "../../../../../hooks/usePagination";
import { Link } from "react-router-dom";
import { SearchBar } from "../../../../../components/SearchBar/SearchBar";
import { useUserContext } from "../../../../../context/UserContext";
import { Role } from "../../../../../types/role/role.enum";

export const DeploymentsHeaderSection = (): JSX.Element => {
  const { setPaginationParameters, paginationRequest } = usePagination({});

  const { user } = useUserContext();

  const roles = user?.relations?.roles ?? [];

  const onSearchChanged = (search: string): void => {
    setPaginationParameters({
      search,
    });
  };

  return (
    <Flex justify="space-between" align="center">
      <Flex vertical>
        <Typography.Title level={2}>Deployments</Typography.Title>
        <Breadcrumb
          items={[
            {
              title: <Link to="/dashboard">Dashboard</Link>,
            },
            {
              title: "Deployments",
            },
          ]}
        />
      </Flex>
      <Flex gap="middle">
        <SearchBar
          placeholder={"Search deployments ..."}
          search={paginationRequest.search}
          setSearch={onSearchChanged}
        />
        {roles.includes(Role.CREATE_DEPLOYMENTS) && (
          <Link to={`/dashboard/deployments/new`}>
            <Button size="large" type="primary">
              Create New Deployment
            </Button>
          </Link>
        )}
      </Flex>
    </Flex>
  );
};
