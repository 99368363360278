import { App, Button, Divider, Flex, Form, Select, Typography } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Nullable } from "../../../../../../../../utils/nullable/nullable.type";
import { UpsertEnvironmentWizardFormFields } from "../../../../../../UpsertEnvironmentWizardSection";
import { HoursOfTheDayHibernationOptions } from "./component/HoursOfTheDayHibernationOptions/HoursOfTheDayHibernationOptions";

export type DaysOfTheWeekHibernationOptionsProps = {
  parentIndex: number;
};

export const DaysOfTheWeekHibernationOptions = ({
  parentIndex,
}: DaysOfTheWeekHibernationOptionsProps): JSX.Element => {
  const { notification } = App.useApp();

  const form = Form.useFormInstance<UpsertEnvironmentWizardFormFields>();

  const hibernationOptionsFormData = Form.useWatch("hibernationOptions", form);

  const addedDaysOfTheWeek =
    ((hibernationOptionsFormData ?? [])[parentIndex] ?? []).daysOfTheWeek
      ?.filter((dayOfTheWeek) => typeof dayOfTheWeek !== "undefined")
      ?.map((dayOfTheWeek) => dayOfTheWeek.dayOfTheWeek) ?? [];

  const dayOfTheWeekMapping: Record<string, number> = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };

  const daysOfTheWeek = Object.keys(dayOfTheWeekMapping);

  const daysOfTheWeekThatCanBeAdded = daysOfTheWeek.filter(
    (daysOfTheWeek) =>
      !addedDaysOfTheWeek.includes(dayOfTheWeekMapping[daysOfTheWeek])
  );

  return (
    <>
      <Form.List
        name={[parentIndex, "daysOfTheWeek"]}
        rules={[
          {
            validator: async (_, daysOfTheWeek) => {
              let error: Nullable<string> = null;

              if (!daysOfTheWeek || !daysOfTheWeek.length) {
                error = "At least one day of the week needs to be added";
              }

              if (error) {
                notification.error({
                  message: error,
                });

                return Promise.reject(new Error(error));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <div style={{ paddingLeft: 35, paddingRight: 35 }}>
            <Flex
              justify="space-between"
              align="center"
              style={{ marginBottom: 35 }}
            >
              <Flex vertical>
                <Typography.Title level={3}>Days Of The Week</Typography.Title>
                <Typography.Text type="secondary">
                  Select the days of the week that the environment will
                  hibernate
                </Typography.Text>
              </Flex>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
                disabled={!daysOfTheWeekThatCanBeAdded.length}
              >
                Add New Day Of The Week
              </Button>
            </Flex>
            <Divider />
            {fields.map((_, index) => {
              return (
                <div key={index}>
                  <Flex align="center" justify="space-between">
                    <Typography.Title level={4} type="secondary">
                      Day Of The Week {index + 1}
                    </Typography.Title>
                    <Button
                      danger
                      type="primary"
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(index)}
                      style={{ marginLeft: "auto" }}
                    >
                      Remove Day Of The Week
                    </Button>
                  </Flex>
                  <Form.Item
                    label="Day Of The Week"
                    labelCol={{ span: 24 }}
                    name={[index, "dayOfTheWeek"]}
                    rules={[
                      {
                        required: true,
                        message: "Day of the week is required",
                      },
                    ]}
                  >
                    <Select>
                      {daysOfTheWeekThatCanBeAdded.map((dayOfTheWeek) => {
                        return (
                          <Select.Option
                            key={dayOfTheWeek}
                            value={dayOfTheWeekMapping[dayOfTheWeek]}
                          >
                            {dayOfTheWeek}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <HoursOfTheDayHibernationOptions
                    parentIndex={parentIndex}
                    subParentIndex={index}
                  />
                </div>
              );
            })}
          </div>
        )}
      </Form.List>
    </>
  );
};
