import { Breadcrumb, Flex, Typography } from "antd";
import { Link } from "react-router-dom";

export const VpnHeaderSection = (): JSX.Element => {
  return (
    <Flex justify="space-between" align="center">
      <Flex vertical>
        <Typography.Title level={2}>VPN</Typography.Title>
        <Breadcrumb
          items={[
            {
              title: <Link to="/dashboard">Dashboard</Link>,
            },
            {
              title: <Link to="/dashboard/security">Security</Link>,
            },
            {
              title: "VPN",
            },
          ]}
        />
      </Flex>
    </Flex>
  );
};
