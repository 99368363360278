import { useCallback, useEffect, useState } from "react";
import { usePagination } from "../../hooks/usePagination";
import { Empty, Flex, Table, TableProps } from "antd";
import {
  ColumnType,
  FilterValue,
  SortOrder,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from "antd/es/table/interface";
import { PaginationSortOrder } from "../../types/pagination/pagination.enum";

export type TableViewProps<D> = {
  total?: number;
  defaultSortColumn?: string;
  defaultSortOrder?: PaginationSortOrder;
  columns: Omit<
    TableProps<D>["columns"],
    "sortOrder" | "sorter" | "defaultSortOrder" | "sortDirections" | "sortIcon"
  >;
  sortableColumns?: string[];
  filterableColumnMapping?: Record<string, string>;
  noPagination?: boolean;
} & Omit<TableProps<D>, "pagination">;

const sortTypeMapping: Record<string, PaginationSortOrder> = {
  ascend: PaginationSortOrder.ASC,
  descend: PaginationSortOrder.DESC,
};

const paginationSortOrderMapping: Record<PaginationSortOrder, SortOrder> = {
  [PaginationSortOrder.ASC]: "ascend",
  [PaginationSortOrder.DESC]: "descend",
};

export const TableView = <D extends Record<string, unknown>>({
  total,
  defaultSortColumn,
  defaultSortOrder,
  sortableColumns,
  columns,
  filterableColumnMapping,
  noPagination,
  ...rest
}: TableViewProps<D>): JSX.Element => {
  const {
    defaultPaginationParameters,
    paginationRequest,
    setPaginationParameters,
  } = usePagination({
    defaultSortOrder,
  });

  const currentLimit =
    paginationRequest.limit ?? defaultPaginationParameters.limit;

  const currentPage = Math.max(
    Math.floor((paginationRequest.skip ?? 0) / currentLimit) + 1,
    defaultPaginationParameters.page
  );

  const [page, setPage] = useState<number>(currentPage);

  const [limit, setLimit] = useState<number>(currentLimit);

  const [sortColumn, setSortColumn] = useState<string>(
    paginationRequest.sortField ?? defaultSortColumn ?? ""
  );

  const [sortType, setSortType] = useState<SortOrder>(
    paginationRequest.sortOrder
      ? paginationSortOrderMapping[paginationRequest.sortOrder]
      : paginationSortOrderMapping[defaultPaginationParameters.sortOrder]
  );

  useEffect(() => {
    setPaginationParameters({
      skip: (Math.max(page, 1) - 1) * limit,
      limit: limit,
      ...(sortColumn
        ? {
            sortField: sortColumn,
            sortOrder:
              sortTypeMapping[
                sortType ?? defaultPaginationParameters.sortOrder
              ],
          }
        : {}),
    });
  }, [
    page,
    limit,
    sortColumn,
    sortType,
    defaultPaginationParameters.sortOrder,
    setPaginationParameters,
  ]);

  const setNewLimit = useCallback(
    (limit: number): void => {
      setPage(defaultPaginationParameters.page);
      setLimit(limit);
    },
    [defaultPaginationParameters.page]
  );

  return (
    <Table
      scroll={{ x: "100%", y: "65vh" }}
      size="large"
      bordered
      locale={{
        emptyText: (
          <Flex
            justify="center"
            align="center"
            style={{
              height: "60vh",
            }}
          >
            <Empty description="No data" />
          </Flex>
        ),
      }}
      {...rest}
      columns={columns?.map((column) => {
        let sortOrderToSet: SortOrder = null;

        const { dataIndex } = column as ColumnType<D>;

        if (dataIndex === sortColumn) {
          sortOrderToSet = sortType;
        }

        return {
          ...column,
          sortOrder: sortOrderToSet,
          sorter: sortableColumns?.includes(dataIndex?.toString() ?? "")
            ? (): number => 0
            : undefined,
        };
      })}
      onChange={(
        _pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<D> | SorterResult<D>[],
        _extra: TableCurrentDataSource<D>
      ): void => {
        const parsedFilters: Record<string, FilterValue | null> = {};

        Object.keys(filters).forEach((filterKey) => {
          const mapping = filterableColumnMapping ?? {};

          if (Object.keys(mapping).includes(filterKey)) {
            const key = mapping[filterKey];

            parsedFilters[key] = filters[filterKey];
          }
        });

        const { column, order } = sorter as SorterResult<D>;

        const sortField = order ? column?.dataIndex?.toString() ?? "" : "";

        setSortColumn(sortField);

        setSortType(
          order ??
            paginationSortOrderMapping[defaultPaginationParameters.sortOrder]
        );

        setPaginationParameters({
          ...parsedFilters,
        });
      }}
      pagination={
        noPagination
          ? false
          : {
              hideOnSinglePage: true,
              showQuickJumper: true,
              total,
              current: page,
              pageSizeOptions: [10, 25, 50, 100],
              pageSize: limit,
              onChange: (page: number, pageSize: number): void => {
                if (limit !== pageSize) {
                  setNewLimit(pageSize);
                } else {
                  setPage(page);
                }
              },
            }
      }
    />
  );
};
