import {
  Alert,
  App,
  Button,
  Divider,
  Flex,
  Form,
  Select,
  Typography,
} from "antd";
import { useRepositoriesContext } from "../../../../../../../../../../context/RepositoriesContext";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Country } from "../../../../../../../../../../types/country/country.enum";
import { mapEnumValueToKey } from "../../../../../../../../../../utils/enum/enum";
import { NewReleaseWizardFormFields } from "../../../../NewReleaseWizardSection";
import { SelectBranchInput } from "../../../../../../../../../../components/SelectBranchInput/SelectBranchInput";
import { Nullable } from "../../../../../../../../../../utils/nullable/nullable.type";
import { TagVersionInput } from "../../../../../../../../../../components/TagVersionInput/TagVersionInput";

export const ReleaseRepositorySelectionFormSection = (): JSX.Element => {
  const { repositories, repositoryMapping } = useRepositoriesContext();

  const form = Form.useFormInstance<NewReleaseWizardFormFields>();

  const { notification } = App.useApp();

  const repositoriesFormData = Form.useWatch("repositories", form);

  if (!repositories || !repositoryMapping) {
    return (
      <Alert
        message="No repositories found. Please check if you have permissions to view repositories"
        type="error"
      />
    );
  }

  const addedRepositories =
    repositoriesFormData
      ?.filter((repository) => repository && repository.repositoryAlias)
      .map((repository) => repository.repositoryAlias!) ?? [];

  const repositoriesThatCanBeAdded =
    repositories?.filter(
      (repository) => !addedRepositories.includes(repository.repositoryAlias)
    ) ?? [];

  return (
    <>
      <Form.List
        name="repositories"
        rules={[
          {
            validator: async (_, repositories) => {
              let error: Nullable<string> = null;

              if (!repositories || !repositories.length) {
                error = "At least one repository is required";
              }

              if (error) {
                notification.error({
                  message: error,
                });

                return Promise.reject(new Error(error));
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            <Flex justify="space-between" align="center">
              <div></div>
              <Flex vertical>
                <Typography.Title
                  level={3}
                  style={{ display: "block", textAlign: "center" }}
                >
                  Repositories Selection
                </Typography.Title>
                <Typography.Text
                  type="secondary"
                  style={{ display: "block", textAlign: "center" }}
                >
                  Select the repositories to include in the release
                </Typography.Text>
              </Flex>
              <Button
                type="dashed"
                onClick={() => {
                  const [repository] = repositoriesThatCanBeAdded;

                  add({
                    repositoryAlias: repository.repositoryAlias,
                    ref: repository.mainRef,
                    countries: repository.countryOptions.enabledForReleases
                      ? repository.countries.includes(Country.SAUDI_ARABIA)
                        ? [Country.SAUDI_ARABIA]
                        : repository.countries
                      : null,
                    version: "",
                  });
                }}
                icon={<PlusOutlined />}
                disabled={!repositoriesThatCanBeAdded.length}
              >
                Add New Repository
              </Button>
            </Flex>
            <Divider />
            {fields.map((_, index) => {
              const repositoryAlias =
                repositoriesFormData?.[index]?.repositoryAlias ?? "";

              const isRepositoryAliasSelected = !!repositoryAlias;

              const isCountrySelectionEnabled = isRepositoryAliasSelected
                ? repositoryMapping[repositoryAlias]?.countryOptions
                    ?.enabledForReleases
                : false;

              const countries =
                repositoryMapping[repositoryAlias]?.countries ?? [];

              const mainRef = repositoryMapping[repositoryAlias]?.mainRef ?? "";

              return (
                <div key={index}>
                  <Flex align="center" justify="space-between">
                    <Typography.Title level={2} type="secondary">
                      Repository {index + 1}
                    </Typography.Title>
                    <Button
                      danger
                      type="primary"
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(index)}
                      style={{ marginLeft: "auto" }}
                    >
                      Remove Repository
                    </Button>
                  </Flex>
                  <Form.Item
                    label="Repository"
                    labelCol={{ span: 24 }}
                    name={[index, "repositoryAlias"]}
                    rules={[
                      {
                        required: true,
                        message: "Repository is required",
                      },
                    ]}
                  >
                    <Select showSearch>
                      {repositoriesThatCanBeAdded.map((repository) => {
                        return (
                          <Select.Option
                            key={repository.repositoryAlias}
                            value={repository.repositoryAlias}
                          >
                            {repository.repositoryAlias}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  {isRepositoryAliasSelected && (
                    <>
                      <Form.Item
                        name={[index, "ref"]}
                        label="Branch To Create Tag From"
                        rules={[
                          {
                            required: true,
                            message: "Branch to create tag from is required",
                          },
                        ]}
                      >
                        <SelectBranchInput
                          mainRef={mainRef}
                          repositoryName={
                            repositoryMapping[repositoryAlias].repositoryName
                          }
                        />
                      </Form.Item>
                      {isCountrySelectionEnabled && !!countries.length && (
                        <Form.Item
                          name={[index, "countries"]}
                          label="Countries To Deploy To"
                          rules={[
                            {
                              required: true,
                              message: "Countries to deploy to is required",
                            },
                          ]}
                        >
                          <Select mode="multiple">
                            {countries.map((country) => {
                              return (
                                <Select.Option key={country} value={country}>
                                  {mapEnumValueToKey(Country, country)}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      )}
                      <Form.Item
                        name={[index, "version"]}
                        label="Version"
                        rules={[
                          { required: true, message: "Version is required" },
                          {
                            pattern: /v[0-9]+.[0-9]+.[0-9]+/,
                            message: "Invalid version format",
                          },
                        ]}
                      >
                        <TagVersionInput
                          repositoryName={
                            repositoryMapping[repositoryAlias].repositoryName
                          }
                        />
                      </Form.Item>
                    </>
                  )}

                  <Divider />
                </div>
              );
            })}
          </>
        )}
      </Form.List>
    </>
  );
};
