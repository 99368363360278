import { Flex } from "antd";
import { ReleaseViewSection } from "./components/ReleaseViewSection/ReleaseViewSection";

const ReleasePage = (): JSX.Element => {
  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <ReleaseViewSection />
    </Flex>
  );
};

export default ReleasePage;
