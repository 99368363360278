import { ReactNode } from "react";
import { TableView } from "../TableView/TableView";
import {
  ReleaseJSON,
  ReleaseRepository,
} from "../../types/release/release.type";
import { RepositoryLink } from "../RepositoryLink/RepositoryLink";
import { RefLink } from "../RefLink/RefLink";
import { TagLink } from "../TagLink/TagLink";
import { Flex, Tag, Tooltip, Typography } from "antd";
import { ReleaseRepositoryStatus } from "../../types/release/release.enum";
import {
  countryColorMapping,
  mapEnumValueToKey,
  releaseRepositoryStatusColorMapping,
  runStatusColorMapping,
} from "../../utils/enum/enum";
import { Country } from "../../types/country/country.enum";
import { RunStatus } from "../../types/run/run.enum";
import { RunIdLink } from "../RunIdLink/RunIdLink";
import { ErrorView } from "../ErrorView/ErrorView";
import { CommitLink } from "../CommitLink/CommitLink";
import { CombinedError } from "urql";
import { ReleaseRepositoryMoreButton } from "./components/ReleaseRepositoryMoreButton/ReleaseRepositoryMoreButton";
import { useRepositoriesContext } from "../../context/RepositoriesContext";

export type ReleaseRepositoriesTableProps = {
  title?: ReactNode;
  search?: string;
  style?: React.CSSProperties;
  release?: ReleaseJSON;
  onRefetch: () => Promise<void>;
  error?: CombinedError;
  onRowsSelected?: (ids: string[]) => void;
};

export const ReleaseRepositoriesTable = ({
  title,
  search,
  style,
  release,
  error,
  onRefetch,
  onRowsSelected,
}: ReleaseRepositoriesTableProps): JSX.Element => {
  const { repositoryMapping } = useRepositoriesContext();

  return (
    <div style={style}>
      <ErrorView error={error} onRefetch={onRefetch} hideChildrenOnError>
        <TableView
          noPagination
          total={release?.repositories?.length ?? 0}
          rowKey="id"
          rowSelection={
            onRowsSelected
              ? {
                  type: "checkbox",
                  onChange: (selectedRowKeys: React.Key[]) => {
                    onRowsSelected(selectedRowKeys as string[]);
                  },
                }
              : undefined
          }
          dataSource={
            release?.repositories.filter((repository) => {
              if (!search) {
                return true;
              }

              return (
                repository.repositoryAlias
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                repository.repositoryName
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                repository.ref.toLowerCase().includes(search.toLowerCase()) ||
                repository.version.toLowerCase().includes(search.toLowerCase())
              );
            }) ?? []
          }
          title={(): ReactNode => title}
          columns={[
            {
              key: "status",
              dataIndex: "status",
              title: "Pipeline Status",
              align: "center",
              width: 250,
              render: (value: string, record: ReleaseRepository): ReactNode => {
                const tag = (
                  <Tag
                    color={
                      releaseRepositoryStatusColorMapping[
                        value as ReleaseRepositoryStatus
                      ]
                    }
                  >
                    {mapEnumValueToKey(ReleaseRepositoryStatus, value)}
                  </Tag>
                );

                if (
                  value === ReleaseRepositoryStatus.FAILED_TO_CREATE_RELEASE_TAG
                ) {
                  return <Tooltip title={record.error}>{tag}</Tooltip>;
                }

                return tag;
              },
            },
            {
              key: "repositoryAlias",
              dataIndex: "repositoryAlias",
              title: "Repository",
              align: "center",
              width: 250,
              fixed: "left",
              render: (
                _value: string,
                record: ReleaseRepository
              ): ReactNode => {
                return (
                  <RepositoryLink
                    repositoryAlias={record.repositoryAlias}
                    repositoryName={record.repositoryName}
                  />
                );
              },
            },
            {
              key: "buildStatus",
              title: "Build Status",
              align: "center",
              width: 175,
              render: (
                _value: string,
                record: ReleaseRepository
              ): ReactNode => {
                const run =
                  (release?.relations?.latestBuildRuns ?? []).find(
                    (run) => run.tertiaryId === record.buildTrackId
                  ) ?? null;

                if (!run) {
                  return "-";
                }

                return (
                  <RunIdLink
                    run={run}
                    component={
                      <Tag color={runStatusColorMapping[run.status]}>
                        {mapEnumValueToKey(RunStatus, run.status)}
                      </Tag>
                    }
                  />
                );
              },
            },
            {
              key: "deploymentStatus",
              title: "Deployment Status",
              align: "center",
              width: 175,
              render: (
                _value: string,
                record: ReleaseRepository
              ): ReactNode => {
                const latestBuildRun =
                  (release?.relations?.latestBuildRuns ?? []).find(
                    (run) => run.tertiaryId === record.buildTrackId
                  ) ?? null;

                if (!latestBuildRun) {
                  return "-";
                }

                if (
                  latestBuildRun.status === RunStatus.QUEUED ||
                  latestBuildRun.status === RunStatus.RUNNING
                ) {
                  return (
                    <Tag color={runStatusColorMapping[RunStatus.QUEUED]}>
                      {"Awaiting Build".toUpperCase()}
                    </Tag>
                  );
                }

                if (latestBuildRun.status === RunStatus.FAILED) {
                  return (
                    <Tag color={runStatusColorMapping[RunStatus.FAILED]}>
                      {"Build Failed".toUpperCase()}
                    </Tag>
                  );
                }

                const hasDeploymentSettings =
                  repositoryMapping &&
                  repositoryMapping[record.repositoryAlias] &&
                  repositoryMapping[record.repositoryAlias].deploySettings;

                if (!hasDeploymentSettings) {
                  return "N/A";
                }

                const run =
                  (release?.relations?.latestDeploymentRuns ?? []).find(
                    (run) => run.tertiaryId === record.deployTrackId
                  ) ?? null;

                if (!run) {
                  return "-";
                }

                return (
                  <RunIdLink
                    run={run}
                    component={
                      <Tag color={runStatusColorMapping[run.status]}>
                        {mapEnumValueToKey(RunStatus, run.status)}
                      </Tag>
                    }
                  />
                );
              },
            },
            {
              key: "country",
              dataIndex: "country",
              title: "Country",
              align: "center",
              width: 200,
              render: (
                value: string,
                _record: ReleaseRepository
              ): ReactNode => {
                return (
                  <Tag color={countryColorMapping[value as Country]}>
                    {mapEnumValueToKey(Country, value)}
                  </Tag>
                );
              },
            },
            {
              key: "version",
              dataIndex: "version",
              title: "Version",
              align: "center",
              width: 250,
              render: (
                _value: string,
                record: ReleaseRepository
              ): ReactNode => {
                return (
                  <Flex gap="small" align="center" justify="center">
                    <TagLink releaseRepository={record} />

                    <Typography.Text
                      type={record.error ? "danger" : "secondary"}
                    >
                      {record.error
                        ? record.error
                        : record.existing
                        ? "( Existing Tag )"
                        : "( New Tag )"}
                    </Typography.Text>
                  </Flex>
                );
              },
            },
            {
              key: "ref",
              dataIndex: "ref",
              title: "Ref",
              align: "center",
              width: 200,
              render: (
                _value: string,
                record: ReleaseRepository
              ): ReactNode => {
                return (
                  <RefLink
                    repositoryName={record.repositoryName}
                    refName={record.ref}
                  />
                );
              },
            },
            {
              key: "commit",
              dataIndex: "commit",
              title: "Commit",
              align: "center",
              width: 400,
              render: (
                _value: string,
                record: ReleaseRepository
              ): ReactNode => {
                if (!record.commit) {
                  return "-";
                }

                return (
                  <CommitLink
                    repositoryName={record.repositoryName}
                    commit={record.commit}
                  />
                );
              },
            },
            {
              key: "more",
              align: "center",
              fixed: "right",
              width: 200,
              render: (
                _value: string,
                record: ReleaseRepository
              ): ReactNode => {
                return (
                  !release?.isReadOnly && (
                    <ReleaseRepositoryMoreButton
                      releaseRepository={record}
                      releaseName={release?.releaseName!}
                      onRefetch={onRefetch}
                    />
                  )
                );
              },
            },
          ]}
        />
      </ErrorView>
    </div>
  );
};
