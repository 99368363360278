import { Empty, Flex, List, Typography } from "antd";
import { CSSProperties, ReactNode } from "react";

export type ListViewerProps = {
  style?: CSSProperties;
  title?: string;
  data: Array<{
    title: ReactNode;
    description?: ReactNode;
  }>;
  emptyText?: string;
};

export const ListViewer = ({
  style,
  title,
  data,
  emptyText,
}: ListViewerProps): JSX.Element => {
  return (
    <List
      header={
        <>{title && <Typography.Title level={3}>{title}</Typography.Title>}</>
      }
      locale={{
        emptyText: (
          <Flex
            justify="center"
            align="center"
            style={{
              height: style?.height,
            }}
          >
            <Empty description={emptyText ?? "No data"} />
          </Flex>
        ),
      }}
      style={style}
      dataSource={data}
      renderItem={(item, index) => (
        <List.Item key={index}>
          <List.Item.Meta title={item.title} description={item.description} />
        </List.Item>
      )}
    />
  );
};
