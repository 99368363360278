export enum DatabaseMigrationStatus {
  TESTING = "testing",
  CLOSED = "closed",
  MIGRATED = "migrated",
}

export enum DatabaseMigrationType {
  POSTGRES = "postgres",
  MONGODB = "mongodb",
}
