import { Flex } from "antd";
import { UsersTableSection } from "./components/UsersTableSection/UsersTableSection";
import { UsersHeaderSection } from "./components/UsersHeaderSection/UsersHeaderSection";

const UsersPage = (): JSX.Element => {
  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <UsersHeaderSection />
      <UsersTableSection />
    </Flex>
  );
};

export default UsersPage;
