import { App, Breadcrumb, Button, Flex, Typography } from "antd";
import { usePagination } from "../../../../../hooks/usePagination";
import { Link } from "react-router-dom";
import { SearchBar } from "../../../../../components/SearchBar/SearchBar";
import { UpsertTeamForm } from "../../../../../components/UpsertTeamForm/UpsertTeamForm";
import { useUserContext } from "../../../../../context/UserContext";
import { Role } from "../../../../../types/role/role.enum";

export const TeamsHeaderSection = (): JSX.Element => {
  const { setPaginationParameters, paginationRequest } = usePagination({});

  const { modal } = App.useApp();

  const { user } = useUserContext();

  const roles = user?.relations?.roles ?? [];

  const onSearchChanged = (search: string): void => {
    setPaginationParameters({
      search,
    });
  };

  const onCreateTeam = (): void => {
    modal.info({
      icon: null,
      width: "75%",
      title: "Create Team",
      content: <UpsertTeamForm />,
      okButtonProps: {
        type: "default",
      },
      okText: "Cancel",
    });
  };

  return (
    <Flex justify="space-between" align="center">
      <Flex vertical>
        <Typography.Title level={2}>Teams</Typography.Title>
        <Breadcrumb
          items={[
            {
              title: <Link to="/dashboard">Dashboard</Link>,
            },
            {
              title: "Teams",
            },
          ]}
        />
      </Flex>
      <Flex gap="middle">
        <SearchBar
          placeholder={"Search teams ..."}
          search={paginationRequest.search}
          setSearch={onSearchChanged}
        />
        {roles.includes(Role.CREATE_TEAMS) && (
          <Button size="large" type="primary" onClick={onCreateTeam}>
            Create New Team
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
