import { TagProps } from "antd";
import { RunStatus } from "../../types/run/run.enum";
import {
  ReleaseRepositoryStatus,
  ReleaseStatus,
} from "../../types/release/release.enum";
import { Country } from "../../types/country/country.enum";
import { RunPlatform } from "../../types/run.platform/run.platform.enum";
import { StatusOverrideStatus } from "../../types/status.override/status.override.enum";
import {
  DatabaseMigrationStatus,
  DatabaseMigrationType,
} from "../../types/database.migration/database.migration.enum";
import {
  EnvironmentAccount,
  EnvironmentPurpose,
  EnvironmentSyncMode,
} from "../../types/environment/environment.enum";
import { BooleanEnum } from "../../types/boolean/boolean.enum";
import {
  DeploymentFeature,
  DeploymentPurpose,
  DeploymentStatus,
} from "../../types/deployment/deployment.enum";

export const mapEnumValueToKey = <E extends Record<string, string>>(
  enumObject: E,
  value: string
): string => {
  return Object.keys(enumObject).find((key) => enumObject[key] === value)!;
};

export const runStatusColorMapping: Record<RunStatus, TagProps["color"]> = {
  [RunStatus.QUEUED]: "orange",
  [RunStatus.RUNNING]: "blue",
  [RunStatus.SUCCEEDED]: "green",
  [RunStatus.FAILED]: "red",
};

export const runPlatformColorMapping: Record<RunPlatform, TagProps["color"]> = {
  [RunPlatform.BITRISE]: "magenta",
  [RunPlatform.GITHUB_ACTION]: "purple",
};

export const releaseStatusColorMapping: Record<
  ReleaseStatus,
  TagProps["color"]
> = {
  [ReleaseStatus.INCOMPLETE]: "blue",
  [ReleaseStatus.COMPLETE]: "green",
};

export const releaseRepositoryStatusColorMapping: Record<
  ReleaseRepositoryStatus,
  TagProps["color"]
> = {
  [ReleaseRepositoryStatus.FAILED_TO_CREATE_RELEASE_TAG]: "red",
  [ReleaseRepositoryStatus.PENDING_APPROVAL]: "orange",
  [ReleaseRepositoryStatus.APPROVED_FOR_DEPLOYMENT]: "green",
};

export const countryColorMapping: Record<Country, TagProps["color"]> = {
  [Country.SAUDI_ARABIA]: "gold",
  [Country.UNITED_ARAB_EMIRATES]: "cyan",
};

export const statusOverrideStatusColorMapping: Record<
  StatusOverrideStatus,
  TagProps["color"]
> = {
  [StatusOverrideStatus.FAILED]: "red",
  [StatusOverrideStatus.PENDING]: "orange",
  [StatusOverrideStatus.PROCESSED]: "blue",
};

export const databaseMigrationTypeColorMapping: Record<
  DatabaseMigrationType,
  TagProps["color"]
> = {
  [DatabaseMigrationType.MONGODB]: "purple",
  [DatabaseMigrationType.POSTGRES]: "cyan",
};

export const databaseMigrationStatusColorMapping: Record<
  DatabaseMigrationStatus,
  TagProps["color"]
> = {
  [DatabaseMigrationStatus.TESTING]: "orange",
  [DatabaseMigrationStatus.CLOSED]: "red",
  [DatabaseMigrationStatus.MIGRATED]: "blue",
};

export const environmentAccountColorMapping: Record<
  EnvironmentAccount,
  TagProps["color"]
> = {
  [EnvironmentAccount.DEVELOPMENT]: "blue",
  [EnvironmentAccount.PRODUCTION]: "purple",
};

export const environmentPurposeColorMapping: Record<
  EnvironmentPurpose,
  TagProps["color"]
> = {
  [EnvironmentPurpose.DEVELOPMENT]: "gold",
  [EnvironmentPurpose.TESTING]: "default",
};

export const environmentSyncModeColorMapping: Record<
  EnvironmentSyncMode,
  TagProps["color"]
> = {
  [EnvironmentSyncMode.DAILY]: "green",
  [EnvironmentSyncMode.WEEEKLY]: "lime",
  [EnvironmentSyncMode.MONTHLY]: "cyan",
  [EnvironmentSyncMode.MANUAL]: "volcano",
};

export const booleanEnumColorMapping: Record<BooleanEnum, TagProps["color"]> = {
  [BooleanEnum.YES]: "green",
  [BooleanEnum.NO]: "red",
};

export const deploymentFeatureColorMapping: Record<
  DeploymentFeature,
  TagProps["color"]
> = {
  [DeploymentFeature.SWITCH_BACKEND_TO_CONNECT_TO_STAGING_DATABASE]: "purple",
  [DeploymentFeature.SWITCH_FRONTEND_TO_CONNECT_TO_STAGING_API]: "volcano",
};

export const deploymentStatusColorMapping: Record<
  DeploymentStatus,
  TagProps["color"]
> = {
  [DeploymentStatus.IN_PROGRESS]: "blue",
  [DeploymentStatus.IN_REVIEW]: "orange",
  [DeploymentStatus.REVIEWED]: "green",
  [DeploymentStatus.FAILED_REVIEW]: "red",
};

export const deploymentPurposeColorMapping: Record<
  DeploymentPurpose,
  TagProps["color"]
> = {
  [DeploymentPurpose.DEVELOPMENT]: "blue",
  [DeploymentPurpose.TESTING]: "purple",
};
