import { usePagination } from "../../../../../../hooks/usePagination";
import { useQuery } from "urql";
import { PaginationResponse } from "../../../../../../types/pagination/pagination.type";
import { ErrorView } from "../../../../../../components/ErrorView/ErrorView";
import { TableView } from "../../../../../../components/TableView/TableView";
import { TextFilterInput } from "../../../../../../components/TextFilterInput/TextFilterInput";
import { getFilterValueUtility } from "../../../../../../utils/filter/filter.utility";
import { RunStatus } from "../../../../../../types/run/run.enum";
import { Tag } from "antd";
import {
  databaseMigrationStatusColorMapping,
  databaseMigrationTypeColorMapping,
  mapEnumValueToKey,
  runStatusColorMapping,
} from "../../../../../../utils/enum/enum";
import { ReactNode } from "react";
import { RepositoryLink } from "../../../../../../components/RepositoryLink/RepositoryLink";
import { RefLink } from "../../../../../../components/RefLink/RefLink";
import { CommitLink } from "../../../../../../components/CommitLink/CommitLink";
import { RunIdLink } from "../../../../../../components/RunIdLink/RunIdLink";
import { getPaginateDatabaseMigrationsQuery } from "../../../../../../providers/graphql/queries/getPaginateDatabaseMigrations.query";
import { DatabaseMigrationJSON } from "../../../../../../types/database.migration/database.migration.type";
import {
  DatabaseMigrationStatus,
  DatabaseMigrationType,
} from "../../../../../../types/database.migration/database.migration.enum";
import { PullRequestLink } from "../../../../../../components/PullRequestLink/PullRequestLink";
import { UserLink } from "../../../../../../components/UserLink/UserLink";

export const DatabaseMigrationsTableSection = (): JSX.Element => {
  const { paginationRequest } = usePagination({
    filterNames: [
      "databaseMigrationIds",
      "pullRequestIds",
      "repositoryNames",
      "owners",
      "refs",
      "commits",
      "types",
      "statuses",
    ],
  });

  const { search, limit, skip, sortField, sortOrder, filters } =
    paginationRequest;

  const [{ data, error, fetching }, refetch] = useQuery<{
    databaseMigrations: PaginationResponse<DatabaseMigrationJSON>;
  }>({
    query: getPaginateDatabaseMigrationsQuery({
      fields: [
        "total",
        {
          data: [
            "id",
            "title",
            "owner",
            "pullRequestId",
            "repositoryName",
            "status",
            "type",
            "databaseMigrationId",
            "ref",
            "commit",
            {
              relations: [
                {
                  user: ["id", "fullName", "email"],
                  latestTestRun: [
                    "runId",
                    "repositoryName",
                    "runPlatform",
                    "status",
                  ],
                  latestProductionRun: [
                    "runId",
                    "repositoryName",
                    "runPlatform",
                    "status",
                  ],
                },
              ],
            },
          ],
        },
      ],
    }),
    variables: {
      databaseMigrationPaginationInput: {
        search,
        limit,
        skip,
        sortField,
        sortOrder,
        filters: {
          ...(filters ? { ...filters } : {}),
        },
      },
    },
    requestPolicy: "network-only",
  });

  const databaseMigrations = data?.databaseMigrations?.data ?? [],
    total = data?.databaseMigrations?.total ?? 0;

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  return (
    <ErrorView error={error} onRefetch={onRefetch}>
      <TableView
        rowKey="databaseMigrationId"
        loading={fetching}
        filterableColumnMapping={{
          databaseMigrationId: "databaseMigrationIds",
          pullRequestId: "pullRequestIds",
          repositoryName: "repositoryNames",
          owner: "owners",
          ref: "refs",
          commit: "commits",
          type: "types",
          status: "statuses",
        }}
        dataSource={databaseMigrations}
        total={total}
        columns={[
          {
            key: "status",
            dataIndex: "status",
            title: "Status",
            align: "center",
            width: 200,
            defaultFilteredValue: [],
            filterResetToDefaultFilteredValue: false,
            filterMultiple: true,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "statuses",
            }),
            filters: Object.values(DatabaseMigrationStatus).map((value) => {
              return {
                text: mapEnumValueToKey(DatabaseMigrationStatus, value),
                value,
              };
            }),
            render: (
              value: string,
              _record: DatabaseMigrationJSON
            ): ReactNode => {
              return (
                <Tag
                  color={
                    databaseMigrationStatusColorMapping[
                      value as DatabaseMigrationStatus
                    ]
                  }
                >
                  {mapEnumValueToKey(DatabaseMigrationStatus, value)}
                </Tag>
              );
            },
          },
          {
            key: "pullRequestId",
            dataIndex: "pullRequestId",
            title: "Pull Request",
            align: "center",
            width: 400,
            fixed: "left",
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "pullRequestIds",
            }),
            filterDropdown: TextFilterInput,
            render: (
              _value: string,
              record: DatabaseMigrationJSON
            ): ReactNode => {
              return (
                <PullRequestLink
                  pullRequestId={record.pullRequestId}
                  repositoryName={record.repositoryName}
                  title={record.title}
                />
              );
            },
          },
          {
            key: "type",
            dataIndex: "type",
            title: "Database Type",
            align: "center",
            width: 200,
            defaultFilteredValue: [],
            filterResetToDefaultFilteredValue: false,
            filterMultiple: true,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "types",
            }),
            filters: Object.values(DatabaseMigrationType).map((value) => {
              return {
                text: mapEnumValueToKey(DatabaseMigrationType, value),
                value,
              };
            }),
            render: (
              value: string,
              _record: DatabaseMigrationJSON
            ): ReactNode => {
              return (
                <Tag
                  color={
                    databaseMigrationTypeColorMapping[
                      value as DatabaseMigrationType
                    ]
                  }
                >
                  {mapEnumValueToKey(DatabaseMigrationType, value)}
                </Tag>
              );
            },
          },
          {
            key: "repositoryName",
            dataIndex: "repositoryName",
            title: "Repository Name",
            align: "center",
            width: 300,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "repositoryNames",
            }),
            filterDropdown: TextFilterInput,
            render: (
              value: string,
              _record: DatabaseMigrationJSON
            ): ReactNode => {
              return <RepositoryLink repositoryName={value} />;
            },
          },
          {
            key: "latestTestRun",
            title: "Latest Test Run",
            align: "center",
            width: 200,
            render: (
              _value: string,
              record: DatabaseMigrationJSON
            ): ReactNode => {
              const run = record?.relations?.latestTestRun ?? null;

              if (!run) {
                return "-";
              }

              return (
                <RunIdLink
                  run={run}
                  component={
                    <Tag color={runStatusColorMapping[run.status]}>
                      {mapEnumValueToKey(RunStatus, run.status)}
                    </Tag>
                  }
                />
              );
            },
          },
          {
            key: "latestProductionRun",
            title: "Latest Production Run",
            align: "center",
            width: 200,
            render: (
              _value: string,
              record: DatabaseMigrationJSON
            ): ReactNode => {
              const run = record?.relations?.latestProductionRun ?? null;

              if (!run) {
                return "-";
              }

              return (
                <RunIdLink
                  run={run}
                  component={
                    <Tag color={runStatusColorMapping[run.status]}>
                      {mapEnumValueToKey(RunStatus, run.status)}
                    </Tag>
                  }
                />
              );
            },
          },
          {
            key: "owner",
            title: "Owner",
            align: "center",
            width: 200,
            render: (
              _value: undefined,
              record: DatabaseMigrationJSON
            ): ReactNode => {
              return (
                <UserLink
                  fullName={record.relations?.user?.fullName}
                  email={record.owner}
                />
              );
            },
          },
          {
            key: "ref",
            dataIndex: "ref",
            title: "Ref",
            align: "center",
            width: 300,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "refs",
            }),
            filterDropdown: TextFilterInput,
            render: (
              _value: string,
              record: DatabaseMigrationJSON
            ): ReactNode => {
              return (
                <RefLink
                  repositoryName={record.repositoryName}
                  refName={record.ref}
                />
              );
            },
          },
          {
            key: "commit",
            dataIndex: "commit",
            title: "Commit SHA",
            align: "center",
            width: 400,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "refs",
            }),
            filterDropdown: TextFilterInput,
            render: (
              _value: string,
              record: DatabaseMigrationJSON
            ): ReactNode => {
              return (
                <CommitLink
                  repositoryName={record.repositoryName}
                  commit={record.commit}
                />
              );
            },
          },
        ]}
      />
    </ErrorView>
  );
};
