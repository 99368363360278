export enum Role {
  VIEW_USERS = "view-users",
  CREATE_USERS = "create-users",
  UPDATE_USERS = "update-users",
  DELETE_USERS = "delete-users",

  VIEW_GROUPS = "view-groups",
  CREATE_GROUPS = "create-groups",
  UPDATE_GROUPS = "update-groups",
  DELETE_GROUPS = "delete-groups",

  VIEW_TEAMS = "view-teams",
  CREATE_TEAMS = "create-teams",
  UPDATE_TEAMS = "update-teams",
  DELETE_TEAMS = "delete-teams",

  VIEW_REPOSITORIES = "view-repositories",
  CREATE_REPOSITORIES = "create-repositories",
  UPDATE_REPOSITORIES = "update-repositories",
  DELETE_REPOSITORIES = "delete-repositories",

  VIEW_WORKFLOWS = "view-workflows",
  CREATE_WORKFLOWS = "create-workflows",
  UPDATE_WORKFLOWS = "update-workflows",
  DELETE_WORKFLOWS = "delete-workflows",

  VIEW_RUNS = "view-runs",

  VIEW_RELEASES = "view-releases",
  CREATE_RELEASES = "create-releases",
  UPDATE_RELEASES = "update-releases",
  DELETE_RELEASES = "delete-releases",

  VIEW_STATUS_OVERRIDES = "view-status-overrides",
  CREATE_STATUS_OVERRIDES = "create-status-overrides",
  UPDATE_STATUS_OVERRIDES = "update-status-overrides",
  DELETE_STATUS_OVERRIDES = "delete-status-overrides",

  VIEW_DATABASE_MIGRATIONS = "view-database-migrations",

  VIEW_ENVIRONMENTS = "view-environments",
  CREATE_ENVIRONMENTS = "create-environments",
  UPDATE_ENVIRONMENTS = "update-environments",
  DELETE_ENVIRONMENTS = "delete-environments",

  VIEW_DEPLOYMENTS = "view-deployments",
  CREATE_DEPLOYMENTS = "create-deployments",
  UPDATE_DEPLOYMENTS = "update-deployments",
  DELETE_DEPLOYMENTS = "delete-deployments",

  VIEW_RULE_SETS = "view-rule-sets",
  CREATE_RULE_SETS = "create-rule-sets",
  UPDATE_RULE_SETS = "update-rule-sets",
  DELETE_RULE_SETS = "delete-rule-sets",

  VIEW_TESTS = "view-tests",
  CREATE_TESTS = "create-tests",
  UPDATE_TESTS = "update-tests",
  DELETE_TESTS = "delete-tests",

  VIEW_VPN_CLIENT_CONFIGURATION = "view-vpn-client-configuration",
}
