import {
  Button,
  Collapse,
  CollapseProps,
  Flex,
  Input,
  Result,
  Spin,
  Typography,
} from "antd";
import { ErrorView } from "../../../../../../components/ErrorView/ErrorView";
import { useState } from "react";
import { vpnClientConfigurationQuery } from "../../../../../../providers/graphql/queries/vpnClientConfigurationQuery.query";
import { useQuery } from "urql";
import { StepTitle } from "./components/StepTitle/StepTitle";
import fileDownload from "js-file-download";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import vpnSetupImage1 from "../../../../../../assets/vpn-setup-image-1.png";
import vpnSetupImage2 from "../../../../../../assets/vpn-setup-image-2.png";
import vpnSetupImage3 from "../../../../../../assets/vpn-setup-image-3.png";
import vpnSetupImage4 from "../../../../../../assets/vpn-setup-image-4.png";
import vpnSetupImage5 from "../../../../../../assets/vpn-setup-image-5.png";
import vpnSetupImage6 from "../../../../../../assets/vpn-setup-image-6.png";
import vpnSetupImage7 from "../../../../../../assets/vpn-setup-image-7.png";

export const VPNSetupSection = (): JSX.Element => {
  const [step, setStep] = useState<number>(0);

  const [{ data, error, fetching }, refetch] = useQuery<{
    vpnClientConfiguration: string;
  }>({
    query: vpnClientConfigurationQuery(),
  });

  const items: CollapseProps["items"] = [
    {
      key: "0",
      label: (
        <StepTitle
          step={0}
          currentStep={step}
          title="Download VPN Client Configuration"
          description="Download your VPN client configuration file by clicking on the button."
          nextButtonText="Download & Proceed"
          onNextButtonClick={() => {
            fileDownload(data?.vpnClientConfiguration!, "vpn-config.ovpn");

            setStep(1);
          }}
        />
      ),
      children: (
        <Input.TextArea
          rows={error ? 10 : 45}
          readOnly
          value={
            data?.vpnClientConfiguration ??
            "Error fetching vpn client configuration"
          }
        />
      ),
    },
    {
      key: "1",
      label: (
        <StepTitle
          step={1}
          currentStep={step}
          title="Download AWS Client VPN Application"
          description="Download the AWS Client VPN application by clicking on the button."
          nextButtonText="Next"
          onNextButtonClick={() => setStep(2)}
          onPreviousButtonClick={() => setStep(0)}
        />
      ),
      children: (
        <Result
          icon={<CloudDownloadOutlined />}
          status="info"
          title="Download & Install AWS Client VPN application"
          subTitle="Visit the download page to download the AWS Client VPN application and install it."
          extra={[
            <Link
              key="0"
              to="https://aws.amazon.com/vpn/client-vpn-download/"
              target="_blank"
            >
              <Button type="primary" size="large">
                Visit download page
              </Button>
            </Link>,
          ]}
        />
      ),
    },
    {
      key: "2",
      label: (
        <StepTitle
          step={2}
          currentStep={step}
          title="Open AWS Client VPN Application"
          description="Open the AWS Client VPN application. It should look like the image displayed below."
          nextButtonText="Next"
          onNextButtonClick={() => setStep(3)}
          onPreviousButtonClick={() => setStep(1)}
        />
      ),
      children: (
        <Flex
          style={{ height: "50vh", width: "100%" }}
          align="center"
          justify="center"
        >
          <img src={vpnSetupImage1} alt="AWS Client VPN Application" />
        </Flex>
      ),
    },
    {
      key: "3",
      label: (
        <StepTitle
          step={3}
          currentStep={step}
          title="Open AWS Client VPN Application Profile Import Window"
          description="On the menu bar navigate to File > Manage Profiles. A window should appear as shown below:"
          nextButtonText="Next"
          onNextButtonClick={() => setStep(4)}
          onPreviousButtonClick={() => setStep(2)}
        />
      ),
      children: (
        <Flex
          style={{ height: "50vh", width: "100%" }}
          align="center"
          justify="center"
        >
          <img
            src={vpnSetupImage2}
            alt="AWS Client VPN Application Profile Import Window"
          />
        </Flex>
      ),
    },
    {
      key: "4",
      label: (
        <StepTitle
          step={4}
          currentStep={step}
          title="Create New VPN Profile"
          description="Click on the Add Profile button and a window should appear as follows:"
          nextButtonText="Next"
          onNextButtonClick={() => setStep(5)}
          onPreviousButtonClick={() => setStep(3)}
        />
      ),
      children: (
        <Flex
          style={{ height: "50vh", width: "100%" }}
          align="center"
          justify="center"
        >
          <img src={vpnSetupImage3} alt="Create New VPN Profile" />
        </Flex>
      ),
    },
    {
      key: "5",
      label: (
        <StepTitle
          step={5}
          currentStep={step}
          title="Enter VPN Profile Details"
          description={
            <>
              <br />
              On <b>Display Name</b> text field enter: <b>Soum-VPN</b>
              <br />
              <br />
              On <b>VPN Configuration File</b> click on the folder icon (Icon
              can be different operating systems) selected the path to where you
              set the <b>vpn-config.ovpn</b>
            </>
          }
          nextButtonText="Next"
          onNextButtonClick={() => setStep(6)}
          onPreviousButtonClick={() => setStep(4)}
        />
      ),
      children: (
        <Flex
          style={{ height: "50vh", width: "100%" }}
          align="center"
          justify="center"
        >
          <img src={vpnSetupImage4} alt="Create New VPN Profile" />
        </Flex>
      ),
    },
    {
      key: "6",
      label: (
        <StepTitle
          step={6}
          currentStep={step}
          title="Save VPN Profile Details"
          description={
            <>
              Then click on the <b>Add Profile</b> button. The current dialog
              should disappear and you will find your profile inserted as
              follows:
            </>
          }
          nextButtonText="Next"
          onNextButtonClick={() => setStep(7)}
          onPreviousButtonClick={() => setStep(5)}
        />
      ),
      children: (
        <Flex
          style={{ height: "50vh", width: "100%" }}
          align="center"
          justify="center"
        >
          <img src={vpnSetupImage5} alt="Save VPN Profile Details" />
        </Flex>
      ),
    },
    {
      key: "7",
      label: (
        <StepTitle
          step={7}
          currentStep={step}
          title="Connect To VPN"
          description={
            <>
              Then click on the <b>Connect</b> button to connect. You will be
              redirected to Soum AWS SSO login where you can login with your
              username and password and mfa.
            </>
          }
          nextButtonText="Next"
          onNextButtonClick={() => setStep(8)}
          onPreviousButtonClick={() => setStep(6)}
        />
      ),
      children: (
        <Flex
          style={{ height: "50vh", width: "100%" }}
          align="center"
          justify="center"
        >
          <img src={vpnSetupImage6} alt="Connect To VPN" />
        </Flex>
      ),
    },
    {
      key: "8",
      label: (
        <StepTitle
          step={8}
          currentStep={step}
          title="You're Now Connected!"
          description={<>Once its connected it should appear as follows:</>}
          nextButtonText="Restart Tutorial"
          onNextButtonClick={() => setStep(0)}
          onPreviousButtonClick={() => setStep(7)}
        />
      ),
      children: (
        <Flex
          style={{ height: "50vh", width: "100%" }}
          align="center"
          justify="center"
        >
          <img src={vpnSetupImage7} alt="You're Now Connected!" />
        </Flex>
      ),
    },
  ];

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  return (
    <>
      {fetching && <Spin fullscreen />}
      <ErrorView error={error} onRefetch={onRefetch}>
        <Flex vertical gap="large">
          <Flex vertical align="center">
            <Typography.Title level={4} type="secondary">
              VPN Setup Tutorial
            </Typography.Title>
            <Typography.Text>
              Follow the steps below to setup your VPN connection.
            </Typography.Text>
          </Flex>
          <Collapse bordered activeKey={step.toString()} items={items} />
        </Flex>
      </ErrorView>
    </>
  );
};
