import { Flex } from "antd";
import { UpdateEnvironmentHeaderSection } from "./components/UpdateEnvironmentHeaderSection/UpdateEnvironmentHeaderSection";
import { useParams } from "react-router-dom";
import { UpsertEnvironmentWizardSection } from "../../../../components/UpsertEnvironmentWizardSection/UpsertEnvironmentWizardSection";

const UpdateEnvironmentPage = (): JSX.Element => {
  const { environmentId } = useParams<{ environmentId: string }>();

  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <UpdateEnvironmentHeaderSection />
      <UpsertEnvironmentWizardSection environmentId={environmentId} />
    </Flex>
  );
};

export default UpdateEnvironmentPage;
