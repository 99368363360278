import { Radio } from "antd";
import { useAtomValue, useSetAtom } from "jotai";
import { changeThemeAtom, themeAtom } from "../../../../state/theme/theme";
import { FaMoon } from "react-icons/fa";
import { MdLightMode } from "react-icons/md";
import { Theme } from "../../../../types/theme/theme.enum";

export const ThemeSwitch = (): JSX.Element => {
  const theme = useAtomValue(themeAtom);

  const changeTheme = useSetAtom(changeThemeAtom);

  return (
    <Radio.Group
      size="large"
      defaultValue={theme}
      buttonStyle="solid"
      onChange={changeTheme}
    >
      <Radio.Button value={Theme.LIGHT}>
        <MdLightMode />
      </Radio.Button>
      <Radio.Button value={Theme.DARK}>
        <FaMoon />
      </Radio.Button>
    </Radio.Group>
  );
};
