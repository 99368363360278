import { Alert, Form, List, Select, Spin, Typography } from "antd";
import { useRepositoriesContext } from "../../../../../../../../../../../context/RepositoriesContext";
import { ErrorView } from "../../../../../../../../../../../components/ErrorView/ErrorView";
import { useQuery } from "urql";
import { getPullRequestStatusChecksQuery } from "../../../../../../../../../../../providers/graphql/queries/getpullRequestStatusChecks.query";
import { useAtomValue } from "jotai";
import { createPRStatusOverrideWizardFormDataAtom } from "../../../../states/create.pr.status.override.wizard.form.data";

export const SelectStatusChecksFormSection = (): JSX.Element => {
  const { repositories, repositoryMapping } = useRepositoriesContext();

  const { repositoryName, pullRequestId } = useAtomValue(
    createPRStatusOverrideWizardFormDataAtom
  );

  const [{ data, error, fetching }, refetch] = useQuery<{
    pullRequestStatusChecks: string[];
  }>({
    query: getPullRequestStatusChecksQuery(),
    variables: {
      pullRequestStatusChecksInput: {
        repositoryName,
        pullRequestId,
      },
    },
    requestPolicy: "network-only",
  });

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  if (!repositories || !repositoryMapping) {
    return (
      <Alert
        message="No repositories found. Please check if you have permissions to view repositories"
        type="error"
      />
    );
  }

  const statusChecksNotToOverride = repositories
    .filter((repository) => repository.repositoryName === repositoryName)
    .flatMap((repository) => repository.statusChecksNotToOverride ?? []);

  const statusChecks = data?.pullRequestStatusChecks?.filter((statusCheck) => {
    return !statusChecksNotToOverride.includes(statusCheck);
  });

  return (
    <>
      {fetching && <Spin fullscreen />}
      <ErrorView hideChildrenOnError error={error} onRefetch={onRefetch}>
        <Form.Item>
          <Typography.Title
            level={3}
            style={{ display: "block", textAlign: "center" }}
          >
            Select PR Status Checks To Override
          </Typography.Title>
          <Typography.Text
            type="secondary"
            style={{ display: "block", textAlign: "center" }}
          >
            Please select the repository and the pull request you want to
            override the status checks
          </Typography.Text>
        </Form.Item>
        <Form.Item
          label="Status Checks To Override"
          labelCol={{ span: 24 }}
          name="statusChecks"
          rules={[
            {
              required: true,
              message: "Please select at least one status check",
            },
          ]}
        >
          <Select mode="multiple">
            {statusChecks?.map((statusCheck) => {
              return (
                <Select.Option key={statusCheck} value={statusCheck}>
                  {statusCheck}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        {Boolean(statusChecksNotToOverride.length) && (
          <Form.Item>
            <Typography.Title
              level={5}
              style={{ display: "block", textAlign: "center" }}
            >
              NB: The following status checks cannot be overriden. Please ensure
              they pass before overriding the other status checks
            </Typography.Title>
            <List
              style={{
                overflowY: "auto",
              }}
              dataSource={statusChecksNotToOverride}
              renderItem={(item) => {
                return (
                  <List.Item>
                    <List.Item.Meta title={`• ${item}`} />
                  </List.Item>
                );
              }}
            />
          </Form.Item>
        )}
      </ErrorView>
    </>
  );
};
