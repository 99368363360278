import { query } from "gql-query-builder";
import IQueryBuilderOptions from "gql-query-builder/build/IQueryBuilderOptions";
import { TypedDocumentNode, gql } from "urql";

export const getPaginateDatabaseMigrationsQuery = ({
  fields,
}: Pick<IQueryBuilderOptions, "fields">): TypedDocumentNode => {
  return gql(
    query({
      fields: [...(fields ?? ["id"])],
      operation: "databaseMigrations",
      variables: {
        databaseMigrationPaginationInput: {
          type: "DatabaseMigrationPaginationInput",
          required: true,
        },
      },
    }).query
  );
};
