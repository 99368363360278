import { Flex } from "antd";
import { NewEnvironmentHeaderSection } from "./components/NewEnvironmentHeaderSection/NewEnvironmentHeaderSection";
import { UpsertEnvironmentWizardSection } from "../../../../components/UpsertEnvironmentWizardSection/UpsertEnvironmentWizardSection";

const NewEnvironmentPage = (): JSX.Element => {
  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <NewEnvironmentHeaderSection />
      <UpsertEnvironmentWizardSection />
    </Flex>
  );
};

export default NewEnvironmentPage;
