export enum EnvironmentAccount {
  DEVELOPMENT = "development",
  PRODUCTION = "production",
}

export enum EnvironmentPurpose {
  DEVELOPMENT = "development",
  TESTING = "testing",
}

export enum EnvironmentHibernationType {
  NO_DEPLOYMENT_CHANGE_OVER_TIME = "no-deployment-change-over-time",
  DURING_CERTAIN_DAYS_OF_THE_WEEK = "during-certain-days-of-the-week",
}

export enum EnvironmentSyncMode {
  DAILY = "daily",
  WEEEKLY = "weekly",
  MONTHLY = "monthly",
  MANUAL = "manual",
}
