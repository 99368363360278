import { App, Button } from "antd";
import { usePagination } from "../../../../../hooks/usePagination";
import { useQuery } from "urql";
import { PaginationResponse } from "../../../../../types/pagination/pagination.type";
import { TeamJSON } from "../../../../../types/team/team.type";
import { ErrorView } from "../../../../../components/ErrorView/ErrorView";
import { TableView } from "../../../../../components/TableView/TableView";
import { ReactNode } from "react";
import { TextFilterInput } from "../../../../../components/TextFilterInput/TextFilterInput";
import { TeamsMoreButton } from "./components/TeamsMoreButton/TeamsMoreButton";
import { getFilterValueUtility } from "../../../../../utils/filter/filter.utility";
import { getPaginateTeamsQuery } from "../../../../../providers/graphql/queries/getPaginateTeams.query";
import { UsersViewer } from "../../../../../components/UsersViewer/UsersViewer";

export const TeamsTableSection = (): JSX.Element => {
  const { paginationRequest } = usePagination({
    filterNames: ["teamNames", "emails"],
  });

  const { search, limit, skip, sortField, sortOrder, filters } =
    paginationRequest;

  const { modal } = App.useApp();

  const [{ data, error, fetching }, refetch] = useQuery<{
    teams: PaginationResponse<TeamJSON>;
  }>({
    query: getPaginateTeamsQuery({
      fields: [
        "total",
        {
          data: [
            "id",
            "teamName",
            "emails",
            "notificationChannel",
            {
              relations: [
                {
                  users: ["id", "fullName", "email"],
                },
              ],
            },
          ],
        },
      ],
    }),
    variables: {
      teamPaginationInput: {
        search,
        limit,
        skip,
        sortField,
        sortOrder,
        filters: {
          ...(filters ? { ...filters } : {}),
        },
      },
    },
    requestPolicy: "network-only",
  });

  const teams = data?.teams.data ?? [],
    total = data?.teams.total ?? 0;

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  const onShowUsers = (team: TeamJSON): void => {
    modal.info({
      icon: null,
      title: `Users in ${team.teamName}`,
      width: "75%",
      content: (
        <UsersViewer
          style={{ height: "65vh" }}
          users={team.relations?.users ?? []}
        />
      ),
      okText: "Cancel",
      okButtonProps: {
        type: "default",
      },
    });
  };

  return (
    <ErrorView error={error} onRefetch={onRefetch}>
      <TableView
        rowKey="teamName"
        loading={fetching}
        sortableColumns={["teamName"]}
        filterableColumnMapping={{
          teamName: "teamNames",
          email: "emails",
        }}
        dataSource={teams}
        total={total}
        columns={[
          {
            key: "teamName",
            dataIndex: "teamName",
            title: "Team Name",
            align: "center",
            width: 200,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "teamNames",
            }),
            filterDropdown: TextFilterInput,
          },
          {
            key: "users",
            title: "Users",
            align: "center",
            width: 200,
            render: (_value: undefined, record: TeamJSON): ReactNode => {
              return (
                <Button type="link" onClick={onShowUsers.bind(this, record)}>
                  {record.emails.length ?? 0} users
                </Button>
              );
            },
          },
          {
            key: "notificationChannel",
            dataIndex: "notificationChannel",
            title: "Slack Notification Channel ID",
            align: "center",
            width: 200,
          },
          {
            key: "more",
            align: "center",
            fixed: "right",
            width: 100,
            render: (_value: undefined, record: TeamJSON): ReactNode => {
              return <TeamsMoreButton team={record} onRefetch={onRefetch} />;
            },
          },
        ]}
      />
    </ErrorView>
  );
};
