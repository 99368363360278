import { App, Button, Checkbox, Form, Modal } from "antd";
import { Nullable } from "../../utils/nullable/nullable.type";
import { ErrorView } from "../ErrorView/ErrorView";
import { useMutation } from "urql";
import { DeploymentJSON } from "../../types/deployment/deployment.type";
import { SelectEnvironmentInput } from "../SelectEnvironmentInput/SelectEnvironmentInput";
import { updateDeploymentEnvironmentMutation } from "../../providers/graphql/mutations/updateDeploymentEnvironment.mutation";

type SwitchDeploymentEnvironmentFormFields = {
  environmentId?: Nullable<string>;
  unset?: boolean;
};

export type SwitchDeploymentEnvironmentFormProps = {
  deployment?: Partial<DeploymentJSON>;
};

export const SwitchDeploymentEnvironmentForm = ({
  deployment,
}: SwitchDeploymentEnvironmentFormProps): JSX.Element => {
  const { message } = App.useApp();

  const [{ fetching, error }, mutation] = useMutation(
    updateDeploymentEnvironmentMutation()
  );

  const onSubmit = async (
    values: SwitchDeploymentEnvironmentFormFields
  ): Promise<void> => {
    const { error } = await mutation({
      updateDeploymentEnvironmentInput: {
        deploymentId: deployment?.deploymentId,
        environmentId: values.unset ? null : values.environmentId ?? null,
      },
    });

    if (error) {
      return;
    }

    message.info("Switched environment successfully!");

    Modal.destroyAll();
  };

  const [form] = Form.useForm<SwitchDeploymentEnvironmentFormFields>();

  const unset = Form.useWatch("unset", form);

  return (
    <ErrorView disableRetryButton error={error}>
      <Form
        form={form}
        size="large"
        layout="vertical"
        disabled={fetching}
        onFinish={onSubmit}
        scrollToFirstError
      >
        {!unset && (
          <Form.Item
            name="environmentId"
            label="Environment"
            rules={[
              {
                required: true,
                message: "Please select the environment that should be used!",
              },
            ]}
            initialValue={deployment?.environmentId ?? ""}
          >
            <SelectEnvironmentInput
              currentEnvironmentId={deployment?.environmentId ?? ""}
            />
          </Form.Item>
        )}
        <Form.Item
          name="unset"
          valuePropName="checked"
          initialValue={deployment?.environmentId ? false : true}
        >
          <Checkbox>Set Deployment To Use No Environment</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button loading={fetching} block type="primary" htmlType="submit">
            Switch Environment
          </Button>
        </Form.Item>
      </Form>
    </ErrorView>
  );
};
