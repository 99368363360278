import { ReleaseDetailsFormSection } from "./components/ReleaseDetailsFormSection/ReleaseDetailsFormSection";
import { ReleaseRepositorySelectionFormSection } from "./components/ReleaseRepositorySelection/ReleaseRepositorySelectionFormSection";

export type NewReleaseWizardContentProps = {
  currentStep: number;
};

export const NewReleaseWizardContent = ({
  currentStep,
}: NewReleaseWizardContentProps): JSX.Element => {
  return (
    <div style={{ minHeight: "50vh", paddingLeft: 50, paddingRight: 50 }}>
      {currentStep === 0 && <ReleaseDetailsFormSection />}
      {currentStep === 1 && <ReleaseRepositorySelectionFormSection />}
    </div>
  );
};
