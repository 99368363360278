import { Alert, Flex, Form, Input, Select, Typography } from "antd";
import { useWorkflowsContext } from "../../../../../../../../../../context/WorkflowsContext";
import { mapEnumValueToKey } from "../../../../../../../../../../utils/enum/enum";
import { DeploymentFeature } from "../../../../../../../../../../types/deployment/deployment.enum";
import { SelectEnvironmentInput } from "../../../../../../../../../../components/SelectEnvironmentInput/SelectEnvironmentInput";
import { NewDeploymentWizardFormFields } from "../../../../NewDeploymentWizardSection";

export const DeploymentDetailsFormSection = (): JSX.Element => {
  const { workflows, workflowMapping } = useWorkflowsContext();

  const form = Form.useFormInstance<NewDeploymentWizardFormFields>();

  const environmentId = Form.useWatch("environmentId", form);

  if (!workflows || !workflowMapping) {
    return (
      <Alert
        message="No workflows found. Please check if you have permissions to view workflows"
        type="error"
      />
    );
  }

  return (
    <>
      <Form.Item>
        <Typography.Title
          level={3}
          style={{ display: "block", textAlign: "center" }}
        >
          Deployment Details
        </Typography.Title>
        <Typography.Text
          type="secondary"
          style={{ display: "block", textAlign: "center" }}
        >
          Enter the details of the deployment
        </Typography.Text>
      </Form.Item>
      <Form.Item name="workflowId" label="Workflow">
        <Select>
          {workflows.map((workflow) => (
            <Select.Option
              key={workflow.workflowId}
              value={workflow.workflowId}
            >
              {workflowMapping[workflow.workflowId].workflowName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="deploymentName"
        label="Deployment name"
        rules={[
          {
            required: true,
            message: "Please input the deployment name!",
          },
        ]}
      >
        <Input size="large" type="text" />
      </Form.Item>
      <Form.Item
        name="features"
        label={
          <Flex vertical gap="middle" style={{ marginBottom: 35 }}>
            <Typography.Text>Deployment Features</Typography.Text>
            <Typography.Text type="danger">
              NB: These features only apply if the deployment is linked to an
              environment that is deployable in development account
            </Typography.Text>
          </Flex>
        }
      >
        <Select mode="multiple">
          {Object.values(DeploymentFeature).map((feature) => (
            <Select.Option key={feature} value={feature}>
              {mapEnumValueToKey(DeploymentFeature, feature)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="environmentId"
        label={
          <Flex vertical gap="middle" style={{ marginBottom: 35 }}>
            <Typography.Text>Environment To Deploy To</Typography.Text>
            {!environmentId && (
              <Typography.Text type="danger">
                NB: By selecting no environment, the deployment won't be
                deployed anywhere
              </Typography.Text>
            )}
          </Flex>
        }
      >
        <SelectEnvironmentInput />
      </Form.Item>
    </>
  );
};
