import {
  Alert,
  App,
  Button,
  Divider,
  Flex,
  Form,
  Select,
  Space,
  Typography,
} from "antd";
import { useRepositoriesContext } from "../../../../../../../../../../context/RepositoriesContext";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Country } from "../../../../../../../../../../types/country/country.enum";
import { mapEnumValueToKey } from "../../../../../../../../../../utils/enum/enum";
import { NewDeploymentWizardFormFields } from "../../../../NewDeploymentWizardSection";
import { SelectBranchInput } from "../../../../../../../../../../components/SelectBranchInput/SelectBranchInput";
import { Nullable } from "../../../../../../../../../../utils/nullable/nullable.type";
import { RepositoryCharacteristic } from "../../../../../../../../../../types/repository/repository.enum";
import { SelectCronjobsInput } from "../../../../../../../../../../components/SelectCronjobsInput/SelectCronjobsInput";

export const DeploymentRepositorySelectionFormSection = (): JSX.Element => {
  const { repositories, repositoryMapping } = useRepositoriesContext();

  const form = Form.useFormInstance<NewDeploymentWizardFormFields>();

  const { notification } = App.useApp();

  const repositoriesFormData = Form.useWatch("repositories", form);

  if (!repositories || !repositoryMapping) {
    return (
      <Alert
        message="No repositories found. Please check if you have permissions to view repositories"
        type="error"
      />
    );
  }

  const addedRepositories =
    repositoriesFormData
      ?.filter((repository) => repository && repository.repositoryAlias)
      .map((repository) => repository.repositoryAlias!) ?? [];

  const repositoriesThatCanBeAdded =
    repositories?.filter(
      (repository) => !addedRepositories.includes(repository.repositoryAlias)
    ) ?? [];

  return (
    <>
      <Form.List
        name="repositories"
        rules={[
          {
            validator: async (_, repositories) => {
              let error: Nullable<string> = null;

              if (!repositories || !repositories.length) {
                error = "At least one repository is required";
              }

              if (error) {
                notification.error({
                  message: error,
                });

                return Promise.reject(new Error(error));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            <Flex justify="space-between" align="center">
              <div></div>
              <Flex vertical>
                <Typography.Title
                  level={3}
                  style={{ display: "block", textAlign: "center" }}
                >
                  Repositories Selection
                </Typography.Title>
                <Typography.Text
                  type="secondary"
                  style={{ display: "block", textAlign: "center" }}
                >
                  Select the repositories to include in the deployment
                </Typography.Text>
              </Flex>
              <Button
                type="dashed"
                onClick={() => {
                  const [repository] = repositoriesThatCanBeAdded;

                  add({
                    repositoryAlias: repository.repositoryAlias,
                    ref: repository.mainRef,
                    countries: repository.countryOptions.enabledForDeployments
                      ? repository.countries.includes(Country.SAUDI_ARABIA)
                        ? [Country.SAUDI_ARABIA]
                        : repository.countries
                      : null,
                    features: [],
                    cronJobs: repository.characteristics.some(
                      (characteristic) =>
                        characteristic ===
                        RepositoryCharacteristic.CAN_DEPLOY_CRON_JOBS
                    )
                      ? []
                      : null,
                  });
                }}
                icon={<PlusOutlined />}
                disabled={!repositoriesThatCanBeAdded.length}
              >
                Add New Repository
              </Button>
            </Flex>
            <Divider />
            {fields.map((_, index) => {
              const repositoryAlias =
                repositoriesFormData?.[index]?.repositoryAlias ?? "";

              const ref = repositoriesFormData?.[index]?.ref ?? "";

              const isRepositoryAliasSelected = !!repositoryAlias;

              const isCountrySelectionEnabled = isRepositoryAliasSelected
                ? repositoryMapping[repositoryAlias]?.countryOptions
                    ?.enabledForDeployments
                : false;

              const countries =
                repositoryMapping[repositoryAlias]?.countries ?? [];

              const hasCronJobs = isRepositoryAliasSelected
                ? repositoryMapping[repositoryAlias]?.characteristics?.some(
                    (characteristic) =>
                      characteristic ===
                      RepositoryCharacteristic.CAN_DEPLOY_CRON_JOBS
                  )
                : false;

              const features =
                repositoryMapping[repositoryAlias]?.features ?? [];

              const deploymentDependancies =
                repositoryMapping[repositoryAlias]?.deploymentDependancies ??
                [];

              const mainRef = repositoryMapping[repositoryAlias]?.mainRef;

              const deploymentDependanciesThatCanBeAdded =
                repositoriesThatCanBeAdded.filter((repositoryThatCanBeAdded) =>
                  deploymentDependancies.includes(
                    repositoryThatCanBeAdded.repositoryAlias
                  )
                );

              return (
                <div key={index}>
                  <Flex align="center" justify="space-between">
                    <Typography.Title level={2} type="secondary">
                      Repository {index + 1}
                    </Typography.Title>
                    <Button
                      danger
                      type="primary"
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(index)}
                      style={{ marginLeft: "auto" }}
                    >
                      Remove Repository
                    </Button>
                  </Flex>
                  <Form.Item
                    label="Repository Name"
                    labelCol={{ span: 24 }}
                    name={[index, "repositoryAlias"]}
                    rules={[
                      {
                        required: true,
                        message: "Repository name is required",
                      },
                    ]}
                  >
                    <Select showSearch>
                      {repositoriesThatCanBeAdded.map((repository) => {
                        return (
                          <Select.Option
                            key={repository.repositoryAlias}
                            value={repository.repositoryAlias}
                          >
                            {repository.repositoryAlias}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  {isRepositoryAliasSelected && (
                    <>
                      {!!deploymentDependanciesThatCanBeAdded.length && (
                        <Form.Item
                          label="Dependant Repositories"
                          labelCol={{ span: 24 }}
                        >
                          <Space wrap size="middle">
                            {deploymentDependanciesThatCanBeAdded.map(
                              (deploymentDependancyThatCanBeAdded) => {
                                return (
                                  <Button
                                    type="dashed"
                                    onClick={() =>
                                      add({
                                        repositoryAlias:
                                          deploymentDependancyThatCanBeAdded.repositoryAlias,
                                        ref: deploymentDependancyThatCanBeAdded.mainRef,
                                        countries:
                                          deploymentDependancyThatCanBeAdded
                                            .countryOptions
                                            .enabledForDeployments
                                            ? deploymentDependancyThatCanBeAdded.countries.includes(
                                                Country.SAUDI_ARABIA
                                              )
                                              ? [Country.SAUDI_ARABIA]
                                              : deploymentDependancyThatCanBeAdded.countries
                                            : null,
                                        features: [],
                                        cronJobs:
                                          deploymentDependancyThatCanBeAdded.characteristics.some(
                                            (characteristic) =>
                                              characteristic ===
                                              RepositoryCharacteristic.CAN_DEPLOY_CRON_JOBS
                                          )
                                            ? []
                                            : null,
                                      })
                                    }
                                    icon={<PlusOutlined />}
                                  >
                                    {`Add ${deploymentDependancyThatCanBeAdded.repositoryAlias} Repository`}
                                  </Button>
                                );
                              }
                            )}
                          </Space>
                        </Form.Item>
                      )}
                      <Form.Item
                        name={[index, "ref"]}
                        label="Branch To Deploy"
                        rules={[
                          {
                            required: true,
                            message: "Branch to deploy from is required",
                          },
                        ]}
                      >
                        <SelectBranchInput
                          mainRef={mainRef}
                          allowCreateNewBranch
                          repositoryName={
                            repositoryMapping[repositoryAlias].repositoryName
                          }
                        />
                      </Form.Item>
                      {isCountrySelectionEnabled && !!countries.length && (
                        <Form.Item
                          name={[index, "countries"]}
                          label="Countries To Deploy To"
                          rules={[
                            {
                              required: true,
                              message: "Countries to deploy to is required",
                            },
                          ]}
                        >
                          <Select mode="multiple">
                            {countries.map((country) => {
                              return (
                                <Select.Option key={country} value={country}>
                                  {mapEnumValueToKey(Country, country)}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      )}
                      {hasCronJobs && ref && (
                        <Form.Item name={[index, "cronJobs"]} label="Cron Jobs">
                          <SelectCronjobsInput
                            repositoryName={
                              repositoryMapping[repositoryAlias].repositoryName
                            }
                            refName={ref}
                          />
                        </Form.Item>
                      )}
                      {!!features.length && (
                        <Form.Item
                          name={[index, "features"]}
                          label="Repository Features"
                        >
                          <Select mode="multiple">
                            {features.map((feature) => {
                              return (
                                <Select.Option key={feature} value={feature}>
                                  {feature}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      )}
                    </>
                  )}
                  <Divider />
                </div>
              );
            })}
          </>
        )}
      </Form.List>
    </>
  );
};
