import { Steps } from "antd";

export type UpsertEnvironmentWizardStepsProps = {
  currentStep: number;
  fetching: boolean;
  error: unknown;
};

export const UpsertEnvironmentWizardSteps = ({
  currentStep,
  fetching,
  error,
}: UpsertEnvironmentWizardStepsProps): JSX.Element => {
  return (
    <Steps
      current={currentStep}
      status={fetching ? "wait" : error ? "error" : "process"}
      items={[
        {
          title: "Environment Details",
        },
        {
          title: "Select Teams",
        },
        {
          title: "Select Users",
        },
        {
          title: "Hibernation Options",
        },
      ]}
    />
  );
};
