import { Button, Flex } from "antd";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

export type PullRequestLinkProps = {
  repositoryName: string;
  pullRequestId: number;
  title: string;
  extra?: ReactNode;
};

export const PullRequestLink = ({
  repositoryName,
  pullRequestId,
  title,
  extra,
}: PullRequestLinkProps): JSX.Element => {
  return (
    <Flex gap="small" justify="center" align="center">
      <Link
        to={`https://github.com/soum-sa/${repositoryName}/pull/${pullRequestId}`}
        target="_blank"
      >
        <Button
          type="link"
          style={{ width: 250, textWrap: "wrap" }}
        >{`#${pullRequestId} - ${title}`}</Button>
      </Link>
      {extra}
    </Flex>
  );
};
