import IQueryBuilderOptions from "gql-query-builder/build/IQueryBuilderOptions";
import { TypedDocumentNode, gql } from "urql";
import { query } from "gql-query-builder";

export const meQuery = ({
  fields,
}: Pick<IQueryBuilderOptions, "fields">): TypedDocumentNode => {
  return gql(
    query({
      fields: [...(fields ?? []), "id"],
      operation: "me",
    }).query
  );
};
