import { Flex } from "antd";
import { NewReleaseWizardSection } from "./components/NewReleaseWizardSection/NewReleaseWizardSection";
import { NewReleaseHeaderSection } from "./components/NewReleaseHeaderSection/NewReleaseHeaderSection";

const NewReleasePage = (): JSX.Element => {
  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <NewReleaseHeaderSection />
      <NewReleaseWizardSection />
    </Flex>
  );
};

export default NewReleasePage;
