import { Button, Flex } from "antd";

export type NewPRStatusOverrideWizardButtonsProps = {
  currentStep: number;
  lastStep: number;
  onPrevious: () => void;
};

export const NewPRStatusOverrideWizardButtons = ({
  currentStep,
  lastStep,
  onPrevious,
}: NewPRStatusOverrideWizardButtonsProps): JSX.Element => {
  const isOnLastStep = currentStep === lastStep;

  return (
    <Flex
      gap="middle"
      justify="space-between"
      style={{ paddingLeft: 50, paddingRight: 50 }}
    >
      <Button disabled={currentStep === 0} onClick={onPrevious}>
        Previous
      </Button>
      <Button htmlType="submit" type="primary">
        {isOnLastStep ? "Create PR Status Override" : "Next"}
      </Button>
    </Flex>
  );
};
