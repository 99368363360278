import { query } from "gql-query-builder";
import { TypedDocumentNode, gql } from "urql";

export const getPullRequestStatusChecksQuery = (): TypedDocumentNode => {
  return gql(
    query({
      operation: "pullRequestStatusChecks",
      variables: {
        pullRequestStatusChecksInput: {
          type: "PullRequestStatusChecksInput",
          required: true,
        },
      },
    }).query
  );
};
