export enum DeploymentTrackIdType {
  SETUP = "Setup",
  BUILD = "Build",
  DEPLOY = "Deploy",
}

export enum DeploymentFeature {
  SWITCH_BACKEND_TO_CONNECT_TO_STAGING_DATABASE = "switch-backend-to-connect-to-staging-database",
  SWITCH_FRONTEND_TO_CONNECT_TO_STAGING_API = "switch-frontend-to-connect-to-staging-api",
}

export enum DeploymentStatus {
  IN_PROGRESS = "in-progress",
  IN_REVIEW = "in-review",
  REVIEWED = "reviewed",
  FAILED_REVIEW = "failed-review",
}

export enum DeploymentPurpose {
  DEVELOPMENT = "development",
  TESTING = "testing",
}
