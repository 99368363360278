import {
  Alert,
  App,
  Breadcrumb,
  Button,
  Card,
  Descriptions,
  DescriptionsProps,
  Flex,
  Modal,
  Spin,
  Tag,
  Typography,
} from "antd";
import { ReleaseRepositoriesTable } from "../../../../../../components/ReleaseRepositoriesTable/ReleaseRepositoriesTable";
import { SearchBar } from "../../../../../../components/SearchBar/SearchBar";
import { useState } from "react";
import { ReleaseJSON } from "../../../../../../types/release/release.type";
import { useMutation, useQuery } from "urql";
import { getReleaseQuery } from "../../../../../../providers/graphql/queries/getRelease.query";
import { ErrorView } from "../../../../../../components/ErrorView/ErrorView";
import {
  mapEnumValueToKey,
  releaseStatusColorMapping,
} from "../../../../../../utils/enum/enum";
import {
  ReleaseRepositoryStatus,
  ReleaseStatus,
} from "../../../../../../types/release/release.enum";
import { Link, useParams } from "react-router-dom";
import { useRelease } from "../../../../../../hooks/useRelease";
import { EditReleaseNotesView } from "./components/EditReleaseNotesView/EditReleaseNotesView";
import { UpsertReleaseRepositoryForm } from "../../../../../../components/UpsertReleaseRepositoryForm/UpsertReleaseRepositoryForm";
import { approveReleaseRepositoriesMutation } from "../../../../../../providers/graphql/mutations/approveReleaseRepositories.mutation";
import { useError } from "../../../../../../hooks/useError";
import { deleteReleaseRepositoriesMutation } from "../../../../../../providers/graphql/mutations/deleteReleaseRepositories.mutation";
import { useUserContext } from "../../../../../../context/UserContext";
import { Role } from "../../../../../../types/role/role.enum";
import moment from "moment";

export const ReleaseViewSection = (): JSX.Element => {
  const { releaseName } = useParams<{ releaseName: string }>();

  const { modal, notification, message } = App.useApp();

  const { errorNotificationMessage } = useError();

  const [search, setSearch] = useState<string>();

  const { user } = useUserContext();

  const roles = user?.relations?.roles ?? [];

  const [selectedRowKeyIds, setSelectedRowKeyIds] = useState<string[]>([]);

  const fields = [
    "id",
    "releaseName",
    "isReadOnly",
    {
      repositories: [
        "id",
        "repositoryName",
        "repositoryAlias",
        "ref",
        "commit",
        "version",
        "status",
        "country",
        "error",
        "existing",
        "buildTrackId",
        "deployTrackId",
      ],
      relations: [
        {
          latestBuildRuns: [
            "runId",
            "repositoryName",
            "runPlatform",
            "status",
            "tertiaryId",
          ],
          latestDeploymentRuns: [
            "runId",
            "repositoryName",
            "runPlatform",
            "status",
            "tertiaryId",
          ],
        },
      ],
    },
  ];

  const {
    release: dynamicRelease,
    error: dynamicReleaseError,
    onRefetch: onRefetchReleaseWithRepositories,
  } = useRelease(fields, releaseName);

  const [
    {
      data: staticRelease,
      error: staticReleaseError,
      fetching: isGettingDeployment,
    },
    refetch,
  ] = useQuery<{
    release: ReleaseJSON;
  }>({
    query: getReleaseQuery({
      fields: [
        "id",
        "releaseName",
        "releaseStatus",
        "releaseNotes",
        "isReadOnly",
        "updatedAt",
        "createdAt",
      ],
    }),
    variables: {
      releaseInput: {
        releaseName,
      },
    },
    requestPolicy: "network-only",
  });

  const [
    { fetching: isApprovingReleaseRepositories },
    approveReleaseRepositories,
  ] = useMutation(approveReleaseRepositoriesMutation());

  const [
    { fetching: isDeletingReleaseRepositories },
    deleteReleaseRepositories,
  ] = useMutation(deleteReleaseRepositoriesMutation());

  const fetching =
    isGettingDeployment ||
    isApprovingReleaseRepositories ||
    isDeletingReleaseRepositories;

  const release = Object.assign(
    staticRelease?.release ?? {},
    dynamicRelease ?? {}
  ) as Partial<ReleaseJSON>;

  const onRefetch = async (): Promise<void> => {
    setSelectedRowKeyIds([]);

    await refetch({ requestPolicy: "network-only" });

    await onRefetchReleaseWithRepositories();
  };

  if (!release) return <Spin fullscreen />;

  const items: DescriptionsProps["items"] = [
    {
      label: "Release Name",
      labelStyle: {
        width: 220,
      },

      children: release.releaseName,
      span: 1,
    },
    {
      label: "Status",
      labelStyle: {
        width: 100,
      },
      children: (
        <Tag color={releaseStatusColorMapping[release?.releaseStatus!]}>
          {mapEnumValueToKey(ReleaseStatus, release?.releaseStatus!)}
        </Tag>
      ),
      span: 1,
    },
    {
      label: "Completed Released On",
      labelStyle: {
        width: 220,
      },
      children: (
        <span>
          {
            moment(new Date(`${release.updatedAt ? release.updatedAt : release.createdAt}`)).format("LLL")
          }
        </span>
      ),
      span: 1,
    },
    {
      label: "Release Notes",
      labelStyle: {
        width: 150,
      },
      children: <EditReleaseNotesView release={release} />,
      span: 3,
    },
  ];

  const canApproveAndDeploySelectedRepositories = ((): boolean => {
    if (release.isReadOnly) return false;

    if (!selectedRowKeyIds.length || !dynamicRelease?.repositories.length)
      return false;

    const repositories = dynamicRelease.repositories.filter((repository) =>
      selectedRowKeyIds.includes(repository.id)
    );

    if (
      repositories.some(
        (repository) =>
          repository.status !== ReleaseRepositoryStatus.PENDING_APPROVAL
      )
    ) {
      return false;
    }

    return true;
  })();

  const canDeleteSelectedRepositories = ((): boolean => {
    if (release.isReadOnly) return false;

    if (!selectedRowKeyIds.length || !dynamicRelease?.repositories.length)
      return false;

    const repositories = dynamicRelease.repositories.filter((repository) =>
      selectedRowKeyIds.includes(repository.id)
    );

    if (
      repositories.some(
        (repository) =>
          repository.status === ReleaseRepositoryStatus.APPROVED_FOR_DEPLOYMENT
      )
    ) {
      return false;
    }

    return true;
  })();

  const onAddNewReleaseRepository = (): void => {
    modal.info({
      icon: null,
      width: "75%",
      title: "Add New Repository",
      content: (
        <UpsertReleaseRepositoryForm
          onRefetch={onRefetchReleaseWithRepositories}
          releaseName={releaseName!}
        />
      ),
      okButtonProps: {
        type: "default",
      },
      okText: "Cancel",
    });
  };

  const onApproveAndDeploySelectedRepositories = (): void => {
    modal.confirm({
      icon: null,
      title: "Approve & Deploy Repositories",
      content: `Are you sure you want to approve and deploy the selected repositories in release ${releaseName}?`,
      okButtonProps: {
        style: {
          backgroundColor: "green",
        },
      },
      okText: "Approve & Deploy Repositories",
      onOk: async (): Promise<void> => {
        const { error } = await approveReleaseRepositories({
          approveReleaseRepositoriesInput: {
            releaseName,
            repositories: dynamicRelease?.repositories
              .filter((repository) => selectedRowKeyIds.includes(repository.id))
              .map((repository) => {
                return {
                  repositoryAlias: repository.repositoryAlias,
                  country: repository.country,
                };
              }),
          },
        });

        if (error) {
          notification.error(errorNotificationMessage(error));
          return;
        }

        message.success("Repository approved successfully");

        onRefetch();

        Modal.destroyAll();
      },
    });
  };

  const onDeleteSelectedRepositories = (): void => {
    modal.confirm({
      icon: null,
      title: "Remove Repositories",
      content: `Are you sure you want to remove the selected repositories in release ${releaseName}?`,
      okButtonProps: {
        danger: true,
      },
      okText: "Remove Repositories",
      onOk: async (): Promise<void> => {
        const { error } = await deleteReleaseRepositories({
          deleteReleaseRepositoriesInput: {
            releaseName,
            repositories: dynamicRelease?.repositories
              .filter((repository) => selectedRowKeyIds.includes(repository.id))
              .map((repository) => {
                return {
                  repositoryAlias: repository.repositoryAlias,
                  country: repository.country,
                };
              }),
          },
        });

        if (error) {
          notification.error(errorNotificationMessage(error));
          return;
        }

        message.success("Repository removed successfully");

        onRefetch();

        Modal.destroyAll();
      },
    });
  };

  return (
    <>
      {fetching && <Spin fullscreen />}
      <ErrorView
        error={staticReleaseError}
        onRefetch={onRefetch}
        hideChildrenOnError
      >
        <Flex vertical gap="large">
          {release.isReadOnly ? (
            <Alert
              type="info"
              message="This release is read-only. You cannot make any changes to it."
              showIcon
              style={{ fontSize: "1.25em" }}
            />
          ) : release.releaseStatus === ReleaseStatus.COMPLETE ? (
            <Alert
              type="info"
              message="The release is complete. After 3 days, the release will be automatically made to read only."
              showIcon
              style={{ fontSize: "1.25em" }}
            />
          ) : null}
          <Card
            title={
              <Flex vertical style={{ paddingBottom: 15 }}>
                <Typography.Title level={2}>
                  Release: {releaseName}
                </Typography.Title>
                <Breadcrumb
                  items={[
                    {
                      title: <Link to="/dashboard">Dashboard</Link>,
                    },
                    {
                      title: <Link to="/dashboard/releases">Releases</Link>,
                    },
                    {
                      title: releaseName,
                    },
                  ]}
                />
              </Flex>
            }
          >
            <Descriptions column={3} bordered items={items} />
          </Card>
          <ReleaseRepositoriesTable
            release={dynamicRelease}
            error={dynamicReleaseError}
            onRefetch={onRefetch}
            onRowsSelected={setSelectedRowKeyIds}
            search={search}
            title={
              <Flex justify="space-between" align="center">
                <Typography.Title level={3}>Repositories</Typography.Title>
                <Flex gap="middle">
                  <SearchBar
                    style={{ height: 40 }}
                    setSearch={setSearch}
                    placeholder="Search repositories ..."
                  />
                  {roles.includes(Role.UPDATE_RELEASES) && (
                    <Button
                      size="large"
                      type="primary"
                      disabled={!canApproveAndDeploySelectedRepositories}
                      style={{ backgroundColor: "green" }}
                      onClick={onApproveAndDeploySelectedRepositories}
                    >
                      Approve & Deploy
                    </Button>
                  )}
                  {roles.includes(Role.DELETE_RELEASES) && (
                    <Button
                      type="primary"
                      size="large"
                      danger
                      disabled={!canDeleteSelectedRepositories}
                      onClick={onDeleteSelectedRepositories}
                    >
                      Delete
                    </Button>
                  )}
                  {roles.includes(Role.UPDATE_RELEASES) && (
                    <Button
                      type="primary"
                      size="large"
                      onClick={onAddNewReleaseRepository}
                      disabled={release.isReadOnly}
                    >
                      Add New Repository
                    </Button>
                  )}
                </Flex>
              </Flex>
            }
          />
        </Flex>
      </ErrorView>
    </>
  );
};
