import { CSSProperties } from "react";
import { Button, Flex, Select, Spin, Tooltip } from "antd";
import { ErrorView } from "../ErrorView/ErrorView";
import { useQuery } from "urql";
import { GitRefPullRequest } from "../../types/git/git.type";
import { Nullable } from "../../utils/nullable/nullable.type";
import { getAllOpenPullRequestsQuery } from "../../providers/graphql/queries/getAllOpenPullRequests.query";
import { IoIosRefresh } from "react-icons/io";

export type SelectPullRequestInputProps = {
  style?: CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
  repositoryName: string;
};

export const SelectPullRequestInput = ({
  style,
  value,
  onChange,
  repositoryName,
}: SelectPullRequestInputProps): Nullable<JSX.Element> => {
  const [{ data, error, fetching }, refetch] = useQuery<{
    allOpenPullRequests: GitRefPullRequest[];
  }>({
    query: getAllOpenPullRequestsQuery({
      fields: ["pullRequestId", "title"],
    }),
    variables: {
      allOpenPullRequestsInput: {
        repositoryName,
      },
    },
    requestPolicy: "network-only",
    pause: !repositoryName,
  });

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  const gitPullRequests = data?.allOpenPullRequests ?? [];

  return (
    <ErrorView error={error} onRefetch={onRefetch}>
      <Flex gap="middle">
        <Select
          value={value}
          onChange={onChange}
          showSearch
          style={style}
          dropdownRender={(menu) => {
            if (fetching) {
              return (
                <Flex justify="center">
                  <Spin />
                </Flex>
              );
            }

            return menu;
          }}
        >
          {gitPullRequests.map((value, index) => {
            return (
              <Select.Option key={index} value={value.pullRequestId}>
                {value.title}
              </Select.Option>
            );
          })}
        </Select>
        <Tooltip title="Refresh">
          <Button
            loading={fetching}
            icon={<IoIosRefresh />}
            type="link"
            onClick={onRefetch}
          />
        </Tooltip>
      </Flex>
    </ErrorView>
  );
};
