import { atomWithStorage } from "jotai/utils";
import { Theme } from "../../types/theme/theme.enum";
import { atom } from "jotai";

export const themeAtom = atomWithStorage<Theme>("theme", Theme.LIGHT);

export const isDarkThemeAtom = atom((get) => {
  return get(themeAtom) === Theme.DARK;
});

export const changeThemeAtom = atom(null, (get, set) => {
  const isDarkTheme = get(isDarkThemeAtom);
  set(themeAtom, isDarkTheme ? Theme.LIGHT : Theme.DARK);
});
