import { Alert, App, Button, Checkbox, Form, Modal, Select } from "antd";
import { Nullable } from "../../utils/nullable/nullable.type";
import { ErrorView } from "../ErrorView/ErrorView";
import { useMutation } from "urql";
import { DeploymentJSON } from "../../types/deployment/deployment.type";
import { updateDeploymentWorkflowMutation } from "../../providers/graphql/mutations/updateDeploymentWorkflow.mutation";
import { useWorkflowsContext } from "../../context/WorkflowsContext";

type SwitchDeploymentWorkflowFormFields = {
  workflowId?: Nullable<string>;
  unset?: boolean;
};

export type SwitchDeploymentWorkflowFormProps = {
  deployment?: Partial<DeploymentJSON>;
};

export const SwitchDeploymentWorkflowForm = ({
  deployment,
}: SwitchDeploymentWorkflowFormProps): JSX.Element => {
  const { workflows, workflowMapping } = useWorkflowsContext();

  const { message } = App.useApp();

  const [{ fetching, error }, mutation] = useMutation(
    updateDeploymentWorkflowMutation()
  );

  const onSubmit = async (
    values: SwitchDeploymentWorkflowFormFields
  ): Promise<void> => {
    const { error } = await mutation({
      updateDeploymentWorkflowInput: {
        deploymentId: deployment?.deploymentId,
        workflowId: values.unset ? null : values.workflowId ?? null,
      },
    });

    if (error) {
      return;
    }

    message.info("Switched workflow successfully!");

    Modal.destroyAll();
  };

  const [form] = Form.useForm<SwitchDeploymentWorkflowFormFields>();

  const unset = Form.useWatch("unset", form);

  if (!workflows || !workflowMapping) {
    return (
      <Alert
        message="No workflows found. Please check if you have permissions to view workflows"
        type="error"
      />
    );
  }

  return (
    <ErrorView disableRetryButton error={error}>
      <Form
        form={form}
        size="large"
        layout="vertical"
        disabled={fetching}
        onFinish={onSubmit}
        scrollToFirstError
      >
        {!unset && (
          <Form.Item
            name="workflowId"
            label="Workflow"
            rules={[
              {
                required: true,
                message: "Please select the workflow that should be used!",
              },
            ]}
            initialValue={deployment?.workflowId ?? ""}
          >
            <Select>
              {workflows.map((workflow) => (
                <Select.Option
                  key={workflow.workflowId}
                  value={workflow.workflowId}
                >
                  {workflowMapping[workflow.workflowId].workflowName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="unset"
          valuePropName="checked"
          initialValue={deployment?.workflowId ? false : true}
        >
          <Checkbox>Set Deployment To Use No Workflow</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button loading={fetching} block type="primary" htmlType="submit">
            Switch Workflow
          </Button>
        </Form.Item>
      </Form>
    </ErrorView>
  );
};
