import { Steps } from "antd";

export type NewReleaseWizardStepsProps = {
  currentStep: number;
  fetching: boolean;
  error: unknown;
};

export const NewReleaseWizardSteps = ({
  currentStep,
  fetching,
  error,
}: NewReleaseWizardStepsProps): JSX.Element => {
  return (
    <Steps
      current={currentStep}
      status={fetching ? "wait" : error ? "error" : "process"}
      items={[
        {
          title: "Release Details",
        },
        {
          title: "Repositories Selection",
        },
      ]}
    />
  );
};
