import { Flex } from "antd";
import { TestsTableSection } from "./components/TestsTableSection/TestsTableSection";
import { TestsHeaderSection } from "./components/TestsHeaderSection/TestsHeaderSection";

const TestsPage = (): JSX.Element => {
  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <TestsHeaderSection />
      <TestsTableSection />
    </Flex>
  );
};

export default TestsPage;
