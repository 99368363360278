import { App, Breadcrumb, Button, Flex, Typography } from "antd";
import { usePagination } from "../../../../../../hooks/usePagination";
import { Link } from "react-router-dom";
import { SearchBar } from "../../../../../../components/SearchBar/SearchBar";
import { UserUpsertForm } from "../../../../../../components/UserUpsertForm/UserUpsertForm";
import { useUserContext } from "../../../../../../context/UserContext";
import { Role } from "../../../../../../types/role/role.enum";

export const UsersHeaderSection = (): JSX.Element => {
  const { setPaginationParameters, paginationRequest } = usePagination({});

  const { modal } = App.useApp();

  const { user } = useUserContext();

  const roles = user?.relations?.roles ?? [];

  const onSearchChanged = (search: string): void => {
    setPaginationParameters({
      search,
    });
  };

  const onCreateUser = (): void => {
    modal.info({
      icon: null,
      width: "75%",
      title: "Create User",
      content: <UserUpsertForm />,
      okButtonProps: {
        type: "default",
      },
      okText: "Cancel",
    });
  };

  return (
    <Flex justify="space-between" align="center">
      <Flex vertical>
        <Typography.Title level={2}>Users</Typography.Title>
        <Breadcrumb
          items={[
            {
              title: <Link to="/dashboard">Dashboard</Link>,
            },
            {
              title: "IAM",
            },
            {
              title: "Users",
            },
          ]}
        />
      </Flex>
      <Flex gap="middle">
        <SearchBar
          placeholder={"Search users ..."}
          search={paginationRequest.search}
          setSearch={onSearchChanged}
        />
        {roles.includes(Role.CREATE_USERS) && (
          <Button size="large" type="primary" onClick={onCreateUser}>
            Create New User
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
