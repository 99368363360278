import { Flex } from "antd";
import { RunsTableSection } from "./components/RunsTableSection/RunsTableSection";
import { RunsHeaderSection } from "./components/RunsHeaderSection/RunsHeaderSection";

const RunsPage = (): JSX.Element => {
  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <RunsHeaderSection />
      <RunsTableSection />
    </Flex>
  );
};

export default RunsPage;
