import { BsSearch } from "react-icons/bs";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { Input, InputRef } from "antd";

export type SearchBarProps = {
  style?: CSSProperties;
  search?: string;
  placeholder?: string;
  setSearch: (search: string) => void;
};

export const SearchBar = (props: SearchBarProps): JSX.Element => {
  const searchBarRef = useRef<InputRef>(null);

  const [value, setValue] = useState<string>(props.search ?? "");

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchBarRef.current) {
        if (value === searchBarRef.current.input?.value) {
          props.setSearch(value);
        }
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, props]);

  const onValueChanged = (event: React.ChangeEvent<HTMLInputElement>): void =>
    setValue(event.target.value);

  return (
    <Input
      style={{ width: 350, ...props.style }}
      size="large"
      ref={searchBarRef}
      value={value}
      onChange={onValueChanged}
      autoFocus
      placeholder={props.placeholder ?? "Search ..."}
      prefix={<BsSearch />}
    />
  );
};
