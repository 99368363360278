import { ReactNode, createContext, useContext } from "react";
import { Nullable } from "../utils/nullable/nullable.type";
import { WorkflowJSON } from "../types/workflow/workflow.type";

export const WorkflowsContext = createContext<{
  workflows?: Nullable<WorkflowJSON[]>;
  workflowMapping: Nullable<Record<WorkflowJSON["workflowId"], WorkflowJSON>>;
}>({
  workflows: null,
  workflowMapping: null,
});

export const useWorkflowsContext = (): {
  workflows?: Nullable<WorkflowJSON[]>;
  workflowMapping?: Nullable<Record<WorkflowJSON["workflowId"], WorkflowJSON>>;
} => useContext(WorkflowsContext);

export type WorkflowsContextProviderProps = {
  children: ReactNode;
  workflows?: Nullable<WorkflowJSON[]>;
};

export const WorkflowsContextProvider = ({
  children,
  workflows,
}: WorkflowsContextProviderProps): JSX.Element => {
  return (
    <WorkflowsContext.Provider
      value={{
        workflows,
        workflowMapping: workflows
          ? workflows.reduce((previous, current) => {
              return { ...previous, [current.workflowId]: current };
            }, {})
          : null,
      }}
    >
      {children}
    </WorkflowsContext.Provider>
  );
};
