import { App, Button, Checkbox, Flex, List, Typography } from "antd";
import { DeploymentRepository } from "../../types/deployment/deployment.type";
import { useState } from "react";
import { useMutation } from "urql";
import { updateDeploymentRepositoryMutation } from "../../providers/graphql/mutations/updateDeploymentRepository.mutation";
import { ErrorView } from "../ErrorView/ErrorView";

export type DeploymentRepositoriesCronJobListViewProps = {
  deploymentId: string;
  deploymentRepository: DeploymentRepository;
  onRefetch: () => void;
  style?: React.CSSProperties;
};

export const DeploymentRepositoriesCronJobListView = ({
  deploymentId,
  deploymentRepository,
  onRefetch,
  style,
}: DeploymentRepositoriesCronJobListViewProps): JSX.Element => {
  const { message } = App.useApp();

  const [cronJobs, setCronJobs] = useState(
    deploymentRepository?.cronJobs?.map(({ name, suspended }) => {
      return {
        name,
        suspended,
      };
    }) ?? []
  );

  const [{ fetching, error }, mutation] = useMutation(
    updateDeploymentRepositoryMutation()
  );

  const onUpdate = async (): Promise<void> => {
    const { error } = await mutation({
      updateDeploymentRepositoryInput: {
        deploymentId,
        repositoryAlias: deploymentRepository.repositoryAlias,
        ref: deploymentRepository.ref,
        country: deploymentRepository.country ?? null,
        features: deploymentRepository.features ?? [],
        cronJobs,
        reDeploy: false,
      },
    });

    if (!error) {
      return;
    }

    message.info(`Deployment cronjobs edited successfully!`);

    onRefetch();
  };

  const onChange = (name: string, value: boolean): void => {
    setCronJobs((prev) => {
      return prev.map((cronJob) => {
        if (cronJob.name === name) {
          return {
            ...cronJob,
            suspended: !value,
          };
        }

        return cronJob;
      });
    });
  };

  return (
    <ErrorView disableRetryButton error={error}>
      <List
        header={
          <Flex gap="middle" justify="space-between" align="center">
            <Typography.Title level={3}>
              Cron Jobs To Be Deployed: {deploymentRepository.repositoryAlias}
            </Typography.Title>
            <Button loading={fetching} type="primary" onClick={onUpdate}>
              Update
            </Button>
          </Flex>
        }
        style={style}
        dataSource={cronJobs}
        renderItem={(item, index) => {
          return (
            <List.Item
              key={index}
              actions={[
                <Checkbox
                  disabled={fetching}
                  key="1"
                  checked={!item.suspended}
                  onChange={(e) => onChange(item.name, e.target.checked)}
                >
                  Active
                </Checkbox>,
              ]}
            >
              <List.Item.Meta title={item.name} />
            </List.Item>
          );
        }}
      />
    </ErrorView>
  );
};
