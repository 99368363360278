import { Outlet, useLocation, useRouteLoaderData } from "react-router-dom";
import { Nullable } from "../../utils/nullable/nullable.type";
import { RepositoryJSON } from "../../types/repository/repository.type";
import { RepositoriesContextProvider } from "../../context/RepositoriesContext";
import { WorkflowsContextProvider } from "../../context/WorkflowsContext";
import { WorkflowJSON } from "../../types/workflow/workflow.type";
import { useCallback } from "react";
import { App } from "antd";

export const ContextLayout = (): JSX.Element => {
  const location = useLocation();

  const routeId = useCallback((): string => {
    const pathnameToRouteIdMapping: Record<string, string> = {
      "/dashboard/releases": "dashboard-releases",
      "/dashboard/more/runs": "dashboard-more",
      "/dashboard/more/pr-status-overrides": "dashboard-more",
      "/dashboard/more/pr-status-overrides/new": "dashboard-more",
      "/dashboard/deployments": "dashboard-deployments",
      "/dashboard/quality-assurance/tests": "dashboard-quality-assurance-tests",
    };

    for (const pathname of Object.keys(pathnameToRouteIdMapping)) {
      if (location.pathname.startsWith(pathname)) {
        return pathnameToRouteIdMapping[pathname];
      }
    }

    return "dashboard";
  }, [location.pathname]);

  const routeLoaderData = useRouteLoaderData(routeId()) as Nullable<{
    repositories?: Nullable<RepositoryJSON[]>;
    workflows?: Nullable<WorkflowJSON[]>;
  }>;

  return (
    <RepositoriesContextProvider repositories={routeLoaderData?.repositories}>
      <WorkflowsContextProvider workflows={routeLoaderData?.workflows}>
        <App>
          <Outlet />
        </App>
      </WorkflowsContextProvider>
    </RepositoriesContextProvider>
  );
};
