import { Flex } from "antd";
import { TeamsHeaderSection } from "./components/TeamsHeaderSection/TeamsHeaderSection";
import { TeamsTableSection } from "./components/TeamsTableSection/TeamsTableSection";

const TeamsPage = (): JSX.Element => {
  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <TeamsHeaderSection />
      <TeamsTableSection />
    </Flex>
  );
};

export default TeamsPage;
