import { CSSProperties, useState } from "react";
import { Button, Flex, Select, Spin, Tooltip } from "antd";
import { ErrorView } from "../ErrorView/ErrorView";
import { useQuery } from "urql";
import { Nullable } from "../../utils/nullable/nullable.type";
import { getPaginateTeamsQuery } from "../../providers/graphql/queries/getPaginateTeams.query";
import { PaginationResponse } from "../../types/pagination/pagination.type";
import { TeamJSON } from "../../types/team/team.type";
import { IoIosRefresh } from "react-icons/io";

export type SelectTeamInputProps = {
  style?: CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
};

export const SelectTeamInput = ({
  style,
  value,
  onChange,
}: SelectTeamInputProps): Nullable<JSX.Element> => {
  const [search, setSearch] = useState<string>();

  const [{ data, error, fetching }, refetch] = useQuery<{
    teams: PaginationResponse<TeamJSON>;
  }>({
    query: getPaginateTeamsQuery({
      fields: [
        {
          data: ["id", "teamName"],
        },
      ],
    }),
    variables: {
      teamPaginationInput: {
        ...(search ? { search } : {}),
        skip: 0,
        limit: 100,
      },
    },
    requestPolicy: "network-only",
  });

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  const teams = data?.teams?.data ?? [];

  return (
    <ErrorView error={error} onRefetch={onRefetch}>
      <Flex gap="middle">
        <Select
          value={value}
          onChange={onChange}
          showSearch
          onSearch={setSearch}
          style={style}
          filterOption={false}
          dropdownRender={(menu) => {
            if (fetching) {
              return (
                <Flex justify="center">
                  <Spin />
                </Flex>
              );
            }

            return menu;
          }}
        >
          {teams.map((value, index) => {
            return (
              <Select.Option key={index} value={value.teamName}>
                {value.teamName}
              </Select.Option>
            );
          })}
        </Select>
        <Tooltip title="Refresh">
          <Button
            loading={fetching}
            icon={<IoIosRefresh />}
            type="link"
            onClick={onRefetch}
          />
        </Tooltip>
      </Flex>
    </ErrorView>
  );
};
