import { Breadcrumb, Flex, Typography } from "antd";
import { Link } from "react-router-dom";

export const NewPRStatusOverrideHeaderSection = (): JSX.Element => {
  return (
    <Flex justify="space-between" align="center">
      <Flex vertical>
        <Typography.Title level={2}>New PR Status Override</Typography.Title>
        <Breadcrumb
          items={[
            {
              title: <Link to="/dashboard">Dashboard</Link>,
            },
            {
              title: "More",
            },
            {
              title: (
                <Link to="/dashboard/more/pr-status-overrides">
                  PR Status Overrides
                </Link>
              ),
            },
            {
              title: "New PR Status Override",
            },
          ]}
        />
      </Flex>
    </Flex>
  );
};
