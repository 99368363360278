import { Breadcrumb, Flex, Typography } from "antd";
import { Link } from "react-router-dom";

export const NewEnvironmentHeaderSection = (): JSX.Element => {
  return (
    <Flex justify="space-between" align="center">
      <Flex vertical>
        <Typography.Title level={2}>New Environment</Typography.Title>
        <Breadcrumb
          items={[
            {
              title: <Link to="/dashboard">Dashboard</Link>,
            },
            {
              title: <Link to="/dashboard/environments">Environments</Link>,
            },
            {
              title: "New Environment",
            },
          ]}
        />
      </Flex>
    </Flex>
  );
};
