import { App, Button } from "antd";
import { usePagination } from "../../../../../../hooks/usePagination";
import { useQuery } from "urql";
import { PaginationResponse } from "../../../../../../types/pagination/pagination.type";
import { GroupJSON } from "../../../../../../types/group/group.type";
import { ErrorView } from "../../../../../../components/ErrorView/ErrorView";
import { TableView } from "../../../../../../components/TableView/TableView";
import { ReactNode } from "react";
import { TextFilterInput } from "../../../../../../components/TextFilterInput/TextFilterInput";
import { GroupsMoreButton } from "./components/GroupsMoreButton/GroupsMoreButton";
import { getFilterValueUtility } from "../../../../../../utils/filter/filter.utility";
import { getPaginateGroupsQuery } from "../../../../../../providers/graphql/queries/getPaginateGroups.query";
import { PermissionsViewer } from "../../../../../../components/PermissionsViewer/PermissionsViewer";
import { UsersViewer } from "../../../../../../components/UsersViewer/UsersViewer";

export const GroupsTableSection = (): JSX.Element => {
  const { paginationRequest } = usePagination({
    filterNames: ["groupNames", "emails"],
  });

  const { search, limit, skip, sortField, sortOrder, filters } =
    paginationRequest;

  const { modal } = App.useApp();

  const [{ data, error, fetching }, refetch] = useQuery<{
    groups: PaginationResponse<GroupJSON>;
  }>({
    query: getPaginateGroupsQuery({
      fields: [
        "total",
        {
          data: [
            "id",
            "groupName",
            "emails",
            "roles",
            {
              relations: [
                {
                  users: ["id", "fullName", "email"],
                },
              ],
            },
          ],
        },
      ],
    }),
    variables: {
      groupPaginationInput: {
        search,
        limit,
        skip,
        sortField,
        sortOrder,
        filters: {
          ...(filters ? { ...filters } : {}),
        },
      },
    },
    requestPolicy: "network-only",
  });

  const groups = data?.groups.data ?? [],
    total = data?.groups.total ?? 0;

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  const onShowPermissions = (group: GroupJSON): void => {
    modal.info({
      icon: null,
      title: `Permissions for ${group.groupName}`,
      width: "75%",
      content: (
        <PermissionsViewer
          style={{ height: "65vh", overflowY: "auto" }}
          readOnly
          selectedPermissions={group.roles}
        />
      ),
      okText: "Cancel",
      okButtonProps: {
        type: "default",
      },
    });
  };

  const onShowUsers = (group: GroupJSON): void => {
    modal.info({
      icon: null,
      title: `Users in ${group.groupName}`,
      width: "75%",
      content: (
        <UsersViewer
          style={{ height: "65vh" }}
          users={group.relations?.users ?? []}
        />
      ),
      okText: "Cancel",
      okButtonProps: {
        type: "default",
      },
    });
  };

  return (
    <ErrorView error={error} onRefetch={onRefetch}>
      <TableView
        rowKey="groupName"
        loading={fetching}
        sortableColumns={["fullName", "email"]}
        filterableColumnMapping={{
          groupName: "groupNames",
          email: "emails",
        }}
        dataSource={groups}
        total={total}
        columns={[
          {
            key: "groupName",
            dataIndex: "groupName",
            title: "Group Name",
            align: "center",
            width: 200,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "groupNames",
            }),
            filterDropdown: TextFilterInput,
          },

          {
            key: "users",
            title: "Users",
            align: "center",
            width: 200,
            render: (_value: undefined, record: GroupJSON): ReactNode => {
              return (
                <Button type="link" onClick={onShowUsers.bind(this, record)}>
                  {record.emails.length ?? 0} users
                </Button>
              );
            },
          },
          {
            key: "permissions",
            title: "Permissions",
            align: "center",
            width: 200,
            render: (_value: undefined, record: GroupJSON): ReactNode => {
              return (
                <Button
                  type="link"
                  onClick={onShowPermissions.bind(this, record)}
                >
                  {record.roles.length ?? 0} permissions
                </Button>
              );
            },
          },
          {
            key: "more",
            align: "center",
            fixed: "right",
            width: 100,
            render: (_value: undefined, record: GroupJSON): ReactNode => {
              return <GroupsMoreButton group={record} onRefetch={onRefetch} />;
            },
          },
        ]}
      />
    </ErrorView>
  );
};
