import {
  Descriptions,
  DescriptionsProps,
  Divider,
  Flex,
  List,
  Typography,
} from "antd";
import { EnvironmentJSON } from "../../../../../../../types/environment/environment.type";
import { EnvironmentHibernationType } from "../../../../../../../types/environment/environment.enum";
import { mapEnumValueToKey } from "../../../../../../../utils/enum/enum";

export type EnvironmentHibernationOptionsViewProps = {
  environment: EnvironmentJSON;
  style?: React.CSSProperties;
};

const dayOfTheWeekMapping: string[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const EnvironmentHibernationOptionsView = ({
  style,
  environment,
}: EnvironmentHibernationOptionsViewProps): JSX.Element => {
  return (
    <List
      style={{
        ...style,
        overflowY: "auto",
      }}
      dataSource={environment.hibernationOptions ?? []}
      renderItem={(item, index) => {
        const items = ((): DescriptionsProps["items"] => {
          const items: DescriptionsProps["items"] = [];

          if (
            item.type ===
            EnvironmentHibernationType.NO_DEPLOYMENT_CHANGE_OVER_TIME
          ) {
            items.push({
              label: null,
              children: (
                <Typography.Title
                  level={4}
                  type="secondary"
                  style={{ paddingLeft: 35 }}
                >
                  Hours with no deployment change:
                  <br />
                  <br />
                  <Typography.Text
                    style={{ fontSize: "1.15em", paddingLeft: 35 }}
                  >
                    {item.hoursWithNoDeploymentChange} Hours
                  </Typography.Text>
                </Typography.Title>
              ),
            });

            items.push({
              label: null,
              children: <Divider />,
            });
          }

          if (
            item.type ===
            EnvironmentHibernationType.DURING_CERTAIN_DAYS_OF_THE_WEEK
          ) {
            for (const day of item.daysOfTheWeek) {
              items.push({
                label: null,
                children: (
                  <Flex vertical gap="middle" style={{ paddingLeft: 35 }}>
                    {day.hoursOfTheDay.map((hour, index) => (
                      <Flex key={index} vertical gap="large">
                        {index === 0 && (
                          <Typography.Title level={4} type="secondary">
                            Day:
                            <Typography.Text
                              style={{ marginLeft: 15, fontSize: "1.15em" }}
                            >
                              {dayOfTheWeekMapping[day.dayOfTheWeek]}
                            </Typography.Text>
                          </Typography.Title>
                        )}
                        <Flex gap="middle" style={{ paddingLeft: 35 }}>
                          <Flex gap="large">
                            <Typography.Text strong type="secondary">
                              From:
                            </Typography.Text>
                            <Typography.Text>{hour.startHour}</Typography.Text>
                          </Flex>
                          <Flex gap="large">
                            <Typography.Text strong type="secondary">
                              To:
                            </Typography.Text>
                            <Typography.Text>{hour.endHour}</Typography.Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                ),
              });

              items.push({
                label: null,
                children: <Divider />,
              });
            }
          }

          return items;
        })();

        return (
          <Descriptions
            column={1}
            title={
              <Typography.Title level={4}>
                {mapEnumValueToKey(EnvironmentHibernationType, item.type)}
              </Typography.Title>
            }
            key={index}
            items={items}
          />
        );
      }}
    />
  );
};
