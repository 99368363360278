import { Avatar, Flex, Space, Typography } from "antd";
import Logo from "../../../assets/logo.png";
import { ThemeSwitch } from "./ThemeSwitch/ThemeSwitch";
import { IoIosArrowDown } from "react-icons/io";
import { UserOutlined } from "@ant-design/icons";
import { Nullable } from "../../../utils/nullable/nullable.type";
import { UserJSON } from "../../../types/user/user.type";

export type NavigationBarProps = {
  user?: Nullable<UserJSON>;
};

export const NavigationBar = ({ user }: NavigationBarProps): JSX.Element => {
  return (
    <Flex justify="space-between" align="center" style={{ height: 70 }}>
      <Flex gap="small" align="center">
        <img src={Logo} alt="logo" height={40} />
        <Typography.Text
          style={{ color: "#fff", fontWeight: "bold", fontSize: "2em" }}
        >
          Soum Web Portal
        </Typography.Text>
      </Flex>
      <Flex gap="large" align="center">
        <ThemeSwitch />
        <>
          {user && (
            <Space size="small">
              <Avatar
                src={user?.photo}
                size={50}
                style={{ borderColor: "white", opacity: 0.85 }}
                icon={<UserOutlined />}
              />
              <Flex vertical>
                <Flex gap="small" align="center">
                  <Typography.Text
                    style={{ color: "white", opacity: 0.85 }}
                    strong
                  >
                    {user?.fullName}
                  </Typography.Text>
                  <IoIosArrowDown />
                </Flex>
                <Typography.Text
                  style={{ color: "white", opacity: 0.85, fontSize: 10 }}
                >
                  {user?.email}
                </Typography.Text>
              </Flex>
            </Space>
          )}
        </>
      </Flex>
    </Flex>
  );
};
