import { CSSProperties, ReactNode } from "react";
import { Button, Descriptions, Flex, Select, Spin, Tooltip } from "antd";
import { ErrorView } from "../ErrorView/ErrorView";
import { useQuery } from "urql";
import { Nullable } from "../../utils/nullable/nullable.type";
import { getUserEnvironmentsQuery } from "../../providers/graphql/queries/getUserEnvironments.query";
import { EnvironmentJSON } from "../../types/environment/environment.type";
import { IoIosRefresh } from "react-icons/io";

export type SelectEnvironmentInputProps = {
  style?: CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
  currentEnvironmentId?: string;
};

export const SelectEnvironmentInput = ({
  style,
  value,
  onChange,
  currentEnvironmentId,
}: SelectEnvironmentInputProps): Nullable<JSX.Element> => {
  const [{ data, error, fetching }, refetch] = useQuery<{
    userEnvironments: EnvironmentJSON[];
  }>({
    query: getUserEnvironmentsQuery({
      fields: [
        "id",
        "environmentId",
        "environmentName",
        "account",
        {
          relations: [{ deployment: ["deploymentName"] }],
        },
      ],
    }),
    requestPolicy: "network-only",
  });

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  const userEnvironments = data?.userEnvironments ?? [];

  const deploymentText = (environment: EnvironmentJSON): ReactNode => {
    if (currentEnvironmentId === environment.environmentId) {
      return (
        <span style={{ color: "green" }}>
          Current Environment Used By The Deployment
        </span>
      );
    }

    if (!environment.relations?.deployment) {
      return <span style={{ color: "green" }}>Free Environment</span>;
    }

    return (
      <span style={{ color: "red" }}>
        {`Deployment ${environment.relations?.deployment.deploymentName} Will Unlinked From Environment`}
      </span>
    );
  };

  return (
    <ErrorView error={error} onRefetch={onRefetch}>
      <Flex gap="middle">
        <Select
          value={value}
          onChange={onChange}
          showSearch
          style={style}
          dropdownRender={(menu) => {
            if (fetching) {
              return (
                <Flex justify="center">
                  <Spin />
                </Flex>
              );
            }

            return menu;
          }}
          labelRender={(value) => {
            const environment = userEnvironments.find(
              (environment) => environment.environmentId === value.value
            );

            if (!environment) {
              return null;
            }

            return (
              <Flex gap="middle">
                <span>{environment.environmentName}</span>
                <span>{deploymentText(environment)}</span>
              </Flex>
            );
          }}
        >
          {userEnvironments.map((value, index) => {
            return (
              <Select.Option key={index} value={value.environmentId}>
                <Descriptions
                  column={1}
                  items={[
                    {
                      label: "Environment",
                      children: value.environmentName,
                    },
                    {
                      label: "Account",
                      children: value.account,
                    },
                    {
                      label: "Deployment",
                      children: deploymentText(value),
                    },
                  ]}
                />
              </Select.Option>
            );
          })}
        </Select>
        <Tooltip title="Refresh">
          <Button
            loading={fetching}
            icon={<IoIosRefresh />}
            type="link"
            onClick={onRefetch}
          />
        </Tooltip>
      </Flex>
    </ErrorView>
  );
};
