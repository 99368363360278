import { Flex } from "antd";
import { DeploymentsHeaderSection } from "./components/DeploymentsHeaderSection/DeploymentsHeaderSection";
import { DeploymentsTableSection } from "./components/DeploymentsTableSection/DeploymentsTableSection";

const DeploymentsPage = (): JSX.Element => {
  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <DeploymentsHeaderSection />
      <DeploymentsTableSection />
    </Flex>
  );
};

export default DeploymentsPage;
