import { Flex } from "antd";
import { GroupsHeaderSection } from "./components/GroupsHeaderSection/GroupsHeaderSection";
import { GroupsTableSection } from "./components/GroupsTableSection/GroupsTableSection";

const GroupsPage = (): JSX.Element => {
  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <GroupsHeaderSection />
      <GroupsTableSection />
    </Flex>
  );
};

export default GroupsPage;
