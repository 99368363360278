import { Alert, Button, Flex } from "antd";
import { ReactNode } from "react";
import { CombinedError } from "urql";
import { useError } from "../../hooks/useError";

export type ErrorViewProps = {
  children?: ReactNode;
  error?: CombinedError | Error;
  onRefetch?: () => void;
  customErrorView?: ReactNode;
  hideChildrenOnError?: boolean;
  disableRetryButton?: boolean;
  style?: React.CSSProperties;
};

export const ErrorView = ({
  children,
  error,
  onRefetch,
  customErrorView,
  hideChildrenOnError,
  disableRetryButton,
  style,
}: ErrorViewProps): JSX.Element | null => {
  const { parseError } = useError();

  const { status, messages } = parseError(error);

  const hideChildren = hideChildrenOnError && error;

  return (
    <div style={style}>
      {error ? (
        customErrorView ? (
          customErrorView
        ) : (
          <Alert
            showIcon
            type="error"
            style={{ marginTop: 25, marginBottom: 25, width: "100%" }}
            message={
              status === 0
                ? "Oops! An Error Occurred"
                : `Oops! A ${status} Error Occurred`
            }
            description={
              <Flex vertical gap="middle" style={{ marginTop: 25 }}>
                {messages.map((message, index) => (
                  <pre style={{ textWrap: "wrap" }} key={index}>
                    {message}
                  </pre>
                ))}
              </Flex>
            }
            action={
              disableRetryButton ? null : (
                <Button
                  size="large"
                  style={{ backgroundColor: "green" }}
                  onClick={onRefetch ?? ((): void => window.location.reload())}
                >
                  Retry
                </Button>
              )
            }
          />
        )
      ) : null}
      {!hideChildren && children}
    </div>
  );
};
