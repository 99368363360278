import { Flex } from "antd";
import { WorkflowsTableSection } from "./components/WorkflowsTableSection/WorkflowsTableSection";
import { WorkflowsHeaderSection } from "./components/WorkflowsHeaderSection/WorkflowsHeaderSection";

const WorkflowsPage = (): JSX.Element => {
  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <WorkflowsHeaderSection />
      <WorkflowsTableSection />
    </Flex>
  );
};

export default WorkflowsPage;
