import { App, Button, Form, Input, Modal } from "antd";
import { Nullable } from "../../utils/nullable/nullable.type";
import { TeamJSON } from "../../types/team/team.type";
import { ErrorView } from "../ErrorView/ErrorView";
import { useMutation } from "urql";
import { createTeamMutation } from "../../providers/graphql/mutations/createTeam.mutation";
import { FaUsers } from "react-icons/fa";
import { updateTeamNotificationChannelMutation } from "../../providers/graphql/mutations/updateTeamNotificationChannel.mutation";
import { MdEditSquare } from "react-icons/md";

type UpsertTeamFormFields = {
  teamName?: string;
  notificationChannel?: Nullable<string>;
};

export type UpsertTeamFormProps = {
  team?: Nullable<TeamJSON>;
};

export const UpsertTeamForm = ({ team }: UpsertTeamFormProps): JSX.Element => {
  const { message } = App.useApp();

  const isEditing = Boolean(team);

  const [{ fetching, error }, mutation] = useMutation(
    isEditing ? updateTeamNotificationChannelMutation() : createTeamMutation()
  );

  const onSubmit = async (values: UpsertTeamFormFields): Promise<void> => {
    const { error } = await mutation(
      isEditing
        ? {
            updateTeamNotificationChannelInput: {
              teamName: team?.teamName,
              notificationChannel: values.notificationChannel ?? null,
            },
          }
        : {
            createTeamInput: {
              ...values,
              notificationChannel: values.notificationChannel ?? null,
            },
          }
    );

    if (error) {
      return;
    }

    message.info(`Team ${isEditing ? "updated" : "created"} successfully!`);

    Modal.destroyAll();
  };

  return (
    <ErrorView disableRetryButton error={error}>
      <Form
        size="large"
        layout="vertical"
        disabled={fetching}
        onFinish={onSubmit}
        initialValues={{
          ...(team && { ...team }),
        }}
        scrollToFirstError
      >
        <Form.Item
          name="teamName"
          label="Team name"
          rules={[
            {
              required: true,
              message: "Please input the team name!",
            },
          ]}
        >
          <Input
            disabled={isEditing}
            readOnly={isEditing}
            size="large"
            prefix={<FaUsers />}
            type="text"
          />
        </Form.Item>
        <Form.Item
          name="notificationChannel"
          label="Slack notification channel id"
        >
          <Input size="large" prefix={<MdEditSquare />} type="text" />
        </Form.Item>
        <Form.Item>
          <Button loading={fetching} block type="primary" htmlType="submit">
            {isEditing ? "Edit Team" : "Create Team"}
          </Button>
        </Form.Item>
      </Form>
    </ErrorView>
  );
};
