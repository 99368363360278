import { Flex } from "antd";
import { VPNSetupSection } from "./components/VPNSetupSection/VPNSetupSection";
import { VpnHeaderSection } from "./components/VPNHeaderSection/VPNHeaderSection";

const VPNPage = (): JSX.Element => {
  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <VpnHeaderSection />
      <VPNSetupSection />
    </Flex>
  );
};

export default VPNPage;
