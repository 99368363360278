import { Tag } from "antd";
import { usePagination } from "../../../../../hooks/usePagination";
import { useQuery } from "urql";
import { PaginationResponse } from "../../../../../types/pagination/pagination.type";
import { ReleaseJSON } from "../../../../../types/release/release.type";
import { ErrorView } from "../../../../../components/ErrorView/ErrorView";
import { TableView } from "../../../../../components/TableView/TableView";
import { ReactNode, useEffect, useState } from "react";
import { TextFilterInput } from "../../../../../components/TextFilterInput/TextFilterInput";
import { ReleasesMoreButton } from "./components/ReleasesMoreButton/ReleasesMoreButton";
import { getFilterValueUtility } from "../../../../../utils/filter/filter.utility";
import { getPaginateReleasesQuery } from "../../../../../providers/graphql/queries/getPaginateReleases.query";
import { ReleaseStatus } from "../../../../../types/release/release.enum";
import {
  mapEnumValueToKey,
  releaseStatusColorMapping,
} from "../../../../../utils/enum/enum";
import { ReleaseRepositoriesView } from "./components/ReleaseRepositoriesView/ReleaseRepositoriesView";
import moment from "moment";

export const ReleasesTableSection = (): JSX.Element => {
  const { paginationRequest } = usePagination({
    filterNames: ["releaseNames", "repositoryNames", "versions"],
  });

  const { search, limit, skip, sortField, sortOrder, filters } =
    paginationRequest;

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const [{ data, error, fetching }, refetch] = useQuery<{
    releases: PaginationResponse<ReleaseJSON>;
  }>({
    query: getPaginateReleasesQuery({
      fields: [
        "total",
        {
          data: ["id", "releaseName", "releaseNotes", "releaseStatus", "createdAt", "updatedAt"],
        },
      ],
    }),
    variables: {
      releasePaginationInput: {
        search,
        limit,
        skip,
        sortField,
        sortOrder,
        filters: {
          ...(filters ? { ...filters } : {}),
        },
      },
    },
    requestPolicy: "network-only",
  });

  const releases = data?.releases.data ?? [],
    total = data?.releases.total ?? 0;

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  useEffect(() => {
    setExpandedRowKeys([]);
  }, [fetching]);

  return (
    <ErrorView error={error} onRefetch={onRefetch}>
      <TableView
        rowKey="releaseName"
        loading={fetching}
        sortableColumns={["fullName", "email"]}
        filterableColumnMapping={{
          releaseName: "releaseNames",
        }}
        dataSource={releases}
        total={total}
        expandable={{
          expandedRowRender: (record: ReleaseJSON): ReactNode => {
            if (!expandedRowKeys.length) return null;

            return <ReleaseRepositoriesView releaseName={record.releaseName} />;
          },
          expandedRowKeys,
          onExpand: (expanded: boolean, record: ReleaseJSON): void => {
            setExpandedRowKeys(expanded ? [record.releaseName] : []);
          },
        }}
        columns={[
          {
            key: "releaseName",
            dataIndex: "releaseName",
            title: "Release Name",
            align: "center",
            width: 200,
            fixed: "left",
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "releaseNames",
            }),
            filterDropdown: TextFilterInput,
          },
          {
            key: "releaseNotes",
            dataIndex: "releaseNotes",
            title: "Release Notes",
            align: "center",
            width: 400,
          },
          {
            key: "releaseStatus",
            dataIndex: "releaseStatus",
            title: "Release Status",
            align: "center",
            width: 200,
            fixed: "right",
            render: (value: string, _record: ReleaseJSON): ReactNode => {
              return (
                <Tag color={releaseStatusColorMapping[value as ReleaseStatus]}>
                  {mapEnumValueToKey(ReleaseStatus, value)}
                </Tag>
              );
            },
          },
          {
            key: "updatedAt",
            title: "Completed Released On",
            align: "center",
            width: 200,
            fixed: "right",
            render: (_value: string, record: ReleaseJSON): ReactNode => {
              return (
                <span>{moment(new Date(record?.updatedAt ?? record.createdAt)).format("LLL")}</span>
              );
            },
          },
          {
            key: "more",
            align: "center",
            fixed: "right",
            width: 100,
            render: (_value: undefined, record: ReleaseJSON): ReactNode => {
              return (
                <ReleasesMoreButton release={record} onRefetch={onRefetch} />
              );
            },
          },
        ]}
      />
    </ErrorView>
  );
};
