import { Flex } from "antd";
import { DatabaseMigrationsHeaderSection } from "./components/DatabaseMigrationsHeaderSection/DatabaseMigrationsHeaderSection";
import { DatabaseMigrationsTableSection } from "./components/DatabaseMigrationsTableSection/DatabaseMigrationsTableSection";

const DatabaseMigrationsPage = (): JSX.Element => {
  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <DatabaseMigrationsHeaderSection />
      <DatabaseMigrationsTableSection />
    </Flex>
  );
};

export default DatabaseMigrationsPage;
