import { App, Button, Divider, Flex, Form, Typography } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { SelectUserInput } from "../../../../../SelectUserInput/SelectUserInput";
import { Nullable } from "../../../../../../utils/nullable/nullable.type";
import { useAtomValue } from "jotai";
import { upsertEnvironmentWizardFormDataAtom } from "../../../../states/upsert.environment.wizard.form.data";

export const SelectUsersSection = (): JSX.Element => {
  const { notification } = App.useApp();

  const upsertEnvironmentWizardFormData = useAtomValue(
    upsertEnvironmentWizardFormDataAtom
  );

  const { teams } = upsertEnvironmentWizardFormData;

  return (
    <>
      <Form.List
        name="users"
        rules={[
          {
            validator: async (_, users) => {
              let error: Nullable<string> = null;

              if ((!users || !users.length) && (!teams || !teams.length)) {
                error =
                  "At least one user or team is required to be able to access the environment";
              }

              if (error) {
                notification.error({
                  message: error,
                });

                return Promise.reject(new Error(error));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            <Flex justify="space-between" align="center">
              <div></div>
              <Flex vertical>
                <Typography.Title
                  level={3}
                  style={{ display: "block", textAlign: "center" }}
                >
                  User Selection
                </Typography.Title>
                <Typography.Text
                  type="secondary"
                  style={{ display: "block", textAlign: "center" }}
                >
                  Select the users that will have access to this environment
                </Typography.Text>
              </Flex>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
              >
                Add New User
              </Button>
            </Flex>
            <Divider />
            {fields.map((_, index) => {
              return (
                <div key={index}>
                  <Flex align="center" justify="space-between">
                    <Typography.Title level={2} type="secondary">
                      User {index + 1}
                    </Typography.Title>
                    <Button
                      danger
                      type="primary"
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(index)}
                      style={{ marginLeft: "auto" }}
                    >
                      Remove User
                    </Button>
                  </Flex>
                  <Form.Item
                    label="User"
                    labelCol={{ span: 24 }}
                    name={[index, "email"]}
                    rules={[
                      {
                        required: true,
                        message: "Please select a user first",
                      },
                    ]}
                  >
                    <SelectUserInput />
                  </Form.Item>
                  <Divider style={{ marginTop: 50 }} />
                </div>
              );
            })}
          </>
        )}
      </Form.List>
    </>
  );
};
