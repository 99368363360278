import { Alert, App, Button, Form, Modal, Select } from "antd";
import { Nullable } from "../../utils/nullable/nullable.type";
import { ErrorView } from "../ErrorView/ErrorView";
import { useMutation } from "urql";
import { DeploymentRepository } from "../../types/deployment/deployment.type";
import { useRepositoriesContext } from "../../context/RepositoriesContext";
import { SelectBranchInput } from "../SelectBranchInput/SelectBranchInput";
import { mapEnumValueToKey } from "../../utils/enum/enum";
import { Country } from "../../types/country/country.enum";
import { RepositoryCharacteristic } from "../../types/repository/repository.enum";
import { SelectCronjobsInput } from "../SelectCronjobsInput/SelectCronjobsInput";
import { updateDeploymentRepositoryMutation } from "../../providers/graphql/mutations/updateDeploymentRepository.mutation";
import { addDeploymentRepositoryMutation } from "../../providers/graphql/mutations/addDeploymentRepository.mutation";
import { RepositoryJSON } from "../../types/repository/repository.type";

type UpsertDeploymentRepositoryFormFields = Partial<
  Pick<
    DeploymentRepository,
    "repositoryAlias" | "ref" | "commit" | "features"
  > & {
    countries: Nullable<Country[]>;
    country: Nullable<Country>;
    cronJobs: string[];
  }
>;

export type UpsertDeploymentRepositoryFormProps = {
  deploymentId: string;
  deploymentRepository?: DeploymentRepository;
  onRefetch: () => void;
};

export const UpsertDeploymentRepositoryForm = ({
  deploymentId,
  deploymentRepository,
  onRefetch,
}: UpsertDeploymentRepositoryFormProps): JSX.Element => {
  const { repositories, repositoryMapping } = useRepositoriesContext();

  const [form] = Form.useForm<UpsertDeploymentRepositoryFormFields>();

  const repositoryAlias = Form.useWatch("repositoryAlias", form);

  const ref = Form.useWatch("ref", form);

  const { message } = App.useApp();

  const isEditing = Boolean(deploymentRepository);

  const [{ fetching, error }, mutation] = useMutation(
    isEditing
      ? updateDeploymentRepositoryMutation()
      : addDeploymentRepositoryMutation()
  );

  const getRepositoryFromRepositoryAlias = (
    repositoryAlias?: string
  ): Nullable<RepositoryJSON> => {
    return (repositoryMapping ?? {})[repositoryAlias ?? ""] ?? null;
  };

  const onSubmit = async (
    values: UpsertDeploymentRepositoryFormFields
  ): Promise<void> => {
    const repositoryFromAlias = getRepositoryFromRepositoryAlias(
      values.repositoryAlias
    );

    const { error } = await mutation({
      ...(isEditing
        ? {
            updateDeploymentRepositoryInput: {
              ...values,
              deploymentId,
              country: repositoryFromAlias?.countryOptions
                ?.enabledForDeployments
                ? values.country ?? null
                : null,
              features: values.features ?? [],
              cronJobs: repositoryFromAlias?.characteristics.some(
                (characteristic) =>
                  characteristic ===
                  RepositoryCharacteristic.CAN_DEPLOY_CRON_JOBS
              )
                ? values.cronJobs?.map((cronJob) => {
                    const existingCronJob =
                      deploymentRepository?.cronJobs?.find(
                        ({ name }) => name === cronJob
                      );

                    return {
                      name: cronJob,
                      suspended: existingCronJob
                        ? existingCronJob.suspended
                        : false,
                    };
                  }) ?? null
                : null,
              reDeploy: true,
            },
          }
        : {
            addDeploymentRepositoryInput: {
              ...values,
              deploymentId,
              countries: repositoryFromAlias?.countryOptions
                ?.enabledForDeployments
                ? values.countries ?? null
                : null,
              features: values.features ?? [],
              cronJobs:
                values.cronJobs?.map((cronJob) => {
                  return {
                    name: cronJob,
                    suspended: false,
                  };
                }) ?? null,
            },
          }),
    });

    if (error) {
      return;
    }

    message.info(`Repository ${isEditing ? "updated" : "added"} successfully!`);

    onRefetch();

    Modal.destroyAll();
  };

  if (!repositories || !repositoryMapping) {
    return (
      <Alert
        message="No repositories found. Please check if you have permissions to view repositories"
        type="error"
      />
    );
  }

  const isRepositoryAliasSelected = !!repositoryAlias;

  const isCountrySelectionEnabled = isRepositoryAliasSelected
    ? repositoryMapping[repositoryAlias]?.countryOptions?.enabledForDeployments
    : false;

  const hasCronJobs = isRepositoryAliasSelected
    ? repositoryMapping[repositoryAlias]?.characteristics?.some(
        (characteristic) =>
          characteristic === RepositoryCharacteristic.CAN_DEPLOY_CRON_JOBS
      )
    : false;

  const features = repositoryMapping[repositoryAlias ?? ""]?.features ?? [];

  return (
    <ErrorView disableRetryButton error={error}>
      <Form
        size="large"
        layout="vertical"
        form={form}
        disabled={fetching}
        onFinish={onSubmit}
        initialValues={{
          ...(deploymentRepository && {
            ...deploymentRepository,
            cronJobs:
              deploymentRepository.cronJobs?.map((cronJob) => cronJob.name) ??
              undefined,
          }),
        }}
        scrollToFirstError
      >
        <Form.Item
          label="Repository Name"
          labelCol={{ span: 24 }}
          name="repositoryAlias"
          rules={[
            {
              required: true,
              message: "Repository name is required",
            },
          ]}
        >
          <Select disabled={isEditing} showSearch>
            {repositories.map((repository) => {
              return (
                <Select.Option
                  key={repository.repositoryAlias}
                  value={repository.repositoryAlias}
                >
                  {repository.repositoryAlias}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        {isRepositoryAliasSelected && (
          <>
            <Form.Item
              name="ref"
              label="Branch To Create Tag From"
              rules={[
                {
                  required: true,
                  message: "Branch to create tag from is required",
                },
              ]}
              initialValue={repositoryMapping[repositoryAlias].mainRef}
            >
              <SelectBranchInput
                allowCreateNewBranch
                mainRef={repositoryMapping[repositoryAlias].mainRef}
                repositoryName={
                  repositoryMapping[repositoryAlias].repositoryName
                }
              />
            </Form.Item>
            {isCountrySelectionEnabled && (
              <Form.Item
                name={isEditing ? "country" : "countries"}
                label={
                  isEditing ? "Country To Deploy To" : "Countries To Deploy To"
                }
                rules={[
                  {
                    required: true,
                    message: isEditing
                      ? "Country to deploy to is required"
                      : "Countries to deploy to is required",
                  },
                ]}
                initialValue={[Country.SAUDI_ARABIA]}
              >
                <Select
                  disabled={isEditing}
                  mode={isEditing ? undefined : "multiple"}
                >
                  {Object.values(Country).map((country) => {
                    return (
                      <Select.Option key={country} value={country}>
                        {mapEnumValueToKey(Country, country)}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
            {hasCronJobs && ref && (
              <Form.Item name="cronJobs" label="Cron Jobs">
                <SelectCronjobsInput
                  repositoryName={
                    repositoryMapping[repositoryAlias].repositoryName
                  }
                  refName={ref}
                />
              </Form.Item>
            )}
            {!!features.length && (
              <Form.Item name="features" label="Repository Features">
                <Select mode="multiple">
                  {features.map((feature) => {
                    return (
                      <Select.Option key={feature} value={feature}>
                        {feature}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
          </>
        )}
        <Form.Item>
          <Button loading={fetching} block type="primary" htmlType="submit">
            {isEditing ? "Update Repository" : "Add Repository"}
          </Button>
        </Form.Item>
      </Form>
    </ErrorView>
  );
};
