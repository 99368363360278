import { ReactNode } from "react";
import { TableView } from "../TableView/TableView";
import {
  DeploymentJSON,
  DeploymentRepository,
} from "../../types/deployment/deployment.type";
import { RepositoryLink } from "../RepositoryLink/RepositoryLink";
import { RefLink } from "../RefLink/RefLink";
import { App, Button, Flex, Tag } from "antd";
import {
  countryColorMapping,
  mapEnumValueToKey,
  runStatusColorMapping,
} from "../../utils/enum/enum";
import { Country } from "../../types/country/country.enum";
import { RunStatus } from "../../types/run/run.enum";
import { RunIdLink } from "../RunIdLink/RunIdLink";
import { ErrorView } from "../ErrorView/ErrorView";
import { CommitLink } from "../CommitLink/CommitLink";
import { CombinedError } from "urql";
import { useRepositoriesContext } from "../../context/RepositoriesContext";
import { DeploymentRepositoryMoreButton } from "./components/ReleaseRepositoryMoreButton/DeploymentRepositoryMoreButton";
import { PullRequestLink } from "../PullRequestLink/PullRequestLink";
import { PullRequestState } from "../../types/pull.request/pull.request.enum";
import { RepositoryCharacteristic } from "../../types/repository/repository.enum";
import { Link } from "react-router-dom";
import { ListViewer, ListViewerProps } from "../ListViewer/ListViewer";
import { DeploymentRepositoriesCronJobListView } from "../DeploymentRepositoriesCronJobListView/DeploymentRepositoriesCronJobListView";

export type DeploymentRepositoriesTableProps = {
  title?: ReactNode;
  search?: string;
  style?: React.CSSProperties;
  deployment?: Partial<DeploymentJSON>;
  onRefetch: () => Promise<void>;
  error?: CombinedError;
  onRowsSelected?: (ids: string[]) => void;
  isEditable: boolean;
};

export const DeploymentRepositoriesTable = ({
  title,
  search,
  style,
  deployment,
  error,
  onRefetch,
  onRowsSelected,
  isEditable,
}: DeploymentRepositoriesTableProps): JSX.Element => {
  const { repositoryMapping } = useRepositoriesContext();

  const { modal } = App.useApp();

  const onShowListViewModal = (
    title: string,
    data: ListViewerProps["data"],
    emptyText: ListViewerProps["emptyText"]
  ): void => {
    modal.info({
      icon: null,
      title: title,
      width: "75%",
      content: (
        <ListViewer
          style={{ height: "65vh", overflowY: "auto" }}
          data={data}
          emptyText={emptyText}
        />
      ),
      okText: "Cancel",
      okButtonProps: {
        type: "default",
      },
    });
  };

  const onShowEditCronJobsModal = (
    deploymentRepository: DeploymentRepository
  ): void => {
    modal.info({
      icon: null,
      title: null,
      width: "75%",
      content: (
        <DeploymentRepositoriesCronJobListView
          deploymentId={deployment?.deploymentId!}
          deploymentRepository={deploymentRepository}
          onRefetch={onRefetch}
          style={{ height: "65vh", overflowY: "auto" }}
        />
      ),
      okText: "Cancel",
      okButtonProps: {
        type: "default",
      },
    });
  };

  return (
    <div style={style}>
      <ErrorView error={error} onRefetch={onRefetch} hideChildrenOnError>
        <TableView
          noPagination
          total={deployment?.repositories?.length ?? 0}
          rowKey="id"
          rowSelection={
            onRowsSelected
              ? {
                  type: "checkbox",
                  onChange: (selectedRowKeys: React.Key[]) => {
                    onRowsSelected(selectedRowKeys as string[]);
                  },
                }
              : undefined
          }
          dataSource={
            deployment?.repositories?.filter((repository) => {
              if (!search) {
                return true;
              }

              return (
                repository.repositoryAlias
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                repository.repositoryName
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                repository.ref.toLowerCase().includes(search.toLowerCase())
              );
            }) ?? []
          }
          title={(): ReactNode => title}
          columns={[
            {
              key: "runningStatus",
              title: "POD Running Status",
              align: "center",
              width: 250,
              render: (
                _value: string,
                record: DeploymentRepository
              ): ReactNode => {
                if (!deployment?.relations?.environment) {
                  return "-";
                }

                const repository = (repositoryMapping ?? {})[
                  record.repositoryAlias
                ];

                const canDeployToK8s = repository?.characteristics?.find(
                  (characteristic) =>
                    characteristic ===
                    RepositoryCharacteristic.IS_K8S_REPOSITORY
                );

                if (!canDeployToK8s) {
                  return "-";
                }

                const kubernetesDeployment = (
                  deployment?.relations?.kubernetesDeployments ?? []
                ).find(
                  (kubernetesDeployment) =>
                    kubernetesDeployment.labels?.repositoryAlias ===
                      record.repositoryAlias &&
                    (kubernetesDeployment.labels?.country ??
                      Country.SAUDI_ARABIA) ===
                      (record.country ?? Country.SAUDI_ARABIA)
                );

                const deployedCommitNotLatest =
                  kubernetesDeployment?.labels?.commit ===
                  record.commit ? null : (
                    <Tag color="red">Deployed Commit Doesnt Match Ref</Tag>
                  );

                return kubernetesDeployment ? (
                  <Flex vertical gap="middle" align="center">
                    {!kubernetesDeployment.readyPods &&
                    !kubernetesDeployment.totalPods &&
                    !kubernetesDeployment.unavailablePods ? (
                      <Tag color="volcano">HIBERNATING</Tag>
                    ) : (
                      <>
                        <Tag color="green">
                          {`RUNNING: ${kubernetesDeployment.readyPods} /
                            ${kubernetesDeployment.totalPods}`}
                        </Tag>
                        {!!kubernetesDeployment.unavailablePods && (
                          <Tag color="volcano">
                            PENDING: {kubernetesDeployment.unavailablePods}
                          </Tag>
                        )}
                      </>
                    )}
                    {deployedCommitNotLatest}
                  </Flex>
                ) : (
                  <Tag color="red">NOT DEPLOYED</Tag>
                );
              },
            },
            {
              key: "ref",
              dataIndex: "ref",
              title: "Ref",
              align: "center",
              width: 400,
              render: (
                _value: string,
                record: DeploymentRepository
              ): ReactNode => {
                const branch = (deployment?.relations?.branches ?? []).find(
                  (branch) =>
                    branch.repositoryName === record.repositoryName &&
                    branch.ref === record.ref
                );

                const repository = (repositoryMapping ?? {})[
                  record.repositoryAlias
                ];

                const canDeployToK8s = repository?.characteristics?.find(
                  (characteristic) =>
                    characteristic ===
                    RepositoryCharacteristic.IS_K8S_REPOSITORY
                );

                const diffrentKubernetesDeployment =
                  deployment?.relations?.environment && canDeployToK8s
                    ? Boolean(
                        (
                          deployment?.relations?.kubernetesDeployments ?? []
                        ).find(
                          (kubernetesDeployment) =>
                            kubernetesDeployment.labels?.repositoryAlias ===
                              record.repositoryAlias &&
                            (kubernetesDeployment.labels?.country ??
                              Country.SAUDI_ARABIA) ===
                              (record.country ?? Country.SAUDI_ARABIA) &&
                            kubernetesDeployment.labels?.commit !==
                              record.commit
                        )
                      )
                    : false;

                const diffrentRefCommit = branch?.commit !== record.commit;

                const isDeletedRef =
                  branch?.commit === "0000000000000000000000000000000000000000";

                return (
                  <RefLink
                    repositoryName={record.repositoryName}
                    refName={record.ref}
                    extra={
                      isEditable ? (
                        diffrentRefCommit || diffrentKubernetesDeployment ? (
                          <Tag color="red">
                            {isDeletedRef
                              ? "Using Deleted Ref"
                              : diffrentRefCommit
                              ? "Ref Not using Latest Commit Hence Latest Commit Not Deployed"
                              : "Latest Commit Not Deployed"}
                          </Tag>
                        ) : null
                      ) : null
                    }
                  />
                );
              },
            },
            {
              key: "repositoryAlias",
              dataIndex: "repositoryAlias",
              title: "Repository",
              align: "center",
              width: 250,
              fixed: "left",
              render: (
                _value: string,
                record: DeploymentRepository
              ): ReactNode => {
                return (
                  <RepositoryLink
                    repositoryAlias={record.repositoryAlias}
                    repositoryName={record.repositoryName}
                  />
                );
              },
            },
            {
              key: "buildStatus",
              title: "Build Status",
              align: "center",
              width: 175,
              render: (
                _value: string,
                record: DeploymentRepository
              ): ReactNode => {
                const run =
                  (deployment?.relations?.latestBuildRuns ?? []).find(
                    (run) => run.tertiaryId === record.buildTrackId
                  ) ?? null;

                if (!run) {
                  return "-";
                }

                return (
                  <RunIdLink
                    run={run}
                    component={
                      <Tag color={runStatusColorMapping[run.status]}>
                        {mapEnumValueToKey(RunStatus, run.status)}
                      </Tag>
                    }
                  />
                );
              },
            },
            {
              key: "deploymentStatus",
              title: "Deployment Status",
              align: "center",
              width: 175,
              render: (
                _value: string,
                record: DeploymentRepository
              ): ReactNode => {
                const latestBuildRun =
                  (deployment?.relations?.latestBuildRuns ?? []).find(
                    (run) => run.tertiaryId === record.buildTrackId
                  ) ?? null;

                if (!latestBuildRun) {
                  return "-";
                }

                if (
                  latestBuildRun.status === RunStatus.QUEUED ||
                  latestBuildRun.status === RunStatus.RUNNING
                ) {
                  return (
                    <Tag color={runStatusColorMapping[RunStatus.QUEUED]}>
                      {"Awaiting Build".toUpperCase()}
                    </Tag>
                  );
                }

                if (latestBuildRun.status === RunStatus.FAILED) {
                  return (
                    <Tag color={runStatusColorMapping[RunStatus.FAILED]}>
                      {"Build Failed".toUpperCase()}
                    </Tag>
                  );
                }

                const hasDeploymentSettings =
                  repositoryMapping &&
                  repositoryMapping[record.repositoryAlias] &&
                  repositoryMapping[record.repositoryAlias].deploySettings;

                if (!hasDeploymentSettings) {
                  return "N/A";
                }

                const run =
                  (deployment?.relations?.latestDeploymentRuns ?? []).find(
                    (run) => run.tertiaryId === record.deployTrackId
                  ) ?? null;

                if (!run) {
                  return "-";
                }

                return (
                  <RunIdLink
                    run={run}
                    component={
                      <Tag color={runStatusColorMapping[run.status]}>
                        {mapEnumValueToKey(RunStatus, run.status)}
                      </Tag>
                    }
                  />
                );
              },
            },
            {
              key: "urls",
              title: "Urls",
              align: "center",
              width: 500,
              render: (
                _value: string,
                record: DeploymentRepository
              ): ReactNode => {
                if (!deployment?.relations?.environment) {
                  return "-";
                }

                const deploymentUrls =
                  (repositoryMapping ?? {})[record.repositoryAlias]
                    ?.deploymentUrls ?? [];

                if (!deploymentUrls.length) {
                  return "-";
                }

                return (
                  <Flex vertical gap="middle" align="center">
                    {deploymentUrls.map((deploymentUrl) => {
                      const url = deploymentUrl.value
                        .replace(
                          "{environmentId}",
                          deployment?.relations?.environment?.environmentId ??
                            ""
                        )
                        .replace(
                          "{account}",
                          deployment?.relations?.environment?.account ?? ""
                        )
                        .replace(
                          "{country}",
                          record.country ?? Country.SAUDI_ARABIA
                        );

                      return (
                        <Flex vertical gap="small">
                          {deploymentUrl.label}
                          <Link to={url} target="_blank">
                            {url}
                          </Link>
                        </Flex>
                      );
                    })}
                  </Flex>
                );
              },
            },
            {
              key: "pullRequest",
              title: "Pull Requests",
              align: "center",
              width: 400,
              render: (
                _value: string,
                record: DeploymentRepository
              ): ReactNode => {
                const pullRequests = (
                  deployment?.relations?.pullRequests ?? []
                ).filter((pullRequest) => {
                  return (
                    pullRequest.repositoryName === record.repositoryName &&
                    pullRequest.headRef === record.ref
                  );
                });

                if (!pullRequests.length) {
                  return "-";
                }

                return (
                  <Flex vertical gap="large">
                    {pullRequests.map((pullRequest) => {
                      const mergeableState =
                        pullRequest.merged ||
                        pullRequest.state ===
                          "closed" ? null : pullRequest.mergeableState ===
                          "dirty" ? (
                          <div>
                            <Tag color="red">Merge Conflict Detected</Tag>
                          </div>
                        ) : pullRequest.mergeableState === "unknown" ? (
                          <div>
                            <Tag color="orange">Rechecking Mergability</Tag>
                          </div>
                        ) : pullRequest.mergeableState === "clean" ? (
                          <div>
                            <Tag color="green">Mergeable</Tag>
                          </div>
                        ) : pullRequest.mergeableState === "behind" ? (
                          <div>
                            <Tag color="red">
                              Rebasing From Base Ref Is Needed
                            </Tag>
                          </div>
                        ) : (
                          <div>
                            <Tag color="orange">
                              Waiting Required Status Checks
                            </Tag>
                          </div>
                        );

                      return (
                        <Flex vertical gap="small">
                          <PullRequestLink
                            key={pullRequest.pullRequestId}
                            repositoryName={pullRequest.repositoryName}
                            title={pullRequest.title}
                            pullRequestId={pullRequest.pullRequestId}
                            extra={
                              <span
                                style={{
                                  color: pullRequest.merged
                                    ? "green"
                                    : pullRequest.state ===
                                      PullRequestState.OPEN
                                    ? undefined
                                    : "red",
                                }}
                              >
                                {pullRequest.merged
                                  ? "MERGED"
                                  : `${pullRequest.state.toUpperCase()}`}
                              </span>
                            }
                          />

                          {mergeableState}
                        </Flex>
                      );
                    })}
                  </Flex>
                );
              },
            },
            {
              key: "country",
              dataIndex: "country",
              title: "Country",
              align: "center",
              width: 200,
              render: (
                value: string,
                _record: DeploymentRepository
              ): ReactNode => {
                return (
                  <Tag color={countryColorMapping[value as Country]}>
                    {mapEnumValueToKey(Country, value)}
                  </Tag>
                );
              },
            },
            {
              key: "commit",
              dataIndex: "commit",
              title: "Commit",
              align: "center",
              width: 400,
              render: (
                _value: string,
                record: DeploymentRepository
              ): ReactNode => {
                const branch = (deployment?.relations?.branches ?? []).find(
                  (branch) =>
                    branch.repositoryName === record.repositoryName &&
                    branch.ref === record.ref
                );

                const diffrentRefCommit = branch?.commit !== record.commit;

                const isDeletedRef =
                  branch?.commit === "0000000000000000000000000000000000000000";

                return (
                  <CommitLink
                    repositoryName={record.repositoryName}
                    commit={record.commit}
                    extra={
                      isEditable ? (
                        diffrentRefCommit ? (
                          isDeletedRef ? (
                            <Tag color="red">Ref Is Deleted</Tag>
                          ) : (
                            <Tag color="green">Latest: {branch?.commit}</Tag>
                          )
                        ) : null
                      ) : null
                    }
                  />
                );
              },
            },

            {
              key: "features",
              dataIndex: "features",
              title: "Repository Features",
              align: "center",
              width: 200,
              render: (
                _value: string,
                record: DeploymentRepository
              ): ReactNode => {
                return (
                  <Button
                    type="link"
                    onClick={onShowListViewModal.bind(
                      this,
                      `Repository Features: ${record.repositoryAlias}`,
                      record.features?.map((feature) => {
                        return {
                          title: feature,
                        };
                      }) ?? [],
                      "No features found"
                    )}
                  >
                    {record.features?.length ?? 0} features
                  </Button>
                );
              },
            },
            {
              key: "cronJobs",
              dataIndex: "cronJobs",
              title: "Cron Jobs To Deploy",
              align: "center",
              width: 400,
              render: (
                _value: string,
                record: DeploymentRepository
              ): ReactNode => {
                const repository = (repositoryMapping ?? {})[
                  record.repositoryAlias
                ];

                const canDeployCronJobs = repository?.characteristics?.find(
                  (characteristic) =>
                    characteristic ===
                    RepositoryCharacteristic.CAN_DEPLOY_CRON_JOBS
                );

                if (!canDeployCronJobs) {
                  return "-";
                }

                return (
                  <Button
                    type="link"
                    onClick={onShowEditCronJobsModal.bind(this, record)}
                  >
                    {record.cronJobs?.length ?? 0} cron jobs
                  </Button>
                );
              },
            },
            {
              key: "runningStatus",
              title: "Cron Job Running Status",
              align: "center",
              width: 200,
              render: (
                _value: string,
                record: DeploymentRepository
              ): ReactNode => {
                if (!deployment?.relations?.environment) {
                  return "-";
                }

                const repository = (repositoryMapping ?? {})[
                  record.repositoryAlias
                ];

                const canDeployCronJobs = repository?.characteristics?.find(
                  (characteristic) =>
                    characteristic ===
                    RepositoryCharacteristic.CAN_DEPLOY_CRON_JOBS
                );

                if (!canDeployCronJobs || !record.cronJobs?.length) {
                  return "-";
                }

                const kubernetesCronJobs = (
                  deployment?.relations?.kubernetesCronJobs ?? []
                ).filter(
                  (kubernetesDeployment) =>
                    kubernetesDeployment.labels?.repositoryAlias ===
                      record.repositoryAlias &&
                    (kubernetesDeployment.labels?.country ??
                      Country.SAUDI_ARABIA) ===
                      (record.country ?? Country.SAUDI_ARABIA)
                );

                if (!kubernetesCronJobs.length) {
                  return <Tag color="red">NOT DEPLOYED</Tag>;
                }

                const totalActive = kubernetesCronJobs.filter(
                  (kubernetesCronJob) => !kubernetesCronJob.suspended
                ).length;

                const totalSuspended = kubernetesCronJobs.filter(
                  (kubernetesCronJob) => kubernetesCronJob.suspended
                ).length;

                return (
                  <Button
                    type="link"
                    onClick={onShowListViewModal.bind(
                      this,
                      `Repository Cron Jobs Running: ${record.repositoryAlias}`,
                      kubernetesCronJobs.map((kubernetesCronJob) => {
                        return {
                          title: kubernetesCronJob.labels?.cronJobName,
                          description: `Status: ${
                            kubernetesCronJob.suspended ? "Suspended" : "Active"
                          }`,
                        };
                      }) ?? [],
                      "No cron jobs running"
                    )}
                  >
                    {totalActive} active, {totalSuspended} suspended
                  </Button>
                );
              },
            },
            {
              key: "more",
              align: "center",
              fixed: "right",
              width: 200,
              render: (
                _value: string,
                record: DeploymentRepository
              ): ReactNode => {
                return (
                  <DeploymentRepositoryMoreButton
                    deploymentId={deployment?.deploymentId!}
                    deploymentRepository={record}
                    deploymentName={deployment?.deploymentName!}
                    onRefetch={onRefetch}
                    isEditable={isEditable}
                    environment={deployment?.relations?.environment}
                  />
                );
              },
            },
          ]}
        />
      </ErrorView>
    </div>
  );
};
