import { UpsertEnvironmentWizardFormFields } from "../UpsertEnvironmentWizardSection";
import { atom } from "jotai";

export const upsertEnvironmentWizardFormDataAtom =
  atom<UpsertEnvironmentWizardFormFields>({});

export const updateUpsertEnvironmentWizardFormDataAtom = atom(
  null,
  (get, set, value: Partial<UpsertEnvironmentWizardFormFields>) => {
    const upsertEnvironmentWizardFormData = get(
      upsertEnvironmentWizardFormDataAtom
    );

    set(
      upsertEnvironmentWizardFormDataAtom,
      Object.assign(upsertEnvironmentWizardFormData ?? {}, value ?? {})
    );
  }
);

export const clearUpsertEnvironmentWizardFormDataAtom = atom(null, (_, set) => {
  set(upsertEnvironmentWizardFormDataAtom, {});
});
