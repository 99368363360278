import { Button, Card, Flex, Typography } from "antd";
import { Link, useLocation } from "react-router-dom";

const Error404Page = (): JSX.Element => {
  const location = useLocation();

  return (
    <Flex align="center" justify="center" style={{ height: "100%" }}>
      <Card style={{ padding: 25 }}>
        <Flex vertical justify="center" align="center" gap="large">
          <Typography.Title
            level={1}
            style={{ fontSize: "12em", lineHeight: "0.25em" }}
            type="secondary"
          >
            Oops!
          </Typography.Title>
          <Typography.Title level={2}>404 - Not Found</Typography.Title>
          <Typography.Text>
            The page you are looking for does not exist.
          </Typography.Text>
          <Link
            to={location.pathname.startsWith("/dashboard") ? "/dashboard" : "/"}
          >
            <Button
              type="primary"
              size="large"
              style={{ width: 200, marginTop: 25 }}
            >
              Go Back Home
            </Button>
          </Link>
        </Flex>
      </Card>
    </Flex>
  );
};

export default Error404Page;
