import { App, Avatar, Button } from "antd";
import { usePagination } from "../../../../../../hooks/usePagination";
import { useQuery } from "urql";
import { PaginationResponse } from "../../../../../../types/pagination/pagination.type";
import { UserJSON } from "../../../../../../types/user/user.type";
import { ErrorView } from "../../../../../../components/ErrorView/ErrorView";
import { TableView } from "../../../../../../components/TableView/TableView";
import { ReactNode } from "react";
import { Nullable } from "../../../../../../utils/nullable/nullable.type";
import { UserOutlined } from "@ant-design/icons";
import { TextFilterInput } from "../../../../../../components/TextFilterInput/TextFilterInput";
import { UsersMoreButton } from "./components/UsersMoreButton/UsersMoreButton";
import { getFilterValueUtility } from "../../../../../../utils/filter/filter.utility";
import { getPaginateUsersQuery } from "../../../../../../providers/graphql/queries/getPaginateUsers.query";
import { PermissionsViewer } from "../../../../../../components/PermissionsViewer/PermissionsViewer";
import { ListViewer } from "../../../../../../components/ListViewer/ListViewer";

export const UsersTableSection = (): JSX.Element => {
  const { paginationRequest } = usePagination({
    filterNames: ["emails", "gitIds", "notificationIds"],
  });

  const { search, limit, skip, sortField, sortOrder, filters } =
    paginationRequest;

  const { modal } = App.useApp();

  const [{ data, error, fetching }, refetch] = useQuery<{
    users: PaginationResponse<UserJSON>;
  }>({
    query: getPaginateUsersQuery({
      fields: [
        "total",
        {
          data: [
            "id",
            "photo",
            "fullName",
            "email",
            "gitId",
            "notificationId",
            "roles",
            {
              relations: [
                {
                  groups: ["id", "groupName", "roles"],
                },
                "roles",
              ],
            },
          ],
        },
      ],
    }),
    variables: {
      userPaginationInput: {
        search,
        limit,
        skip,
        sortField,
        sortOrder,
        filters: {
          ...(filters ? { ...filters } : {}),
        },
      },
    },
    requestPolicy: "network-only",
  });

  const users = data?.users.data ?? [],
    total = data?.users.total ?? 0;

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  const onShowPermissions = (user: UserJSON): void => {
    modal.info({
      icon: null,
      title: `Permissions for ${user.fullName}`,
      width: "75%",
      content: (
        <PermissionsViewer
          style={{ height: "65vh", overflowY: "auto" }}
          readOnly
          selectedPermissions={user.relations?.roles ?? []}
        />
      ),
      okText: "Cancel",
      okButtonProps: {
        type: "default",
      },
    });
  };

  const onShowGroups = (user: UserJSON): void => {
    modal.info({
      icon: null,
      title: `Groups for ${user.fullName}`,
      width: "75%",
      content: (
        <ListViewer
          style={{ height: "65vh", overflowY: "auto" }}
          data={(user.relations?.groups ?? []).map((group) => {
            return {
              title: group.groupName,
            };
          })}
          emptyText="No groups found for this user."
        />
      ),
      okText: "Cancel",
      okButtonProps: {
        type: "default",
      },
    });
  };

  return (
    <ErrorView error={error} onRefetch={onRefetch}>
      <TableView
        rowKey="email"
        loading={fetching}
        sortableColumns={["fullName", "email", "gitId", "notificationId"]}
        filterableColumnMapping={{
          email: "emails",
          gitId: "gitIds",
          notificationId: "notificationIds",
        }}
        dataSource={users}
        total={total}
        columns={[
          {
            key: "photo",
            dataIndex: "photo",
            title: "Photo",
            align: "center",
            width: 100,
            render: (value: Nullable<string>): ReactNode => {
              return <Avatar src={value} size={50} icon={<UserOutlined />} />;
            },
          },
          {
            key: "fullName",
            dataIndex: "fullName",
            title: "Full Name",
            align: "center",
            width: 200,
          },
          {
            key: "email",
            dataIndex: "email",
            title: "Email",
            align: "center",
            width: 200,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "emails",
            }),
            filterDropdown: TextFilterInput,
          },
          {
            key: "gitId",
            dataIndex: "gitId",
            title: "Github Username",
            align: "center",
            width: 175,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "gitIds",
            }),
            filterDropdown: TextFilterInput,
          },
          {
            key: "notificationId",
            dataIndex: "notificationId",
            title: "Slack User Id",
            align: "center",
            width: 175,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "notificationIds",
            }),
            filterDropdown: TextFilterInput,
          },
          {
            key: "groups",
            title: "Groups",
            align: "center",
            width: 200,
            render: (_value: undefined, record: UserJSON): ReactNode => {
              return (
                <Button type="link" onClick={onShowGroups.bind(this, record)}>
                  {record.relations?.groups?.length ?? 0} groups
                </Button>
              );
            },
          },
          {
            key: "permissions",
            title: "Permissions",
            align: "center",
            width: 200,
            render: (_value: undefined, record: UserJSON): ReactNode => {
              return (
                <Button
                  type="link"
                  onClick={onShowPermissions.bind(this, record)}
                >
                  {record.relations?.roles?.length ?? 0} permissions
                </Button>
              );
            },
          },
          {
            key: "more",
            align: "center",
            fixed: "right",
            width: 100,
            render: (_value: undefined, record: UserJSON): ReactNode => {
              return <UsersMoreButton user={record} onRefetch={onRefetch} />;
            },
          },
        ]}
      />
    </ErrorView>
  );
};
