import { CSSProperties, useEffect, useState } from "react";
import { App, Button, Flex, Select, Spin, Tooltip } from "antd";
import { ErrorView } from "../ErrorView/ErrorView";
import { useQuery } from "urql";
import { GitRefBranch } from "../../types/git/git.type";
import { getAllBranchesQuery } from "../../providers/graphql/queries/getAllBranches.query";
import { Nullable } from "../../utils/nullable/nullable.type";
import { IoIosRefresh } from "react-icons/io";
import { CreateBranchFormForm } from "../CreateBranchForm/CreateBranchForm";

export type SelectBranchInputProps = {
  style?: CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
  repositoryName: string;
  mainRef?: string;
  allowCreateNewBranch?: boolean;
};

export const SelectBranchInput = ({
  style,
  value,
  onChange,
  repositoryName,
  mainRef,
  allowCreateNewBranch,
}: SelectBranchInputProps): Nullable<JSX.Element> => {
  const { modal } = App.useApp();

  const [previousRepositoryName] = useState(repositoryName);

  const [{ data, error, fetching }, refetch] = useQuery<{
    allBranches: GitRefBranch[];
  }>({
    query: getAllBranchesQuery({
      fields: ["ref"],
    }),
    variables: {
      allBranchesInput: {
        repositoryName,
      },
    },
    requestPolicy: "network-only",
    pause: !repositoryName,
  });

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  const gitBranches = data?.allBranches ?? [];

  useEffect(() => {
    if (onChange && mainRef) {
      onChange(mainRef);
    }
  }, [previousRepositoryName, mainRef]);

  return (
    <ErrorView error={error} onRefetch={onRefetch}>
      <Flex gap="middle">
        <Select
          defaultActiveFirstOption
          value={value}
          onChange={onChange}
          showSearch
          style={style}
          dropdownRender={(menu) => {
            if (fetching) {
              return (
                <Flex justify="center">
                  <Spin />
                </Flex>
              );
            }

            return (
              <Flex vertical gap="middle">
                {menu}
                {allowCreateNewBranch && (
                  <Button
                    type="link"
                    onClick={() =>
                      modal.info({
                        icon: null,
                        width: "50%",
                        title: "Create New Branch",
                        content: (
                          <CreateBranchFormForm
                            repositoryName={repositoryName}
                            gitBranches={gitBranches}
                            defaultFromRef={mainRef}
                            onRefetch={onRefetch}
                            onChange={onChange}
                          />
                        ),
                        okText: "Cancel",
                        okButtonProps: {
                          type: "default",
                        },
                      })
                    }
                  >
                    Create New Branch
                  </Button>
                )}
              </Flex>
            );
          }}
        >
          {mainRef && <Select.Option value={mainRef}>{mainRef}</Select.Option>}
          {gitBranches.map((value, index) => {
            return (
              <Select.Option key={index} value={value.ref}>
                {value.ref}
              </Select.Option>
            );
          })}
        </Select>
        <Tooltip title="Refresh">
          <Button
            loading={fetching}
            icon={<IoIosRefresh />}
            type="link"
            onClick={onRefetch}
          />
        </Tooltip>
      </Flex>
    </ErrorView>
  );
};
