import { query } from "gql-query-builder";
import { TypedDocumentNode, gql } from "urql";

export const getExhaustiveFileListQuery = (): TypedDocumentNode => {
  return gql(
    query({
      operation: "exhaustiveFileList",
      variables: {
        exhaustiveFileListInput: {
          type: "ExhaustiveFileListInput",
          required: true,
        },
      },
    }).query
  );
};
