import { App, Button, Form, Input, Modal } from "antd";
import { ErrorView } from "../ErrorView/ErrorView";
import { useMutation } from "urql";
import { DeploymentJSON } from "../../types/deployment/deployment.type";
import { cloneDeploymentMutation } from "../../providers/graphql/mutations/cloneDeployment.mutation";

type CloneDeploymentFormFields = {
  deploymentName?: string;
};

export type CloneDeploymentFormProps = {
  deployment?: Partial<DeploymentJSON>;
  onRefetch: () => void;
};

export const CloneDeploymentForm = ({
  deployment,
  onRefetch,
}: CloneDeploymentFormProps): JSX.Element => {
  const { message } = App.useApp();

  const [{ fetching, error }, mutation] = useMutation(
    cloneDeploymentMutation()
  );

  const onSubmit = async (values: CloneDeploymentFormFields): Promise<void> => {
    const { error } = await mutation({
      cloneDeploymentInput: {
        ...values,
        deploymentId: deployment?.deploymentId,
      },
    });

    if (error) {
      return;
    }

    message.info("Deployment cloned successfully");

    onRefetch();

    Modal.destroyAll();
  };

  const [form] = Form.useForm<CloneDeploymentFormFields>();

  return (
    <ErrorView disableRetryButton error={error}>
      <Form
        form={form}
        size="large"
        layout="vertical"
        disabled={fetching}
        onFinish={onSubmit}
        scrollToFirstError
      >
        <Form.Item
          name="deploymentName"
          label="Deployment name"
          rules={[
            {
              required: true,
              message: "Please input the deployment name!",
            },
          ]}
        >
          <Input size="large" type="text" />
        </Form.Item>
        <Form.Item>
          <Button loading={fetching} block type="primary" htmlType="submit">
            Clone Deployment
          </Button>
        </Form.Item>
      </Form>
    </ErrorView>
  );
};
