import { App, Button, Flex, Modal, Spin, Typography } from "antd";
import { useParams } from "react-router-dom";
import { DeploymentHeaderSection } from "./components/DeploymentHeaderSection/DeploymentHeaderSection";
import { useMutation } from "urql";
import { ErrorView } from "../../../../../../components/ErrorView/ErrorView";
import { DeploymentRepositoriesTable } from "../../../../../../components/DeploymentRepositoriesTable/DeploymentRepositoriesTable";
import { useDeployment } from "../../../../../../hooks/useDeployment";
import { useEffect, useState } from "react";
import { useUserContext } from "../../../../../../context/UserContext";
import { SearchBar } from "../../../../../../components/SearchBar/SearchBar";
import { Role } from "../../../../../../types/role/role.enum";
import {
  DeploymentPurpose,
  DeploymentStatus,
} from "../../../../../../types/deployment/deployment.enum";
import { UpsertDeploymentRepositoryForm } from "../../../../../../components/UpsertDeploymentRepositoryForm/UpsertDeploymentRepositoryForm";
import { useError } from "../../../../../../hooks/useError";
import { reDeployDeploymentRepositoriesMutation } from "../../../../../../providers/graphql/mutations/reDeployDeploymentRepositories.mutation";
import { deleteDeploymentRepositoriesMutation } from "../../../../../../providers/graphql/mutations/deleteDeploymentRepositories.mutation";

export const DeploymentViewSection = (): JSX.Element => {
  const { deploymentId } = useParams<{ deploymentId: string }>();

  const [search, setSearch] = useState<string>();

  const { user } = useUserContext();

  const { errorNotificationMessage } = useError();

  const roles = user?.relations?.roles ?? [];

  const { modal, notification, message } = App.useApp();

  const [selectedRowKeyIds, setSelectedRowKeyIds] = useState<string[]>([]);

  const [initialLoad, setInitialLoad] = useState<boolean>(false);

  const fields = [
    "id",
    "deploymentId",
    "deploymentName",
    "environmentId",
    "workflowId",
    "features",
    "purpose",
    "status",
    {
      reviewers: ["emails", "teamNames"],
      owners: ["emails", "teamNames"],
      repositories: [
        "id",
        "repositoryName",
        "repositoryAlias",
        "ref",
        "commit",
        "country",
        "buildTrackId",
        "deployTrackId",
        { cronJobs: ["name", "suspended"] },
      ],
      relations: [
        {
          workflow: [
            "id",
            "workflowId",
            "workflowName",
            "matchBaseRef",
            "matchHeadRef",
          ],
          environment: [
            "id",
            "environmentId",
            "environmentName",
            "purpose",
            "account",
            "hibernating",
            "expired",
            "suspendHibernationUntil",
            "suspendExpirationUntil",
            {
              relations: [
                {
                  latestSync: [
                    "runId",
                    "repositoryName",
                    "runPlatform",
                    "status",
                    "tertiaryId",
                  ],
                },
              ],
            },
          ],
          latestBuildRuns: [
            "runId",
            "repositoryName",
            "runPlatform",
            "status",
            "tertiaryId",
          ],
          latestDeploymentRuns: [
            "runId",
            "repositoryName",
            "runPlatform",
            "status",
            "tertiaryId",
          ],
          branches: ["id", "repositoryName", "ref", "commit"],
          pullRequests: [
            "id",
            "repositoryName",
            "title",
            "pullRequestId",
            "headRef",
            "state",
            "merged",
            "mergeable",
            "mergeableState",
          ],
          kubernetesDeployments: [
            "id",
            "name",
            "labels",
            "unavailablePods",
            "totalPods",
            "readyPods",
          ],
          kubernetesCronJobs: ["id", "name", "suspended", "labels"],
          teamReviewers: [
            "id",
            "teamName",
            "emails",
            {
              relations: [
                {
                  users: ["id", "fullName", "email"],
                },
              ],
            },
          ],
          userReviewers: ["id", "fullName", "email"],
          teamOwners: [
            "id",
            "teamName",
            "emails",
            {
              relations: [
                {
                  users: ["id", "fullName", "email"],
                },
              ],
            },
          ],
          userOwners: ["id", "fullName", "email"],
        },
      ],
    },
  ];

  const {
    deployment,
    error,
    onRefetch: onRefetchDeployment,
    fetching: isGettingDeployment,
  } = useDeployment(fields, deploymentId);

  const [
    { fetching: isReDeployingDeploymentRepositories },
    reDeployDeploymentRepositories,
  ] = useMutation(reDeployDeploymentRepositoriesMutation());

  const [
    { fetching: isDeletingDeploymentRepositories },
    deleteDeploymentRepositories,
  ] = useMutation(deleteDeploymentRepositoriesMutation());

  useEffect(() => {
    if (deployment) {
      setInitialLoad(true);
    }
  }, [deployment]);

  const fetching =
    (isGettingDeployment && !initialLoad) ||
    isReDeployingDeploymentRepositories ||
    isDeletingDeploymentRepositories;

  const purpose = deployment?.purpose;

  const status = deployment?.status;

  const isEditable =
    purpose === DeploymentPurpose.DEVELOPMENT
      ? status === null
        ? true
        : status !== DeploymentStatus.REVIEWED
      : false;

  const onRefetch = async (): Promise<void> => {
    setSelectedRowKeyIds([]);

    setInitialLoad(false);

    await onRefetchDeployment();
  };

  const onRedeploySelectedRepositories = (): void => {
    modal.confirm({
      icon: null,
      title: "Re-Deploy Repositories",
      content: `Are you sure you want to re-deploy the selected repositories in deployment ${deployment?.deploymentName}?`,
      okButtonProps: {
        style: {
          backgroundColor: "purple",
        },
      },
      okText: "Re-Deploy Repositories",
      onOk: async (): Promise<void> => {
        const { error } = await reDeployDeploymentRepositories({
          reDeployDeploymentRepositoriesInput: {
            deploymentId,
            repositories: deployment?.repositories
              ?.filter((repository) =>
                selectedRowKeyIds.includes(repository.id)
              )
              ?.map((repository) => {
                return {
                  repositoryAlias: repository.repositoryAlias,
                  country: repository.country,
                };
              }),
          },
        });

        if (error) {
          notification.error(errorNotificationMessage(error));
          return;
        }

        message.success("Repository redeployed successfully");

        onRefetch();

        Modal.destroyAll();
      },
    });
  };

  const onDeleteSelectedRepositories = (): void => {
    modal.confirm({
      icon: null,
      title: "Remove Repositories",
      content: `Are you sure you want to remove the selected repositories in deployment ${deployment?.deploymentName}?`,
      okButtonProps: {
        danger: true,
      },
      okText: "Remove Repositories",
      onOk: async (): Promise<void> => {
        const { error } = await deleteDeploymentRepositories({
          deleteDeploymentRepositoriesInput: {
            deploymentId,
            repositories: deployment?.repositories
              ?.filter((repository) =>
                selectedRowKeyIds.includes(repository.id)
              )
              ?.map((repository) => {
                return {
                  repositoryAlias: repository.repositoryAlias,
                  country: repository.country,
                };
              }),
          },
        });

        if (error) {
          notification.error(errorNotificationMessage(error));
          return;
        }

        message.success("Repository removed successfully");

        onRefetch();

        Modal.destroyAll();
      },
    });
  };

  const onAddNewDeploymentRepository = (): void => {
    modal.info({
      icon: null,
      width: "75%",
      title: "Add New Repository",
      content: (
        <UpsertDeploymentRepositoryForm
          onRefetch={onRefetch}
          deploymentId={deploymentId!}
        />
      ),
      okButtonProps: {
        type: "default",
      },
      okText: "Cancel",
    });
  };

  const canDoBulkAction = isEditable && !!selectedRowKeyIds.length;

  const canReDeploy =
    deployment?.relations?.environment &&
    !deployment?.relations?.environment?.hibernating &&
    !deployment?.relations?.environment?.expired;

  return (
    <>
      {fetching && <Spin fullscreen />}
      <ErrorView hideChildrenOnError error={error} onRefetch={onRefetch}>
        {initialLoad && (
          <Flex vertical gap="large">
            <DeploymentHeaderSection
              deployment={deployment}
              onRefetch={onRefetch}
              isEditable={isEditable}
            />
            <DeploymentRepositoriesTable
              error={error}
              deployment={deployment}
              onRefetch={onRefetch}
              onRowsSelected={setSelectedRowKeyIds}
              search={search}
              isEditable={isEditable}
              title={
                <Flex justify="space-between" align="center">
                  <Typography.Title level={3}>Repositories</Typography.Title>
                  <Flex gap="middle">
                    <SearchBar
                      style={{ height: 40 }}
                      setSearch={setSearch}
                      placeholder="Search repositories ..."
                    />
                    {roles.includes(Role.UPDATE_DEPLOYMENTS) && (
                      <Button
                        size="large"
                        type="primary"
                        disabled={!canReDeploy || !canDoBulkAction}
                        style={{ backgroundColor: "purple" }}
                        onClick={onRedeploySelectedRepositories}
                      >
                        Re-Deploy
                      </Button>
                    )}
                    {roles.includes(Role.UPDATE_DEPLOYMENTS) && (
                      <Button
                        type="primary"
                        size="large"
                        danger
                        disabled={!canDoBulkAction}
                        onClick={onDeleteSelectedRepositories}
                      >
                        Delete
                      </Button>
                    )}
                    {roles.includes(Role.UPDATE_DEPLOYMENTS) && (
                      <Button
                        type="primary"
                        size="large"
                        onClick={onAddNewDeploymentRepository}
                        disabled={!canReDeploy || !isEditable}
                      >
                        Add New Repository
                      </Button>
                    )}
                  </Flex>
                </Flex>
              }
            />
          </Flex>
        )}
      </ErrorView>
    </>
  );
};
