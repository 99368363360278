import { usePagination } from "../../../../../../hooks/usePagination";
import { useQuery } from "urql";
import { PaginationResponse } from "../../../../../../types/pagination/pagination.type";
import { ErrorView } from "../../../../../../components/ErrorView/ErrorView";
import { TableView } from "../../../../../../components/TableView/TableView";
import { TextFilterInput } from "../../../../../../components/TextFilterInput/TextFilterInput";
import { getFilterValueUtility } from "../../../../../../utils/filter/filter.utility";
import { getPaginateTestsQuery } from "../../../../../../providers/graphql/queries/getPaginateTests.query";
import { TestJSON } from "../../../../../../types/test/test.type";
import moment from "moment";
import { ReactNode } from "react";
import { Alert, App, Button, Descriptions, Dropdown, Flex, Tag } from "antd";
import { useRepositoriesContext } from "../../../../../../context/RepositoriesContext";
import { Link } from "react-router-dom";
import { RunStatus } from "../../../../../../types/run/run.enum";
import { UserLink } from "../../../../../../components/UserLink/UserLink";
import { RefLink } from "../../../../../../components/RefLink/RefLink";
import { RepositoryLink } from "../../../../../../components/RepositoryLink/RepositoryLink";
import { DeploymentLink } from "../../../../../../components/DeploymentLink/DeploymentLink";
import { RunIdLink } from "../../../../../../components/RunIdLink/RunIdLink";
import {
  mapEnumValueToKey,
  runStatusColorMapping,
} from "../../../../../../utils/enum/enum";
import { RunJSON } from "../../../../../../types/run/run.type";
import { KubernetesDeployment } from "../../../../../../types/kubernetes/kubernetes.type";
import {
  ListViewer,
  ListViewerProps,
} from "../../../../../../components/ListViewer/ListViewer";

export const TestsTableSection = (): JSX.Element => {
  const { repositories, repositoryMapping } = useRepositoriesContext();

  const { paginationRequest } = usePagination({
    filterNames: ["testIds", "testNames", "emails", "deploymentIds"],
  });

  const { search, limit, skip, sortField, sortOrder, filters } =
    paginationRequest;

  const { modal } = App.useApp();

  const [{ data, error, fetching }, refetch] = useQuery<{
    tests: PaginationResponse<TestJSON>;
  }>({
    query: getPaginateTestsQuery({
      fields: [
        "total",
        {
          data: [
            "id",
            "testId",
            "testName",
            "repositoryAlias",
            "repositoryName",
            "ref",
            "commit",
            "runOn",
            "parameters",
            "createdAt",
            {
              relations: [
                {
                  user: ["id", "fullName", "email"],
                  latestRun: [
                    "id",
                    "runId",
                    "repositoryName",
                    "secondaryId",
                    "runPlatform",
                    "status",
                  ],
                  deployment: [
                    "deploymentId",
                    "deploymentName",
                    {
                      relations: [
                        {
                          latestBuildRuns: [
                            "runId",
                            "repositoryName",
                            "runPlatform",
                            "status",
                            "tertiaryId",
                          ],
                          latestDeploymentRuns: [
                            "runId",
                            "repositoryName",
                            "runPlatform",
                            "status",
                            "tertiaryId",
                          ],
                          kubernetesDeployments: [
                            "id",
                            "name",
                            "labels",
                            "unavailablePods",
                            "totalPods",
                            "readyPods",
                          ],
                          environment: [
                            "id",
                            "environmentId",
                            "environmentName",
                            "expired",
                            "hibernating",
                            {
                              relations: [
                                {
                                  latestSync: [
                                    "runId",
                                    "repositoryName",
                                    "runPlatform",
                                    "status",
                                    "tertiaryId",
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    }),
    variables: {
      testPaginationInput: {
        search,
        limit,
        skip,
        sortField,
        sortOrder,
        filters: {
          ...(filters ? { ...filters } : {}),
        },
      },
    },
    requestPolicy: "network-only",
  });

  const tests = data?.tests.data ?? [],
    total = data?.tests.total ?? 0;

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  if (!repositories || !repositoryMapping) {
    return (
      <Alert
        message="No repositories found. Please check if you have permissions to view repositories"
        type="error"
      />
    );
  }

  const onShowListViewModal = (
    title: string,
    data: ListViewerProps["data"],
    emptyText: ListViewerProps["emptyText"]
  ): void => {
    modal.info({
      icon: null,
      title: title,
      width: "75%",
      content: (
        <ListViewer
          style={{ height: "65vh", overflowY: "auto" }}
          data={data}
          emptyText={emptyText}
        />
      ),
      okText: "Cancel",
      okButtonProps: {
        type: "default",
      },
    });
  };

  return (
    <ErrorView error={error} onRefetch={onRefetch}>
      <TableView
        rowKey="testId"
        loading={fetching}
        sortableColumns={["createdAt"]}
        filterableColumnMapping={{
          testId: "testIds",
          testName: "testNames",
          email: "emails",
          deploymentId: "deploymentIds",
        }}
        dataSource={tests}
        total={total}
        columns={[
          {
            key: "status",
            title: "Status",
            align: "center",
            width: 500,
            render: (_value: string, record: TestJSON): ReactNode => {
              const run = record.relations?.latestRun;

              if (run) {
                return (
                  <Descriptions
                    items={[
                      {
                        label: "Running Test",
                        children: (
                          <RunIdLink
                            run={run}
                            component={
                              <Tag color={runStatusColorMapping[run.status]}>
                                {mapEnumValueToKey(RunStatus, run.status)}
                              </Tag>
                            }
                          />
                        ),
                      },
                    ]}
                  />
                );
              }

              if (!record.runOn.deploymentId) {
                return "-";
              }

              const deployment = record.relations?.deployment;

              if (!deployment) {
                return "-";
              }

              const environment = deployment.relations?.environment;

              if (!environment) {
                return (
                  <Tag color="red">Deployment Not Linked To Environment</Tag>
                );
              }

              const latestSync = environment.relations?.latestSync;

              const kubernetesDeployments =
                deployment.relations?.kubernetesDeployments ?? [];

              const latestDeploymentRuns =
                deployment.relations?.latestDeploymentRuns ?? [];

              const latestBuildRuns =
                deployment.relations?.latestBuildRuns ?? [];

              const groupedLatestBuildRun = latestBuildRuns.reduce(
                (previous, current) => {
                  if (previous[current.status]) {
                    previous[current.status].push(current);
                  } else {
                    previous[current.status] = [current];
                  }
                  return previous;
                },
                {} as Record<string, RunJSON[]>
              );

              const groupedLatestDeploymentRuns = latestDeploymentRuns.reduce(
                (previous, current) => {
                  if (previous[current.status]) {
                    previous[current.status].push(current);
                  } else {
                    previous[current.status] = [current];
                  }
                  return previous;
                },
                {} as Record<string, RunJSON[]>
              );

              const groupedKubernetesDeployments = kubernetesDeployments.reduce(
                (previous, current) => {
                  const status = current.readyPods > 0 ? "RUNNING" : "PENDING";

                  if (previous[status]) {
                    previous[status].push(current);
                  } else {
                    previous[status] = [current];
                  }
                  return previous;
                },
                {} as Record<string, KubernetesDeployment[]>
              );

              if (environment.hibernating || environment.expired) {
                return (
                  <Tag color="red">
                    {latestSync?.status !== RunStatus.SUCCEEDED
                      ? "Failed To Trigger Test Due To Failing Environment Sync"
                      : latestBuildRuns.some(
                          (latestBuildRun) =>
                            latestBuildRun.status !== RunStatus.SUCCEEDED
                        )
                      ? "Failed To Trigger Test Due To Repository Build Errors"
                      : latestDeploymentRuns.some(
                          (latestDeploymentRun) =>
                            latestDeploymentRun.status !== RunStatus.SUCCEEDED
                        )
                      ? "Failed To Trigger Test Due To Repository Deployment Errors"
                      : "Failed To Trigger Test Due To Repositories Not Running Okay"}
                  </Tag>
                );
              }

              return (
                <Flex vertical gap="large">
                  {latestSync && (
                    <Descriptions
                      items={[
                        {
                          label: "Environment Sync",
                          children: (
                            <RunIdLink
                              run={latestSync}
                              component={
                                <Tag
                                  color={
                                    runStatusColorMapping[latestSync.status]
                                  }
                                >
                                  {mapEnumValueToKey(
                                    RunStatus,
                                    latestSync.status
                                  )}
                                </Tag>
                              }
                            />
                          ),
                        },
                      ]}
                    />
                  )}
                  <Descriptions
                    items={[
                      {
                        label: "Repository Builds",
                        children: !latestBuildRuns.length ? (
                          <Tag color="volcano">No Builds Triggerred</Tag>
                        ) : (
                          <Flex>
                            {Object.keys(groupedLatestBuildRun).map(
                              (status) => {
                                return (
                                  <Tag
                                    color={
                                      runStatusColorMapping[status as RunStatus]
                                    }
                                  >
                                    {`${
                                      groupedLatestBuildRun[status].length
                                    } ${mapEnumValueToKey(RunStatus, status)}`}
                                  </Tag>
                                );
                              }
                            )}
                          </Flex>
                        ),
                      },
                    ]}
                  />
                  <Descriptions
                    items={[
                      {
                        label: "Repository Deployments",
                        children: !latestDeploymentRuns.length ? (
                          <Tag color="volcano">No Builds Triggerred</Tag>
                        ) : (
                          <Flex>
                            {Object.keys(groupedLatestDeploymentRuns).map(
                              (status) => {
                                return (
                                  <Tag
                                    color={
                                      runStatusColorMapping[status as RunStatus]
                                    }
                                  >
                                    {`${
                                      groupedLatestDeploymentRuns[status].length
                                    } ${mapEnumValueToKey(RunStatus, status)}`}
                                  </Tag>
                                );
                              }
                            )}
                          </Flex>
                        ),
                      },
                    ]}
                  />
                  <Descriptions
                    items={[
                      {
                        label: "Repository Running",
                        children: !kubernetesDeployments.length ? (
                          <Tag color="volcano">No Builds Triggerred</Tag>
                        ) : (
                          <Flex>
                            {Object.keys(groupedKubernetesDeployments).map(
                              (status) => {
                                return (
                                  <Tag
                                    color={
                                      status === "RUNNING" ? "green" : "blue"
                                    }
                                  >
                                    {`${groupedKubernetesDeployments[status].length} ${status}`}
                                  </Tag>
                                );
                              }
                            )}
                          </Flex>
                        ),
                      },
                    ]}
                  />
                </Flex>
              );
            },
          },
          {
            key: "testId",
            dataIndex: "testId",
            title: "Test Id",
            align: "center",
            width: 300,
            fixed: "left",
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "testIds",
            }),
            filterDropdown: TextFilterInput,
          },
          {
            key: "testName",
            dataIndex: "testName",
            title: "Test Name",
            align: "center",
            width: 200,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "testNames",
            }),
            filterDropdown: TextFilterInput,
          },
          {
            key: "email",
            dataIndex: "email",
            title: "Triggered By",
            align: "center",
            width: 200,
            render: (value: string, record: TestJSON): ReactNode => {
              return (
                <UserLink
                  email={value}
                  fullName={record.relations?.user?.fullName}
                />
              );
            },
          },
          {
            key: "deploymentId",
            title: "Linked Deployment",
            align: "center",
            width: 300,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "deploymentIds",
            }),
            filterDropdown: TextFilterInput,
            render: (_value: string, record: TestJSON): ReactNode => {
              return (
                <DeploymentLink
                  deployment={record.relations?.deployment}
                  emptyView={null}
                />
              );
            },
          },
          {
            key: "ref",
            dataIndex: "ref",
            title: "Ref To Deploy",
            align: "center",
            width: 400,
            render: (value: string, record: TestJSON): ReactNode => {
              return (
                <Flex vertical>
                  <RepositoryLink
                    repositoryName={record.repositoryName}
                    repositoryAlias={record.repositoryAlias}
                  />
                  <RefLink
                    repositoryName={record.repositoryName}
                    refName={value}
                  />
                </Flex>
              );
            },
          },
          {
            key: "testOn",
            title: "Ref To Run Test On",
            align: "center",
            width: 400,
            render: (_value: string, record: TestJSON): ReactNode => {
              if (!(record.runOn.repositoryName && record.runOn.ref)) {
                return null;
              }

              return (
                <Flex vertical>
                  <RepositoryLink
                    repositoryName={record.runOn.repositoryName}
                  />
                  <RefLink
                    repositoryName={record.runOn.repositoryName}
                    refName={record.runOn.ref}
                  />
                </Flex>
              );
            },
          },
          {
            key: "parameters",
            title: "Run Parameters",
            align: "center",
            width: 200,
            render: (_value: undefined, record: TestJSON): ReactNode => {
              if (!record.parameters) {
                return null;
              }

              return (
                <Button
                  type="link"
                  onClick={onShowListViewModal.bind(
                    this,
                    `Run Parameters For Test: ${record.testName}`,
                    Object.keys(record.parameters).map((parameter) => {
                      const value = record.parameters![parameter];
                      return {
                        title: parameter,
                        description:
                          typeof value === "object"
                            ? JSON.stringify(value)
                            : String(value),
                      };
                    }),
                    "No parameters found"
                  )}
                >
                  {Object.keys(record.parameters).length} parameters
                </Button>
              );
            },
          },
          {
            key: "createdAt",
            dataIndex: "createdAt",
            title: "Triggered At",
            align: "center",
            width: 200,
            render: (value: string, _record: TestJSON): ReactNode => {
              return moment(new Date(value)).format("LLL");
            },
          },
          {
            key: "artifacts",
            align: "center",
            width: 200,
            render: (_value: string, record: TestJSON): ReactNode => {
              if (!record.relations?.latestRun) {
                return null;
              }

              if (record.relations.latestRun.status !== RunStatus.SUCCEEDED) {
                return null;
              }

              const test = repositoryMapping[
                record.repositoryAlias
              ]?.tests?.find(({ name }) => name === record.testName);

              if (!test) {
                return null;
              }

              const artifacts = test.options.artifacts ?? [];

              return (
                <Dropdown
                  menu={{
                    items: artifacts.map((artifact) => {
                      return {
                        key: artifact.label,
                        label: (
                          <Link
                            to={`${
                              process.env.REACT_APP_BACKEND_ENDPOINT
                            }/artifact/${
                              artifact.shouldPreview ? "preview" : "download"
                            }/${record.relations?.latestRun?.runId}/${
                              record.relations?.latestRun?.secondaryId
                            }/${artifact.path}`}
                            target="_blank"
                          >
                            {artifact.label}
                          </Link>
                        ),
                      };
                    }),
                    disabled: fetching,
                  }}
                  placement="bottom"
                  arrow
                >
                  <Button type="primary">Download Artifacts</Button>
                </Dropdown>
              );
            },
          },
        ]}
      />
    </ErrorView>
  );
};
