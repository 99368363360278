import { usePagination } from "../../../../../../hooks/usePagination";
import { useQuery } from "urql";
import { PaginationResponse } from "../../../../../../types/pagination/pagination.type";
import { RepositoryJSON } from "../../../../../../types/repository/repository.type";
import { ErrorView } from "../../../../../../components/ErrorView/ErrorView";
import { TableView } from "../../../../../../components/TableView/TableView";
import { TextFilterInput } from "../../../../../../components/TextFilterInput/TextFilterInput";
import { getFilterValueUtility } from "../../../../../../utils/filter/filter.utility";
import { getPaginateRepositoriesQuery } from "../../../../../../providers/graphql/queries/getPaginateRepositories.query";
import { App, Button, Flex, Typography } from "antd";
import { ReactNode } from "react";
import { RepositoryCharacteristic } from "../../../../../../types/repository/repository.enum";
import { mapEnumValueToKey } from "../../../../../../utils/enum/enum";
import {
  ListViewer,
  ListViewerProps,
} from "../../../../../../components/ListViewer/ListViewer";
import { Country } from "../../../../../../types/country/country.enum";
import { RepositoryLink } from "../../../../../../components/RepositoryLink/RepositoryLink";
import { RefLink } from "../../../../../../components/RefLink/RefLink";
import { RunPlatform } from "../../../../../../types/run.platform/run.platform.enum";

export const RepositoriesTableSection = (): JSX.Element => {
  const { paginationRequest } = usePagination({
    filterNames: [
      "repositoryAliases",
      "repositoryNames",
      "mainRefs",
      "characteristics",
      "features",
      "countries",
      "testDependancies",
      "deploymentDependancies",
    ],
  });

  const { modal } = App.useApp();

  const { search, limit, skip, sortField, sortOrder, filters } =
    paginationRequest;

  const [{ data, error, fetching }, refetch] = useQuery<{
    repositories: PaginationResponse<RepositoryJSON>;
  }>({
    query: getPaginateRepositoriesQuery({
      fields: [
        "total",
        {
          data: [
            "id",
            "repositoryName",
            "mainRef",
            "characteristics",
            "features",
            "testDependancies",
            "deploymentDependancies",
            "statusChecksNotToOverride",
            "countries",
            {
              countryOptions: ["enabledForDeployments", "enabledForReleases"],
              tests: ["name", "options"],
              buildSettings: [
                "runPlatform",
                "runPlatformSettings",
                "statusCheck",
              ],
              deploySettings: [
                "runPlatform",
                "runPlatformSettings",
                "statusCheck",
              ],
            },
          ],
        },
      ],
    }),
    variables: {
      repositoryPaginationInput: {
        search,
        limit,
        skip,
        sortField,
        sortOrder,
        filters: {
          ...(filters ? { ...filters } : {}),
        },
      },
    },
    requestPolicy: "network-only",
  });

  const repositories = data?.repositories.data ?? [],
    total = data?.repositories.total ?? 0;

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  const onShowListViewModal = (
    title: string,
    data: ListViewerProps["data"],
    emptyText: ListViewerProps["emptyText"]
  ): void => {
    modal.info({
      icon: null,
      title: title,
      width: "75%",
      content: (
        <ListViewer
          style={{ height: "65vh", overflowY: "auto" }}
          data={data}
          emptyText={emptyText}
        />
      ),
      okText: "Cancel",
      okButtonProps: {
        type: "default",
      },
    });
  };

  return (
    <ErrorView error={error} onRefetch={onRefetch}>
      <TableView
        rowKey="repositoryName"
        loading={fetching}
        sortableColumns={["repositoryName"]}
        filterableColumnMapping={{
          repositoryName: "repositoryNames",
          repositoryAlias: "repositoryAliases",
          mainRef: "mainRefs",
          characteristics: "characteristics",
          features: "features",
          countries: "countries",
          testDependancies: "testDependancies",
          deploymentDependancies: "deploymentDependancies",
        }}
        dataSource={repositories}
        total={total}
        columns={[
          {
            key: "repositoryAlias",
            dataIndex: "repositoryAlias",
            title: "Repository Alias",
            align: "center",
            width: 300,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "repositoryAliases",
            }),
            filterDropdown: TextFilterInput,
            render: (value: string, record: RepositoryJSON): ReactNode => {
              return (
                <RepositoryLink
                  repositoryName={record.repositoryAlias}
                  repositoryAlias={value}
                />
              );
            },
          },
          {
            key: "repositoryName",
            dataIndex: "repositoryName",
            title: "Repository Name",
            align: "center",
            width: 300,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "repositoryNames",
            }),
            filterDropdown: TextFilterInput,
            render: (value: string, _record: RepositoryJSON): ReactNode => {
              return <RepositoryLink repositoryName={value} />;
            },
          },
          {
            key: "mainRef",
            dataIndex: "mainRef",
            title: "Main Ref",
            align: "center",
            width: 300,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "mainRefs",
            }),
            filterDropdown: TextFilterInput,
            render: (_value: string, record: RepositoryJSON): ReactNode => {
              return (
                <RefLink
                  repositoryName={record.repositoryAlias}
                  refName={record.mainRef}
                />
              );
            },
          },
          {
            key: "characteristics",
            dataIndex: "characteristics",
            title: "Characteristics",
            align: "center",
            width: 200,
            filterResetToDefaultFilteredValue: false,
            filterMultiple: true,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "characteristics",
            }),
            filters: Object.values(RepositoryCharacteristic).map((value) => {
              return {
                text: mapEnumValueToKey(RepositoryCharacteristic, value),
                value,
              };
            }),
            render: (_value: undefined, record: RepositoryJSON): ReactNode => {
              return (
                <Button
                  type="link"
                  onClick={onShowListViewModal.bind(
                    this,
                    `Repository Characteristics: ${record.repositoryAlias}`,
                    record.characteristics.map((characteristic) => {
                      return {
                        title: mapEnumValueToKey(
                          RepositoryCharacteristic,
                          characteristic
                        ),
                      };
                    }),
                    "No characteristics found"
                  )}
                >
                  {record.characteristics.length} characteristics
                </Button>
              );
            },
          },
          {
            key: "features",
            dataIndex: "features",
            title: "Features",
            align: "center",
            width: 200,
            render: (_value: undefined, record: RepositoryJSON): ReactNode => {
              return (
                <Button
                  type="link"
                  onClick={onShowListViewModal.bind(
                    this,
                    `Repository Features: ${record.repositoryAlias}`,
                    record.features.map((feature) => {
                      return {
                        title: feature,
                      };
                    }),
                    "No features found"
                  )}
                >
                  {record.features.length} features
                </Button>
              );
            },
          },
          {
            key: "countries",
            dataIndex: "countries",
            title: "Countries",
            align: "center",
            width: 200,
            defaultFilteredValue: [],
            filterResetToDefaultFilteredValue: false,
            filterMultiple: true,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "countries",
            }),
            filters: Object.values(Country).map((value) => {
              return {
                text: mapEnumValueToKey(Country, value),
                value,
              };
            }),
            render: (_value: undefined, record: RepositoryJSON): ReactNode => {
              return (
                <Button
                  type="link"
                  onClick={onShowListViewModal.bind(
                    this,
                    `Repository Countries: ${record.repositoryAlias}`,
                    record.countries.map((country) => {
                      return {
                        title: mapEnumValueToKey(Country, country),
                      };
                    }),
                    "No countries found"
                  )}
                >
                  {record.countries.length} countries
                </Button>
              );
            },
          },
          {
            key: "testDependancies",
            title: "Test Repository Dependancies",
            align: "center",
            width: 200,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "testDependancies",
            }),
            filterDropdown: TextFilterInput,
            render: (_value: undefined, record: RepositoryJSON): ReactNode => {
              return (
                <Button
                  type="link"
                  onClick={onShowListViewModal.bind(
                    this,
                    `Test Repository Dependancies: ${record.repositoryAlias}`,
                    record.testDependancies.map((repository) => {
                      return {
                        title: repository,
                      };
                    }),
                    "No test dependancies found"
                  )}
                >
                  {record.testDependancies.length} repositories
                </Button>
              );
            },
          },
          {
            key: "countryOptions",
            title: "Country Options",
            align: "center",
            width: 300,
            render: (_value: undefined, record: RepositoryJSON): ReactNode => {
              return (
                <Flex vertical gap="middle">
                  <Typography.Text>
                    Enabled for Environments:{" "}
                    {record.countryOptions.enabledForDeployments ? "yes" : "no"}
                  </Typography.Text>
                  <Typography.Text>
                    Enabled for Releases:{" "}
                    {record.countryOptions.enabledForReleases ? "yes" : "no"}
                  </Typography.Text>
                </Flex>
              );
            },
          },
          {
            key: "deploymentDependancies",
            title: "Deployment Repository Dependancies",
            align: "center",
            width: 200,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "deploymentDependancies",
            }),
            filterDropdown: TextFilterInput,
            render: (_value: undefined, record: RepositoryJSON): ReactNode => {
              return (
                <Button
                  type="link"
                  onClick={onShowListViewModal.bind(
                    this,
                    `Deployment Repository Dependancies: ${record.repositoryAlias}`,
                    record.deploymentDependancies.map((repository) => {
                      return {
                        title: repository,
                      };
                    }),
                    "No deployment dependancies found"
                  )}
                >
                  {record.deploymentDependancies.length} repositories
                </Button>
              );
            },
          },
          {
            key: "tests",
            title: "Tests",
            align: "center",
            width: 200,
            render: (_value: undefined, record: RepositoryJSON): ReactNode => {
              return (
                <Button
                  type="link"
                  onClick={onShowListViewModal.bind(
                    this,
                    `Repository Tests: ${record.repositoryAlias}`,
                    record.tests.map((test) => {
                      return {
                        title: test.name,
                        description: (
                          <Flex vertical>
                            {test.options.repositoryNameToRunTestOn && (
                              <Typography.Text>
                                Repository Override:{" "}
                                {test.options.repositoryNameToRunTestOn}
                              </Typography.Text>
                            )}
                            {test.options.runInNewDeployment && (
                              <Typography.Text>
                                Repository Override:{" "}
                                {test.options.runInNewDeployment ? "yes" : "no"}
                              </Typography.Text>
                            )}
                            <Typography.Text>
                              Run Platform Override:{" "}
                              {mapEnumValueToKey(
                                RunPlatform,
                                test.options.runPlatform
                              )}
                            </Typography.Text>
                            {test.options
                              .allowSelectPostmanCollectionAndEnv && (
                              <Typography.Text>
                                Allow Select Postman Collection And Env:{" "}
                                {test.options.allowSelectPostmanCollectionAndEnv
                                  ? "yes"
                                  : "no"}
                              </Typography.Text>
                            )}
                            {test.options.selectableOptions && (
                              <Flex vertical gap="large">
                                {Object.keys(
                                  test.options.selectableOptions
                                ).map((key) => {
                                  return (
                                    <div style={{ paddingLeft: 10 }} key={key}>
                                      <Typography.Title level={5}>
                                        Label:{" "}
                                        {
                                          test.options.selectableOptions![key]
                                            .label
                                        }{" "}
                                        Value: {key}
                                      </Typography.Title>
                                      <Flex style={{ paddingLeft: 10 }}>
                                        <>
                                          {test.options.selectableOptions![
                                            key
                                          ].options.map((option) => {
                                            return (
                                              <Typography.Text
                                                key={option.value}
                                              >
                                                {option.label}: {option.value}
                                              </Typography.Text>
                                            );
                                          })}
                                        </>
                                      </Flex>
                                    </div>
                                  );
                                })}
                              </Flex>
                            )}
                            {test.options.selectableFilePaths && (
                              <Flex vertical gap="large">
                                {Object.keys(
                                  test.options.selectableFilePaths
                                ).map((key) => {
                                  return (
                                    <div style={{ paddingLeft: 10 }} key={key}>
                                      <Typography.Title level={5}>
                                        {
                                          test.options.selectableFilePaths![key]
                                            .label
                                        }{" "}
                                        : {key}
                                      </Typography.Title>
                                      <Flex style={{ paddingLeft: 10 }}>
                                        <Typography.Text>
                                          Should Fetch From Overriden
                                          Repository:{" "}
                                          {test.options.selectableFilePaths![
                                            key
                                          ].options
                                            .shouldFetchFileFromDeploymentRepository
                                            ? "yes"
                                            : "no"}
                                        </Typography.Text>
                                        <Typography.Text>
                                          Path To Check For Files:{" "}
                                          {
                                            test.options.selectableFilePaths![
                                              key
                                            ].options.pathToCheckForFiles
                                          }
                                        </Typography.Text>
                                        <Typography.Text>
                                          Allowed Extensions:{" "}
                                          {test.options.selectableFilePaths![
                                            key
                                          ].options.allowedExtensions.join(
                                            ", "
                                          )}
                                        </Typography.Text>
                                      </Flex>
                                    </div>
                                  );
                                })}
                              </Flex>
                            )}
                          </Flex>
                        ),
                      };
                    }),
                    "No tests found"
                  )}
                >
                  {record.tests.length} tests
                </Button>
              );
            },
          },

          {
            key: "deployWorkflowName",
            dataIndex: "deployWorkflowName",
            title: "Deployment Workflow Name",
            align: "center",
            width: 200,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "deployWorkflowNames",
            }),
            filterDropdown: TextFilterInput,
          },
        ]}
      />
    </ErrorView>
  );
};
