import { Flex } from "antd";
import { RepositoriesTableSection } from "./components/RepositoriesTableSection/RepositoriesTableSection";
import { RepositoriesHeaderSection } from "./components/RepositoriesHeaderSection/RepositoriesHeaderSection";

const RepositoriesPage = (): JSX.Element => {
  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <RepositoriesHeaderSection />
      <RepositoriesTableSection />
    </Flex>
  );
};

export default RepositoriesPage;
