import { Alert, Form, Select, Typography } from "antd";
import { useRepositoriesContext } from "../../../../../../../../../../../context/RepositoriesContext";
import { NewPRStatusOverrideWizardFormFields } from "../../../../NewPRStatusOverrideWizardSection";
import { SelectPullRequestInput } from "../../../../../../../../../../../components/SelectPullRequestInput/SelectPullRequestInput";

export const SelectPullRequestFormSection = (): JSX.Element => {
  const { repositories, repositoryMapping } = useRepositoriesContext();

  const form = Form.useFormInstance<NewPRStatusOverrideWizardFormFields>();

  const repositoryName = Form.useWatch("repositoryName", form);

  if (!repositories || !repositoryMapping) {
    return (
      <Alert
        message="No repositories found. Please check if you have permissions to view repositories"
        type="error"
      />
    );
  }

  return (
    <>
      <Form.Item>
        <Typography.Title
          level={3}
          style={{ display: "block", textAlign: "center" }}
        >
          Select Repository & Pull Request
        </Typography.Title>
        <Typography.Text
          type="secondary"
          style={{ display: "block", textAlign: "center" }}
        >
          Please select the repository and the pull request you want to override
          the status checks
        </Typography.Text>
      </Form.Item>
      <Form.Item
        label="Repository Name"
        labelCol={{ span: 24 }}
        name={"repositoryName"}
        rules={[
          {
            required: true,
            message: "Repository name is required",
          },
        ]}
      >
        <Select showSearch>
          {repositories.map((repository) => {
            return (
              <Select.Option
                key={repository.repositoryAlias}
                value={repository.repositoryName}
              >
                {repository.repositoryAlias}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      {repositoryName && (
        <Form.Item
          name="pullRequestId"
          label="Pull Request"
          rules={[
            {
              required: true,
              message: "Please elect a pull request before proceeding",
            },
          ]}
        >
          <SelectPullRequestInput repositoryName={repositoryName} />
        </Form.Item>
      )}
    </>
  );
};
