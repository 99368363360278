import { usePagination } from "../../../../../../hooks/usePagination";
import { useQuery } from "urql";
import { PaginationResponse } from "../../../../../../types/pagination/pagination.type";
import { ErrorView } from "../../../../../../components/ErrorView/ErrorView";
import { TableView } from "../../../../../../components/TableView/TableView";
import { TextFilterInput } from "../../../../../../components/TextFilterInput/TextFilterInput";
import { getFilterValueUtility } from "../../../../../../utils/filter/filter.utility";
import { getPaginateRunsQuery } from "../../../../../../providers/graphql/queries/getPaginateRuns.query";
import { RunJSON } from "../../../../../../types/run/run.type";
import { RunStatus } from "../../../../../../types/run/run.enum";
import { Tag } from "antd";
import {
  mapEnumValueToKey,
  runPlatformColorMapping,
  runStatusColorMapping,
} from "../../../../../../utils/enum/enum";
import { ReactNode } from "react";
import { RepositoryLink } from "../../../../../../components/RepositoryLink/RepositoryLink";
import { RunIdLink } from "../../../../../../components/RunIdLink/RunIdLink";
import { RunPlatform } from "../../../../../../types/run.platform/run.platform.enum";

export const RunsTableSection = (): JSX.Element => {
  const { paginationRequest } = usePagination({
    filterNames: ["runIds", "repositoryNames", "workflowNames", "statuses"],
  });

  const { search, limit, skip, sortField, sortOrder, filters } =
    paginationRequest;

  const [{ data, error, fetching }, refetch] = useQuery<{
    runs: PaginationResponse<RunJSON>;
  }>({
    query: getPaginateRunsQuery({
      fields: [
        "total",
        {
          data: [
            "id",
            "runId",
            "secondaryId",
            "tertiaryId",
            "repositoryName",
            "runPlatform",
            "status",
            "workflowName",
          ],
        },
      ],
    }),
    variables: {
      runPaginationInput: {
        search,
        limit,
        skip,
        sortField,
        sortOrder,
        filters: {
          ...(filters ? { ...filters } : {}),
        },
      },
    },
    requestPolicy: "network-only",
  });

  const runs = data?.runs.data ?? [],
    total = data?.runs.total ?? 0;

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  return (
    <ErrorView error={error} onRefetch={onRefetch}>
      <TableView
        rowKey="runId"
        loading={fetching}
        sortableColumns={["runId"]}
        filterableColumnMapping={{
          runId: "runIds",
          repositoryName: "repositoryNames",
          ref: "refs",
          commit: "commits",
          workflowName: "workflowNames",
          status: "statuses",
        }}
        dataSource={runs}
        total={total}
        columns={[
          {
            key: "status",
            dataIndex: "status",
            title: "Status",
            align: "center",
            width: 200,
            defaultFilteredValue: [],
            filterResetToDefaultFilteredValue: false,
            filterMultiple: true,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "statuses",
            }),
            filters: Object.values(RunStatus).map((value) => {
              return {
                text: mapEnumValueToKey(RunStatus, value),
                value,
              };
            }),
            render: (value: string, _record: RunJSON): ReactNode => {
              return (
                <Tag color={runStatusColorMapping[value as RunStatus]}>
                  {mapEnumValueToKey(RunStatus, value)}
                </Tag>
              );
            },
          },
          {
            key: "runId",
            dataIndex: "runId",
            title: "Run Id",
            align: "center",
            width: 400,
            fixed: "left",
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "runIds",
            }),
            filterDropdown: TextFilterInput,
            render: (_value: string, record: RunJSON): ReactNode => {
              return <RunIdLink run={record} />;
            },
          },
          {
            key: "repositoryName",
            dataIndex: "repositoryName",
            title: "Repository Name",
            align: "center",
            width: 300,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "repositoryNames",
            }),
            filterDropdown: TextFilterInput,
            render: (value: string, _record: RunJSON): ReactNode => {
              return <RepositoryLink repositoryName={value} />;
            },
          },
          {
            key: "runPlatform",
            dataIndex: "runPlatform",
            title: "Run Platform",
            align: "center",
            width: 200,
            defaultFilteredValue: [],
            filterResetToDefaultFilteredValue: false,
            filterMultiple: true,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "runPlatforms",
            }),
            filters: Object.values(RunPlatform).map((value) => {
              return {
                text: mapEnumValueToKey(RunPlatform, value),
                value,
              };
            }),
            render: (value: string, _record: RunJSON): ReactNode => {
              return (
                <Tag color={runPlatformColorMapping[value as RunPlatform]}>
                  {mapEnumValueToKey(RunPlatform, value)}
                </Tag>
              );
            },
          },
          {
            key: "workflowName",
            dataIndex: "workflowName",
            title: "Workflow Name",
            align: "center",
            width: 200,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "workflowNames",
            }),
            filterDropdown: TextFilterInput,
          },
        ]}
      />
    </ErrorView>
  );
};
