import { Button, Flex } from "antd";
import { useParams } from "react-router-dom";

export type UpsertEnvironmentWizardButtonsProps = {
  currentStep: number;
  lastStep: number;
  onPrevious: () => void;
};

export const UpsertEnvironmentWizardButtons = ({
  currentStep,
  lastStep,
  onPrevious,
}: UpsertEnvironmentWizardButtonsProps): JSX.Element => {
  const isOnLastStep = currentStep === lastStep;

  const params = useParams<{ environmentId: string }>();

  const isUpdating = !!params?.environmentId;

  return (
    <Flex
      gap="middle"
      justify="space-between"
      style={{ paddingLeft: 50, paddingRight: 50 }}
    >
      <Button disabled={currentStep === 0} onClick={onPrevious}>
        Previous
      </Button>
      <Button htmlType="submit" type="primary">
        {isOnLastStep
          ? isUpdating
            ? "Update Environment"
            : "Create Environment"
          : "Next"}
      </Button>
    </Flex>
  );
};
