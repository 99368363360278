import { App, Button, Divider, Flex, Form, Modal, Typography } from "antd";
import { Nullable } from "../../utils/nullable/nullable.type";
import { ErrorView } from "../ErrorView/ErrorView";
import { useMutation } from "urql";
import {
  DeploymentJSON,
  DeploymentOwners,
} from "../../types/deployment/deployment.type";
import { updateDeploymentOwnersMutation } from "../../providers/graphql/mutations/updateDeploymentOwners.mutation";
import { EntityType } from "../../pages/dashboard/deployments/new/components/NewDeploymentWizardSection/type/entity.enum";
import { SelectTeamInput } from "../SelectTeamInput/SelectTeamInput";
import { SelectUserInput } from "../SelectUserInput/SelectUserInput";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

type UpdatedDeploymentOwnersFormFields = {
  owners?: Array<{
    entityType?: EntityType;
    email?: string;
    teamName?: string;
  }>;
};

export type UpdatedDeploymentOwnersFormProps = {
  deployment?: Partial<DeploymentJSON>;
};

export const UpdatedDeploymentOwnersForm = ({
  deployment,
}: UpdatedDeploymentOwnersFormProps): JSX.Element => {
  const { message, notification } = App.useApp();

  const [form] = Form.useForm();

  const ownersFormData = Form.useWatch("owners", form);

  const [{ fetching, error }, mutation] = useMutation(
    updateDeploymentOwnersMutation()
  );

  const onSubmit = async (
    values: UpdatedDeploymentOwnersFormFields
  ): Promise<void> => {
    const { error } = await mutation({
      updateDeploymentOwnersInput: {
        deploymentId: deployment?.deploymentId,
        owners: values?.owners?.reduce(
          (previous, current) => {
            if (current.email) {
              previous.emails.push(current.email);
            }

            if (current.teamName) {
              previous.teamNames.push(current.teamName);
            }

            return previous;
          },
          {
            emails: [],
            teamNames: [],
          } as DeploymentOwners
        ) ?? {
          emails: [],
          teamNames: [],
        },
      },
    });

    if (error) {
      return;
    }

    message.info("Updated deployment owners successfully!");

    Modal.destroyAll();
  };

  const initialValue = ((): Array<{
    entityType?: EntityType;
    email?: string;
    teamName?: string;
  }> => {
    const items: Array<{
      entityType?: EntityType;
      email?: string;
      teamName?: string;
    }> = [];

    if (deployment?.owners?.emails?.length) {
      deployment.owners.emails.forEach((email) => {
        items.push({
          entityType: EntityType.USER,
          email,
        });
      });
    }

    if (deployment?.owners?.teamNames?.length) {
      deployment.owners.teamNames.forEach((teamName) => {
        items.push({
          entityType: EntityType.TEAM,
          teamName,
        });
      });
    }

    return items;
  })();

  return (
    <ErrorView disableRetryButton error={error}>
      <Form
        form={form}
        size="large"
        layout="vertical"
        disabled={fetching}
        onFinish={onSubmit}
        scrollToFirstError
      >
        <Form.List
          name="owners"
          initialValue={initialValue}
          rules={[
            {
              validator: async (_, owners) => {
                let error: Nullable<string> = null;

                if (!owners || !owners.length) {
                  error = "At least one owner is required";
                }

                if (error) {
                  notification.error({
                    message: error,
                  });

                  return Promise.reject(new Error(error));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              <Flex justify="space-between" align="center">
                <div></div>
                <Flex vertical>
                  <Typography.Title
                    level={3}
                    style={{ display: "block", textAlign: "center" }}
                  >
                    Owners Selection
                  </Typography.Title>
                  <Typography.Text
                    type="secondary"
                    style={{ display: "block", textAlign: "center" }}
                  >
                    Select the teams and users that will be owners of this
                    deployment
                  </Typography.Text>
                </Flex>
                <Flex gap="middle">
                  <Button
                    type="dashed"
                    onClick={() =>
                      add({
                        entityType: EntityType.USER,
                      })
                    }
                    icon={<PlusOutlined />}
                  >
                    Add User
                  </Button>
                  <Button
                    type="dashed"
                    onClick={() =>
                      add({
                        entityType: EntityType.TEAM,
                      })
                    }
                    icon={<PlusOutlined />}
                  >
                    Add Team
                  </Button>
                </Flex>
              </Flex>
              <Divider />
              {fields.map((_, index) => {
                const entityType = ownersFormData?.[index]?.entityType;

                return (
                  <div key={index}>
                    <Flex align="center" justify="space-between">
                      <Typography.Title level={2} type="secondary">
                        Owner {index + 1}
                      </Typography.Title>
                      <Button
                        danger
                        type="primary"
                        icon={<MinusCircleOutlined />}
                        onClick={() => remove(index)}
                        style={{ marginLeft: "auto" }}
                      >
                        Remove Owner
                      </Button>
                    </Flex>
                    {entityType === EntityType.TEAM && (
                      <Form.Item
                        label="Team"
                        labelCol={{ span: 24 }}
                        name={[index, "teamName"]}
                        rules={[
                          {
                            required: true,
                            message: "Please select a team first",
                          },
                        ]}
                      >
                        <SelectTeamInput />
                      </Form.Item>
                    )}
                    {entityType === EntityType.USER && (
                      <Form.Item
                        label="User"
                        labelCol={{ span: 24 }}
                        name={[index, "email"]}
                        rules={[
                          {
                            required: true,
                            message: "Please select a user first",
                          },
                        ]}
                      >
                        <SelectUserInput />
                      </Form.Item>
                    )}
                    <Divider style={{ marginTop: 50 }} />
                  </div>
                );
              })}
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button loading={fetching} block type="primary" htmlType="submit">
            Update Deployment Owners
          </Button>
        </Form.Item>
      </Form>
    </ErrorView>
  );
};
