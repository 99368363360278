import { App, Button, Dropdown, MenuProps, Modal } from "antd";
import { GroupJSON } from "../../../../../../../../types/group/group.type";
import { useError } from "../../../../../../../../hooks/useError";
import { deleteGroupMutation } from "../../../../../../../../providers/graphql/mutations/deleteGroup.mutation";
import { useMutation } from "urql";
import { updateGroupRolesMutation } from "../../../../../../../../providers/graphql/mutations/updateGroupRoles.mutation";
import { PermissionsViewer } from "../../../../../../../../components/PermissionsViewer/PermissionsViewer";
import { Role } from "../../../../../../../../types/role/role.enum";
import { UpdateUsersInGroupForm } from "./components/UpdateUsersInGroupForm/UpdateUsersInGroupForm";
import { useUserContext } from "../../../../../../../../context/UserContext";
import { Nullable } from "../../../../../../../../utils/nullable/nullable.type";

export type GroupsMoreButtonProps = {
  group: GroupJSON;
  onRefetch: () => void;
};

export const GroupsMoreButton = ({
  group,
  onRefetch,
}: GroupsMoreButtonProps): Nullable<JSX.Element> => {
  const { modal, notification, message } = App.useApp();

  const { user } = useUserContext();

  const roles = user?.relations?.roles ?? [];

  const { errorNotificationMessage } = useError();

  const [{ fetching: isDeletingGroup }, deleteGroup] = useMutation(
    deleteGroupMutation()
  );

  const [{ fetching: isUpdatingGroupRoles }, updateGroupRoles] = useMutation(
    updateGroupRolesMutation()
  );

  const fetching = isDeletingGroup || isUpdatingGroupRoles;

  const items: MenuProps["items"] = [
    ...(roles.includes(Role.UPDATE_GROUPS)
      ? [
          {
            key: "update-users-in-group",
            label: "Updated users in group",
          },
          {
            key: "update-roles",
            label: "Updated group permissions",
          },
        ]
      : []),
    ...(roles.includes(Role.DELETE_GROUPS)
      ? [
          {
            key: "delete-group",
            label: "Delete group",
          },
        ]
      : []),
  ];

  const onUpdateGroupRoles = async (roles: Role[]): Promise<void> => {
    const { error } = await updateGroupRoles({
      updateGroupRolesInput: {
        groupName: group.groupName,
        roles,
      },
    });

    if (error) {
      notification.error(errorNotificationMessage(error));
      return;
    }

    message.success("Group roles updated successfully");

    onRefetch();

    Modal.destroyAll();
  };

  const onMenuItemSelected = ({ key }: { key: string }): void => {
    switch (key) {
      case "update-roles":
        modal.info({
          icon: null,
          width: "75%",
          content: (
            <PermissionsViewer
              title={`Update ${group.groupName}'s Roles`}
              style={{ height: "65vh", overflowY: "auto" }}
              selectedPermissions={group.roles}
              onUpdatePermissions={onUpdateGroupRoles}
            />
          ),
          okText: "Cancel",
          okButtonProps: {
            type: "default",
          },
        });
        break;
      case "update-users-in-group":
        modal.info({
          icon: null,
          width: "75%",
          content: (
            <UpdateUsersInGroupForm
              users={group.relations?.users ?? []}
              group={group}
              onRefreshGroups={onRefetch}
            />
          ),
          okText: "Cancel",
          okButtonProps: {
            type: "default",
          },
        });
        break;
      case "delete-group":
        modal.confirm({
          icon: null,
          title: "Delete Group",
          content: `Are you sure you want to delete ${group.groupName}?`,
          okButtonProps: {
            danger: true,
          },
          okText: "Delete Group",
          onOk: async (): Promise<void> => {
            const { error } = await deleteGroup({
              deleteGroupInput: {
                groupName: group.groupName,
              },
            });

            if (error) {
              notification.error(errorNotificationMessage(error));
              return;
            }

            message.success("Group deleted successfully");

            onRefetch();

            Modal.destroyAll();
          },
        });
        break;
      default:
        break;
    }
  };

  if (!items?.length) {
    return null;
  }

  return (
    <Dropdown
      menu={{
        items,
        onClick: onMenuItemSelected,
        disabled: fetching,
        style: { width: 200 },
      }}
      placement="bottom"
      arrow
    >
      <Button loading={fetching} type="primary">
        More
      </Button>
    </Dropdown>
  );
};
