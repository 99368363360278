import { CSSProperties } from "react";
import { Button, Flex, Select, Spin, Tooltip } from "antd";
import { ErrorView } from "../ErrorView/ErrorView";
import { useQuery } from "urql";
import { Nullable } from "../../utils/nullable/nullable.type";
import { IoIosRefresh } from "react-icons/io";
import { getExhaustiveFileListQuery } from "../../providers/graphql/queries/getExhaustiveFileList.query";

export type SelectFileInputProps = {
  style?: CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
  repositoryName?: string;
  repositoryRef?: string;
  path?: string;
  allowedExtensions?: string[];
};

export const SelectFileInput = ({
  style,
  value,
  onChange,
  repositoryName,
  repositoryRef: ref,
  path,
  allowedExtensions,
}: SelectFileInputProps): Nullable<JSX.Element> => {
  const [{ data, error, fetching }, refetch] = useQuery<{
    exhaustiveFileList: string[];
  }>({
    query: getExhaustiveFileListQuery(),
    variables: {
      exhaustiveFileListInput: {
        repositoryName,
        ref,
        path: path ?? null,
      },
    },
    requestPolicy: "network-only",
    pause: !repositoryName || !ref,
  });

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  const exhaustiveFileList = data?.exhaustiveFileList ?? [];

  return (
    <ErrorView error={error} onRefetch={onRefetch}>
      <Flex gap="middle">
        <Select
          value={value}
          onChange={onChange}
          showSearch
          style={style}
          dropdownRender={(menu) => {
            if (fetching) {
              return (
                <Flex justify="center">
                  <Spin />
                </Flex>
              );
            }

            return menu;
          }}
        >
          {exhaustiveFileList
            .filter((value) => {
              if (!allowedExtensions) {
                return true;
              }

              return allowedExtensions.some((extension) =>
                value.endsWith(`.${extension}`)
              );
            })
            .map((value, index) => {
              return (
                <Select.Option key={index} value={value}>
                  {path ? value.replace(`${path}/`, "") : value}
                </Select.Option>
              );
            })}
        </Select>
        <Tooltip title="Refresh">
          <Button
            loading={fetching}
            icon={<IoIosRefresh />}
            type="link"
            onClick={onRefetch}
          />
        </Tooltip>
      </Flex>
    </ErrorView>
  );
};
