import { SelectPullRequestFormSection } from "./components/SelectPullRequestFormSection/SelectPullRequestFormSection";
import { SelectStatusChecksFormSection } from "./components/SelectStatusChecksFormSection/SelectStatusChecksFormSection";

export type NewPRStatusOverrideWizardContentProps = {
  currentStep: number;
};

export const NewPRStatusOverrideWizardContent = ({
  currentStep,
}: NewPRStatusOverrideWizardContentProps): JSX.Element => {
  return (
    <div style={{ minHeight: "50vh", paddingLeft: 50, paddingRight: 50 }}>
      {currentStep === 0 && <SelectPullRequestFormSection />}
      {currentStep === 1 && <SelectStatusChecksFormSection />}
    </div>
  );
};
