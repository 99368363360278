import { App, Button, Dropdown, MenuProps, Modal } from "antd";
import { UserJSON } from "../../../../../../../../types/user/user.type";
import { useError } from "../../../../../../../../hooks/useError";
import { UserUpsertForm } from "../../../../../../../../components/UserUpsertForm/UserUpsertForm";
import { deleteUserMutation } from "../../../../../../../../providers/graphql/mutations/deleteUser.mutation";
import { useMutation } from "urql";
import { updateUserRolesMutation } from "../../../../../../../../providers/graphql/mutations/updateUserRoles.mutation";
import { PermissionsViewer } from "../../../../../../../../components/PermissionsViewer/PermissionsViewer";
import { Role } from "../../../../../../../../types/role/role.enum";
import { Nullable } from "../../../../../../../../utils/nullable/nullable.type";
import { useUserContext } from "../../../../../../../../context/UserContext";

export type UsersMoreButtonProps = {
  user: UserJSON;
  onRefetch: () => void;
};

export const UsersMoreButton = ({
  user,
  onRefetch,
}: UsersMoreButtonProps): Nullable<JSX.Element> => {
  const { modal, notification, message } = App.useApp();

  const { errorNotificationMessage } = useError();

  const { user: loggedInUser } = useUserContext();

  const roles = loggedInUser?.relations?.roles ?? [];

  const [{ fetching: isDeletingUser }, deleteUser] = useMutation(
    deleteUserMutation()
  );

  const [{ fetching: isUpdatingUserRoles }, updateUserRoles] = useMutation(
    updateUserRolesMutation()
  );

  const fetching = isDeletingUser || isUpdatingUserRoles;

  const items: MenuProps["items"] = [
    ...(roles.includes(Role.UPDATE_USERS)
      ? [
          {
            key: "update-profile",
            label: "Updated user profile",
          },
          {
            key: "update-roles",
            label: "Updated user permissions",
          },
        ]
      : []),
    ...(roles.includes(Role.DELETE_USERS)
      ? [{ key: "delete-user", label: "Delete user" }]
      : []),
  ];

  const onUpdateUserRoles = async (roles: Role[]): Promise<void> => {
    const { error } = await updateUserRoles({
      updateUserRolesInput: {
        email: user.email,
        roles,
      },
    });

    if (error) {
      notification.error(errorNotificationMessage(error));
      return;
    }

    message.success("User roles updated successfully");

    onRefetch();

    Modal.destroyAll();
  };

  const onMenuItemSelected = ({ key }: { key: string }): void => {
    switch (key) {
      case "update-roles":
        modal.info({
          icon: null,
          width: "75%",
          content: (
            <PermissionsViewer
              title={`Update ${user.fullName}'s Roles`}
              style={{ height: "65vh", overflowY: "auto" }}
              selectedPermissions={user.relations?.roles ?? []}
              onUpdatePermissions={onUpdateUserRoles}
            />
          ),
          okText: "Cancel",
          okButtonProps: {
            type: "default",
          },
        });
        break;
      case "update-profile":
        modal.info({
          icon: null,
          width: "75%",
          title: "Update User",
          content: <UserUpsertForm user={user} />,
          okButtonProps: {
            type: "default",
          },
          okText: "Cancel",
        });
        break;
      case "delete-user":
        modal.confirm({
          icon: null,
          title: "Delete User",
          content: `Are you sure you want to delete ${user.fullName}?`,
          okButtonProps: {
            danger: true,
          },
          okText: "Delete User",
          onOk: async (): Promise<void> => {
            const { error } = await deleteUser({
              deleteUserInput: {
                email: user.email,
              },
            });

            if (error) {
              notification.error(errorNotificationMessage(error));
              return;
            }

            message.success("User deleted successfully");

            onRefetch();

            Modal.destroyAll();
          },
        });
        break;
      default:
        break;
    }
  };

  if (!items?.length) {
    return null;
  }

  return (
    <Dropdown
      menu={{
        items,
        onClick: onMenuItemSelected,
        disabled: fetching,
        style: { width: 200 },
      }}
      placement="bottom"
      arrow
    >
      <Button loading={fetching} type="primary">
        More
      </Button>
    </Dropdown>
  );
};
