import { Flex } from "antd";
import { NewTestHeaderSection } from "./components/NewTestHeaderSection/NewTestHeaderSection";
import { NewTestFormSection } from "./components/NewTestFormSection/NewTestFormSection";

const NewTestPage = (): JSX.Element => {
  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <NewTestHeaderSection />
      <NewTestFormSection />
    </Flex>
  );
};

export default NewTestPage;
