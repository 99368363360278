import { PaginationRequest } from "../../types/pagination/pagination.type";

export type GetFilterValueUtilityOptions<T> = {
  paginationRequest: PaginationRequest<Record<string, unknown>, string>;
  filterName: string;
  forceToArray?: boolean;
  defaultValue: T;
};

export const getFilterValueUtility = <T>({
  paginationRequest,
  filterName,
  forceToArray,
  defaultValue,
}: GetFilterValueUtilityOptions<T>): T => {
  const value = (paginationRequest.filters ?? {})[filterName] ?? undefined;

  if (!value) {
    return defaultValue;
  }

  if (forceToArray && !Array.isArray(value)) {
    return [value] as T;
  }

  return value as T;
};
