import { App, Button, Dropdown, MenuProps, Modal } from "antd";
import { TeamJSON } from "../../../../../../../types/team/team.type";
import { useError } from "../../../../../../../hooks/useError";
import { deleteTeamMutation } from "../../../../../../../providers/graphql/mutations/deleteTeam.mutation";
import { useMutation } from "urql";
import { UpdateUsersInTeamForm } from "./components/UpdateUsersInTeamForm/UpdateUsersInTeamForm";
import { UpsertTeamForm } from "../../../../../../../components/UpsertTeamForm/UpsertTeamForm";
import { useUserContext } from "../../../../../../../context/UserContext";
import { Role } from "../../../../../../../types/role/role.enum";
import { Nullable } from "../../../../../../../utils/nullable/nullable.type";

export type TeamsMoreButtonProps = {
  team: TeamJSON;
  onRefetch: () => void;
};

export const TeamsMoreButton = ({
  team,
  onRefetch,
}: TeamsMoreButtonProps): Nullable<JSX.Element> => {
  const { modal, notification, message } = App.useApp();

  const { errorNotificationMessage } = useError();

  const { user } = useUserContext();

  const roles = user?.relations?.roles ?? [];

  const [{ fetching }, deleteTeam] = useMutation(deleteTeamMutation());

  const items: MenuProps["items"] = [
    ...(roles.includes(Role.UPDATE_TEAMS)
      ? [
          {
            key: "update-team",
            label: "Updated team details",
          },
          {
            key: "update-users-in-team",
            label: "Updated users in team",
          },
        ]
      : []),
    ...(roles.includes(Role.DELETE_TEAMS)
      ? [
          {
            key: "delete-team",
            label: "Delete team",
          },
        ]
      : []),
  ];

  const onMenuItemSelected = ({ key }: { key: string }): void => {
    switch (key) {
      case "update-team":
        modal.info({
          icon: null,
          title: `Update ${team.teamName} team`,
          width: "75%",
          content: <UpsertTeamForm team={team} />,
          okText: "Cancel",
          okButtonProps: {
            type: "default",
          },
        });
        break;
      case "update-users-in-team":
        modal.info({
          icon: null,
          width: "75%",
          content: (
            <UpdateUsersInTeamForm
              users={team.relations?.users ?? []}
              team={team}
              onRefreshTeams={onRefetch}
            />
          ),
          okText: "Cancel",
          okButtonProps: {
            type: "default",
          },
        });
        break;
      case "delete-team":
        modal.confirm({
          icon: null,
          title: "Delete Team",
          content: `Are you sure you want to delete ${team.teamName}?`,
          okButtonProps: {
            danger: true,
          },
          okText: "Delete Team",
          onOk: async (): Promise<void> => {
            const { error } = await deleteTeam({
              deleteTeamInput: {
                teamName: team.teamName,
              },
            });

            if (error) {
              notification.error(errorNotificationMessage(error));
              return;
            }

            message.success("Team deleted successfully");

            onRefetch();

            Modal.destroyAll();
          },
        });
        break;
      default:
        break;
    }
  };

  if (!items?.length) {
    return null;
  }

  return (
    <Dropdown
      menu={{
        items,
        onClick: onMenuItemSelected,
        disabled: fetching,
        style: { width: 200 },
      }}
      placement="bottom"
      arrow
    >
      <Button loading={fetching} type="primary">
        More
      </Button>
    </Dropdown>
  );
};
