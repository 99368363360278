import { App, Layout } from "antd";
import { Outlet, useLocation, useRouteLoaderData } from "react-router-dom";
import { NavigationBar } from "./components/NavigationBar";
import { globalStyleAtom } from "../../state/global.style/global.style";
import { useAtomValue } from "jotai";
import { UserContextProvider } from "../../context/UserContext";
import { Nullable } from "../../utils/nullable/nullable.type";
import { UserJSON } from "../../types/user/user.type";
import { SideLayout } from "./components/SideLayout/SideLayout";
import { useMemo } from "react";

export const RootLayout = (): JSX.Element => {
  const globalStyle = useAtomValue(globalStyleAtom);

  const location = useLocation();

  const routeLoaderData = useRouteLoaderData("dashboard") as Nullable<{
    user?: UserJSON;
  }>;

  const user = routeLoaderData?.user;

  const isMenuVisible = useMemo(
    () => location.pathname.startsWith("/dashboard"),
    [location.pathname]
  );

  return (
    <App>
      <UserContextProvider user={user}>
        <Layout style={{ height: "100vh", overflow: "hidden" }}>
          {isMenuVisible && (
            <Layout.Sider width={200}>
              <SideLayout user={user} />
            </Layout.Sider>
          )}
          <Layout>
            <Layout.Header
              style={{
                background: globalStyle.navigationBarBackgroundColor,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <NavigationBar user={user} />
            </Layout.Header>
            <Layout.Content
              style={{
                overflowY: "auto",
                padding: 25,
              }}
            >
              <Outlet />
            </Layout.Content>
          </Layout>
        </Layout>
      </UserContextProvider>
    </App>
  );
};
