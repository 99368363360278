import { App, Button, Dropdown, MenuProps, Modal } from "antd";
import { ReleaseJSON } from "../../../../../../../types/release/release.type";
import { useError } from "../../../../../../../hooks/useError";
import { deleteReleaseMutation } from "../../../../../../../providers/graphql/mutations/deleteRelease.mutation";
import { useMutation } from "urql";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../../../../../context/UserContext";
import { Nullable } from "../../../../../../../utils/nullable/nullable.type";
import { Role } from "../../../../../../../types/role/role.enum";

export type ReleasesMoreButtonProps = {
  release: ReleaseJSON;
  onRefetch: () => void;
};

export const ReleasesMoreButton = ({
  release,
  onRefetch,
}: ReleasesMoreButtonProps): Nullable<JSX.Element> => {
  const { modal, notification, message } = App.useApp();

  const { errorNotificationMessage } = useError();

  const { user } = useUserContext();

  const roles = user?.relations?.roles ?? [];

  const navigate = useNavigate();

  const [{ fetching }, deleteRelease] = useMutation(deleteReleaseMutation());

  const items: MenuProps["items"] = [
    ...(roles.includes(Role.VIEW_RELEASES)
      ? [
          {
            key: "view-release",
            label: "View release",
          },
        ]
      : []),
    ...(roles.includes(Role.DELETE_RELEASES)
      ? [
          {
            key: "delete-release",
            label: "Delete release",
          },
        ]
      : []),
  ];

  const onMenuItemSelected = ({ key }: { key: string }): void => {
    switch (key) {
      case "view-release":
        navigate(`/dashboard/releases/${encodeURI(release.releaseName)}`);
        break;
      case "delete-release":
        modal.confirm({
          icon: null,
          title: "Delete Release",
          content: `Are you sure you want to delete ${release.releaseName}?`,
          okButtonProps: {
            danger: true,
          },
          okText: "Delete Release",
          onOk: async (): Promise<void> => {
            const { error } = await deleteRelease({
              deleteReleaseInput: {
                releaseName: release.releaseName,
              },
            });

            if (error) {
              notification.error(errorNotificationMessage(error));
              return;
            }

            message.success("Release deleted successfully");

            onRefetch();

            Modal.destroyAll();
          },
        });
        break;
      default:
        break;
    }
  };

  if (!items?.length) {
    return null;
  }

  return (
    <Dropdown
      menu={{
        items,
        onClick: onMenuItemSelected,
        disabled: fetching,
        style: { width: 200 },
      }}
      placement="bottom"
      arrow
    >
      <Button loading={fetching} type="primary">
        More
      </Button>
    </Dropdown>
  );
};
