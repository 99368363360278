import { Empty, Flex, List } from "antd";
import { CSSProperties } from "react";
import { TeamJSON } from "../../types/team/team.type";
import { TeamListItemView } from "./components/TeamListItemView/TeamListItemView";

export type TeamsViewerProps = {
  style?: CSSProperties;
  teams: TeamJSON[];
};

export const TeamsViewer = ({
  style,
  teams,
}: TeamsViewerProps): JSX.Element => {
  return (
    <List
      style={{
        ...style,
        overflowY: "auto",
        width: "100%",
      }}
      locale={{
        emptyText: (
          <Flex
            justify="center"
            align="center"
            style={{
              height: style?.height,
            }}
          >
            <Empty description="No teams" />
          </Flex>
        ),
      }}
      dataSource={teams}
      renderItem={(item, index) => <TeamListItemView team={item} key={index} />}
    />
  );
};
