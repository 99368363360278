import { App, Button, DatePicker, Form, Modal } from "antd";
import { ErrorView } from "../ErrorView/ErrorView";
import { useMutation } from "urql";
import { EnvironmentJSON } from "../../types/environment/environment.type";
import { suspendEnvironmentHibernationMutation } from "../../providers/graphql/mutations/suspendEnvironmentHibernation.mutation";
import dayjs from "dayjs";

type SuspendEnvironmentHibernationFormFields = {
  suspendHibernationUntil?: dayjs.Dayjs;
};

export type SuspendEnvironmentHibernationFormProps = {
  environment: EnvironmentJSON;
  onRefetch: () => void;
};

export const SuspendEnvironmentHibernationForm = ({
  environment,
  onRefetch,
}: SuspendEnvironmentHibernationFormProps): JSX.Element => {
  const { message } = App.useApp();

  const [{ fetching, error }, mutation] = useMutation(
    suspendEnvironmentHibernationMutation()
  );

  const onSubmit = async (
    values: SuspendEnvironmentHibernationFormFields
  ): Promise<void> => {
    const { error } = await mutation({
      suspendEnvironmentHibernationInput: {
        environmentId: environment.environmentId,
        suspendHibernationUntil: values.suspendHibernationUntil?.format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
      },
    });

    if (error) {
      return;
    }

    message.info("Environment hibernation set to suspended successfully");

    onRefetch();

    Modal.destroyAll();
  };

  const minDate = dayjs();

  const prefferedDate = dayjs().add(2, "hour");

  const maxDate = dayjs().add(6, "hour");

  return (
    <ErrorView disableRetryButton error={error}>
      <Form
        size="large"
        layout="vertical"
        disabled={fetching}
        onFinish={onSubmit}
        scrollToFirstError
        initialValues={{
          suspendHibernationUntil: prefferedDate,
        }}
      >
        <Form.Item
          label="Suspend Hibernation Until"
          labelCol={{ span: 24 }}
          name="suspendHibernationUntil"
          rules={[
            {
              required: true,
              message: "Please select a date and time",
            },
          ]}
        >
          <DatePicker
            showTime
            minDate={minDate}
            maxDate={maxDate}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item>
          <Button loading={fetching} block type="primary" htmlType="submit">
            Suspend Hibernation
          </Button>
        </Form.Item>
      </Form>
    </ErrorView>
  );
};
