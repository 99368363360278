import { Button, Typography } from "antd";
import { Link } from "react-router-dom";
import { ReleaseRepository } from "../../types/release/release.type";
import { ReleaseRepositoryStatus } from "../../types/release/release.enum";

export type TagLinkProps = {
  releaseRepository: ReleaseRepository;
};

export const TagLink = ({ releaseRepository }: TagLinkProps): JSX.Element => {
  if (
    releaseRepository.status ===
    ReleaseRepositoryStatus.FAILED_TO_CREATE_RELEASE_TAG
  ) {
    return <Typography.Text>{releaseRepository.version}</Typography.Text>;
  }

  return (
    <Link
      to={`https://github.com/soum-sa/${releaseRepository.repositoryName}/releases/tag/${releaseRepository.version}`}
      target="_blank"
    >
      <Button type="link">{releaseRepository.version}</Button>
    </Link>
  );
};
