import {
  App,
  Button,
  Divider,
  Flex,
  Form,
  InputNumber,
  Select,
  Typography,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Nullable } from "../../../../../../utils/nullable/nullable.type";
import { UpsertEnvironmentWizardFormFields } from "../../../../UpsertEnvironmentWizardSection";
import { EnvironmentHibernationType } from "../../../../../../types/environment/environment.enum";
import { mapEnumValueToKey } from "../../../../../../utils/enum/enum";
import { DaysOfTheWeekHibernationOptions } from "./components/DaysOfTheWeekHibernationOptions/DaysOfTheWeekHibernationOptions";

export const HibernationOptionsSection = (): JSX.Element => {
  const { notification } = App.useApp();

  const form = Form.useFormInstance<UpsertEnvironmentWizardFormFields>();

  const hibernationOptionsFormData = Form.useWatch("hibernationOptions", form);

  const addedHibernationOptionTypes =
    hibernationOptionsFormData?.filter(Boolean).map(({ type }) => type) ?? [];

  const hibernationOptionTypesThatCanBeAdded = Object.values(
    EnvironmentHibernationType
  ).filter((type) => !addedHibernationOptionTypes.includes(type));

  return (
    <>
      <Form.List
        name="hibernationOptions"
        rules={[
          {
            validator: async (_, hibernationOptions) => {
              let error: Nullable<string> = null;

              if (!hibernationOptions || !hibernationOptions.length) {
                error =
                  "At least one hibernation option needs to be added to the environment";
              }

              if (error) {
                notification.error({
                  message: error,
                });

                return Promise.reject(new Error(error));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            <Flex justify="space-between" align="center">
              <div></div>
              <Flex vertical>
                <Typography.Title
                  level={3}
                  style={{ display: "block", textAlign: "center" }}
                >
                  Hibernation Options
                </Typography.Title>
                <Typography.Text
                  type="secondary"
                  style={{ display: "block", textAlign: "center" }}
                >
                  Select hibernation options that will be used to hibernate the
                  environment
                </Typography.Text>
              </Flex>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
                disabled={!hibernationOptionTypesThatCanBeAdded.length}
              >
                Add New Hibernation Option
              </Button>
            </Flex>
            <Divider />
            {fields.map((_, index) => {
              const type = hibernationOptionsFormData?.[index]?.type;

              return (
                <div key={index}>
                  <Flex align="center" justify="space-between">
                    <Typography.Title level={2} type="secondary">
                      Hibernation Option {index + 1}
                    </Typography.Title>
                    <Button
                      danger
                      type="primary"
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(index)}
                      style={{ marginLeft: "auto" }}
                    >
                      Remove Hibernation Option
                    </Button>
                  </Flex>
                  <Form.Item
                    label="Type Of Hibernation"
                    labelCol={{ span: 24 }}
                    name={[index, "type"]}
                    rules={[
                      {
                        required: true,
                        message: "Please select the type of hibernation",
                      },
                    ]}
                  >
                    <Select>
                      {hibernationOptionTypesThatCanBeAdded.map(
                        (hibernationOption) => {
                          return (
                            <Select.Option
                              key={hibernationOption}
                              value={hibernationOption}
                            >
                              {mapEnumValueToKey(
                                EnvironmentHibernationType,
                                hibernationOption
                              )}
                            </Select.Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                  {type && (
                    <>
                      {type ===
                        EnvironmentHibernationType.NO_DEPLOYMENT_CHANGE_OVER_TIME && (
                        <>
                          <Form.Item
                            label="Hours With No Deployment Change"
                            labelCol={{ span: 24 }}
                            name={[index, "hoursWithNoDeploymentChange"]}
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please input the number of hours with no deployment change",
                              },
                            ]}
                          >
                            <InputNumber
                              min={1}
                              max={6}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </>
                      )}
                      {type ===
                        EnvironmentHibernationType.DURING_CERTAIN_DAYS_OF_THE_WEEK && (
                        <>
                          <DaysOfTheWeekHibernationOptions
                            parentIndex={index}
                          />
                        </>
                      )}
                    </>
                  )}
                  <Divider style={{ marginTop: 50 }} />
                </div>
              );
            })}
          </>
        )}
      </Form.List>
    </>
  );
};
