import {
  App,
  Button,
  Divider,
  Flex,
  Form,
  InputNumber,
  Typography,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Nullable } from "../../../../../../../../../../utils/nullable/nullable.type";
import { UpsertEnvironmentWizardFormFields } from "../../../../../../../../UpsertEnvironmentWizardSection";

export type HoursOfTheDayHibernationOptionsProps = {
  parentIndex: number;
  subParentIndex: number;
};

export const HoursOfTheDayHibernationOptions = ({
  parentIndex,
  subParentIndex,
}: HoursOfTheDayHibernationOptionsProps): JSX.Element => {
  const { notification } = App.useApp();

  const form = Form.useFormInstance<UpsertEnvironmentWizardFormFields>();

  const hibernationOptionsFormData = Form.useWatch("hibernationOptions", form);

  return (
    <>
      <Form.List
        name={[subParentIndex, "hoursOfTheDay"]}
        rules={[
          {
            validator: async (_, hoursOfTheDay) => {
              let error: Nullable<string> = null;

              if (!hoursOfTheDay || !hoursOfTheDay.length) {
                error = "At least one hour range needs to be added";
              }

              if (error) {
                notification.error({
                  message: error,
                });

                return Promise.reject(new Error(error));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <div style={{ paddingLeft: 35, paddingRight: 35 }}>
            <Flex
              justify="space-between"
              align="center"
              style={{ marginBottom: 35 }}
            >
              <Flex vertical>
                <Typography.Title level={3}>Hours Of The Day</Typography.Title>
                <Typography.Text type="secondary">
                  Select the hours of the day that the environment will
                  hibernate
                </Typography.Text>
              </Flex>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
              >
                Hours Of The Day
              </Button>
            </Flex>
            <Divider />
            {fields.map((_, index) => {
              const minEndHour =
                (((hibernationOptionsFormData ?? [])[parentIndex] ?? [])
                  .daysOfTheWeek ?? [])[subParentIndex]?.hoursOfTheDay[index]
                  ?.startHour ?? 0;

              return (
                <div key={index}>
                  <Flex align="center" justify="space-between">
                    <Typography.Title level={4} type="secondary">
                      Hours Of The Day {index + 1}
                    </Typography.Title>
                    <Button
                      danger
                      type="primary"
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(index)}
                      style={{ marginLeft: "auto" }}
                    >
                      Remove Hours Of The Day
                    </Button>
                  </Flex>
                  <Form.Item
                    label="Start Hour"
                    labelCol={{ span: 24 }}
                    name={[index, "startHour"]}
                    rules={[
                      {
                        required: true,
                        message: "Start hour is required",
                      },
                    ]}
                  >
                    <InputNumber min={0} max={23} style={{ width: "100%" }} />
                  </Form.Item>
                  <Form.Item
                    label="End Hour"
                    labelCol={{ span: 24 }}
                    name={[index, "endHour"]}
                    rules={[
                      {
                        required: true,
                        message: "End hour is required",
                      },
                    ]}
                  >
                    <InputNumber
                      min={minEndHour}
                      max={23}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </div>
              );
            })}
          </div>
        )}
      </Form.List>
    </>
  );
};
