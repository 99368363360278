import { Breadcrumb, Flex, Typography } from "antd";
import { usePagination } from "../../../../../../hooks/usePagination";
import { Link } from "react-router-dom";
import { SearchBar } from "../../../../../../components/SearchBar/SearchBar";

export const DatabaseMigrationsHeaderSection = (): JSX.Element => {
  const { setPaginationParameters, paginationRequest } = usePagination({});

  const onSearchChanged = (search: string): void => {
    setPaginationParameters({
      search,
    });
  };

  return (
    <Flex justify="space-between" align="center">
      <Flex vertical>
        <Typography.Title level={2}>Database Migrations</Typography.Title>
        <Breadcrumb
          items={[
            {
              title: <Link to="/dashboard">Dashboard</Link>,
            },
            {
              title: "Migrations",
            },
            {
              title: "Database",
            },
          ]}
        />
      </Flex>
      <Flex gap="middle">
        <SearchBar
          placeholder={"Search database migrations ..."}
          search={paginationRequest.search}
          setSearch={onSearchChanged}
        />
      </Flex>
    </Flex>
  );
};
