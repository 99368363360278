import { Flex } from "antd";
import { DeploymentViewSection } from "./components/DeploymentViewSection/DeploymentViewSection";

const DeploymentPage = (): JSX.Element => {
  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <DeploymentViewSection />
    </Flex>
  );
};

export default DeploymentPage;
