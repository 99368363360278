import { authExchange } from "@urql/exchange-auth";
import { Operation } from "urql";

export default authExchange(async (utils) => {
  return {
    addAuthToOperation(operation): Operation {
      return operation;
    },
    didAuthError(error, _operation): boolean {
      return error?.response?.status === 401;
    },
    async refreshAuth(): Promise<void> {
      window.location.replace(`/?to=${window.location.pathname}`);
    },
  };
});
