import {
  Alert,
  App,
  Button,
  Flex,
  Form,
  Select,
  Space,
  Typography,
} from "antd";
import { useMutation } from "urql";
import { triggerNewTestMutation } from "../../../../../../../providers/graphql/mutations/triggerNewTest.mutation";
import { ErrorView } from "../../../../../../../components/ErrorView/ErrorView";
import { useRepositoriesContext } from "../../../../../../../context/RepositoriesContext";
import { SelectBranchInput } from "../../../../../../../components/SelectBranchInput/SelectBranchInput";
import { SelectPostmanCollectionInput } from "../../../../../../../components/SelectPostmanCollectionInput/SelectPostmanCollectionInput";
import { SelectPostmanEnvironmentInput } from "../../../../../../../components/SelectPostmanEnvironmentInput/SelectPostmanEnvironmentInput";
import { SelectFileInput } from "../../../../../../../components/SelectFileInput/SelectFileInput";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { SelectDeploymentForTestInput } from "../../../../../../../components/SelectDeploymentForTestInput/SelectDeploymentForTestInput";
import { Nullable } from "../../../../../../../utils/nullable/nullable.type";

type NewTestFormSectionFields = {
  repositoryAlias?: string;
  testName?: string;
  refToRunTestOn?: string;
  ref?: string;
  deploymentId?: Nullable<string>;
} & Partial<Record<string, unknown>>;

export const NewTestFormSection = (): JSX.Element => {
  const { repositories, repositoryMapping } = useRepositoriesContext();

  const { message } = App.useApp();

  const navigate = useNavigate();

  const [form] = Form.useForm<NewTestFormSectionFields>();

  const repositoryAlias = Form.useWatch("repositoryAlias", form);

  const testName = Form.useWatch("testName", form);

  const ref = Form.useWatch("ref", form);

  const refToRunTestOn = Form.useWatch("refToRunTestOn", form);

  const [{ fetching, error }, mutation] = useMutation(triggerNewTestMutation());

  const selectableRepositories =
    repositories?.filter((repository) => repository.tests.length > 0) ?? [];

  const isRepositorySelected = !!repositoryAlias;

  const tests = (repositoryMapping ?? {})[repositoryAlias ?? ""]?.tests ?? [];

  const test = tests.find((test) => test.name === testName);

  const isTestSelected = !!test;

  const selectableOptions = test?.options?.selectableOptions ?? {};

  const selectableFilePaths = test?.options?.selectableFilePaths ?? {};

  const repositoryNameToRunTestOn =
    test?.options?.repositoryNameToRunTestOn ?? null;

  const allowSelectPostmanCollectionAndEnv =
    test?.options.allowSelectPostmanCollectionAndEnv;

  const runInNewDeployment = Boolean(test?.options.runInNewDeployment);

  const testDependancies =
    (repositoryMapping ?? {})[repositoryAlias ?? ""]?.testDependancies ?? [];

  const onSubmit = async (values: NewTestFormSectionFields): Promise<void> => {
    const {
      repositoryAlias,
      refToRunTestOn,
      ref,
      testName,
      deploymentId,
      ...rest
    } = values;

    const repositoryName = (repositoryMapping ?? {})[repositoryAlias ?? ""]
      ?.repositoryName;

    const { error } = await mutation({
      triggerNewTestInput: {
        repositoryAlias,
        repositoryName,
        repositoryNameToRunTestOn: repositoryNameToRunTestOn ?? null,
        refToRunTestOn: refToRunTestOn ?? null,
        ref,
        testName,
        parameters: Object.keys(rest ?? {}).length ? rest : null,
        deploymentId: deploymentId === "null" ? null : deploymentId ?? null,
      },
    });

    if (error) {
      return;
    }

    message.info("Test triggered successfully!");

    navigate("/dashboard/quality-assurance/tests");
  };

  useEffect(() => {
    const keys = Object.keys(form.getFieldsValue()).filter(
      (key) => key !== "repositoryAlias"
    );

    form.resetFields(
      Array.from(new Set(["testName", "refToRunTestOn", "ref", ...keys]))
    );
  }, [repositoryAlias]);

  if (!repositories || !repositoryMapping) {
    return (
      <Alert
        message="No repositories found. Please check if you have permissions to view repositories"
        type="error"
      />
    );
  }

  return (
    <ErrorView disableRetryButton error={error}>
      <Flex justify="center">
        <Form
          size="large"
          layout="vertical"
          disabled={fetching}
          onFinish={onSubmit}
          scrollToFirstError
          form={form}
          style={{ width: "60%" }}
        >
          <Form.Item>
            <Flex vertical>
              <Typography.Title
                level={3}
                style={{ display: "block", textAlign: "center" }}
              >
                Trigger New Test
              </Typography.Title>
              <Typography.Text
                type="secondary"
                style={{ display: "block", textAlign: "center" }}
              >
                Select the which test you would like to trigger
              </Typography.Text>
            </Flex>
          </Form.Item>
          <Form.Item
            label="Repository Name"
            labelCol={{ span: 24 }}
            name="repositoryAlias"
            rules={[
              {
                required: true,
                message: "Repository name is required",
              },
            ]}
          >
            <Select showSearch>
              {selectableRepositories.map((repository) => {
                return (
                  <Select.Option
                    key={repository.repositoryAlias}
                    value={repository.repositoryAlias}
                  >
                    {repository.repositoryAlias}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          {isRepositorySelected && (
            <>
              <Form.Item
                name="ref"
                label={`Select ${repositoryAlias}'s branch that will be deployed`}
                rules={[
                  {
                    required: true,
                    message: `Branch to deploy for ${repositoryAlias} is required`,
                  },
                ]}
              >
                <SelectBranchInput
                  repositoryName={
                    repositoryMapping[repositoryAlias].repositoryName
                  }
                />
              </Form.Item>
              <Form.Item
                label="Test Name"
                labelCol={{ span: 24 }}
                name="testName"
                rules={[
                  {
                    required: true,
                    message: "Test name is required",
                  },
                ]}
              >
                <Select>
                  {tests.map((test) => {
                    return (
                      <Select.Option key={test.name} value={test.name}>
                        {test.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {isTestSelected && (
                <>
                  {test.options.runInNewDeployment && (
                    <>
                      <Form.Item
                        label="Deployment To Run Test On"
                        labelCol={{ span: 24 }}
                        name="deploymentId"
                        rules={[
                          {
                            required: true,
                            message: `Deployment to run test on is required`,
                          },
                        ]}
                        initialValue="null"
                      >
                        <SelectDeploymentForTestInput />
                      </Form.Item>
                    </>
                  )}
                  {!!testDependancies.length &&
                    runInNewDeployment &&
                    testName && (
                      <Form.Item
                        label={`Repositories that will also be deployed before running the test: ${testName}`}
                        labelCol={{ span: 24 }}
                      >
                        <Space wrap size="middle">
                          {testDependancies.map((testDependancy) => {
                            return (
                              <Typography.Text type="secondary">
                                {testDependancy}
                              </Typography.Text>
                            );
                          })}
                        </Space>
                      </Form.Item>
                    )}
                  {repositoryNameToRunTestOn && (
                    <Form.Item
                      name="refToRunTestOn"
                      label={`Select ${repositoryNameToRunTestOn}'s branch to run test ${testName} on`}
                      rules={[
                        {
                          required: true,
                          message: `Branch to run ${testName} on is required`,
                        },
                      ]}
                    >
                      <SelectBranchInput
                        repositoryName={repositoryNameToRunTestOn}
                      />
                    </Form.Item>
                  )}
                  {allowSelectPostmanCollectionAndEnv && (
                    <>
                      <Form.Item
                        label="Postman Collection"
                        labelCol={{ span: 24 }}
                        name="postman_collection"
                        rules={[
                          {
                            required: true,
                            message: "Postman collecttion is required",
                          },
                        ]}
                      >
                        <SelectPostmanCollectionInput />
                      </Form.Item>
                      <Form.Item
                        label="Postman Environment"
                        labelCol={{ span: 24 }}
                        name="postman_environment"
                        rules={[
                          {
                            required: true,
                            message: "Postman environment is required",
                          },
                        ]}
                      >
                        <SelectPostmanEnvironmentInput />
                      </Form.Item>
                    </>
                  )}
                  {Object.entries(selectableOptions).map(([key, value]) => {
                    return (
                      <Form.Item
                        label={value.label}
                        labelCol={{ span: 24 }}
                        name={key}
                        rules={[
                          {
                            required: true,
                            message: `${value.label} is required`,
                          },
                        ]}
                      >
                        <Select>
                          {value.options.map((option) => {
                            return (
                              <Select.Option
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    );
                  })}
                  {!!ref &&
                    (!!repositoryNameToRunTestOn ? !!refToRunTestOn : true) && (
                      <>
                        {Object.entries(selectableFilePaths).map(
                          ([key, value]) => {
                            return (
                              <Form.Item
                                label={value.label}
                                labelCol={{ span: 24 }}
                                name={key}
                                rules={[
                                  {
                                    required: true,
                                    message: `${value.label} is required`,
                                  },
                                ]}
                              >
                                <SelectFileInput
                                  repositoryName={
                                    value.options
                                      .shouldFetchFileFromDeploymentRepository
                                      ? repositoryMapping[repositoryAlias]
                                          .repositoryName
                                      : repositoryNameToRunTestOn ?? undefined
                                  }
                                  repositoryRef={
                                    value.options
                                      .shouldFetchFileFromDeploymentRepository
                                      ? ref
                                      : refToRunTestOn
                                  }
                                  path={value.options.pathToCheckForFiles}
                                  allowedExtensions={
                                    value.options.allowedExtensions
                                  }
                                />
                              </Form.Item>
                            );
                          }
                        )}
                      </>
                    )}
                </>
              )}
            </>
          )}
          <Form.Item>
            <Button loading={fetching} block type="primary" htmlType="submit">
              Trigger Test
            </Button>
          </Form.Item>
        </Form>
      </Flex>
    </ErrorView>
  );
};
