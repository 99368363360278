import { App, Breadcrumb, Button, Flex, Typography } from "antd";
import { usePagination } from "../../../../../../hooks/usePagination";
import { Link } from "react-router-dom";
import { SearchBar } from "../../../../../../components/SearchBar/SearchBar";
import { CreateGroupForm } from "./components/CreateGroupForm/CreateGroupForm";
import { useUserContext } from "../../../../../../context/UserContext";
import { Role } from "../../../../../../types/role/role.enum";

export const GroupsHeaderSection = (): JSX.Element => {
  const { setPaginationParameters, paginationRequest } = usePagination({});

  const { modal } = App.useApp();

  const { user } = useUserContext();

  const roles = user?.relations?.roles ?? [];

  const onSearchChanged = (search: string): void => {
    setPaginationParameters({
      search,
    });
  };

  const onCreateGroup = (): void => {
    modal.info({
      icon: null,
      width: "75%",
      title: "Create Group",
      content: <CreateGroupForm />,
      okButtonProps: {
        type: "default",
      },
      okText: "Cancel",
    });
  };

  return (
    <Flex justify="space-between" align="center">
      <Flex vertical>
        <Typography.Title level={2}>Groups</Typography.Title>
        <Breadcrumb
          items={[
            {
              title: <Link to="/dashboard">Dashboard</Link>,
            },
            {
              title: "IAM",
            },
            {
              title: "Groups",
            },
          ]}
        />
      </Flex>
      <Flex gap="middle">
        <SearchBar
          placeholder={"Search groups ..."}
          search={paginationRequest.search}
          setSearch={onSearchChanged}
        />
        {roles.includes(Role.CREATE_GROUPS) && (
          <Button size="large" type="primary" onClick={onCreateGroup}>
            Create New Group
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
