import { App, Button, Tag } from "antd";
import { useQuery } from "urql";
import { ReactNode } from "react";
import { usePagination } from "../../../../../../hooks/usePagination";
import { PaginationResponse } from "../../../../../../types/pagination/pagination.type";
import { StatusOverrideJSON } from "../../../../../../types/status.override/status.override.type";
import { getPaginateStatusOverridesQuery } from "../../../../../../providers/graphql/queries/getPaginateStatusOverrides.query";
import { ErrorView } from "../../../../../../components/ErrorView/ErrorView";
import { TableView } from "../../../../../../components/TableView/TableView";
import { getFilterValueUtility } from "../../../../../../utils/filter/filter.utility";
import { TextFilterInput } from "../../../../../../components/TextFilterInput/TextFilterInput";
import { PullRequestLink } from "../../../../../../components/PullRequestLink/PullRequestLink";
import { UserLink } from "../../../../../../components/UserLink/UserLink";
import { StatusOverrideStatus } from "../../../../../../types/status.override/status.override.enum";
import {
  mapEnumValueToKey,
  statusOverrideStatusColorMapping,
} from "../../../../../../utils/enum/enum";
import { ListViewer } from "../../../../../../components/ListViewer/ListViewer";
import { PRStatusOverridesMoreButton } from "./components/PRStatusOverridesMoreButton/PRStatusOverridesMoreButton";

export const PRStatusOverridesTableSection = (): JSX.Element => {
  const { paginationRequest } = usePagination({
    filterNames: [
      "statusOverrideIds",
      "owners",
      "statuses",
      "statusChecks",
      "repositoryNames",
    ],
  });

  const { modal } = App.useApp();

  const { search, limit, skip, sortField, sortOrder, filters } =
    paginationRequest;

  const [{ data, error, fetching }, refetch] = useQuery<{
    statusOverrides: PaginationResponse<StatusOverrideJSON>;
  }>({
    query: getPaginateStatusOverridesQuery({
      fields: [
        "total",
        {
          data: [
            "id",
            "statusOverrideId",
            "title",
            "owner",
            "pullRequestId",
            "repositoryName",
            "status",
            "statusChecks",
            {
              relations: [
                {
                  user: ["id", "fullName", "email"],
                },
              ],
            },
          ],
        },
      ],
    }),
    variables: {
      statusOverridePaginationInput: {
        search,
        limit,
        skip,
        sortField,
        sortOrder,
        filters: {
          ...(filters ? { ...filters } : {}),
        },
      },
    },
    requestPolicy: "network-only",
  });

  const statusOverrides = data?.statusOverrides.data ?? [],
    total = data?.statusOverrides.total ?? 0;

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  const onShowStatusChecksModal = (
    statusOverride: StatusOverrideJSON
  ): void => {
    modal.info({
      icon: null,
      title: "Status Checks",
      width: "75%",
      content: (
        <ListViewer
          style={{ height: "65vh", overflowY: "auto" }}
          data={statusOverride.statusChecks.map((statusCheck) => {
            return {
              title: statusCheck,
            };
          })}
          emptyText="No Status Checks"
        />
      ),
      okText: "Cancel",
      okButtonProps: {
        type: "default",
      },
    });
  };

  return (
    <ErrorView error={error} onRefetch={onRefetch}>
      <TableView
        rowKey="statusOverrideId"
        loading={fetching}
        sortableColumns={["title"]}
        filterableColumnMapping={{
          statusOverrideId: "statusOverrideIds",
          owner: "owners",
          status: "statuses",
          statusChecks: "statusChecks",
          repositoryName: "repositoryNames",
        }}
        dataSource={statusOverrides}
        total={total}
        columns={[
          {
            key: "statusOverrideId",
            dataIndex: "statusOverrideId",
            title: "ID",
            align: "center",
            width: 200,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "statusOverrideIds",
            }),
            filterDropdown: TextFilterInput,
          },
          {
            key: "title",
            dataIndex: "title",
            title: "Pull Request",
            align: "center",
            width: 400,
            filteredValue: getFilterValueUtility<number[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "pullRequestIds",
            }),
            filterDropdown: TextFilterInput,
            render: (
              _value: undefined,
              record: StatusOverrideJSON
            ): ReactNode => {
              return (
                <PullRequestLink
                  repositoryName={record.repositoryName}
                  pullRequestId={record.pullRequestId}
                  title={record.title}
                />
              );
            },
          },
          {
            key: "repositoryName",
            dataIndex: "repositoryName",
            title: "Repository Name",
            align: "center",
            width: 200,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "repositoryNames",
            }),
            filterDropdown: TextFilterInput,
          },
          {
            key: "owner",
            title: "Owner",
            align: "center",
            width: 200,
            render: (
              _value: undefined,
              record: StatusOverrideJSON
            ): ReactNode => {
              return (
                <UserLink
                  fullName={record.relations?.user?.fullName}
                  email={record.owner}
                />
              );
            },
          },
          {
            key: "status",
            dataIndex: "status",
            title: "Status",
            align: "center",
            width: 200,
            defaultFilteredValue: [],
            filterResetToDefaultFilteredValue: false,
            filterMultiple: true,
            filteredValue: getFilterValueUtility<string[]>({
              paginationRequest,
              forceToArray: true,
              defaultValue: [],
              filterName: "statuses",
            }),
            filters: Object.values(StatusOverrideStatus).map((value) => {
              return {
                text: mapEnumValueToKey(StatusOverrideStatus, value),
                value,
              };
            }),
            render: (value: string, _record: StatusOverrideJSON): ReactNode => {
              return (
                <Tag
                  color={
                    statusOverrideStatusColorMapping[
                      value as StatusOverrideStatus
                    ]
                  }
                >
                  {mapEnumValueToKey(StatusOverrideStatus, value)}
                </Tag>
              );
            },
          },
          {
            key: "statusChecks",
            dataIndex: "statusChecks",
            title: "Status Checks",
            align: "center",
            width: 200,
            render: (
              _value: undefined,
              record: StatusOverrideJSON
            ): ReactNode => {
              return (
                <Button
                  type="link"
                  onClick={onShowStatusChecksModal.bind(this, record)}
                >
                  {record.statusChecks.length} status checks
                </Button>
              );
            },
          },
          {
            key: "more",
            align: "center",
            fixed: "right",
            width: 100,
            render: (
              _value: undefined,
              record: StatusOverrideJSON
            ): ReactNode => {
              return (
                <PRStatusOverridesMoreButton
                  statusOverride={record}
                  onRefetch={onRefetch}
                />
              );
            },
          },
        ]}
      />
    </ErrorView>
  );
};
