import { FaDatabase, FaUser, FaUsers } from "react-icons/fa";
import { GiTestTubes } from "react-icons/gi";
import { SiDotenv } from "react-icons/si";
import { AiOutlineDeploymentUnit } from "react-icons/ai";
import { MdVpnLock } from "react-icons/md";
import { GoWorkflow } from "react-icons/go";
import { RiGitRepositoryLine } from "react-icons/ri";
import { SiAzurepipelines } from "react-icons/si";
import Logo from "../../../../assets/logo.png";
import { useEffect, useMemo, useState } from "react";
import { Menu, MenuProps } from "antd";
import { Link, useLocation } from "react-router-dom";
import { Nullable } from "../../../../utils/nullable/nullable.type";
import { UserJSON } from "../../../../types/user/user.type";
import { Role } from "../../../../types/role/role.enum";
import { GrDeploy } from "react-icons/gr";
import { IoGitPullRequestSharp } from "react-icons/io5";

export type SideLayoutProps = {
  user?: Nullable<UserJSON>;
};

export const SideLayout = ({ user }: SideLayoutProps): JSX.Element => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([
    "dashboard",
    "dashboard/dashboard",
  ]);

  const urlKeyMapping: Record<string, string[]> = useMemo(() => {
    return {
      "/dashboard": ["dashboard", "dashboard/dashboard"],
      "/dashboard/iam/users": ["iam", "iam/users"],
      "/dashboard/iam/groups": ["iam", "iam/groups"],
      "/dashboard/teams": ["teams", "teams/teams"],
      "/dashboard/environments": ["environments", "environments/environments"],
      "/dashboard/deployments": ["deployments", "deployments/deployments"],
      "/dashboard/releases": ["releases", "releases/releases"],
      "/dashboard/quality-assurance/tests": [
        "quality-assurance",
        "quality-assurance/tests",
      ],
      "/dashboard/migrations/database": ["migrations", "migrations/database"],
      "/dashboard/security/vpn": ["security", "security/vpn"],
      "/dashboard/settings/workflows": ["settings", "settings/workflows"],
      "/dashboard/settings/repositories": ["settings", "settings/repositories"],
      "/dashboard/more/runs": ["more", "more/runs"],
      "/dashboard/more/pr-status-override": ["more", "more/pr-status-override"],
    };
  }, []);

  const location = useLocation();

  const roles = user?.relations?.roles ?? [];

  useEffect(() => {
    Object.keys(urlKeyMapping).forEach((url) => {
      if (location.pathname.startsWith(url)) {
        setSelectedKeys(urlKeyMapping[url]);
      }
    });
  }, [urlKeyMapping, location.pathname]);

  const items = ((): MenuProps["items"] => {
    const items: MenuProps["items"] = [];

    if (roles.includes(Role.VIEW_USERS) || roles.includes(Role.VIEW_GROUPS)) {
      items.push({
        key: "iam",
        label: "IAM",
        type: "group",
        children: [
          ...(roles.includes(Role.VIEW_USERS)
            ? [
                {
                  key: "iam/users",
                  label: <Link to="/dashboard/iam/users">Users</Link>,
                  icon: <FaUser />,
                },
              ]
            : []),
          ...(roles.includes(Role.VIEW_GROUPS)
            ? [
                {
                  key: "iam/groups",
                  label: <Link to="/dashboard/iam/groups">Groups</Link>,
                  icon: <FaUsers />,
                },
              ]
            : []),
        ],
      });

      items.push({
        type: "divider",
      });
    }

    if (roles.includes(Role.VIEW_TEAMS)) {
      items.push({
        key: "teams",
        label: "Teams",
        type: "group",
        children: [
          ...(roles.includes(Role.VIEW_TEAMS)
            ? [
                {
                  key: "teams/teams",
                  label: <Link to="/dashboard/teams">Teams</Link>,
                  icon: <FaUsers />,
                },
              ]
            : []),
        ],
      });

      items.push({
        type: "divider",
      });
    }

    if (roles.includes(Role.VIEW_ENVIRONMENTS)) {
      items.push({
        key: "environments",
        label: "Environments",
        type: "group",
        children: [
          ...(roles.includes(Role.VIEW_ENVIRONMENTS)
            ? [
                {
                  key: "environments/environments",
                  label: <Link to="/dashboard/environments">Environments</Link>,
                  icon: <SiDotenv />,
                },
              ]
            : []),
        ],
      });

      items.push({
        type: "divider",
      });
    }

    if (roles.includes(Role.VIEW_DEPLOYMENTS)) {
      items.push({
        key: "deployments",
        label: "Deployments",
        type: "group",
        children: [
          ...(roles.includes(Role.VIEW_DEPLOYMENTS)
            ? [
                {
                  key: "deployments/deployments",
                  label: <Link to="/dashboard/deployments">Deployments</Link>,
                  icon: <AiOutlineDeploymentUnit />,
                },
              ]
            : []),
        ],
      });

      items.push({
        type: "divider",
      });
    }

    if (roles.includes(Role.VIEW_RELEASES)) {
      items.push({
        key: "releases",
        label: "Releases",
        type: "group",
        children: [
          ...(roles.includes(Role.VIEW_RELEASES)
            ? [
                {
                  key: "releases/releases",
                  label: <Link to="/dashboard/releases">Releases</Link>,
                  icon: <GrDeploy />,
                },
              ]
            : []),
        ],
      });

      items.push({
        type: "divider",
      });
    }
    if (roles.includes(Role.VIEW_TESTS)) {
      items.push({
        key: "quality-assurance",
        label: "Quality Asssurance",
        type: "group",
        children: [
          ...(roles.includes(Role.VIEW_TESTS)
            ? [
                {
                  key: "quality-assurance/tests",
                  label: (
                    <Link to="/dashboard/quality-assurance/tests">Tests</Link>
                  ),
                  icon: <GiTestTubes />,
                },
              ]
            : []),
        ],
      });

      items.push({
        type: "divider",
      });
    }

    if (roles.includes(Role.VIEW_DATABASE_MIGRATIONS)) {
      items.push({
        key: "migrations",
        label: "Migrations",
        type: "group",
        children: [
          ...(roles.includes(Role.VIEW_DATABASE_MIGRATIONS)
            ? [
                {
                  key: "migrations/database",
                  label: (
                    <Link to="/dashboard/migrations/database">Database</Link>
                  ),
                  icon: <FaDatabase />,
                },
              ]
            : []),
        ],
      });

      items.push({
        type: "divider",
      });
    }

    if (roles.includes(Role.VIEW_VPN_CLIENT_CONFIGURATION)) {
      items.push({
        key: "security",
        label: "Secuity",
        type: "group",
        children: [
          ...(roles.includes(Role.VIEW_VPN_CLIENT_CONFIGURATION)
            ? [
                {
                  key: "security/vpn",
                  label: <Link to="/dashboard/security/vpn">VPN</Link>,
                  icon: <MdVpnLock />,
                },
              ]
            : []),
        ],
      });

      items.push({
        type: "divider",
      });
    }

    if (
      roles.includes(Role.VIEW_REPOSITORIES) ||
      roles.includes(Role.VIEW_WORKFLOWS)
    ) {
      items.push({
        key: "settings",
        label: "Settings",
        type: "group",
        children: [
          ...(roles.includes(Role.VIEW_WORKFLOWS)
            ? [
                {
                  key: "settings/workflows",
                  label: (
                    <Link to="/dashboard/settings/workflows">Workflows</Link>
                  ),
                  icon: <GoWorkflow />,
                },
              ]
            : []),
          ...(roles.includes(Role.VIEW_REPOSITORIES)
            ? [
                {
                  key: "settings/repositories",
                  label: (
                    <Link to="/dashboard/settings/repositories">
                      Repositories
                    </Link>
                  ),
                  icon: <RiGitRepositoryLine />,
                },
              ]
            : []),
        ],
      });

      items.push({
        type: "divider",
      });
    }

    if (
      roles.includes(Role.VIEW_RUNS) ||
      roles.includes(Role.VIEW_STATUS_OVERRIDES)
    ) {
      items.push({
        key: "more",
        label: "More",
        type: "group",
        children: [
          ...(roles.includes(Role.VIEW_RUNS)
            ? [
                {
                  key: "more/runs",
                  label: <Link to="/dashboard/more/runs">CI Runs</Link>,
                  icon: <SiAzurepipelines />,
                },
              ]
            : []),
          ...(roles.includes(Role.VIEW_STATUS_OVERRIDES)
            ? [
                {
                  key: "more/pr-status-override",
                  label: (
                    <Link to="/dashboard/more/pr-status-overrides">
                      PR Status Override
                    </Link>
                  ),
                  icon: <IoGitPullRequestSharp />,
                },
              ]
            : []),
        ],
      });

      items.push({
        type: "divider",
      });
    }

    return items;
  })();

  return (
    <>
      <div style={{ padding: 20 }}>
        <img
          src={Logo}
          alt="Logo"
          height={100}
          style={{ display: "block", margin: "auto" }}
        />
      </div>
      <Menu
        style={{ height: "calc(100vh - 140px)", overflowY: "auto" }}
        theme="light"
        mode="inline"
        selectedKeys={selectedKeys}
        items={items}
      />
    </>
  );
};
