import { App, Button, Form, Modal, Select } from "antd";
import { ErrorView } from "../ErrorView/ErrorView";
import { useMutation } from "urql";
import { DeploymentJSON } from "../../types/deployment/deployment.type";
import { updateDeploymentFeaturesMutation } from "../../providers/graphql/mutations/updateDeploymentFeatures.mutation";
import { DeploymentFeature } from "../../types/deployment/deployment.enum";
import { mapEnumValueToKey } from "../../utils/enum/enum";

type UpdatedDeploymentFeaturesFormFields = {
  features?: DeploymentFeature[];
};

export type UpdatedDeploymentFeaturesFormProps = {
  deployment?: Partial<DeploymentJSON>;
};

export const UpdatedDeploymentFeaturesForm = ({
  deployment,
}: UpdatedDeploymentFeaturesFormProps): JSX.Element => {
  const { message } = App.useApp();

  const [{ fetching, error }, mutation] = useMutation(
    updateDeploymentFeaturesMutation()
  );

  const onSubmit = async (
    values: UpdatedDeploymentFeaturesFormFields
  ): Promise<void> => {
    const { error } = await mutation({
      updateDeploymentFeaturesInput: {
        deploymentId: deployment?.deploymentId,
        ...values,
      },
    });

    if (error) {
      return;
    }

    message.info("Updated deployment features successfully!");

    Modal.destroyAll();
  };

  return (
    <ErrorView disableRetryButton error={error}>
      <Form
        size="large"
        layout="vertical"
        disabled={fetching}
        onFinish={onSubmit}
        scrollToFirstError
      >
        <Form.Item
          name="features"
          label="Deployment Features"
          initialValue={deployment?.features ?? []}
        >
          <Select mode="multiple">
            {Object.values(DeploymentFeature).map((feature) => (
              <Select.Option key={feature} value={feature}>
                {mapEnumValueToKey(DeploymentFeature, feature)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button loading={fetching} block type="primary" htmlType="submit">
            Update Deployment Features
          </Button>
        </Form.Item>
      </Form>
    </ErrorView>
  );
};
