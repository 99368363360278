import { query } from "gql-query-builder";
import IQueryBuilderOptions from "gql-query-builder/build/IQueryBuilderOptions";
import { TypedDocumentNode, gql } from "urql";

export const getPaginateUsersQuery = ({
  fields,
}: Pick<IQueryBuilderOptions, "fields">): TypedDocumentNode => {
  return gql(
    query({
      fields: [...(fields ?? ["id"])],
      operation: "users",
      variables: {
        userPaginationInput: {
          type: "UserPaginationInput",
          required: true,
        },
      },
    }).query
  );
};
