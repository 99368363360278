import { cacheExchange, Client, fetchExchange } from "urql";
import { devtoolsExchange } from "@urql/devtools";
import authExchange from "./exchanges/auth.exchange";

export const graphQLClient = new Client({
  url: `${process.env.REACT_APP_BACKEND_ENDPOINT}/graphql`,
  exchanges: [devtoolsExchange, cacheExchange, authExchange, fetchExchange],
  fetchOptions: {
    credentials: "include",
  },
});
