import { Form, Input, Select, Typography } from "antd";
import { MdEditSquare } from "react-icons/md";
import {
  EnvironmentAccount,
  EnvironmentSyncMode,
} from "../../../../../../types/environment/environment.enum";
import { mapEnumValueToKey } from "../../../../../../utils/enum/enum";
import { useParams } from "react-router-dom";
import { UpsertEnvironmentWizardFormFields } from "../../../../UpsertEnvironmentWizardSection";

export const EnvironmentDetailsFormSection = (): JSX.Element => {
  const params = useParams<{ environmentId: string }>();

  const isReadOnly = !!params?.environmentId;

  const form = Form.useFormInstance<UpsertEnvironmentWizardFormFields>();

  const account = Form.useWatch("account", form);

  return (
    <>
      <Form.Item>
        <Typography.Title
          level={3}
          style={{ display: "block", textAlign: "center" }}
        >
          Environment Details
        </Typography.Title>
        <Typography.Text
          type="secondary"
          style={{ display: "block", textAlign: "center" }}
        >
          Enter the details of the environment
        </Typography.Text>
      </Form.Item>
      <Form.Item
        name="environmentId"
        label="Environment Id"
        rules={[
          {
            required: true,
            message: "Please input the environment id!",
          },
        ]}
      >
        <Input
          disabled={isReadOnly}
          size="large"
          prefix={<MdEditSquare />}
          type="text"
        />
      </Form.Item>
      <Form.Item
        name="environmentName"
        label="Environment Name"
        rules={[
          {
            required: true,
            message: "Please input the environment name!",
          },
        ]}
      >
        <Input size="large" prefix={<MdEditSquare />} type="text" />
      </Form.Item>
      <Form.Item
        name="account"
        label="Account"
        rules={[
          {
            required: true,
            message: "Please select the account to use!",
          },
        ]}
      >
        <Select disabled={isReadOnly}>
          {Object.values(EnvironmentAccount).map((value) => {
            return (
              <Select.Option key={value} value={value}>
                {mapEnumValueToKey(EnvironmentAccount, value)}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      {account === EnvironmentAccount.DEVELOPMENT && (
        <Form.Item
          name="syncMode"
          label="Sync mode"
          rules={[
            {
              required: true,
              message: "Please select the sync mode to use!",
            },
          ]}
        >
          <Select>
            {Object.values(EnvironmentSyncMode).map((value) => {
              return (
                <Select.Option key={value} value={value}>
                  {mapEnumValueToKey(EnvironmentSyncMode, value)}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      )}
    </>
  );
};
