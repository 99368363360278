import { mutation } from "gql-query-builder";
import IQueryBuilderOptions from "gql-query-builder/build/IQueryBuilderOptions";
import { TypedDocumentNode, gql } from "urql";

export const processStatusOverrideMutation = (
  args?: Pick<IQueryBuilderOptions, "fields">
): TypedDocumentNode => {
  return gql(
    mutation({
      variables: {
        processStatusOverrideInput: {
          type: "ProcessStatusOverrideInput",
          required: true,
        },
      },
      operation: "processStatusOverride",
      fields: args?.fields ?? ["id"],
    }).query
  );
};
