import { DeploymentDetailsFormSection } from "./components/DeploymentDetailsFormSection/DeploymentDetailsFormSection";
import { DeploymentOwnersSection } from "./components/DeploymentOwnersSection/DeploymentOwnersSection";
import { DeploymentRepositorySelectionFormSection } from "./components/DeploymentRepositorySelection/DeploymentRepositorySelectionFormSection";
import { DeploymentReviewersSection } from "./components/DeploymentReviewersSection/DeploymentReviewersSection";

export type NewDeploymentWizardContentProps = {
  currentStep: number;
};

export const NewDeploymentWizardContent = ({
  currentStep,
}: NewDeploymentWizardContentProps): JSX.Element => {
  return (
    <div style={{ minHeight: "50vh", paddingLeft: 50, paddingRight: 50 }}>
      {currentStep === 0 && <DeploymentDetailsFormSection />}
      {currentStep === 1 && <DeploymentOwnersSection />}
      {currentStep === 2 && <DeploymentReviewersSection />}
      {currentStep === 3 && <DeploymentRepositorySelectionFormSection />}
    </div>
  );
};
