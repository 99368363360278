import { Flex } from "antd";
import { ReleasesHeaderSection } from "./components/ReleasesHeaderSection/ReleasesHeaderSection";
import { ReleasesTableSection } from "./components/ReleasesTableSection/ReleasesTableSection";

const ReleasesPage = (): JSX.Element => {
  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <ReleasesHeaderSection />
      <ReleasesTableSection />
    </Flex>
  );
};

export default ReleasesPage;
