import { App, Button, Dropdown, MenuProps, Modal } from "antd";
import { useMutation } from "urql";
import { StatusOverrideJSON } from "../../../../../../../../types/status.override/status.override.type";
import { Nullable } from "../../../../../../../../utils/nullable/nullable.type";
import { useError } from "../../../../../../../../hooks/useError";
import { useUserContext } from "../../../../../../../../context/UserContext";
import { deleteStatusOverrideMutation } from "../../../../../../../../providers/graphql/mutations/deleteStatusOverride.mutation";
import { Role } from "../../../../../../../../types/role/role.enum";
import { StatusOverrideStatus } from "../../../../../../../../types/status.override/status.override.enum";
import { processStatusOverrideMutation } from "../../../../../../../../providers/graphql/mutations/processStatusOverride.mutation";

export type PRStatusOverridesMoreButtonProps = {
  statusOverride: StatusOverrideJSON;
  onRefetch: () => void;
};

export const PRStatusOverridesMoreButton = ({
  statusOverride,
  onRefetch,
}: PRStatusOverridesMoreButtonProps): Nullable<JSX.Element> => {
  const { modal, notification, message } = App.useApp();

  const { errorNotificationMessage } = useError();

  const { user } = useUserContext();

  const roles = user?.relations?.roles ?? [];

  const [{ fetching: isDeletingStatusOverride }, deleteStatusOverride] =
    useMutation(deleteStatusOverrideMutation());

  const [{ fetching: isProcessingStatusOverride }, processStatusOverride] =
    useMutation(processStatusOverrideMutation());

  const fetching = isDeletingStatusOverride || isProcessingStatusOverride;

  const items: MenuProps["items"] = [
    ...(roles.includes(Role.UPDATE_STATUS_OVERRIDES)
      ? [
          ...(statusOverride.status === StatusOverrideStatus.PROCESSED
            ? [
                {
                  key: "re-process-status-override",
                  label: "Re-process pr status override",
                },
              ]
            : [
                {
                  key: "process-status-override",
                  label: "Process pr status override",
                },
              ]),
        ]
      : []),
    ...(roles.includes(Role.DELETE_TEAMS)
      ? [
          {
            key: "delete-status-override",
            label: "Delete pr status override",
          },
        ]
      : []),
  ];

  const onMenuItemSelected = ({ key }: { key: string }): void => {
    switch (key) {
      case "process-status-override":
      case "re-process-status-override":
        const isReprocessing = key === "re-process-status-override";

        modal.confirm({
          icon: null,
          title: isReprocessing
            ? "Re-process PR Status Override"
            : "Process PR Status Override",
          content: isReprocessing
            ? `Are you sure you want to re-process pr status override id ${statusOverride.statusOverrideId}?`
            : `Are you sure you want to process pr status override id ${statusOverride.statusOverrideId}?`,
          okButtonProps: {
            danger: true,
          },
          okText: isReprocessing
            ? "Re-process PR Status Override"
            : "Process PR Status Override",
          onOk: async (): Promise<void> => {
            const { error } = await processStatusOverride({
              processStatusOverrideInput: {
                statusOverrideId: statusOverride.statusOverrideId,
              },
            });

            if (error) {
              notification.error(errorNotificationMessage(error));
              return;
            }

            message.success(
              isReprocessing
                ? "PR status override re-processed successfully"
                : "PR status override processed successfully"
            );

            onRefetch();

            Modal.destroyAll();
          },
        });
        break;
      case "delete-status-override":
        modal.confirm({
          icon: null,
          title: "Delete PR Status Override",
          content: `Are you sure you want to delete pr status override id ${statusOverride.statusOverrideId}?`,
          okButtonProps: {
            danger: true,
          },
          okText: "Delete PR Status Override",
          onOk: async (): Promise<void> => {
            const { error } = await deleteStatusOverride({
              deleteStatusOverrideInput: {
                statusOverrideId: statusOverride.statusOverrideId,
              },
            });

            if (error) {
              notification.error(errorNotificationMessage(error));
              return;
            }

            message.success("PR status override deleted successfully");

            onRefetch();

            Modal.destroyAll();
          },
        });
        break;
      default:
        break;
    }
  };

  if (!items?.length) {
    return null;
  }

  return (
    <Dropdown
      menu={{
        items,
        onClick: onMenuItemSelected,
        disabled: fetching,
        style: { width: 200 },
      }}
      placement="bottom"
      arrow
    >
      <Button loading={fetching} type="primary">
        More
      </Button>
    </Dropdown>
  );
};
