import { Alert, App, Button, Form, Modal, Select } from "antd";
import { Nullable } from "../../utils/nullable/nullable.type";
import { ErrorView } from "../ErrorView/ErrorView";
import { useMutation } from "urql";
import { ReleaseRepository } from "../../types/release/release.type";
import { useRepositoriesContext } from "../../context/RepositoriesContext";
import { SelectBranchInput } from "../SelectBranchInput/SelectBranchInput";
import { mapEnumValueToKey } from "../../utils/enum/enum";
import { Country } from "../../types/country/country.enum";
import { updateReleaseRepositoryRefAndVersionMutation } from "../../providers/graphql/mutations/updateReleaseRepositoryRefAndVersion.mutation";
import { addReleaseRepositoryMutation } from "../../providers/graphql/mutations/addReleaseRepository.mutation";
import { TagVersionInput } from "../TagVersionInput/TagVersionInput";

type UpsertReleaseRepositoryFormFields = Partial<
  Pick<ReleaseRepository, "repositoryAlias" | "ref" | "commit" | "version"> & {
    countries: Nullable<Country[]>;
    country: Nullable<Country>;
  }
>;

export type UpsertReleaseRepositoryFormProps = {
  releaseName: string;
  releaseRepository?: ReleaseRepository;
  onRefetch: () => void;
};

export const UpsertReleaseRepositoryForm = ({
  releaseName,
  releaseRepository,
  onRefetch,
}: UpsertReleaseRepositoryFormProps): JSX.Element => {
  const { repositories, repositoryMapping } = useRepositoriesContext();

  const [form] = Form.useForm<UpsertReleaseRepositoryFormFields>();

  const repositoryAlias = Form.useWatch("repositoryAlias", form);

  const { message } = App.useApp();

  const isEditing = Boolean(releaseRepository);

  const [{ fetching, error }, mutation] = useMutation(
    isEditing
      ? updateReleaseRepositoryRefAndVersionMutation()
      : addReleaseRepositoryMutation()
  );

  const onSubmit = async (
    values: UpsertReleaseRepositoryFormFields
  ): Promise<void> => {
    const { error } = await mutation({
      ...(isEditing
        ? {
            updateReleaseRepositoryRefAndVersionInput: {
              ...values,
              releaseName,
              country: (repositoryMapping ?? {})[values.repositoryAlias ?? ""]
                .countryOptions.enabledForReleases
                ? values.country ?? null
                : null,
            },
          }
        : {
            addReleaseRepositoryInput: {
              ...values,
              releaseName,
              countries: (repositoryMapping ?? {})[values.repositoryAlias ?? ""]
                .countryOptions.enabledForReleases
                ? values.countries ?? null
                : null,
            },
          }),
    });

    if (error) {
      return;
    }

    message.info(`Repository ${isEditing ? "updated" : "added"} successfully!`);

    onRefetch();

    Modal.destroyAll();
  };

  if (!repositories || !repositoryMapping) {
    return (
      <Alert
        message="No repositories found. Please check if you have permissions to view repositories"
        type="error"
      />
    );
  }

  const isRepositoryAliasSelected = !!repositoryAlias;

  const isCountrySelectionEnabled = isRepositoryAliasSelected
    ? repositoryMapping[repositoryAlias]?.countryOptions?.enabledForReleases
    : false;

  return (
    <ErrorView disableRetryButton error={error}>
      <Form
        size="large"
        layout="vertical"
        form={form}
        disabled={fetching}
        onFinish={onSubmit}
        initialValues={{
          ...(releaseRepository && { ...releaseRepository }),
        }}
        scrollToFirstError
      >
        <Form.Item
          label="Repository Name"
          labelCol={{ span: 24 }}
          name="repositoryAlias"
          rules={[
            {
              required: true,
              message: "Repository name is required",
            },
          ]}
        >
          <Select disabled={isEditing} showSearch>
            {repositories.map((repository) => {
              return (
                <Select.Option
                  key={repository.repositoryAlias}
                  value={repository.repositoryAlias}
                >
                  {repository.repositoryAlias}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        {isRepositoryAliasSelected && (
          <>
            <Form.Item
              name="ref"
              label="Branch To Create Tag From"
              rules={[
                {
                  required: true,
                  message: "Branch to create tag from is required",
                },
              ]}
              initialValue={repositoryMapping[repositoryAlias].mainRef}
            >
              <SelectBranchInput
                mainRef={repositoryMapping[repositoryAlias].mainRef}
                repositoryName={
                  repositoryMapping[repositoryAlias].repositoryName
                }
              />
            </Form.Item>
            {isCountrySelectionEnabled && (
              <Form.Item
                name={isEditing ? "country" : "countries"}
                label={
                  isEditing ? "Country To Deploy To" : "Countries To Deploy To"
                }
                rules={[
                  {
                    required: true,
                    message: isEditing
                      ? "Country to deploy to is required"
                      : "Countries to deploy to is required",
                  },
                ]}
                initialValue={[Country.SAUDI_ARABIA]}
              >
                <Select
                  disabled={isEditing}
                  mode={isEditing ? undefined : "multiple"}
                >
                  {Object.values(Country).map((country) => {
                    return (
                      <Select.Option key={country} value={country}>
                        {mapEnumValueToKey(Country, country)}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              name={"version"}
              label="Version"
              rules={[
                { required: true, message: "Version is required" },
                {
                  pattern: /v[0-9]+.[0-9]+.[0-9]+/,
                  message: "Invalid version format",
                },
              ]}
            >
              <TagVersionInput
                repositoryName={
                  repositoryMapping[repositoryAlias].repositoryName
                }
              />
            </Form.Item>
          </>
        )}
        <Form.Item>
          <Button loading={fetching} block type="primary" htmlType="submit">
            {isEditing ? "Update Repository" : "Add Repository"}
          </Button>
        </Form.Item>
      </Form>
    </ErrorView>
  );
};
