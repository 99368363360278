import { App, Button, Divider, Flex, Form, Typography } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { SelectTeamInput } from "../../../../../../../../../../components/SelectTeamInput/SelectTeamInput";
import { EntityType } from "../../../../type/entity.enum";
import { NewDeploymentWizardFormFields } from "../../../../NewDeploymentWizardSection";
import { SelectUserInput } from "../../../../../../../../../../components/SelectUserInput/SelectUserInput";
import { Nullable } from "../../../../../../../../../../utils/nullable/nullable.type";
import { createDeploymentWizardFormDataAtom } from "../../../../states/create.deployment.wizard.form.data";
import { useAtomValue } from "jotai";

export const DeploymentReviewersSection = (): JSX.Element => {
  const form = Form.useFormInstance<NewDeploymentWizardFormFields>();

  const reviewersFormData = Form.useWatch("reviewers", form);

  const createDeploymentWizardFormData = useAtomValue(
    createDeploymentWizardFormDataAtom
  );

  const workflowId = createDeploymentWizardFormData?.workflowId;

  const { notification } = App.useApp();

  return (
    <>
      <Form.List
        name="reviewers"
        rules={[
          {
            validator: async (_, reviewers) => {
              let error: Nullable<string> = null;

              if (!workflowId) return;

              if (!reviewers || !reviewers.length) {
                error =
                  "At least one reviewer is required when a workflow is selected";
              }

              if (error) {
                notification.error({
                  message: error,
                });

                return Promise.reject(new Error(error));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            <Flex justify="space-between" align="center">
              <div></div>
              <Flex vertical>
                <Typography.Title
                  level={3}
                  style={{ display: "block", textAlign: "center" }}
                >
                  Reviewers Selection
                </Typography.Title>
                <Typography.Text
                  type="secondary"
                  style={{ display: "block", textAlign: "center" }}
                >
                  Select the teams and users that will be reviewers of this
                  deployment
                </Typography.Text>
              </Flex>
              <Flex gap="middle">
                <Button
                  type="dashed"
                  onClick={() =>
                    add({
                      entityType: EntityType.USER,
                    })
                  }
                  icon={<PlusOutlined />}
                >
                  Add User
                </Button>
                <Button
                  type="dashed"
                  onClick={() =>
                    add({
                      entityType: EntityType.TEAM,
                    })
                  }
                  icon={<PlusOutlined />}
                >
                  Add Team
                </Button>
              </Flex>
            </Flex>
            <Divider />
            {fields.map((_, index) => {
              const entityType = reviewersFormData?.[index]?.entityType;

              return (
                <div key={index}>
                  <Flex align="center" justify="space-between">
                    <Typography.Title level={2} type="secondary">
                      Reviewer {index + 1}
                    </Typography.Title>
                    <Button
                      danger
                      type="primary"
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(index)}
                      style={{ marginLeft: "auto" }}
                    >
                      Remove Reviewer
                    </Button>
                  </Flex>
                  {entityType === EntityType.TEAM && (
                    <Form.Item
                      label="Team"
                      labelCol={{ span: 24 }}
                      name={[index, "teamName"]}
                      rules={[
                        {
                          required: true,
                          message: "Please select a team first",
                        },
                      ]}
                    >
                      <SelectTeamInput />
                    </Form.Item>
                  )}
                  {entityType === EntityType.USER && (
                    <Form.Item
                      label="User"
                      labelCol={{ span: 24 }}
                      name={[index, "email"]}
                      rules={[
                        {
                          required: true,
                          message: "Please select a user first",
                        },
                      ]}
                    >
                      <SelectUserInput />
                    </Form.Item>
                  )}
                  <Divider style={{ marginTop: 50 }} />
                </div>
              );
            })}
          </>
        )}
      </Form.List>
    </>
  );
};
