import { Flex } from "antd";
import { PRStatusOverridesHeaderSection } from "./components/PRStatusOverridesHeaderSection/PRStatusOverridesHeaderSection";
import { PRStatusOverridesTableSection } from "./components/PRStatusOverridesTableSection/PRStatusOverridesTableSection";

const PRStatusOverridePage = (): JSX.Element => {
  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <PRStatusOverridesHeaderSection />
      <PRStatusOverridesTableSection />
    </Flex>
  );
};

export default PRStatusOverridePage;
