import {
  Alert,
  App,
  Breadcrumb,
  Button,
  Card,
  Descriptions,
  Flex,
  Tag,
  Typography,
} from "antd";
import { DeploymentJSON } from "../../../../../../../../types/deployment/deployment.type";
import { Link } from "react-router-dom";
import {
  deploymentFeatureColorMapping,
  mapEnumValueToKey,
  runStatusColorMapping,
} from "../../../../../../../../utils/enum/enum";
import {
  DeploymentFeature,
  DeploymentPurpose,
  DeploymentStatus,
} from "../../../../../../../../types/deployment/deployment.enum";
import moment from "moment";
import { EnvironmentsMoreButton } from "../../../../../../../../components/EnvironmentsMoreButton/EnvironmentsMoreButton";
import { DeploymentProgressSection } from "./components/DeploymentProgressSection/DeploymentProgressSection";
import { useUserContext } from "../../../../../../../../context/UserContext";
import { SwitchDeploymentEnvironmentForm } from "../../../../../../../../components/SwitchDeploymentEnvironmentForm/SwitchDeploymentEnvironmentForm";
import { SwitchDeploymentWorkflowForm } from "../../../../../../../../components/SwitchDeploymentWorkflowForm/SwitchDeploymentWorkflowForm";
import { UpdatedDeploymentFeaturesForm } from "../../../../../../../../components/UpdatedDeploymentFeaturesForm/UpdatedDeploymentFeaturesForm";
import { UserJSON } from "../../../../../../../../types/user/user.type";
import { UsersViewer } from "../../../../../../../../components/UsersViewer/UsersViewer";
import { TeamJSON } from "../../../../../../../../types/team/team.type";
import { TeamsViewer } from "../../../../../../../../components/TeamsViewer/TeamsViewer";
import { UpdatedDeploymentOwnersForm } from "../../../../../../../../components/UpdatedDeploymentOwnersForm/UpdatedDeploymentOwnersForm";
import { UpdatedDeploymentReviewersForm } from "../../../../../../../../components/UpdatedDeploymentReviewersForm/UpdatedDeploymentReviewersForm";
import { RunIdLink } from "../../../../../../../../components/RunIdLink/RunIdLink";
import { RunStatus } from "../../../../../../../../types/run/run.enum";
import {
  EnvironmentAccount,
  EnvironmentPurpose,
} from "../../../../../../../../types/environment/environment.enum";

export type DeploymentHeaderSectionProps = {
  deployment?: Partial<DeploymentJSON>;
  onRefetch: () => void;
  isEditable: boolean;
};

export const DeploymentHeaderSection = ({
  deployment,
  onRefetch,
  isEditable,
}: DeploymentHeaderSectionProps): JSX.Element => {
  const { modal } = App.useApp();

  const { user } = useUserContext();

  const deploymentName = deployment?.deploymentName;

  const environment = deployment?.relations?.environment ?? null;

  const workflow = deployment?.relations?.workflow;

  const purpose = deployment?.purpose;

  const status = deployment?.status;

  const canEditWorkflow =
    purpose === DeploymentPurpose.DEVELOPMENT
      ? (status === null && !workflow) ||
        ((status === DeploymentStatus.IN_PROGRESS ||
          status === DeploymentStatus.FAILED_REVIEW) &&
          workflow)
        ? true
        : false
      : false;

  const ownerEmails = [
    ...(deployment?.relations?.userOwners?.map((user) => user.email) ?? []),
    ...(deployment?.relations?.teamOwners?.flatMap((team) => team.emails) ??
      []),
  ];

  const isOwner = ownerEmails.includes(user?.email ?? "");

  const onSwitchEnvironment = () => {
    modal.info({
      icon: null,
      width: "50%",
      title: "Switch Deployment Environment",
      content: <SwitchDeploymentEnvironmentForm deployment={deployment} />,
      okButtonProps: {
        type: "default",
      },
      okText: "Cancel",
    });
  };

  const onSwitchWorkflow = () => {
    modal.info({
      icon: null,
      width: "50%",
      title: "Switch Deployment Environment",
      content: <SwitchDeploymentWorkflowForm deployment={deployment} />,
      okButtonProps: {
        type: "default",
      },
      okText: "Cancel",
    });
  };

  const onUpdatedFeatures = () => {
    modal.info({
      icon: null,
      width: "50%",
      title: "Update Deployment Features",
      content: <UpdatedDeploymentFeaturesForm deployment={deployment} />,
      okButtonProps: {
        type: "default",
      },
      okText: "Cancel",
    });
  };

  const onShowUsers = (title: string, users: UserJSON[]): void => {
    modal.info({
      icon: null,
      title: title,
      width: "75%",
      content: <UsersViewer style={{ height: "65vh" }} users={users} />,
      okText: "Cancel",
      okButtonProps: {
        type: "default",
      },
    });
  };

  const onShowTeams = (title: string, teams: TeamJSON[]): void => {
    modal.info({
      icon: null,
      title: title,
      width: "75%",
      content: <TeamsViewer style={{ height: "65vh" }} teams={teams} />,
      okText: "Cancel",
      okButtonProps: {
        type: "default",
      },
    });
  };

  const onUpdatedOwners = () => {
    modal.info({
      icon: null,
      width: "75%",
      title: "Update Deployment Owners",
      content: <UpdatedDeploymentOwnersForm deployment={deployment} />,
      okButtonProps: {
        type: "default",
      },
      okText: "Cancel",
    });
  };

  const onUpdatedReviewers = () => {
    modal.info({
      icon: null,
      width: "75%",
      title: "Update Deployment Reviewers",
      content: <UpdatedDeploymentReviewersForm deployment={deployment} />,
      okButtonProps: {
        type: "default",
      },
      okText: "Cancel",
    });
  };

  return (
    <>
      <Flex vertical gap="large">
        {status === DeploymentStatus.REVIEWED && (
          <Alert
            message="This deployment has been reviewed and marked as completed"
            type="info"
            style={{ fontSize: "1.25em" }}
            showIcon
          />
        )}
        <Card
          title={
            <Flex justify="space-between" align="center">
              <Flex vertical style={{ marginBottom: 15 }}>
                <Typography.Title level={2}>
                  Deployment: {deploymentName}
                </Typography.Title>
                <Breadcrumb
                  items={[
                    {
                      title: <Link to="/dashboard">Dashboard</Link>,
                    },
                    {
                      title: (
                        <Link to="/dashboard/deployments">Deployments</Link>
                      ),
                    },
                    {
                      title: deploymentName,
                    },
                  ]}
                />
              </Flex>
              <div style={{ width: "60%" }}>
                <DeploymentProgressSection
                  deployment={deployment}
                  onRefetch={onRefetch}
                  isOwner={isOwner}
                />
              </div>
            </Flex>
          }
        >
          <Descriptions
            column={4}
            bordered
            layout="vertical"
            items={[
              {
                label: "Environment",
                children: environment?.environmentId ? (
                  <Flex vertical gap="large">
                    <Descriptions
                      column={1}
                      items={[
                        {
                          contentStyle: {
                            marginTop: "1rem",
                          },
                          labelStyle: {
                            marginTop: "1rem",
                          },
                          label: "Environment Name",
                          children: environment?.environmentName,
                        },
                        {
                          contentStyle: {
                            marginTop: "0.7rem",
                          },
                          labelStyle: {
                            marginTop: "1rem",
                          },
                          label: "Environment Id",
                          children: (
                            <Link
                              to={`/dashboard/environments?environmentIds=${environment?.environmentId}`}
                              target="_blank"
                            >
                              <Button type="link">
                                {environment?.environmentId}
                              </Button>
                            </Link>
                          ),
                        },
                        {
                          contentStyle: {
                            marginTop: "1rem",
                          },
                          labelStyle: {
                            marginTop: "1rem",
                          },
                          label: "Sync Status",
                          children:
                            environment.account ===
                            EnvironmentAccount.PRODUCTION ? (
                              "N/A"
                            ) : environment.relations?.latestSync ? (
                              <RunIdLink
                                run={environment.relations.latestSync}
                                component={
                                  <Tag
                                    color={
                                      runStatusColorMapping[
                                        environment.relations.latestSync.status
                                      ]
                                    }
                                  >
                                    {mapEnumValueToKey(
                                      RunStatus,
                                      environment.relations.latestSync.status
                                    )}
                                  </Tag>
                                }
                              />
                            ) : (
                              <span style={{ color: "red" }}>No Sync</span>
                            ),
                        },
                        {
                          contentStyle: {
                            marginTop: "1rem",
                          },
                          labelStyle: {
                            marginTop: "1rem",
                          },
                          label: "Status",
                          children: environment?.expired ? (
                            <span style={{ color: "red" }}>Expired</span>
                          ) : environment?.hibernating ? (
                            <span style={{ color: "yellow" }}>Hibernating</span>
                          ) : (
                            <span style={{ color: "green" }}>Active</span>
                          ),
                        },
                        ...(!environment?.hibernating &&
                        environment?.suspendHibernationUntil
                          ? [
                              {
                                contentStyle: {
                                  marginTop: "1rem",
                                },
                                labelStyle: {
                                  marginTop: "1rem",
                                },
                                label: "Will Hibernate After",
                                children: moment(
                                  new Date(environment?.suspendHibernationUntil)
                                ).format("LLL"),
                              },
                            ]
                          : []),
                        ...(!environment?.expired &&
                        environment?.suspendExpirationUntil
                          ? [
                              {
                                contentStyle: {
                                  marginTop: "1rem",
                                },
                                labelStyle: {
                                  marginTop: "1rem",
                                },
                                label: "Will Expire After",
                                children: moment(
                                  new Date(environment?.suspendExpirationUntil)
                                ).format("LLL"),
                              },
                            ]
                          : []),
                        ...(isEditable
                          ? [
                              {
                                label: null,
                                contentStyle: {
                                  marginTop: "1rem",
                                },
                                children: (
                                  <Flex
                                    gap="small"
                                    justify="space-between"
                                    style={{ width: "100%" }}
                                  >
                                    {environment &&
                                      environment.purpose ===
                                        EnvironmentPurpose.DEVELOPMENT && (
                                        <Button
                                          type="default"
                                          onClick={onSwitchEnvironment}
                                        >
                                          Switch Environment
                                        </Button>
                                      )}
                                    {environment && (
                                      <EnvironmentsMoreButton
                                        environment={environment}
                                        onRefetch={onRefetch}
                                        buttonText="More Options"
                                      />
                                    )}
                                  </Flex>
                                ),
                              },
                            ]
                          : []),
                      ]}
                    />
                  </Flex>
                ) : (
                  <Flex vertical gap="large" style={{ width: 250 }}>
                    <Typography.Text type="warning" strong>
                      No Environment Linked To This Deployment
                    </Typography.Text>
                    {isEditable && (
                      <Button type="default" onClick={onSwitchEnvironment}>
                        Link Environment
                      </Button>
                    )}
                  </Flex>
                ),
              },
              {
                label: "Workflows",
                children: (
                  <>
                    {workflow ? (
                      <Descriptions
                        column={1}
                        items={[
                          {
                            contentStyle: {
                              marginTop: "0.7rem",
                            },
                            labelStyle: {
                              marginTop: "1rem",
                            },
                            label: "Name",
                            children: (
                              <Link
                                to={`/dashboard/settings/workflows?workflowIds=${workflow?.workflowId}`}
                                target="_blank"
                              >
                                <Button type="link">
                                  {workflow?.workflowName}
                                </Button>
                              </Link>
                            ),
                          },
                          {
                            contentStyle: {
                              marginTop: "1rem",
                            },
                            labelStyle: {
                              marginTop: "1rem",
                            },
                            label: "Match Base Ref",
                            children: workflow?.matchBaseRef,
                          },
                          {
                            contentStyle: {
                              marginTop: "1rem",
                            },
                            labelStyle: {
                              marginTop: "1rem",
                            },
                            label: "Head Base Ref",
                            children: workflow?.matchHeadRef,
                          },
                          ...(canEditWorkflow
                            ? [
                                {
                                  contentStyle: {
                                    marginTop: "0.7rem",
                                  },
                                  labelStyle: {
                                    marginTop: "1rem",
                                  },
                                  label: null,
                                  children: (
                                    <Button
                                      type="default"
                                      onClick={onSwitchWorkflow}
                                    >
                                      Switch Workflow
                                    </Button>
                                  ),
                                },
                              ]
                            : []),
                        ]}
                      />
                    ) : (
                      <Flex vertical gap="large" style={{ width: 250 }}>
                        <Typography.Text type="warning" strong>
                          No Workflow Linked To This Deployment
                        </Typography.Text>
                        {isEditable && (
                          <Button type="default" onClick={onSwitchWorkflow}>
                            Link Workflow
                          </Button>
                        )}
                      </Flex>
                    )}
                  </>
                ),
              },
              {
                label: "Owners & Reviewers",
                children: (
                  <Descriptions
                    column={1}
                    items={[
                      {
                        label: "Owners",
                        labelStyle: {
                          width: 100,
                        },
                        children: (
                          <Flex vertical gap="middle" style={{ width: "100%" }}>
                            <Flex gap="small" justify="space-between">
                              <Button
                                type="link"
                                onClick={onShowUsers.bind(
                                  this,
                                  `User Owners Of Deployment: ${deploymentName}`,
                                  deployment?.relations?.userOwners ?? []
                                )}
                              >
                                {`${
                                  deployment?.relations?.userOwners?.length ?? 0
                                } Users`}
                              </Button>
                              <Button
                                type="link"
                                onClick={onShowTeams.bind(
                                  this,
                                  `Team Owners Of Deployment: ${deploymentName}`,
                                  deployment?.relations?.teamOwners ?? []
                                )}
                              >
                                {`${
                                  deployment?.relations?.teamOwners?.length ?? 0
                                } Teams`}
                              </Button>
                            </Flex>
                            {isEditable && (
                              <Button type="default" onClick={onUpdatedOwners}>
                                Edit Owners
                              </Button>
                            )}
                          </Flex>
                        ),
                      },
                      {
                        labelStyle: {
                          width: 100,
                          marginTop: 15,
                        },
                        contentStyle: {
                          marginTop: 15,
                        },
                        label: (
                          <Flex justify="space-between" gap="small">
                            Reviewers
                          </Flex>
                        ),
                        children: (
                          <Flex vertical gap="middle" style={{ width: "100%" }}>
                            <Flex gap="small" justify="space-between">
                              <Button
                                type="link"
                                onClick={onShowUsers.bind(
                                  this,
                                  `User Reviewers Of Deployment: ${deploymentName}`,
                                  deployment?.relations?.userReviewers ?? []
                                )}
                              >
                                {`${
                                  deployment?.relations?.userReviewers
                                    ?.length ?? 0
                                } Users`}
                              </Button>
                              <Button
                                type="link"
                                onClick={onShowTeams.bind(
                                  this,
                                  `Team Reviewers Of Deployment: ${deploymentName}`,
                                  deployment?.relations?.teamReviewers ?? []
                                )}
                              >
                                {`${
                                  deployment?.relations?.teamReviewers
                                    ?.length ?? 0
                                } Teams`}
                              </Button>
                            </Flex>
                            {isEditable && (
                              <Button
                                type="default"
                                onClick={onUpdatedReviewers}
                              >
                                Edit Reviewers
                              </Button>
                            )}
                          </Flex>
                        ),
                      },
                    ]}
                  />
                ),
              },
              {
                label: "Deployment Features",
                children: (
                  <Flex
                    vertical
                    gap="middle"
                    justify="flex-start"
                    style={{ marginTop: 10, width: 300 }}
                  >
                    {!!deployment?.features?.length &&
                      environment?.account ===
                        EnvironmentAccount.PRODUCTION && (
                        <Alert
                          type="info"
                          message="These features wont be applied when deployment is linked to a production environment"
                        />
                      )}
                    {deployment?.features?.map((feature) => (
                      <Tag
                        key={feature}
                        color={deploymentFeatureColorMapping[feature]}
                        style={{ fontSize: "0.65em" }}
                      >
                        {mapEnumValueToKey(DeploymentFeature, feature)}
                      </Tag>
                    ))}
                    {!deployment?.features?.length && (
                      <Typography.Text>No Features</Typography.Text>
                    )}
                    {isEditable &&
                      environment?.account ===
                        EnvironmentAccount.DEVELOPMENT && (
                        <Button type="default" onClick={onUpdatedFeatures}>
                          Edit
                        </Button>
                      )}
                  </Flex>
                ),
              },
            ]}
          />
        </Card>
      </Flex>
    </>
  );
};
