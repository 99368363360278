import {
  LoaderFunctionArgs,
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import { RootLayout } from "./layouts/RootLayout/RootLayout";
import { ConfigProvider, Spin, theme } from "antd";
import { useAtomValue } from "jotai";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "urql";
import { graphQLClient } from "./providers/graphql/client";
import { globalStyleAtom } from "./state/global.style/global.style";
import { UserJSON } from "./types/user/user.type";
import LoginPage from "./pages/login/Login.page";
import Error404Page from "./pages/errors/404.page";
import UsersPage from "./pages/dashboard/iam/users/Users.page";
import { meQuery } from "./providers/graphql/queries/me.query";
import GroupsPage from "./pages/dashboard/iam/groups/Groups.page";
import TeamsPage from "./pages/dashboard/teams/Teams.page";
import VPNPage from "./pages/dashboard/security/vpn/VPN.page";
import RepositoriesPage from "./pages/dashboard/settings/repositories/Repositories.page";
import WorkflowsPage from "./pages/dashboard/settings/workflows/Workflows.page";
import ReleasesPage from "./pages/dashboard/releases/Releases.page";
import RunsPage from "./pages/dashboard/more/runs/Runs.page";
import NewReleasePage from "./pages/dashboard/releases/new/NewRelease.page";
import { RepositoryJSON } from "./types/repository/repository.type";
import { getAllRepositoriesQuery } from "./providers/graphql/queries/getAllRepositories.query";
import { isDarkThemeAtom } from "./state/theme/theme";
import { ContextLayout } from "./layouts/ContextLayout/ContextLayout";
import ReleasePage from "./pages/dashboard/releases/[releaseName]/Release.page";
import PRStatusOverridePage from "./pages/dashboard/more/pr-status-override/PRStatusOverride.page";
import NewPRStatusOverridePage from "./pages/dashboard/more/pr-status-override/new/NewPRStatusOverride.page";
import DatabaseMigrationsPage from "./pages/dashboard/migrations/database/DatabaseMigrations.page";
import EnvironmentsPage from "./pages/dashboard/environments/Environments.page";
import NewEnvironmentPage from "./pages/dashboard/environments/new/NewEnvironment.page";
import UpdateEnvironmentPage from "./pages/dashboard/environments/[environmentId]/UpdateEnvironment.page";
import { getAllWorkflowsQuery } from "./providers/graphql/queries/getAllWorkflows.query";
import { WorkflowJSON } from "./types/workflow/workflow.type";
import DeploymentsPage from "./pages/dashboard/deployments/Deployments.page";
import NewDeploymentPage from "./pages/dashboard/deployments/new/NewDeployment.page";
import DeploymentPage from "./pages/dashboard/deployments/[deploymentId]/Deployment.page";
import TestsPage from "./pages/dashboard/quality-assurance/tests/Tests.page";
import NewTestPage from "./pages/dashboard/quality-assurance/tests/new/NewTest.page";

const repositoriesAndWorkflowLoader = async (_args: LoaderFunctionArgs) => {
  const allRepositoriesResult = await graphQLClient
    .query<{ allRepositories: RepositoryJSON[] }>(
      getAllRepositoriesQuery({
        fields: [
          "id",
          "repositoryName",
          "repositoryAlias",
          "mainRef",
          "characteristics",
          "features",
          "testDependancies",
          "deploymentDependancies",
          "statusChecksNotToOverride",
          "countries",
          {
            deploymentUrls: ["label", "value"],
            countryOptions: ["enabledForDeployments", "enabledForReleases"],
            tests: ["name", "options"],
            buildSettings: [
              "runPlatform",
              "runPlatformSettings",
              "statusCheck",
            ],
            deploySettings: [
              "runPlatform",
              "runPlatformSettings",
              "statusCheck",
            ],
          },
        ],
      }),
      {}
    )
    .toPromise();

  const allWorkflowsResult = await graphQLClient
    .query<{ allWorkflows: WorkflowJSON[] }>(
      getAllWorkflowsQuery({
        fields: [
          "id",
          "workflowId",
          "workflowName",
          "matchBaseRef",
          "matchHeadRef",
        ],
      }),
      {}
    )
    .toPromise();

  return {
    repositories: allRepositoriesResult?.data?.allRepositories ?? null,
    workflows: allWorkflowsResult?.data?.allWorkflows ?? null,
  };
};

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    Component: RootLayout,
    children: [
      {
        index: true,
        Component: LoginPage,
      },
      {
        path: "*",
        Component: Error404Page,
      },
    ],
  },
  {
    id: "dashboard",
    path: "/dashboard",
    Component: RootLayout,
    loader: async (_args: LoaderFunctionArgs) => {
      const result = await graphQLClient
        .query<{ me?: UserJSON }>(
          meQuery({
            fields: [
              "id",
              "fullName",
              "photo",
              "email",
              "gitId",
              "notificationId",
              "roles",
              {
                relations: [
                  {
                    groups: ["id", "groupName", "roles"],
                  },
                  "roles",
                ],
              },
            ],
          }),
          {}
        )
        .toPromise()
        .catch(() => null);

      const pathname = window.location.pathname;

      if (!result || result?.error?.response?.status === 401)
        return redirect(`/?to=${pathname}`);

      return {
        user: result.data?.me ?? null,
      };
    },
    children: [
      {
        index: true,
      },
      {
        path: "/dashboard/iam/users",
        Component: UsersPage,
      },
      {
        path: "/dashboard/iam/groups",
        Component: GroupsPage,
      },
      {
        path: "/dashboard/teams",
        Component: TeamsPage,
      },
      {
        path: "/dashboard/security/vpn",
        Component: VPNPage,
      },
      {
        path: "/dashboard/settings/repositories",
        Component: RepositoriesPage,
      },
      {
        path: "/dashboard/settings/workflows",
        Component: WorkflowsPage,
      },
      {
        id: "dashboard-releases",
        path: "/dashboard/releases",
        Component: ContextLayout,
        loader: repositoriesAndWorkflowLoader,
        children: [
          {
            index: true,
            Component: ReleasesPage,
          },
          {
            path: "/dashboard/releases/new",
            Component: NewReleasePage,
          },
          {
            path: "/dashboard/releases/:releaseName",
            Component: ReleasePage,
          },
        ],
      },
      {
        id: "dashboard-more",
        path: "/dashboard/more",
        Component: ContextLayout,
        loader: repositoriesAndWorkflowLoader,
        children: [
          {
            path: "/dashboard/more/runs",
            Component: RunsPage,
          },
          {
            path: "/dashboard/more/pr-status-overrides",
            Component: PRStatusOverridePage,
          },
          {
            path: "/dashboard/more/pr-status-overrides/new",
            Component: NewPRStatusOverridePage,
          },
        ],
      },
      {
        path: "/dashboard/migrations/database",
        Component: DatabaseMigrationsPage,
      },
      {
        path: "/dashboard/environments",
        Component: EnvironmentsPage,
      },
      {
        path: "/dashboard/environments/new",
        Component: NewEnvironmentPage,
      },
      {
        path: "/dashboard/environments/:environmentId",
        Component: UpdateEnvironmentPage,
      },
      {
        id: "dashboard-deployments",
        path: "/dashboard/deployments",
        Component: ContextLayout,
        loader: repositoriesAndWorkflowLoader,
        children: [
          {
            index: true,
            Component: DeploymentsPage,
          },
          {
            path: "/dashboard/deployments/new",
            Component: NewDeploymentPage,
          },
          {
            path: "/dashboard/deployments/:deploymentId",
            Component: DeploymentPage,
          },
        ],
      },
      {
        id: "dashboard-quality-assurance-tests",
        path: "/dashboard/quality-assurance/tests",
        Component: ContextLayout,
        loader: repositoriesAndWorkflowLoader,
        children: [
          {
            index: true,
            Component: TestsPage,
          },
          {
            path: "/dashboard/quality-assurance/tests/new",
            Component: NewTestPage,
          },
        ],
      },
      {
        path: "*",
        Component: Error404Page,
      },
    ],
    errorElement: <>Error</>,
  },
]);

const App = (): JSX.Element => {
  const isDarkTheme = useAtomValue(isDarkThemeAtom);

  const globalStyle = useAtomValue(globalStyleAtom);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
      <ConfigProvider
        theme={{
          algorithm: isDarkTheme ? theme.darkAlgorithm : theme.defaultAlgorithm,
          token: {
            fontSize: 14,
            fontSizeLG: 16,
            fontSizeSM: 12,
            fontFamily:
              "Segoe UI,SegoeUI,Helvetica Neue,Helvetica,Arial,sans-serif;",
            colorPrimary: globalStyle.primaryColor,
          },
          components: {
            Menu: {
              iconSize: 16,
            },
          },
        }}
      >
        <Provider value={graphQLClient}>
          <RouterProvider
            router={router}
            fallbackElement={<Spin fullscreen />}
          />
        </Provider>
      </ConfigProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
