import { App, Button, Dropdown, MenuProps, Modal } from "antd";
import { useMutation } from "urql";
import { useNavigate } from "react-router-dom";
import { EnvironmentJSON } from "../../types/environment/environment.type";
import { Nullable } from "../../utils/nullable/nullable.type";
import { useError } from "../../hooks/useError";
import { useUserContext } from "../../context/UserContext";
import { deleteEnvironmentMutation } from "../../providers/graphql/mutations/deleteEnvironment.mutation";
import { hibernateEnvironmentMutation } from "../../providers/graphql/mutations/hibernateEnvironment.mutation";
import { expireEnvironmentMutation } from "../../providers/graphql/mutations/expireEnvironment.mutation";
import { Role } from "../../types/role/role.enum";
import { SuspendEnvironmentHibernationForm } from "../SuspendEnvironmentHibernationForm/SuspendEnvironmentHibernationForm";
import { triggerEnvironmentSyncMutation } from "../../providers/graphql/mutations/triggerEnvironmentSync.mutation";
import { EnvironmentAccount } from "../../types/environment/environment.enum";

export type EnvironmentsMoreButtonProps = {
  environment: EnvironmentJSON;
  onRefetch: () => void;
  buttonText?: string;
};

export const EnvironmentsMoreButton = ({
  buttonText,
  environment,
  onRefetch,
}: EnvironmentsMoreButtonProps): Nullable<JSX.Element> => {
  const { modal, notification, message } = App.useApp();

  const { errorNotificationMessage } = useError();

  const { user } = useUserContext();

  const roles = user?.relations?.roles ?? [];

  const navigate = useNavigate();

  const [{ fetching: isDeletingEnvironment }, deleteEnvironment] = useMutation(
    deleteEnvironmentMutation()
  );

  const [{ fetching: isHibernatingEnvironment }, hibernateEnvironment] =
    useMutation(hibernateEnvironmentMutation());

  const [{ fetching: isTriggeringEnvironmentSync }, triggerEnvironmentSync] =
    useMutation(triggerEnvironmentSyncMutation());

  const [{ fetching: isExpiringEnvironment }, expireEnvironment] = useMutation(
    expireEnvironmentMutation()
  );

  const fetching =
    isDeletingEnvironment ||
    isHibernatingEnvironment ||
    isTriggeringEnvironmentSync ||
    isExpiringEnvironment;

  const items = ((): MenuProps["items"] => {
    const items: MenuProps["items"] = [];

    if (environment.expired) {
      return items;
    }

    if (roles.includes(Role.UPDATE_ENVIRONMENTS)) {
      if (!environment.expired) {
        items.push({
          key: "update-environment",
          label: "Update environment",
        });
      }

      if (environment.hibernating) {
        items.push({
          key: "suspend-hibernation",
          label: "Suspend hibernation",
        });
      } else {
        if (environment.account === EnvironmentAccount.DEVELOPMENT) {
          items.push({
            key: "trigger-environment-sync",
            label: "Trigger environment sync",
          });
        }

        items.push({
          key: "hibernate-environment",
          label: "Hibernate environment",
        });
      }
    }

    if (roles.includes(Role.DELETE_ENVIRONMENTS)) {
      items.push({
        key: "expire-environment",
        label: "Expire environment",
      });

      items.push({
        key: "delete-environment",
        label: "Delete environment",
      });
    }

    return items;
  })();

  const onMenuItemSelected = ({ key }: { key: string }): void => {
    switch (key) {
      case "update-environment":
        navigate(
          `/dashboard/environments/${encodeURI(environment.environmentId)}`
        );
        break;
      case "trigger-environment-sync":
        modal.confirm({
          icon: null,
          title: "Trigger Environment Sync",
          content: `Are you sure you want to trigger environment sync for: ${environment.environmentId}?`,
          okButtonProps: {
            style: {
              backgroundColor: "purple",
            },
          },
          okText: "Sync",
          onOk: async (): Promise<void> => {
            const { error } = await triggerEnvironmentSync({
              triggerEnvironmentSyncInput: {
                environmentId: environment.environmentId,
              },
            });

            if (error) {
              notification.error(errorNotificationMessage(error));
              return;
            }

            message.success("Environment sync triggered successfully");

            onRefetch();

            Modal.destroyAll();
          },
        });
        break;
      case "suspend-hibernation":
        modal.info({
          icon: null,
          width: "50%",
          title: "Suspend Hibernation",
          content: (
            <SuspendEnvironmentHibernationForm
              environment={environment}
              onRefetch={onRefetch}
            />
          ),
          okButtonProps: {
            type: "default",
          },
          okText: "Cancel",
        });
        break;
      case "hibernate-environment":
        modal.confirm({
          icon: null,
          title: "Hibernate Environment",
          content: `Are you sure you want to hibernate environment: ${environment.environmentId}?`,
          okButtonProps: {
            style: {
              backgroundColor: "purple",
            },
          },
          okText: "Hibernate Environment",
          onOk: async (): Promise<void> => {
            const { error } = await hibernateEnvironment({
              hibernateEnvironmentInput: {
                environmentId: environment.environmentId,
              },
            });

            if (error) {
              notification.error(errorNotificationMessage(error));
              return;
            }

            message.success("Environment set to hibernated successfully");

            onRefetch();

            Modal.destroyAll();
          },
        });
        break;
      case "expire-environment":
        modal.confirm({
          icon: null,
          title: "Expire Environment",
          content: `Are you sure you want to expire environment: ${environment.environmentId}?`,
          okButtonProps: {
            danger: true,
          },
          okText: "Expire Environment",
          onOk: async (): Promise<void> => {
            const { error } = await expireEnvironment({
              expireEnvironmentInput: {
                environmentId: environment.environmentId,
              },
            });

            if (error) {
              notification.error(errorNotificationMessage(error));
              return;
            }

            message.success("Environment set to hibernated successfully");

            onRefetch();

            Modal.destroyAll();
          },
        });
        break;
      case "delete-environment":
        modal.confirm({
          icon: null,
          title: "Delete Environment",
          content: `Are you sure you want to delete environment: ${environment.environmentId}?`,
          okButtonProps: {
            danger: true,
          },
          okText: "Delete Environment",
          onOk: async (): Promise<void> => {
            const { error } = await deleteEnvironment({
              deleteEnvironmentInput: {
                environmentId: environment.environmentId,
              },
            });

            if (error) {
              notification.error(errorNotificationMessage(error));
              return;
            }

            message.success("Environment deleted successfully");

            onRefetch();

            Modal.destroyAll();
          },
        });
        break;
      default:
        break;
    }
  };

  if (!items?.length) {
    return null;
  }

  return (
    <Dropdown
      menu={{
        items,
        onClick: onMenuItemSelected,
        disabled: fetching,
        style: { width: 250 },
      }}
      placement="bottom"
      arrow
    >
      <Button loading={fetching} type="primary">
        {buttonText ?? "More"}
      </Button>
    </Dropdown>
  );
};
