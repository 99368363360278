import { CSSProperties, useState } from "react";
import { App, Button, Flex, Input } from "antd";
import { Nullable } from "../../utils/nullable/nullable.type";
import { GitRefTag } from "../../types/git/git.type";
import { getAllTagsQuery } from "../../providers/graphql/queries/getAllTags.query";
import { useError } from "../../hooks/useError";
import { graphQLClient } from "../../providers/graphql/client";

export type TagVersionInputProps = {
  style?: CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
  repositoryName?: string;
};

export const TagVersionInput = ({
  style,
  value,
  onChange,
  repositoryName,
}: TagVersionInputProps): Nullable<JSX.Element> => {
  const { notification } = App.useApp();

  const { errorNotificationMessage } = useError();

  const [fetching, setFetching] = useState<boolean>(false);

  const OnGenerateNewTagSuggestion = async (): Promise<void> => {
    setFetching(true);

    const { data, error } = await graphQLClient
      .query<{
        allTags: GitRefTag[];
      }>(
        getAllTagsQuery({
          fields: ["ref"],
        }),
        {
          allTagsInput: {
            repositoryName,
          },
        },
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise();

    setFetching(false);

    if (error) {
      notification.error(errorNotificationMessage(error));
    }

    const tags = data?.allTags ?? [];

    if (!tags.length) {
      onChange && onChange("v1.0.0");
      return;
    }

    const tag = tags.find((tag) =>
      new RegExp("v[0-9]+.[0-9]+.[0-9]+").test(tag.ref)
    );

    if (!tag) {
      onChange && onChange("v1.0.0");
      return;
    }

    const [firstPart, secondPart, thirdPart] = tag.ref.split(".");

    onChange && onChange(`${firstPart}.${secondPart}.${Number(thirdPart) + 1}`);
  };

  return (
    <Flex gap="middle">
      <Input
        disabled={fetching ? true : undefined}
        style={style}
        placeholder="v1.0.0"
        value={value}
        onChange={(event) => onChange && onChange(event.target.value)}
      />
      <Button
        loading={fetching}
        type="primary"
        onClick={OnGenerateNewTagSuggestion}
      >
        New Tag Suggestion
      </Button>
    </Flex>
  );
};
