import { Button, Divider, Flex, Form, Typography } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { SelectTeamInput } from "../../../../../SelectTeamInput/SelectTeamInput";

export const SelectTeamsSection = (): JSX.Element => {
  return (
    <>
      <Form.List name="teams">
        {(fields, { add, remove }) => (
          <>
            <Flex justify="space-between" align="center">
              <div></div>
              <Flex vertical>
                <Typography.Title
                  level={3}
                  style={{ display: "block", textAlign: "center" }}
                >
                  Team Selection
                </Typography.Title>
                <Typography.Text
                  type="secondary"
                  style={{ display: "block", textAlign: "center" }}
                >
                  Select the teams that will have access to this environment
                </Typography.Text>
              </Flex>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
              >
                Add New Team
              </Button>
            </Flex>
            <Divider />
            {fields.map((_, index) => {
              return (
                <div key={index}>
                  <Flex align="center" justify="space-between">
                    <Typography.Title level={2} type="secondary">
                      Team {index + 1}
                    </Typography.Title>
                    <Button
                      danger
                      type="primary"
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(index)}
                      style={{ marginLeft: "auto" }}
                    >
                      Remove Team
                    </Button>
                  </Flex>
                  <Form.Item
                    label="Team"
                    labelCol={{ span: 24 }}
                    name={[index, "teamName"]}
                    rules={[
                      {
                        required: true,
                        message: "Please select a team first",
                      },
                    ]}
                  >
                    <SelectTeamInput />
                  </Form.Item>
                  <Divider style={{ marginTop: 50 }} />
                </div>
              );
            })}
          </>
        )}
      </Form.List>
    </>
  );
};
