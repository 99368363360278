import { Flex } from "antd";
import { EnvironmentsHeaderSection } from "./components/EnvironmentsHeaderSection/EnvironmentsHeaderSection";
import { EnvironmentsTableSection } from "./components/EnvironmentsTableSection/EnvironmentsTableSection";

const EnvironmentsPage = (): JSX.Element => {
  return (
    <Flex vertical gap="middle" style={{ width: "100%", height: "100%" }}>
      <EnvironmentsHeaderSection />
      <EnvironmentsTableSection />
    </Flex>
  );
};

export default EnvironmentsPage;
