import { UserOutlined } from "@ant-design/icons";
import {
  App,
  Avatar,
  Button,
  Empty,
  Flex,
  Modal,
  Spin,
  Transfer,
  Typography,
} from "antd";
import { TransferDirection } from "antd/es/transfer";
import { Key, useState } from "react";
import { UserJSON } from "../../../../../../../../../types/user/user.type";
import { TeamJSON } from "../../../../../../../../../types/team/team.type";
import { useError } from "../../../../../../../../../hooks/useError";
import { PaginationResponse } from "../../../../../../../../../types/pagination/pagination.type";
import { useMutation, useQuery } from "urql";
import { getPaginateUsersQuery } from "../../../../../../../../../providers/graphql/queries/getPaginateUsers.query";
import { ErrorView } from "../../../../../../../../../components/ErrorView/ErrorView";
import { updateUsersInTeamMutation } from "../../../../../../../../../providers/graphql/mutations/updateUsersInTeam.mutation";

export type UpdateUsersInTeamFormProps = {
  users: UserJSON[];
  team: TeamJSON;
  onRefreshTeams: () => void;
};

export const UpdateUsersInTeamForm = ({
  users,
  team,
  onRefreshTeams,
}: UpdateUsersInTeamFormProps): JSX.Element => {
  const { message, notification } = App.useApp();

  const { teamName } = team;

  const [emails, setEmails] = useState<string[]>(team.emails);

  const [leftSearch, setLeftSearch] = useState<string>();

  const { errorNotificationMessage } = useError();

  const [{ data, error, fetching: isFetchingUsers }, refetch] = useQuery<{
    users: PaginationResponse<UserJSON>;
  }>({
    query: getPaginateUsersQuery({
      fields: [
        "total",
        {
          data: ["id", "photo", "fullName", "email"],
        },
      ],
    }),
    variables: {
      userPaginationInput: {
        skip: 0,
        limit: 100,
        ...(leftSearch ? { search: leftSearch } : {}),
      },
    },
    requestPolicy: "network-only",
  });

  const [{ fetching: isUpdatingUsersInTeam }, updateUsersInTeam] = useMutation(
    updateUsersInTeamMutation()
  );

  const fetching = isFetchingUsers || isUpdatingUsersInTeam;

  const onRefetch = async (): Promise<void> => {
    await refetch({ requestPolicy: "network-only" });
  };

  const onSearch = (direction: TransferDirection, value: string): void => {
    if (direction === "left") {
      setLeftSearch(value);
      return;
    }
  };

  const onChange = (
    _targetKeys: Key[],
    direction: TransferDirection,
    moveKeys: Key[]
  ): void => {
    setEmails((previous) => {
      if (direction === "left") {
        return Array.from(
          new Set(previous.filter((email) => !moveKeys.includes(email)))
        );
      }
      return Array.from(new Set([...previous, ...(moveKeys as string[])]));
    });
  };

  const onFilterOption = (
    inputValue: string,
    item: UserJSON,
    direction: TransferDirection
  ): boolean => {
    if (direction === "left") {
      return true;
    }

    return (
      item.fullName.indexOf(inputValue) !== -1 ||
      item.email.indexOf(inputValue) !== -1
    );
  };

  const searchUsers = data?.users.data ?? [];

  const allUsers = [
    ...users,
    ...searchUsers.filter(
      (searchUser) =>
        users.findIndex((user) => user.email === searchUser.email) === -1
    ),
  ];

  const onUpdate = async (): Promise<void> => {
    const { error } = await updateUsersInTeam({
      updateUsersInTeamInput: {
        teamName,
        emails,
      },
    });

    if (error) {
      notification.error(errorNotificationMessage(error));
      return;
    }

    message.success(`Users in team ${teamName} updated successfully`);

    onRefreshTeams();

    Modal.destroyAll();
  };

  return (
    <>
      <Flex justify="space-between" style={{ marginBottom: 25 }}>
        <Typography.Title level={3}>
          {`Update users in team ${teamName}`}
        </Typography.Title>
        <Button loading={fetching} type="primary" onClick={onUpdate}>
          Update
        </Button>
      </Flex>
      <ErrorView hideChildrenOnError error={error} onRefetch={onRefetch}>
        <Transfer
          locale={{
            titles: ["Select users", "Selected users"],
            notFoundContent: [
              isFetchingUsers ? (
                <Spin key="1" />
              ) : (
                <Empty key="1" description="No users to select" />
              ),
              <Empty key="2" description="No users selected" />,
            ],
          }}
          style={{ height: "65vh" }}
          showSearch
          dataSource={allUsers.map((user) => ({
            key: user.email,
            ...user,
          }))}
          listStyle={{ height: "100%", width: "50%" }}
          targetKeys={emails}
          onSearch={onSearch}
          onChange={onChange}
          filterOption={onFilterOption}
          render={(item) => (
            <Flex
              key={item.email}
              align="center"
              gap={"middle"}
              style={{ padding: 15 }}
            >
              <Avatar size={70} src={item.photo} icon={<UserOutlined />} />
              <Flex vertical gap={"small"}>
                <Typography.Text>{item.fullName}</Typography.Text>
                <Typography.Text>{item.email}</Typography.Text>
              </Flex>
            </Flex>
          )}
        />
      </ErrorView>
    </>
  );
};
